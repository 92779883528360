import flatasticUser from '../user/cfg_user';
import flatasticWg from '../wg/cfg_wg';
import mcmAdmob from '../../scripts/modules/mcmAdMob';
import yepptApi from '../../scripts/modules/api';
import yepptCordova from '../../scripts/modules/yptCordova';
import yepptSocialSharing from '../../scripts/modules/yptSocialsharing';

// templates
import template from './view_toaster.html';

export default angular.module('flatastic.toaster', [
  flatasticUser,
  flatasticWg,
  mcmAdmob,
  yepptApi,
  yepptCordova,
  yepptSocialSharing,
])

/*
 * Toaster:
 * {
 *   title:   'Some Title',
 *   image:   'Some Image Url',
 *   message: 'Some Message',
 *   actions: [{
 *     label: 'Some Button Label',
 *     // and one of the following
 *     goTo: ['state.parent', 'state.child'] // Gehe zu bestimmten Status
 *     link: url // öffne Link im Browser
 *     wgsNearBy: boolean // Frage PLZ ab und lass Nutzer weitere WGs einladen
 *     recommend: boolean // Send Sharing-Text via SocialSharing Plugin
 *     review: boolean // Links to Google Play or App Store depending on platform
 *     close: boolean // Close toaster without action
 *     showAd: boolean // Show an interstitial ad
 *     perform: function() {} // Beliebige Funktion
 *   }, …]
 * }
 *
 */

.directive('toaster',
    ['$timeout', '$rootScope', 'yptCordova', '$state', '$q', 'Wg', 'yptNotify',
    'yptSocialsharing', 'Api', 'User', '$filter', '$window', 'AdMob',
    function ($timeout, $rootScope, yptCordova, $state, $q, Wg, yptNotify,
      yptSocialsharing, Api, User, $filter, $window, AdMob) {

  return {
    restrict: 'E',
    replace: true,
    scope: {},
    controller: function($scope) {

      // Check on start, if there is something to show
      if (User.isLoggedIn()) {
        Api.get('/toaster')
        .success(function(data) {
          if (data !== 'false') {
            console.log('Show Toaster', data);
            $timeout(function() {
              $scope.$broadcast('toaster-show', data);
            }, 1500);
          }
        });
      }

      $scope.isToasterShown = false;

      $scope.toasterClose = function() {
        $scope.isToasterShown = false;
        $timeout(function() {
          delete $scope.toaster;
        }, 1000);
      };

      $scope.$on('toaster-close', function() {
        console.log('toaster-close');
        $scope.toasterClose();
      });

      $scope.$on('toaster-show', function(event, toasterObj) {
        if ($scope.isToasterShown === true) {
          console.debug('Toaster: Not showing. Another toaster is already shown');
          return;
        }
        if (!angular.isArray(toasterObj.actions)) {
          console.debug('Toaster: Property actions is not an array.');
          return;
        }
        $scope.isToasterShown = true;
        $scope.toaster = toasterObj;

        /* Show all possibile functions */
        $scope.toaster.actions = toasterObj.actions.map(function(action) {
          var originalAction = action.perform || noop;

          // preimplemented functionalities
          if (action.close) {
            originalAction = noop;
          } else if (action.link) {
            originalAction = openLink(action.link);
          } else if (action.wgsNearBy) {
            originalAction = !Wg.postCode ? askForZip(showWgsNearby) : showWgsNearby;
          } else if (action.goTo) {
            originalAction = goTo(action.goTo);
          } else if (action.recommend) {
            originalAction = recommendViaSocialShare;
          } else if (action.review) {
            originalAction = askForReview;
          } else if (action.showAd) {
            originalAction = showAd;
          }
          action.perform = handleToasterInteraction(toasterObj, originalAction, action);

          return action;
        });
      });

      //// Functions

      function noop() {}

      function showAd() {
        return AdMob.getInterstitial()
          .then(AdMob.showInterstitialAd)
          .catch(console.error);
      }

      function openLink(url) {
        return function() {
          var link = url || 'https://flatastic-app.com?utm_source=flatastic&utm_medium=app&utm_campaign=toaster';
          var inApp = getQueryParameterByName('inapp', link);
          yptCordova.openLink(link, inApp);
        }
      }

      function handleToasterInteraction(toasterObj, callback, action) {
        return function() {
          callback();
          $scope.$broadcast('toaster-close');
          if (!toasterObj.id) { return; }
          trackToasterInteraction(toasterObj, action);
        }
      }

      function trackToasterInteraction(toasterObj, action) {
        Api.post('/toaster/action', {
          toasterId: toasterObj.id,
          action: action.label,
        });
        $rootScope.$broadcast('track-event', {
          category: 'Toaster',
          action: 'Did click',
          label: toasterObj.id + ' - ' + toasterObj.title + ' - ' + action.label,
        });
      }

      function showWgsNearby() {
        // Alert 'Es gibt noch nicht genug WGs'
        yptNotify.confirm({
          title: $filter('translate')('TSR__NEAR_WGS-TITLE'),
          message: $filter('translate')('TSR__NEAR_WGS-MSG'),
          buttonLabels: [
            $filter('translate')('LATER'),
            $filter('translate')('TSR__NEAR_WGS-BTN_SHARE'),
          ],
          callback: function(answer) {
            if (answer === true) {
              recommendViaSocialShare();
            }
          },
        })
      }

      function goTo(destination) {
        return function() {
          if (!angular.isArray(destination)) {
            destination = [destination];
          }
          var first = destination.shift();
          destination.reduce(function(pre, cur) {
            var deferred = $q.defer();
            $timeout(function() {
              $state.go(cur).then(function() {
                deferred.resolve();
              });
            });
            return deferred.promise;
          }, $state.go(first));
        }
      }

      function getQueryParameterByName(name, url) {
        var match = RegExp('[?&]' + name + '=([^&]*)').exec(url);
        return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
      }

      function askForReview() {
        if (!$window.cordova) {
          yptCordova.openLink('https://flatastic-app.com?utm_source=flatastic&utm_medium=app&utm_campaign=toaster');
        } else if ($window.cordova.platformId === 'android') {
          yptCordova.openLink('https://play.google.com/store/apps/details?id=com.flatastic.app&utm_source=flatastic&utm_medium=app&utm_campaign=toaster');
        } else if ($window.cordova.platformId === 'ios') {
          yptCordova.openLink('itms-apps://itunes.apple.com/app/id840810742');
        }
      }

      function recommendViaSocialShare() {
        yptSocialsharing.share({
          subject: $filter('translate')('TSR__NEAR_WGS-SHARE_SUBJ'),
          message: $filter('translate')('TSR__NEAR_WGS_SHARE-MSG'),
          url: 'https://flatastic-app.com?utm_source=flatastic&utm_medium=app&utm_campaign=recommend',
          trackEvent: {
            name: 'Share',
            parameters: {
              context: 'wgsnearby',
              content: $filter('translate')('TSR__NEAR_WGS-SHARE_SUBJ'),
            },
          },
        });
      }

      function askForZip() {
        return function() {
          yptNotify.prompt({
            title: $filter('translate')('TSR__NEAR_WGS_ZIP-TITLE'),
            message: $filter('translate')('TSR__NEAR_WGS_ZIP-MSG'),
            buttonLabels: [
              $filter('translate')('BTN_CANCEL'),
              $filter('translate')('CONTINUE'),
            ],
          })
          .success(function(input) {
            Wg.save({ postCode: input });
            $timeout(function() {
              procedure();
            }, 900);
          });
        }
      }
    },
    template,
    link: function (scope, iElement, iAttrs) {
      iElement.ready(function() {
        iElement.css('height', iElement[0].offsetHeight + 'px');
      });
    },
  };

}])

.name;
