import { ifElse, contains, without, append, curry } from 'ramda'

/*
 * Taken from: http://stackoverflow.com/questions/1985260/javascript-array-rotate
 */
Array.prototype.rotate = (function() {
    var unshift = Array.prototype.unshift;
    var splice = Array.prototype.splice;

    return function(count) {
        var len = this.length >>> 0;
        var count = count >> 0;

        unshift.apply(this, splice.call(this, count % len, len));
        return this;
    };
})();

/*
 * get the clean date of a date
 */
Date.prototype.makeKeyDate = function() {
  this.setHours(0);
  this.setMinutes(0);
  this.setSeconds(0);
  this.setMilliseconds(0);
  return this;
};

export const toggleItemInList = curry((list, value) => ifElse(
  contains(value),
  without([value]),
  append(value)
)(list))
