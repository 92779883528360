import FeedbackService from './svc_feedback'
import CordovaService from '../../scripts/modules/yptCordova'

export default angular.module('flatastic.feedback.controller', [
  FeedbackService,
  CordovaService,
  'ionic',
])

.controller('FeedbackCtrl',
    ['$scope', 'Feedback', 'yptNotify', '$filter', '$rootScope', '$ionicHistory',
    function ($scope, Feedback, yptNotify, $filter, $rootScope, $ionicHistory) {
  $scope.message = {
    text: '',
  };
  $scope.hasBackView = $ionicHistory.backView;
  $scope.feedback = Feedback;
  $scope.sendFeedback = function() {
    var msg = $scope.message.text;
    console.log('$scope.message.text', $scope.message.text);
    Feedback.submit($scope.message.text)
    .success(function(data) {
      yptNotify.alert({
        title: $filter('translate')('FDB__CONFIRM_SUCCESS-TITLE'),
        message: $filter('translate')('FDB__CONFIRM_SUCCESS-MSG'),
      });
    })
    .error(function(data) {
      $scope.message.text = msg;
      var temp = [];
      for (var key in data) {
        temp.push(data[key]);
      }
      yptNotify.alert({
        title: $filter('translate')('FDB__CONFIRM_ERROR-TITLE'),
        message: $filter('translate')('FDB__CONFIRM_ERROR-MSG', {
          error: data ? temp.join('\n') : $filter('translate')('NO_INTERNET'),
        }),
      });
    });
    $scope.message.text = '';
  };

  $scope.showHelp = function() {
    yptNotify.alert({
      title: $filter('translate')('FDB__HELP-TITLE'),
      message: $filter('translate')('FDB__HELP-MSG'),
      trackEvent: {
        name: 'showHelp',
        parameters: {
          context: 'feedback',
        },
      },
    });
    $rootScope.$broadcast('track-event', {
      category: 'Help',
      action: 'Clicked',
      label: '/feedback',
    });
  };
}])

.name;
