import flatasticWgService from './svc_wg';
import yepptCordova from '../../scripts/modules/yptCordova';
import yepptSocialSharing from '../../scripts/modules/yptSocialsharing';

export default angular.module('flatastic.wg.controller.invite', [
  'ionic',
  flatasticWgService,
  yepptCordova,
  yepptSocialSharing,
])

.controller('WgInviteCtrl',
    ['$scope', 'yptNotify', 'Wg', '$ionicLoading', 'yptSocialsharing', '$filter',
    function ($scope, yptNotify, Wg, $ionicLoading, yptSocialsharing, $filter) {

  $scope.inviteData = Wg.getInviteScaffold();
  $scope.flatmates = Wg.flatmates;
  $scope.invitedFlatmate = false;
  $scope.invite = function() {
    $ionicLoading.show({
      template: $filter('translate')('WG__INVITE_FLATMATE-LOADING', {
        name: $scope.inviteData.firstName,
      }),
    });
    Wg.inviteFlatmate($scope.inviteData)
    .success(function(data) {
      $ionicLoading.hide();
      yptNotify.alert({
        title: $filter('translate')('WG__INVITE_FLATMATE_SUCCESS-TITLE'),
        message: $filter('translate')('WG__INVITE_FLATMATE_SUCCESS-TITLE', {
          name: data.newFlatmate.firstName,
          wgCode: data.wgCode,
        }),
        buttonLabel: $filter('translate')('WG__INVITE_FLATMATE_SUCCESS-SHARE_CODE'),
        callback: function() {
          $scope.shareCode({
            wgCode: data.wgCode,
            firstName: data.newFlatmate.firstName,
          });
        },
      });
      $scope.inviteData = Wg.getInviteScaffold();
    });
  };
  $scope.shareCode = function(invitedFlatmate) {
    yptSocialsharing.share({
      message: $filter('translate')('WG__SHARE_WG_CODE-MSG', {
        name: invitedFlatmate.firstName,
        wgCode: invitedFlatmate.wgCode,
        trackEvent: {
          name: 'Share',
          parameters: {
            context: 'wginvitemodal',
            content: $filter('translate')('SET__SHARE-TITLE'),
          },
        },
      }),
      subject: $filter('translate')('WG__SHARE_WG_CODE-SUBJ'),
    })
    .success(function(res) {
      if (res) {
        yptNotify.alert({
          message: $filter('translate')('WG__SHARE_WG_CODE_SUCCESS-MSG', {
            name: invitedFlatmate.firstName,
          }),
          title: $filter('translate')('WG__SHARE_WG_CODE_SUCCESS-TITLE'),
        });
      } else {
        yptNotify.alert({
          message: $filter('translate')('WG__INVITE_FLATMATE_NOT_SHARED-MSG', {
            name: invitedFlatmate.firstName,
          }),
          title: $filter('translate')('WG__INVITE_FLATMATE_NOT_SHARED-TITLE'),
        });
      }
      console.log('Clicked on share', res);
    })
    .error(function(err) {
      console.log('wg-code not shared.', err);
    });
  };

}])

.name;
