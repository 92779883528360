import flatasticConfig from '../../scripts/config';
import flatasticExpensesServiceExpenseItem from './svc_expense-item';
import yepptApi from '../../scripts/modules/api';
import yepptCollection from '../../scripts/modules/collection';

export default angular.module('flatastic.expenses.service.expense', [
  flatasticConfig,
  flatasticExpensesServiceExpenseItem,
  yepptApi,
  yepptCollection,
])

.factory('Expense',
    ['YepptCollection', 'Api', '$rootScope', 'ExpenseItem', '$filter', 'FlatasticEvents',
    function (YepptCollection, Api, $rootScope, ExpenseItem, $filter, FlatasticEvents) {

  function Expense(initObj) {
    this.set(initObj);
    // Defaults
    this.items    = this.items    || new YepptCollection('id');
    this.totalSum = this.totalSum || 0;
    this.sum      = this.sum      || 0;
  }

  Expense.prototype.set = function(obj) {
    var self = this;
    for (var key in obj) {
      switch (key) {
        case 'items':
          for (var i = 0; i < obj['items'].length; i++) {
            if (obj['items'][i] instanceof ExpenseItem === false) {
              obj['items'][i] = new ExpenseItem(obj['items'][i]);
            }
          }
          self.items = new YepptCollection('id', obj['items']);
          break;
        case 'date':
          self.date = (obj[key] instanceof Date) ?
              obj[key] :
              new Date(obj[key] * 1000);
          break;
        default:
          self[key] = obj[key];
      }
    }
  };

  Expense.prototype.toCollection = function() {
    var self = this;
    $rootScope.$broadcast('Expense:create', self);
    return self;
  };
  Expense.prototype.removeFromCollection = function() {
    var self = this;
    $rootScope.$broadcast('Expense:deleted', self.id);
    return self;
  };

  /*
   * This is the json format we need for the backend
   *
   * {
   *    "name":"Kaisers",
   *    "date":"1411128969",
   *    "items":[
   *      {
   *        "sharers":[
   *        "2", "3", "4"
   *        ],
   *        "name":"Milch",
   *        "quantity":"2",
   *        "price":"1,98"
   *      }
   *    ]
   *  }
   */
  Expense.prototype.rawData = function() {
    var self = this;
    return {
      name: self.name,
      date: parseInt(self.date.getTime() / 1000 + 0.5),
      items: self.items.get(),
    };
  };

  Expense.prototype.setDate = function(date) {
    var self = this;
    self.set({ date: date });
  };

  Expense.prototype.create = function() {
    var self = this;
    self.status = 'saving';
    $rootScope.$broadcast(FlatasticEvents.expenses.willCreate);
    return Api.post('/cashflow', self.rawData())
    .success(function(data) {
      delete self.status;
      delete self.error;
      delete self.local;
      self.set(data);
      $rootScope.$broadcast(FlatasticEvents.expenses.didCreate);
      $rootScope.$broadcast('Expense:updated');
    })
    .error(function(data) {
      console.error('ERROR: POST /cashflow', data);
      self.error = data.error || $filter('translate')('NO_INTERNET');
      delete self.status;
    });
  };
  Expense.prototype.edit = function() {
    var self = this;
    self.status = 'updating';
    return Api.post('/cashflow/id/' + self.id, self.rawData())
    .success(function(data) {
      // an update is actually a delete-->insert
      // therefore set everything new
      self.set(data);
      delete self.status;
      delete self.error;
      $rootScope.$broadcast('Expense:updated');
    })
    .error(function(data) {
      self.error = data.error || $filter('translate')('NO_INTERNET');
      delete self.status;
    });
  };

  Expense.prototype.delete  = function() {
    var self = this;
    self.status = 'deleting…';
    return Api.delete('/cashflow/id/' + self.id)
    .success(function(data) {
      $rootScope.$broadcast('Expense:updated');
      delete self.status;
      delete self.error;
    })
    .error(function(data) {
      self.error = data;
    });
  };

  Expense.prototype.addItem = function(expenseItem) {
    var self = this;
    self.items.push(expenseItem, true);
    // update
    self.update(self.creatorId);
  };
  Expense.prototype.removeItem = function(expenseItem) {
    var self = this;
    self.items.remove(expenseItem.id);
    // update
    self.update(self.creatorId);
  };
  Expense.prototype.update = function(userId) {
    if (!userId) {
      throw new Error('Es muss eine User ID übergeben werden');
    }
    var self = this;
    self.sum = 0;
    self.totalSum = self.items.sum('price');
    self.sum = self.items.reduce(function(processedVal, newVal) {
      return processedVal + newVal.shareOf(userId);
    }, 0);
  };
  Expense.scaffold = function() {
    return {
      name: '',
      date: '',
      items: [],
    };
  };

  return Expense;
}])

.name;
