import modalTemplate from './modal-ui-under-construction.html'

export default angular.module('flatastic.intro.service', [
  'ionic',
])
.factory('IntroService', IntroService)
.name

IntroService.$inject = ['$rootScope', '$ionicModal']
function IntroService($rootScope, $ionicModal) {
  return {
    showUnderConstructionModal,
  }

  ////

  function showUnderConstructionModal() {
    const scope = $rootScope.$new()
    const modal = $ionicModal.fromTemplate(modalTemplate, {
      scope,
      animation: 'slide-in-up',
    })
    scope.modal = modal
    scope.$on('$destroy', function() {
      scope.modal.remove()
      modal.remove();
    });
    return modal.show()
  }

}

