import { showAccessoryBar, hideAccessoryBar } from '../keyboardController';
import FeedbackModule from './cfg_feedback'
import SettingsController from './ctrl_settings'
import WgController from '../wg/ctrl_wg'

// templates
import settingsView from './view_settings.html';
import wgView from '../wg/view_wg.html';

export default angular
.module('flatastic.settings', [
  FeedbackModule,
  SettingsController,
  WgController,
])
.config(
    ['$stateProvider',
    function ($stateProvider) {

  $stateProvider
  .state('wg.settings', {
    url: '/settings',
    views: {
      'menuContent': {
        controller: 'SettingsCtrl',
        template: settingsView,
      },
    },
    onEnter: showAccessoryBar,
    onExit: hideAccessoryBar,
  })
  .state('wg.settings-wg', {
    url: '/wg',
    views: {
      'menuContent': {
        controller: 'WgCtrl',
        template: wgView,
      },
    },
    onEnter: showAccessoryBar,
    onExit: hideAccessoryBar,
  });
}])

.name;
