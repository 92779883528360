import { mapObjIndexed } from 'ramda'

const meta = ['en', 'de']
const trans = {
  [`Step 1 of 3`]: [
    `Step 1 of 3`,
    `Schritt 1 von 3`,
  ],
  [`How do you want to name your home?`]: [
    `How do you want to name your home?`,
    `Wie soll dein Zuhause heißen?`,
  ],
  [`This will help us get everything up and running properly.`]: [
    `This will help us get everything up and running properly.`,
    `So können wir Flatastic für deinen Haushalt optimieren.`,
  ],
  [`Make the home your own! Want to name it «Priate Island»? «Unicorn Rainbow Land»? It's up to you 😎!`]: [
    `Make the home your own! Want to name it "Priate Island"? "Unicorn Rainbow Land"? It's up to you 😎!`,
    `Richte dein Zuhause ein. Wie wäre es mit «Villa Kunterbunt»? «Die Unzertrennlichen»? Deine Entscheidung 😎!`,
  ],
  [`Your home's name`]: [
    `Your home's name`,
    `Name für dein Zuhause`,
  ],
  [`e.g. Pirate Island`]: [
    `e.g. Pirate Island`,
    `z.B. Villa Kunterbunt`,
  ],
  [`Continue`]: [
    `Continue`,
    `Weiter`,
  ],
}

export default {
  en: mapObjIndexed((val, key, obj) => val[0])(trans),
  de: mapObjIndexed((val, key, obj) => val[1])(trans),
}
