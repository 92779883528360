import yepptCordova from './yptCordova'

export default angular.module('yeppt.keyboard', [
  yepptCordova,
])

.run(['yptKeyboard', function(yptKeyboard) {

  // Hide the keyboard's accessory bar by default
  yptKeyboard.hideKeyboardAccessoryBar(true)
  yptKeyboard.disableScroll(true)

}])

.service('yptKeyboard', ['yptCordova', function(yptCordova) {
  this.disableScroll = function(isDisabled) {
    return getPlugin().then(plugin => plugin.disableScroll(isDisabled))
  };
  this.show = function() {
    return getPlugin().then(plugin => plugin.show())
  };
  this.close = function() {
    return getPlugin().then(plugin => plugin.close())
  };
  this.hideKeyboardAccessoryBar = function(value) {
    return getPlugin().then(plugin => plugin.hideKeyboardAccessoryBar(value))
  }
  this.isVisible = function() {
    if (isKeyboardUndefined()) { return warn(); }
    return cordova.plugins.Keyboard.isVisible;
  };

  this.getPlugin = getPlugin

  function warn() {
      console.warn('Keyboard Plugin is not installed');
  }

  function isKeyboardUndefined() {
    var res =  (window.cordova && cordova.plugins && cordova.plugins.Keyboard);
    return !res;
  }

  function getPlugin() {
    return yptCordova.ready.then(function() {
      const plugin = cordova && cordova.plugins && cordova.plugins.Keyboard
      if (!plugin) {
        return Promise.reject('Keyboard Plugin is not installed')
      }
      return plugin
    })
  }

}])

.name;
