import flatasticConfig from '../../scripts/config';
import yepptApi from '../../scripts/modules/api';

export default angular.module('flatastic.shoppinglist.item', [
  yepptApi,
  flatasticConfig,
])

.factory('ShoppinglistItem', ['Api', '$rootScope', 'FlatasticEvents',
    function (Api, $rootScope, FlatasticEvents) {

  function ShoppinglistItem(initObj) {
    var self = this;
    self.status = '';
    self.date = new Date();
    self.set(initObj);
  }

  ShoppinglistItem.getScaffold = function(inserterId) {
    var scaffold = {
      name: '',
      bought: '0',
    };
    if (inserterId) {
      scaffold.inserterId = inserterId;
    }
    return scaffold;
  };

  ShoppinglistItem.prototype.toCollection = function() {
    var self = this;
    $rootScope.$broadcast(FlatasticEvents.shoppinglist.addItem, self);
    return self;
  };
  ShoppinglistItem.prototype.removeFromCollection = function() {
    var self = this;
    $rootScope.$broadcast(FlatasticEvents.shoppinglist.deleteItem, self);
    return self;
  };
  ShoppinglistItem.prototype.saveCollection = function() {
    var self = this;
    $rootScope.$broadcast(FlatasticEvents.shoppinglist.saveItem, self);
    return self;
  };
  ShoppinglistItem.prototype.create = function() {
    var self = this;
    self.status = 'syncing';
    var copy = angular.copy(self);
    copy.bought = String(copy.bought);
    $rootScope.$broadcast(FlatasticEvents.shoppinglist.willCreate);
    Api.post('/shoppinglist', copy)
    .success(function(data) {
      delete self.status;
      delete self.errorMessage;
      // User has checked item before server response comes in
      $rootScope.$broadcast(FlatasticEvents.shoppinglist.didAddItem)
      if (self.bought == 1) {
        self.sync();
      } else {
        self.set(data).saveCollection();
      }
    })
    .error(function(data) {
      self.status = 'not_synced';
      self.errorMessage = data;
    });
  };
  ShoppinglistItem.prototype.set = function(obj) {
    var self = this;
    for (var key in obj) {
      if (key == 'itemName') {
        self['name'] = obj[key];
      } else if (key === 'date' && (obj[key] instanceof Date === false)) {
        self[key] = new Date(obj[key] * 1000);
      } else if (key == 'bought') {
        self[key] = parseInt(obj[key], 10);
      } else {
        self[key] = obj[key];
      }
    }
    return self;
  };
  ShoppinglistItem.prototype.toggle = function() {
    var self = this;
    if (self.status === 'syncing') {
      return;
    }
    self.status = 'syncing';
    self.bought = (self.bought == 1) ? 0 : 1;
    self.date = new Date();
    self.saveCollection();
    return Api.get('/shoppinglist/toggle_item', { params: { 'item_id': self.id } })
    .success(function(data) {
      if (data.bought == 0) {
        $rootScope.$broadcast(FlatasticEvents.shoppinglist.didCheckItem)
      }
      data.date = new Date(data.date * 1000);
      delete self.status;
      delete self.errorMessage;
      self.set(data).saveCollection();
    })
    .error(function(data) {
      self.status = 'not_synced';
      self.errorMessage = data;
      self.saveCollection();
    });
  };
  ShoppinglistItem.prototype.delete = function() {
    var self = this;
    self.status = 'syncing';
    self.invisible = true;
    Api.delete('/shoppinglist/item/' + self.id)
    .success(function(data) {
      delete self.status;
      delete self.errorMessage;
      self.removeFromCollection();
    })
    .error(function(data) {
      self.status = 'not_synced';
      self.errorMessage = data;
      delete self.invisible;
    });
  };
  ShoppinglistItem.prototype.update = function(newProps) {
    var self = this;
    if (self.status === 'syncing') {
      return;
    }
    self.status = 'syncing';
    // Assume success and fallback if update was unsuccessful
    var oldProps = Object.keys(newProps).reduce(function(acc, prop) {
      acc[prop] =  self[prop];
      self[prop] = newProps[prop];
      return acc;
    }, {});
    return Api.patch('/shoppinglist/'+ self.id, newProps)
      .success(function(data) {
        data.date = new Date(data.date * 1000);
        delete self.status;
        delete self.errorMessage;
        self.set(data).saveCollection();
      })
      .error(function(data) {
        self.status = 'not_synced';
        self.errorMessage = data;
        self.set(oldProps).saveCollection();
      });
  };
  ShoppinglistItem.prototype.sync = function() {
    var self = this;
    self.status = 'syncing';
    self.date = self.date || new Date();
    return Api.post('/shoppinglist/sync/', {
      name: self.name,
      id: self.id,
      bought: self.bought,
      date: self.date.getTime() / 1000,
    })
    .success(function(data) {
      self.status = '';
      delete self.errorMessage;
      self.set(data).saveCollection();
    })
    .error(function(data) {
      self.status = 'not_synced';
      self.errorMessage = data;
      self.saveCollection();
    });
  };

  return ShoppinglistItem;
}])

.name;
