import HomelessController from './ctrl_homeless'

import template from './view_homeless.html'

export default angular
.module('flatastic.homeless', [
  HomelessController,
])
.config(
    ['$stateProvider',
    function ($stateProvider) {

  $stateProvider
  .state('homeless', {
    url: '/homeless',
    views: {
      'root': {
        controller: 'HomelessCtrl',
        template,
      },
    },
  });

}])

.name;
