import UserService from '../user/svc_user'
import CordovaModule from '../../scripts/modules/yptCordova'

export default angular.module('flatastic.auth.start.controller', [
  UserService,
  CordovaModule,
])

.controller('StartCtrl',
    ['$scope', 'User', 'yptNotify', '$state', '$ionicSlideBoxDelegate',
    '$ionicHistory', '$filter', '$rootScope', '$timeout',
    function ($scope, User, yptNotify, $state, $ionicSlideBoxDelegate,
    $ionicHistory, $filter, $rootScope, $timeout) {

  $ionicHistory.clearHistory();

  if (User.isLoggedIn()) {
    $state.go('wg.shouts');
  }

  // Animate Flatastic logo when clicked
  $scope.animateLogo = false;
  $scope.animate = function() {
    var cssAnimationDuration = 2500;
    if ($scope.animateLogo) {
      return;
    }
    $scope.animateLogo = true;
    $timeout(function() {
      $scope.animateLogo = false;
    }, cssAnimationDuration);
  };

  $scope.moveIn = function() {
    yptNotify.prompt({
      title: $filter('translate')('ATH__MOVE_IN-TITLE'),
      message: $filter('translate')('ATH__MOVE_IN-MSG'),
      buttonLabels: [
        $filter('translate')('BTN_CANCEL'),
        $filter('translate')('CONTINUE'),
      ],
    })
    .success(function(code) {
      $rootScope.$broadcast('track-event', {
        category: 'Join WG',
        action: 'Clicked',
        label: 'WG Code Proceed',
      });
      $state.go('start-move-in', { code });
    })
    .error(function() {
      $rootScope.$broadcast('track-event', {
        category: 'Join WG',
        action: 'Clicked',
        label: 'WG Code Cancel',
      });
    });
  };

  var NUM_SLIDES = 6;
  $scope.activeSlide = 0;
  if (localStorage['Flatastic.introSlidePosition']) {
    $scope.activeSlide = localStorage['Flatastic.introSlidePosition'];
  }
  $scope.numSlides = function() {
    return $ionicSlideBoxDelegate.slidesCount() || NUM_SLIDES;
  }
  $scope.nextSlide = function() {
    $ionicSlideBoxDelegate.next();
  };
  $scope.slideHasChanged = function(index) {
    localStorage['Flatastic.introSlidePosition'] = index;
    if ((index + 1) ==  $scope.numSlides()) {
      $scope.$broadcast('showPager', 0);
    } else {
      $scope.$broadcast('showPager', 1);
    }

    // Track Views
    var slideTitles = ['welcome', 'chores', 'shoppinglist', 'shouts',
        'expenses', 'main'];
    $rootScope.$broadcast('track-pageview',
        '/start' + '-' + slideTitles[index]);
  };
}])

.name;
