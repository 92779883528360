// template
import menuView from './view_menu.html';

angular.module('flatasticApp')

.controller('MenuCtrl',
    ['$scope', 'User', '$filter',
    'Chores', 'Shoppinglist', 'Wg', 'yptSocialsharing',
    '$window', 'Experiments',
    function ($scope, User, $filter,
    Chores, Shoppinglist, Wg, yptSocialsharing,
    $window, Experiments) {

  $scope.experiments = Experiments.get;

  $scope.user = User.properties;
  $scope.isPremium = User.isPremium;
  $scope.wg   = Wg;
  $scope.flatmates = Wg.flatmates;
  $scope.goToMyProfile = function() {
    $window.location.href = "#/user";
  };
  $scope.goToMyWg = function() {
    $window.localStorage['Flatastic.Wg.switch'] = 'wgProfile';
    $window.location.href = "#/wg";
  };
  $scope.getBadge = function(module) {
    var options = {
      'Shoppinglist': Shoppinglist.numItems(0),
      'Expenses': 0,
      'Shouts': 0,
      'Chores': Chores.numToDo(User.properties.id),
    };
    return options[module];
  };

  var isSharing = false;
  $scope.share = function() {
    if (isSharing) { return; }
    isSharing = true;
    yptSocialsharing.share({
      subject: $filter('translate')('SET__SHARE-TITLE'),
      message: $filter('translate')('SET__SHARE-MSG'),
      url: 'https://flatastic-app.com/app',
      trackEvent: {
        name: 'Share',
        parameters: {
          context: 'menu-bottom',
          content: $filter('translate')('SET__SHARE-TITLE'),
        },
      },
    })
    .finally(function() {
      isSharing = false;
    });
  };

}])

.directive('menu',
      ['$ionicSideMenuDelegate', '$timeout', 'yptKeyboard', '$window',
      function ($ionicSideMenuDelegate, $timeout, yptKeyboard, $window) {
  return {
    restrict: 'E',
    template: menuView,
    controller: 'MenuCtrl',
    link: function(scope, element) {
      var keyboardChecked = false;
      // Run in the next scope digest
      $timeout(function() {
        scope.$watch(function () {
          // watch for menu opening ratio (number between 0-1)
          return $ionicSideMenuDelegate.getOpenRatio();
        }, function (ratio) {
          // Taking care, that there is no side menu with opened keyboard
          if (!keyboardChecked && ratio > 0.01 &&
              document.activeElement.tagName.toLowerCase() === 'input') {
            document.activeElement.blur();
            keyboardChecked = true;
          }

          if (ratio == 0.0) {
            keyboardChecked = false;
            var listener = function() {
              $timeout(function() {
                yptKeyboard.close();
              }, 50);
            };
            $window.addEventListener('native.keyboardshow', listener, false);
            $timeout(function() {
              $window.removeEventListener('native.keyboardshow', listener, false);
            }, 400);
          }
          if (ratio == 1) {
            keyboardChecked = false;
          }

          // iOS only:
          // hide status bar when menu opened (ratio > 0.1)
          // show status bar outside the menu
          // we need to exclude android since the newest Statusbar Plugin Version
          if ($window.StatusBar && $window.cordova
              && ($window.cordova.platformId !== 'android')) {
            if (ratio > 0.1) {
              StatusBar.hide();
            } else {
              StatusBar.show();
            }
          }
        });
      });
    },
  };
}]);
