import FlatasticConfig from '../../scripts/config'
import ApiService from '../../scripts/modules/api'
import CollectionService from '../../scripts/modules/collection'
import UserService from '../user/svc_user'

export default angular.module('flatastic.shouts.service.shout', [
  UserService,
  ApiService,
  CollectionService,
  FlatasticConfig,
])

.factory('Shout',
    ['Api', 'YepptCollection', 'User', '$rootScope', '$timeout', 'FlatasticEvents',
    function (Api, YepptCollection, User, $rootScope, $timeout, FlatasticEvents) {

  function Shout (initObj) {
    var self = this;
    self.comments     = new YepptCollection('id');
    self.likes        = new YepptCollection('userId');
    self.likes.virgin = true;
    self.set(initObj);
  }

  // returns the data object structure for the view
  Shout.getScaffold = function(shoutType, userId) {
    return {
      shout: '',
      shoutType: shoutType || 'shout',
      creatorId: userId    || User.properties.id,
    };
  };

  Shout.prototype.toCollection = function() {
    var self = this;
    $rootScope.$broadcast('Shout:create', self);
    return self;
  };

  Shout.prototype.removeFromCollection = function() {
    var self = this;
    $rootScope.$broadcast('Shout:deleted', self.id);
    return self;
  };

  Shout.prototype.getData = function () {
    return this.data;
  };

  Shout.prototype.create = function() {
    var self = this;
    self.status = 'creating';
    return Api.post('/shouts', { shout: self.shout })
      .then(function({ data }) {
        delete self.status;
        delete self.errorMessage;
        $timeout(function() {
          delete self.justCreated;
        }, 1000);
        $rootScope.$broadcast(FlatasticEvents.shouts.didCreate)
        self.set(data);
      })
      .catch(function({ data }) {
        self.status = 'error';
        self.errorMessage = data;
      });
  };

  Shout.prototype.delete = function() {
    var self = this;
    self.status = 'deleting';
    return Api.delete('/shouts/id/' + self.id)
      .then(function() {
        $rootScope.$broadcast(FlatasticEvents.shouts.didDelete)
        delete self.status;
        delete self.errorMessage;
      })
      .catch(function({ data }) {
        self.status = 'error';
        self.errorMessage = data;
      });
  };

  Shout.prototype.getComments = function() {
    var self = this;
    self.status = 'Getting Comments';
    return Api.get('/shouts/comments', { params: { 'shout_id': self.id } })
    .then(function({ data }) {
      self.comments.remove();
      self.set({ comments: data });
      delete self.status;
      delete self.error;
    })
    .catch(function({ data }) {
      self.error = data;
    });
  };

  Shout.prototype.set = function(obj) {
    var self = this;
    for (var key in obj) {
      if (key === 'comments') {
        self.comments.remove();
        self.comments.push(obj[key]);
      } else if (key === 'likes') {
        self.likes.remove();
        self.likes.push(obj[key]);
        delete self.likes.virgin;
      } else {
        self[key] = obj[key];
      }
    }
    if (!self.insertDate) {
      self.insertDate = new Date();
    }
    if (! (self.insertDate instanceof Date)) {
      self.insertDate = new Date(self.insertDate * 1000);
    }
    self.numLikes = self.likes.length();
  };

  Shout.prototype.getDetails = function() {
    var self = this;
    self.status = 'Getting Details';
    return Api.get('/shouts', { params: { 'shout_id': self.id } })
    .then(function({ data }) {
      self.set(data);
      delete self.status;
      delete self.error;
    })
    .catch(function({ data }) {
      self.error = data;
    });
  };

  Shout.prototype.comment = function(comment) {
    var self = this;
    self.status = 'Posting comment';
    var ids = self.comments.push({
      comment: comment,
      status: 'local',
    });
    return Api.post('/shouts/comment', {
      'shout_id': self.id,
      'comment': comment,
    })
    .then(function({ data }) {
      var comment = self.comments.get(ids[0]);
      delete comment.status;
      for (var key in data) {
        comment[key] = data[key];
      }
      delete self.status;
      delete self.error;
    })
    .catch(function({ data }) {
      self.comments.remove(ids[0]);
      self.error = data;
    });
  };

  Shout.prototype.getNumLikes = function() {
    var self = this;
    return self.likes.length();
  };

  Shout.prototype.like = function() {
    var self = this;
    if (self.status) {
      return;
    }
    var value = (self.liked) ? 0 : 1;
    self.status = 'liking';
    var didLikeShout = !self.liked
    self.liked = !self.liked;
    var likeId;
    var deletedElement;
    var userId = User.properties.id;
    if (value === 1) {
      likeId = self.likes.push({
        userId: userId,
        date: new Date().getTime() / 1000,
        value: 1,
        local: true,
      })[0];
    } else if (value === 0) {
      deletedElement = self.likes.get(userId);
      self.likes.remove(userId);
      self.numLikes = self.likes.length();
    }

    return Api.get('/shouts/like', {
      params: {
        'shout_id': self.id,
        'like': value,
      },
    })
    .then(function({ data }) {
      $rootScope.$broadcast(didLikeShout ? FlatasticEvents.shouts.didLike : FlatasticEvents.shouts.didUnlike)
      delete self.error;
      delete self.status;
      if (likeId && value === 1) {
        var likeElem = self.likes.get(likeId);
        for (var key in data) {
          likeElem[key] = data[key];
        }
        delete likeElem.local;
      }
    })
    .catch(function({ data }) {
      self.error = data;
      if (value === 1 && likeId) {
        self.likes.remove(likeId);
        self.numLikes = self.getNumLikes();
      } else if (value === 0 && deletedElement) {
        self.likes.push(deletedElement);
        self.numLikes = self.getNumLikes();
      }
    });
  };

  return Shout;
}])

.name;
