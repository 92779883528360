import { mapObjIndexed } from 'ramda'

const meta = ['en', 'de']
const trans = {
  [`Create Account`]: [
    `Create Account`,
    `Account erstellen`,
  ],
  [`Create an account to get started with Flatastic`]: [
    'Create an account to get started with Flatastic',
    'Erstelle einen Account um mit Flatastic loszulegen',
  ],
  [`Enter Password`]: [
    `Enter Password`,
    `Passwort eingeben`,
  ],
  [`Enter Email`]: [
    `Enter Email`,
    `E-Mail eingeben`,
  ],
  [`Something didn't work. Please try again.`]: [
    `Something didn't work. Please try again.`,
    `Da ist etwas schief gelaufen. Versuche es noch einmal.`,
  ],
  [`Something went wrong 👆`]: [
    `Something went wrong 👆`,
    `Das hat noch nicht geklappt 👆`,
  ],
  [`Already have an account?`]: [
    `Already have an account?`,
    `Du hast bereits ein Account?`,
  ],
  [`The email address is already in use.`]: [
    'The email address is already in use.',
    'Diese E-Mail-Adresse wird bereits verwendet.',
  ],
  [`Dieser Zugangscode wurde nicht gefunden.`]: [
    `Please double check the access code.`,
    `Bitte überprüfe noch einmal deinen Zugangscode.`,
  ],
  [`You seem to be offline. Please check your connection and retry.`]: [
    `You are offline. Please check your connection and retry.`,
    `Du bist offline. Bitte überprüfe deine Verbindung und versuche es erneut.`,
  ],
  [`How to set a secure password`]: [
    `🔐 The password must contain one number, one letter and at least 8 or more characters `,
    `🔐 Das Passwort muss eine Zahl, einen Buchstaben enthalten und mindestens 8 Zeichen lang sein`,
  ],
}

export default {
  en: mapObjIndexed((val, key, obj) => val[0])(trans),
  de: mapObjIndexed((val, key, obj) => val[1])(trans),
}
