import ApiService from '../../scripts/modules/api'
import ChoresService from './svc_chores'
import WgService from '../wg/svc_wg'
import FlatasticConfig from '../../scripts/config'

export default angular.module('flatastic.chores.proposal', [
  ApiService,
  ChoresService,
  WgService,
  'ionic',
  FlatasticConfig,
])

.factory('ChoreProposal',
    ['Api', 'Chores', 'Wg', '$window',
    '$ionicScrollDelegate', 'LocalStorageKeys',
    function(Api, Chores, Wg, $window,
    $ionicScrollDelegate, LocalStorageKeys) {

  var numAddedTasks = 0;

  var config = {
    storageKey: LocalStorageKeys.choreProposals.hide,
    dueDateShift: 3 * 24 * 60 * 60 * 1000,
  };

  function Proposal(data) {
    for (var key in data) {
      if (data.hasOwnProperty(key)) {
        this[key] = data[key];
      }
    }
  }
  Proposal.prototype.dismiss = function() {
    var self = this;
    self.dismissed = true;
    $ionicScrollDelegate.resize();
    logAction(self, 'dismissed');
  };
  Proposal.prototype.add = function() {
    // Make sure not to hide proposals if one item is added
    delete $window.localStorage[config.storageKey];
    var self = this;
    self.added = true;
    var users = Wg.flatmates.accumulate('id').rotate(numAddedTasks % Wg.flatmates.length());
    var startDate = new Date(new Date().getTime() + config.dueDateShift);
    startDate.setHours(23);
    startDate.setMinutes(59);
    startDate.setSeconds(59);
    startDate.setMilliseconds(999);
    var chore = new Chores.Chore({
      local: true,
      title: self.title,
      fixed: self.fixed,
      rotationTime: self.rotationTime,
      users: users,
      currentUser: users[0],
      startDate: startDate,
      justAdded: true,
      points: self.points || 1,
    });
    // Make sure to modify the instance that was added to the queue
    // Chore.toCollection doesn't work reliably
    Chores.get(Chores.push(chore, true)).create();
    $ionicScrollDelegate.resize();
    numAddedTasks++;
    logAction(self, 'added');
  };

  return Proposal;

  //////////

  function logAction(chore, action) {
    Api.post('/chores/proposals_log', {
      id: chore.id,
      title: chore.title,
      rotationTime: chore.rotationTime,
      quantity: chore.quantity,
      fixed: chore.fixed,
      firstDueDate: chore.firstDueDate,
      action: action,
      points: chore.points || 1,
    });
  }
}])

.name;
