import FirebaseService from '../../scripts/modules/flatasticFirebase';
import CordovaService from '../../scripts/modules/yptCordova';
import KeyboardService from '../../scripts/modules/yptKeyboard';
import { hideAccessoryBar, showAccessoryBar } from '../keyboardController';
import ChoresMonetization from '../monetizationChores';
import ChoresController from './ctrl_chores';
import ChoresHistoryController from './ctrl_chores-history';
import NewChoreController from './ctrl_chores-new';
import ChoreService from './svc_chore';
import ChoresService from './svc_chores';
import ChoresHistoryService from './svc_chores-history';
import choresHistoryView from './view_chores-history.html';
import choresNewView from './view_chores-new.html';
//templates
import choresView from './view_chores.html';


export default angular
.module('flatastic.chores', [
  ChoresHistoryService,
  ChoresHistoryController,
  FirebaseService,
  CordovaService,
  KeyboardService,
  ChoreService,
  ChoresService,
  ChoresController,
  NewChoreController,
])
.config(
    ['$stateProvider',
    function ($stateProvider) {

  $stateProvider
  .state('wg.chores', {
    url: '/chores',
    views: {
      'menuContent': {
        controller: 'ChoresCtrl',
        template: choresView,
      },
    },
  })
  .state('wg.chores-new', {
    url: '/chores-new',
    views: {
      'menuContent': {
        controller: 'ChoresNewCtrl',
        template: choresNewView,
      },
    },
    resolve: {
      isAllowed: ChoresMonetization,
    },
    onEnter: showAccessoryBar,
    onExit: hideAccessoryBar,
  })
  .state('wg.chores-edit', {
    url: '/chores-edit/:id',
    views: {
      'menuContent': {
        controller: 'ChoresNewCtrl',
        template: choresNewView,
      },
    },
    onEnter: showAccessoryBar,
    onExit: hideAccessoryBar,
  })
  .state('wg.chores-history', {
    url: '/chores-history?userId',
    params: {
      userId: null,
    },
    views: {
      'menuContent': {
        controller: 'ChoresHistoryCtrl',
        template: choresHistoryView,
      },
    },
  })

}])

.name;
