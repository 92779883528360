import yepptCordova from './yptCordova';

export default angular.module('yeppt.apprate', [
  yepptCordova,
])

.run(['yptCordova', 'yptAppRate', function(yptCordova, yptAppRate) {

  // increase counter every app start and resume
  yptCordova.ready.then(function() {
    yptAppRate.increaseCounter();
    yptAppRate.showRatePrompt();
  });

  yptCordova.setResume(function() {
    yptAppRate.increaseCounter();
    yptAppRate.showRatePrompt();
  });

}])

.service('yptAppRate',
  ['device', 'yptCordova', '$filter', '$rootScope',
  function(device, yptCordova, $filter, $rootScope) {

  var config = {
    appURLs: {
      iOS: 'itms-apps://itunes.apple.com/app/id840810742?mt=8',
      Android: 'market://details?id=com.flatastic.app',
    },
    showAfter: 25,
    storageKey: 'yptAppRate',
  };

  var isPopupShown = false;

  function getCounterValue() {
    return JSON.parse(localStorage[config.storageKey] || 0);
  }

  this.increaseCounter = function increaseCounter() {
    var counter = getCounterValue();
    // Never ask me again corresponds to -1
    if (counter == -1) {
      return;
    }
    localStorage[config.storageKey] = JSON.stringify(counter + 1);
  };


  this.showRatePrompt = function() {

    // Check if Platform is supported
    if (!device || !device.info ||
          !device.info.platform || !config.appURLs[device.info.platform]) {
      console.warn('yptAppRate: This platform is not supported');
      return;
    }

    // Stop here if it's not time to rate the app yet
    var counter = getCounterValue();
    if (counter == -1 || (counter < config.showAfter)) {
        return;
    }

    // Check if rate popup is already open if so stop.
    if (isPopupShown === true) {
      console.info('yptAppRate: Popup is already open');
      return;
    }
    isPopupShown = true;

    // Handle user's answer
    var callback = function(button) {
      isPopupShown = false;

      switch (button) {

        // Never ask again
        case 1:
          $rootScope.$broadcast('track-event', {
            category: 'App Rate',
            action: 'User did select option',
            label: 'No thanks',
          })
          localStorage[config.storageKey] = JSON.stringify(-1);
          break;

        // Rate it later
        case 2:
          $rootScope.$broadcast('track-event', {
            category: 'App Rate',
            action: 'User did select option',
            label: 'Remind me later',
          })
          localStorage[config.storageKey] = JSON.stringify(0);
          break;

        // Rate it now
        case 3:
          $rootScope.$broadcast('track-event', {
            category: 'App Rate',
            action: 'User did select option',
            label: 'Rate it now',
          })
          yptCordova.openLink(config.appURLs[device.info.platform]);
          localStorage[config.storageKey] = JSON.stringify(-1);
          break;
      }

    };

    const title = $filter('translate')('RATE__DIALOG-TITLE')
    const message = $filter('translate')('RATE__DIALOG-MSG')
    $rootScope.$broadcast('track-event', {
      category: 'App Rate',
      action: 'Dialog did open',
      label: `${title} - ${message}`,
    })

    navigator.notification.confirm(
      message,
      callback,
      title,
      [
        $filter('translate')('RATE__DIALOG-BTN_NO_THANKS'),
        $filter('translate')('RATE__DIALOG-BTN_REMIND_ME_LATER'),
        $filter('translate')('RATE__DIALOG-BTN_RATE_IT_NOW'),
      ]
    );
  };

}])

.name;
