import FlatasticConfig from '../scripts/config'
import StatusBarModule from '../scripts/modules/statusbar'
import WgService from './wg/svc_wg'

const onboardingVariants = {
  control: 0,
  a: 1,
}

export default angular.module('flatastic.initializer', [
  'ionic',
  FlatasticConfig,
  StatusBarModule,
  WgService,
])
.config(
  ['$stateProvider', function ($stateProvider) {

  $stateProvider
    .state('init', {
      url: '/',
      resolve: {
        onboardingVariant: ['Firebase', function(Firebase) {
          return Firebase.getConfigValue('onboarding_variant', onboardingVariants.a)
        }],
      },
      onEnter,
    },
  )
}])
.name;

onEnter.$inject = ['$state', 'onboardingVariant', '$ionicHistory', 'LocalStorageKeys', 'User', 'StatusBar', 'Wg', '$timeout']
function onEnter($state, onboardingVariant, $ionicHistory, LocalStorageKeys, User, StatusBar, Wg, $timeout) {

  if (onboardingVariant === onboardingVariants.control) {
    // Set status bar font (iOS) to white and show it
    if (cordova && cordova.platformId !== 'android') {
      StatusBar.setStyleLightContent();
      StatusBar.show();
    } else if (cordova && cordova.platformId === 'android') {
      StatusBar.backgroundColorByHexString('#111111');
    }
  }

  // User is not logged in
  if (!User.isLoggedIn()) {
    const routeDict = {
      [onboardingVariants.control]: 'start',
      [onboardingVariants.a]: 'intro',
    }
    const startRoute = routeDict[onboardingVariant]
    return goToRouteAndClearHistory(startRoute)
  }

  // User should continue setting up the household
  if (Wg.isSettingUp()) {
    const routeDict = {
      [onboardingVariants.control]: 'wg-setup-invite',
      [onboardingVariants.a]: 'intro-household-type',
    }
    const householdSetupRoute = routeDict[onboardingVariant]
    return goToRouteAndClearHistory(householdSetupRoute)
  }

  // User is homeless
  if (User.isHomeless()) {
    return goToRouteAndClearHistory('homeless')
  }

  // User is living in household
  const screenName = localStorage[LocalStorageKeys.behavior.lastUsedModule] || 'wg.shouts'
  return goToRouteAndClearHistory(screenName)

  ////

  function goToRouteAndClearHistory(route) {
    return $timeout(0) // Ensure that the new state is considered
      .then(() => $state.go(route))
      .then(() => $timeout(750))
      .then(() => $ionicHistory.clearHistory())
  }
}
