import { mapObjIndexed } from 'ramda'

const meta = ['en', 'de']
const trans = {
  [`Create Home`]: [
    'Create Home',
    'Haushalt erstellen',
  ],
  [`Join Home`]: [
    'Join Home',
    'Haushalt beitreten',
  ],
  [`Create a new home`]: [
    'Create a new home',
    'Neuen Haushalt erstellen',
  ],
  [`Create a home from scratch and invite everybody else. Easy as pie!`]: [
    'Create a home from scratch and invite everybody else. Easy as pie!',
    'Erstelle einen neuen Haushalt und lade alle anderen ein. Kinderleicht!',
  ],
  [`Enter your key to join an existing home`]: [
    'Enter your key to join an existing home',
    'Mit deinem Zugangscode in bestehenden Haushalt einziehen.',
  ],
  [`When someone invites you to a Flatastic Home, their message should include a unique 5-character key.`]: [
    `When someone invites you to a Flatastic Home, their message should include a unique 5-character key.`,
    `In der Einladung von deinem Mitbewohner findest du den aus 5 Zeichen bestehenden Zugangscode.`,
  ],
  [`Your key`]: [
    `Your key`,
    `Dein Zugangscode`,
  ],
  [`Join Home`]: [
    `Join Home`,
    `Haushalt beitreten`,
  ],
  [`Create new home`]: [
    `Create new home`,
    `Neuen Haushalt erstellen`,
  ],
  [`Create or join Home`]: [
    `Create or join Home`,
    `Dein Haushalt`,
  ],
  [`Sign in`]: [
    `Sign in`,
    `Anmelden`,
  ],
}

export default {
  en: mapObjIndexed((val, key, obj) => val[0])(trans),
  de: mapObjIndexed((val, key, obj) => val[1])(trans),
}
