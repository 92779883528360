import yepptCordova from '../../scripts/modules/yptCordova';
import yepptApi from '../../scripts/modules/api';
import flatasticConfig from '../../scripts/config';
import flatasticUser from '../user/cfg_user';

export default angular.module('flatastic.experiments', [
  yepptCordova,
  yepptApi,
  flatasticConfig,
  flatasticUser,
])

.run(['$rootScope', 'Experiments', 'yptCordova', 'FlatasticEvents',
    function($rootScope, Experiments, yptCordova, FlatasticEvents) {
  Experiments.load();
  $rootScope.$on(FlatasticEvents.user.didEnterWg, Experiments.load);
  $rootScope.$on(FlatasticEvents.user.didLogout, Experiments.deleteExperiments);
  yptCordova.ready.then(Experiments.load);
  yptCordova.setResume(Experiments.load);
}])

.service('Experiments',
    ['$window', 'User', 'LocalStorageKeys', 'Api',
    function($window, User, LocalStorageKeys, Api) {

  var experiments = {};

  this.experiments = experiments;
  this.load = loadExperiments;
  this.get = get;
  this.deleteExperiments = deleteExperiments;

  function get() {
    return experiments;
  }

  function loadExperiments() {

    // Don't request experiments if user is not logged in
    if (!User.isLoggedIn()) {
      console.debug('Not loading experiments: User is not logged in');
      return;
    }

    // Try to load experiments from LocalStorage
    if ($window.localStorage[LocalStorageKeys.experiments.settings]) {
      experiments = JSON.parse($window.localStorage[LocalStorageKeys.experiments.settings]);
    }

    // It's totally enough to check for new experiments every hour:
    var lastUpdate = getLastUpdate();
    if (lastUpdate) {
      var expLastUpdate = new Date(lastUpdate);
      var updateInterval = 1 * 60 * 60 * 1000;
      if ((expLastUpdate.getTime() + updateInterval) > new Date().getTime()) {
        console.debug('Not getting new experiments. Updated recently.');
        return;
      }
    }

    Api.get('/experiments')
      .success(setExperiments);
  }

  function deleteExperiments() {
    delete $window.localStorage[LocalStorageKeys.experiments.settings];
    delete $window.localStorage[LocalStorageKeys.experiments.lastUpdate];
  }

  function setExperiments(data) {
    $window.localStorage[LocalStorageKeys.experiments.settings] = JSON.stringify(data);
    experiments = data;
    setLastUpdate();
  }

  function getLastUpdate() {
    return $window.localStorage[LocalStorageKeys.experiments.lastUpdate];
  }

  function setLastUpdate() {
    $window.localStorage[LocalStorageKeys.experiments.lastUpdate] = new Date();
  }

}])

.name;
