import flatasticEmailValidator from '../../directives/emailValidator';
import flatasticUserService from './svc_user';
import flatasticUserController from './ctrl_user';

// templates
import template from './view_user.html';

export default angular.module('flatastic.user', [
  flatasticEmailValidator,
  flatasticUserService,
  flatasticUserController,
])
.config(
    ['$stateProvider',
    function ($stateProvider) {

  $stateProvider
  .state('wg.user', {
    url: '/user',
    views: {
      'menuContent': {
        controller: 'UserCtrl',
        template,
      },
    },
  })
}])

.name;
