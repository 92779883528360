angular.module('flatasticApp')

.directive('keepRatio', ['$window', function ($window) {
  return {
    restrict: 'A',
    link: function postLink(scope, element, attrs) {
      var ratio = parseFloat(attrs.keepRatio);
      function keepRatio(ratio) {
        var width = element[0].offsetWidth;
        var height = width / ratio;
        element.css('height', height + 'px');
      }
      keepRatio(ratio);
      element.ready(function() {
        keepRatio(ratio);
      });
      angular.element($window).bind('resize', function() {
        keepRatio(ratio);
      });
      angular.element($window).bind('load', function() {
        keepRatio(ratio);
      });
    },
  };
}]);
