import ShoppinglistProposalsService from './svc_shoppinglistproposals'

export default angular.module('flatastic.shoppinglist.filters', [
  ShoppinglistProposalsService,
])

.filter('shlProposals',
    ['ShoppinglistProposals', function (ShoppinglistProposals) {

  return function(input, typedChars, itemsOnList, count) {
    var validItems = ShoppinglistProposals.get().filter(function(item) {
      // Item must start with the typed characters
      if (typedChars.length > 0 && item.toLowerCase().indexOf(typedChars.toLowerCase()) !== 0) {
        return false;
      }

      // Item must not be on the list
      var i;
      for (i = 0; i < itemsOnList.length; i++) {
        if (itemsOnList[i].name.toLowerCase() == item.toLowerCase()) {
          return false;
        }
      }
      return true;
    });

    return validItems.slice(0, count);
  };
}])

.name;
