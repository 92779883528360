import { equals, hasPath, ifElse, map } from 'ramda'
import { decodeHtmlEntities } from '../../utils/helpers'

export default angular.module('yeppt.cordova', [])

.value('device', {
  info: null,
})

.service('yptCordova',
    ['$q', 'device', '$rootScope',
    function($q, device, $rootScope) {

  var deferred = $q.defer();
  var resolved = false;

  var self = this;
  this.ready = deferred.promise;

  // When app is resumed
  this.onResumeFunctions = [];
  this.setResume = function(cb) {
    this.onResumeFunctions.push(cb);
  };

  // When app is paused
  this.onPauseFunctions = [];
  this.setPause = function(cb) {
    this.onPauseFunctions.push(cb);
  }

  if (!window.cordova) {
    resolved = true
    return deferred.reject(`window.cordova is not defined`)
  }

  document.addEventListener('deviceready', function() {
    resolved = true;

    // save device infos
    device.info = window.device;

    deferred.resolve(window.cordova);

    // on app resume
    document.addEventListener('resume', function(params) {
      self.onResumeFunctions.forEach(function(func) {
        func.apply(self, params);
      });
    }, false);

    // on app pause
    document.addEventListener('pause', function(params) {
      self.onPauseFunctions.forEach(function(func) {
        func.apply(self, params);
      });
    }, false);
  });

  // Just in case the event was missed
  setTimeout(function() {
    if (!resolved) {
      if (window.cordova && window.device) {
        deferred.resolve(window.cordova);
      } else {
        deferred.reject('Did not get Cordova\'s "deviceready" event');
      }
    }
  }, 5000);

  this.openLink = function(url, internal, options) {
    $rootScope.$broadcast('track-event', {
      event: 'open_link',
      url,
    });
    var options = Object.keys(options || {})
      .map(function(key) {
        return key + '=' + options[key];
      }).join(',')
    const open = hasPath(['cordova', 'InAppBrowser', 'open'], window)
      ? window.cordova.InAppBrowser.open
      : window.open
    if (internal) {
      open(url, '_blank', options);
    } else {
      open(url, '_system');
    }
  };

}])

.service('yptNotify', ['$q', '$timeout', '$rootScope',
    function($q, $timeout, $rootScope) {

  var defaults = {
    appName: 'No app name',
    message: 'No Message',
    confirm: 'OK',
    cancel: 'Cancel',
    title: 'Title',
    value: '',
  }
  var init = function(obj) {
    for (var key in obj) {
      defaults[key] = obj[key];
    }
  };

  var myAlert = function(msg) {
    var message    = msg.message     || defaults.message,
        title      = msg.title       || defaults.title,
        buttonName = msg.buttonLabel || defaults.confirm;
    var callback = function(res) {
      $timeout(function() {
        if (!msg.callback) {
          return;
        }
        msg.callback(res);
      });
    };
    if (window.navigator && navigator.notification &&
        navigator.notification.alert) {
      navigator.notification.alert(
        decodeHtmlEntities(message),    // message
        callback,   // callback
        decodeHtmlEntities(title),      // title
        decodeHtmlEntities(buttonName)  // buttonName
      );
    } else {
      alert(message);
      callback();
    }
    if (msg.trackEvent) {
      $rootScope.$broadcast('track-event', msg.trackEvent);
    }
  };

  function showConfirmDialog(msg) {
    if (!hasPath(['notification', 'confirm'])(navigator)) {
      // Default to JS
      return confirm(msg) ? Promise.resolve(true) : Promise.reject(false)
    }
    return new Promise((resolve, reject) =>
      navigator.notification.confirm(
        msg.message,
        ifElse(equals(2), resolve, reject),
        msg.title,
        msg.buttonLabels
      )
    )
  }

  var myConfirm = function(msg) {
    //Set default values if not specified by the user.
    var message      = msg.message      || defaults.message,
        buttonLabels = msg.buttonLabels || [defaults.cancel, defaults.confirm],
        title        = msg.title        || defaults.title;
    var callback = function(res) {
      $timeout(function() {
        (msg.callback || console.log)(res);
      });
    };
    //Use Cordova version of the confirm box if possible.
    if (navigator.notification && navigator.notification.confirm){
      var _callback = function(index){
        if (callback) {
          callback(index == 2); // == Yes
        }
      };

      navigator.notification.confirm(
        decodeHtmlEntities(message),      // message
        _callback,    // callback
        decodeHtmlEntities(title),        // title
        map(decodeHtmlEntities)(buttonLabels)  // buttonName
      );

    //Default to the usual JS confirm method.
    } else {
      if (confirm(message)) {
        callback(true);
      } else {
        callback(false);
      }
    }
  };

  var myPrompt = function(msg) {
    var message      = msg.message       || defaults.message,
        title        = msg.title         || defaults.title,
        buttonLabels = msg.buttonLabels  || [defaults.cancel, defaults.confirm],
        defaultValue = msg.defaultValue  || defaults.value;

    // General stuff
    var deferred = $q.defer();
    var promise = deferred.promise;
    var onSuccess = function(data) {
      deferred.resolve(data);
    };
    var onError = function(data) {
      deferred.reject(data);
    };
    promise.success = function(fn) {
      promise.then(fn);
      return promise;
    };
    promise.error = function(fn) {
      promise.then(null, fn);
      return promise;
    };

    if (navigator && navigator.notification && navigator.notification.prompt) {
      var _callback = function(data) {
        console.log(data);
        if (data.buttonIndex === 2) {
          onSuccess(data.input1)
        } else {
          onError(data.input1)
        }
      };
      navigator.notification.prompt(
        message,
        _callback,
        title,
        buttonLabels,
        defaultValue
      );
    } else {
      var promptResult = window.prompt(message, defaultValue);
      if (promptResult === null) {
        onError('Canceled');
      } else {
        onSuccess(promptResult);
      };
    }

    return promise;

  }

  return {
    init: init,
    alert: myAlert,
    confirm: myConfirm,
    prompt: myPrompt,
    showConfirmDialog,
    // beep:    myBeep,
    // vibrate: myVibrate
  };

}])

.name;
