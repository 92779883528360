const translations = {
  en: {
    'PURCHASE__TITLE': 'Flatastic Premium',
    'PURCHASE__TERMS_OF_SERVICE': 'Terms of Service',
    'PURCHASE__PRIVACY': 'Privacy Policy',
    'PURCHASE_MENU_TITLE': 'Flatastic Premium',
    'PURCHASE_RESTORE': 'Restore purchases',
    'LOADING_PRODUCTS': 'Loading products…',
    'PURCHASES_RESTORING': 'Restoring your purchases…',
    'PURCHASES__DETAILS-TEXT': 'Recurring billing. Cancel anytime.',
    'PURCHASE_MODAL_TITLE': 'Subscription details',
    'PURCHASE_MODAL_PARAGRAPH_1': 'After confirming the purchase, the amount will be charged from your {{ store }} account.',
    'PURCHASE_MODAL_PARAGRAPH_2': 'Your {{ store }} account will be charged again after your subscription is automatically renewed at the end of the subscription period. If you do not want this, you must disable auto-renewal at least 24 hours before the subscription expires.',
    'PURCHASE_MODAL_PARAGRAPH_3': 'You can manage or disable the auto-renewal option at any time after purchase in your Apple ID account settings.',
    'PURCHASE_MODAL_PARAGRAPH_4': 'Any unused portion of a free trial period, if offered, will be forfeited when you purchase a subscription to that publication, where applicable.',
    'PURCHASES_PREMIUM_TITLE': 'Flatastic Premium',
    'PURCHASES_PREMIUM_INTRO': 'Upgrade to Flatastic Premium to enjoy an ad-free experience and to unlock additional features for all your flatmates.',
    'PURCHASES_MONTH': 'month',
    'PURCHASES_YEAR': 'year',
    'PURCHASES_IS_PREMIUM_TITLE': 'Thanks for your love!',
    'PURCHASES_IS_PREMIUM_TEXT_RENEWING': 'Your Premium account will automatically renew on {{ renewDate | date }}. This way you can keep all premium features and keep sending us love!',
    'PURCHASES_IS_PREMIUM_TEXT_ENDING': 'Your Premium account will end on {{ endDate | date }}. Until this date you will keep all premium features.',
    'PURCHASES_DETAILS': 'Subscription details',
    'PURCHASE_FEATURE_ONE_HEADLINE': 'Ad-Free Flatastic',
    'PURCHASE_FEATURE_ONE_DESCRIPTION': 'Never ever see annoying ads anymore. We also don’t like them. No interruptions, no distractions!',
    'PURCHASE_FEATURE_TWO_HEADLINE': 'Export your expenses',
    'PURCHASE_FEATURE_TWO_DESCRIPTION': 'Get all expenses of your household in one file. Save your justice and do your own big-data magic.',
    'PURCHASE_FEATURE_THREE_HEADLINE': 'Show love',
    'PURCHASE_FEATURE_THREE_DESCRIPTION': 'Show that you love what we do and help us to make Flatastic even more awesome.',
    'PURCHASE_FEATURE_FOUR_HEADLINE': 'Unlimited Flatastic',
    'PURCHASE_FEATURE_FOUR_DESCRIPTION': 'Utilize the full power of all flatastic features. No restrictions. No limits!',
    'PURCHASE_FEATURE_HEADLINE-PERSONAL_SPENDINGS': 'Personal Spendings',
    'PURCHASE_FEATURE_DESCRIPTION-PERSONAL_SPENDINGS': 'Keep track of your personal spendings. Check your monthly budget and find out outliers.',
    'PURCHASE_MODAL_HEADLINE': 'Purchases',
    'PREMIUM_FEATURE_MODAL_HEADER': 'Premium-Feature',
    'PREMIUM_FEATURE_MODAL_HEADLINE': 'You have discovered a premium feature.',
    'PREMIUM_FEATURE_MODAL_PARAGRAPH': "Unlock this feature and many more by becoming a premium member. As an addition you'll support our work.",
    'PREMIUM_FEATURE_MODAL_BUTTON_TEXT': 'Become premium',
    'PREMIUM_FEATURE_MODAL_CLOSE_BUTTON': 'Close',
    'PURCHASE__GET_PREMIUM': 'Get premium',
    'PURCHASES__SUBSCRIBING': 'Preparing purchase…',
    'PURCHASE_THANKS_FOR_PREMIUM': 'Thanks for beeing premium, {{ firstName }}',
    'PURCHASE_HEADLINE': 'Live better together with Flatastic Premium',
    'PURCHASE_BTN-ADV': 'Your Advantages',
    'PURCHASE_BTN-REVIEW': 'User Reviews',
    'PURCHASE_BTN-GO-PREMIUM': 'Go Premium',
    'PURCHASE_INFO-TEXT': 'Automatic renewal, cancellable at any time.',
    'PURCHASE_ADV-HEADLINE': 'Your advantages with Premium',
    'PURCHASE_REVIEW-HEADLINE': 'That\'s what our users say',
    'PURCHASE_OFFER-HEADLINE': 'Are you ready to make your living together a little easier?',
    'PURCHASE_OFFER-PARAGRAPH': 'Get premium and support us. You also activate additional features for you.',
  },
  de: {
    'PURCHASE__TITLE': 'Flatastic Premium',
    'PURCHASE__TERMS_OF_SERVICE': 'Rechtliches',
    'PURCHASE__PRIVACY': 'Datenschutz',
    'PURCHASE_MENU_TITLE': 'Flatastic Premium',
    'PURCHASE_RESTORE': 'Käufe wiederherstellen',
    'PURCHASES_RESTORING': 'Deine Käufe werden wiederhergestellt…',
    'PURCHASES__DETAILS-TEXT': 'Automatische Verlängerung. Jederzeit kündbar.',
    'PURCHASE_MODAL_TITLE': 'Details zum Abonnement',
    'PURCHASE_MODAL_PARAGRAPH_1': 'Der Betrag wird in deinem {{ store }}-Konto abgerechnet, nachdem du den Kauf bestätigt hast.',
    'PURCHASE_MODAL_PARAGRAPH_2': 'Dein {{ store }}-Konto wird erneut belastet, wenn dein Abonnement am Ende des Abonnenmentzeitraums automatisch verlängert wird. Wenn du dies nicht möchtest, musst du die automatische Verlängerung mindestens 24 Stunden vor Ablauf des Abonnements deaktivieren.',
    'PURCHASE_MODAL_PARAGRAPH_3': 'Du kannst die Option zur automatischen Verlängerung jederzeit nach dem Kauf in den Einstellungen deines Apple ID-Kontos verwalten, oder deaktivieren.',
    'PURCHASE_MODAL_PARAGRAPH_4': 'Unverbrauchte Artikel im kostenlosen Probezeitraum — falls angeboten — verfallen, wenn Du dir ein Abo für dieses Produkt kaufst.',
    'PURCHASES_PREMIUM_TITLE': 'Flatastic Premium',
    'PURCHASES_PREMIUM_INTRO': 'Mit Flatastic Premium siehst du keine Werbung mehr und schaltest für dich und deine WG zusätzliche Funktionen frei.',
    'PURCHASES_MONTH': 'Monat',
    'PURCHASES_YEAR': 'Jahr',
    'PURCHASES_IS_PREMIUM_TITLE': 'Vielen Dank für deine Liebe!',
    'PURCHASES_IS_PREMIUM_TEXT_RENEWING': 'Dein Premium-Account wird sich am {{ renewDate | date }} automatisch verlängern. Dir bleiben also automatisch alle Premium-Funktionen erhalten.',
    'PURCHASES_IS_PREMIUM_TEXT_ENDING': 'Deine Premium-Mitgliedschaft endet am {{ endDate | date }}. Bis dahin bleiben dir alle Premium-Funktionen erhalten.',
    'PURCHASES_DETAILS': 'Details zum Abonnement',
    'PURCHASE_FEATURE_ONE_HEADLINE': 'Werbefreies Flatastic',
    'PURCHASE_FEATURE_ONE_DESCRIPTION': 'Keine Unterbrechungen mehr durch nervige Werbung. Auch wir mögen sie nicht! (Aber wir sind jung und Flatastic braucht das Geld ;)',
    'PURCHASE_THANKS_FOR_PREMIUM': 'Danke für deine Premium-Mitgliedschaft, {{ firstName }}',

    'PURCHASE_FEATURE_TWO_HEADLINE': 'Finanzeinträge exportieren',
    'PURCHASE_FEATURE_TWO_DESCRIPTION': 'Exportiere alle Einträge deiner WG in einer Datei. Speichere die Gerechtigkeit und mach deine  ganz eigene Big-Data Magic.',
    'PURCHASE_FEATURE_THREE_HEADLINE': 'Zeig Liebe',
    'PURCHASE_FEATURE_THREE_DESCRIPTION': 'Zeig, dass du liebst was wir tun und hilf uns Flatastic immer besser zu machen.',
    'PURCHASE_FEATURE_FOUR_HEADLINE': 'Uneingeschränktes Flatastic',
    'PURCHASE_FEATURE_FOUR_DESCRIPTION': 'Nutze die volle Power aller Flatastic-Funktionen. No Limits!',
    'PURCHASE_FEATURE_HEADLINE-PERSONAL_SPENDINGS': 'Persönliche Ausgaben',
    'PURCHASE_FEATURE_DESCRIPTION-PERSONAL_SPENDINGS': 'Behalte deine eigenen Ausgaben im Blick. Überprüfe dein monatliches Budget und entdecke Ausreißer.',
    'PURCHASE_MODAL_HEADLINE': 'Zahlungen',
    'LOADING_PRODUCTS': 'Produkte werden geladen…',
    'PREMIUM_FEATURE_MODAL_HEADER': 'Premium-Feature',
    'PREMIUM_FEATURE_MODAL_HEADLINE': 'Du hast ein Premium-Feature entdeckt.',
    'PREMIUM_FEATURE_MODAL_PARAGRAPH': "Schalte dieses und viele weitere Features frei, indem du Premium-Mitglied wirst. Zusätzlich unterstützt du uns so.",
    'PREMIUM_FEATURE_MODAL_BUTTON_TEXT': 'Premium werden',
    'PREMIUM_FEATURE_MODAL_CLOSE_BUTTON': 'Schließen',
    'PURCHASE__GET_PREMIUM': 'Premium holen',
    'PURCHASES__SUBSCRIBING': 'Kauf wird vorbereitet…',

    'PURCHASE_HEADLINE': 'Noch einfacheres Zusammenleben mit Flatastic Premium',
    'PURCHASE_BTN-ADV': 'Deine Vorteile',
    'PURCHASE_BTN-REVIEW': 'Nutzermeinungen',
    'PURCHASE_BTN-GO-PREMIUM': 'Premium werden',
    'PURCHASE_INFO-TEXT': 'Automatische Verlängerung, jederzeit kündbar.',
    'PURCHASE_ADV-HEADLINE': 'Deine Vorteile mit Premium',
    'PURCHASE_REVIEW-HEADLINE': 'Das sagen unsere Nutzer',
    'PURCHASE_OFFER-HEADLINE': 'Bist du bereit, dein Zusammenleben ein wenig einfacher zu machen?',
    'PURCHASE_OFFER-PARAGRAPH': 'Werde Premium-Mitglied und unterstütze uns. Schalte dir so zusätzliche Features frei.',
  },
}

export default translations
