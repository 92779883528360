// templates
import { moduleAbbreviationToScreenName } from '../../utils/helpers';
import './inAppNotifcation.scss';
import template from './view_inAppNotification.html';

export default angular.module('flatastic.inAppNotification', [])
.component('inAppNotification', {
  template,
  controller: InAppNotificationController,
})
.name;

/*
 * notification:
 * {
 *   title:   'Some Title',
 *   image:   'Some Image Url',
 *   message: 'Some Message',
 *   module: 'moduleFromData',
 * }
 *
*/

InAppNotificationController.$inject = ['$rootScope', '$state', '$scope']
function InAppNotificationController($rootScope, $state, $scope) {

  $scope.isNotificationShown = false;

  $scope.notificationClose = function() {
    $scope.isNotificationShown = false;
  };

  $scope.$on('inAppNotification-close', function() {
    $scope.notificationClose();
  });

  $scope.$on('inAppNotification-show', function(event, notificationObj) {
    if ($scope.isNotificationShown === true) {
      $scope.$broadcast('inAppNotification-close');
    }
    $scope.isNotificationShown = true;
    $scope.notification = notificationObj;
  });

  $scope.handleNotificationInteraction = function(notificationObj) {
    $scope.$broadcast('inAppNotification-close');
    const screenName = moduleAbbreviationToScreenName(notificationObj.module)
    if (screenName) {
      $state.go(screenName);
    }
    $rootScope.$broadcast('track-event', {
      category: 'InApp Notification',
      action: 'User did click notification',
      label: notificationObj.module,
    });
  }
}
