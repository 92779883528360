angular.module('flatasticApp')

.directive('fileModel', [function() {
  return {
    restrict: 'A',
    scope: {
      model: '=fileModel',
    },
    link: function(scope, element, attrs) {
      element.bind('change', function(){
        scope.$apply(function(){
          scope.model = element[0].files[0];
        });
      });
    },
  };
}]);
