import flatasticNotificationsFeedbackController from './ctrl_notificationsFeedback';
import flatasticNotificationsFeedbackService from './svc_notificationsFeedback';

// templates
import  template from './view_notificationsFeedback.html';

export default angular
.module('flatastic.notificationsFeedback', [
    flatasticNotificationsFeedbackController,
    flatasticNotificationsFeedbackService,
])
.config(
    ['$stateProvider',
    function ($stateProvider) {

    $stateProvider
    .state('wg.settings-notificationsFeedback', {
      url: '/notificationsFeedback',
      views: {
        'menuContent': {
          controller: 'NotificationsFeedbackCtrl',
          template,
        },
      },
    });
  }])
  .name;
