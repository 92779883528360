import AngularTranslate from 'angular-translate'

export default angular.module('flatastic.intro.controller', [
  'ionic',
  AngularTranslate,
])
.controller('IntroController', IntroController)
.name;

IntroController.$inject = ['$ionicSlideBoxDelegate', '$state', '$ionicScrollDelegate', '$translate']
function IntroController($ionicSlideBoxDelegate, $state, $ionicScrollDelegate, $translate) {
  const vm = this
  vm.nextSlide = nextSlide
  vm.scrollTop = scrollTop
  vm.getProposedLanguage = function() { return $translate.proposedLanguage() }

  function scrollTop() {
    $ionicScrollDelegate.scrollTop()
  }

  function nextSlide() {
    if ($ionicSlideBoxDelegate.currentIndex() + 1 === $ionicSlideBoxDelegate.slidesCount()) {
      return $state.go('intro-start')
    }
    $ionicSlideBoxDelegate.next()
  }

}
