import { mapObjIndexed } from 'ramda'

const meta = ['en', 'de']
const trans = {
  [`Notifications`]: [
    `Notifications`,
    `Benachrichtigungen`,
  ],
  [`Enable Notifications`]: [
    `Enable Notifications`,
    `Benachrichtigungen aktivieren`,
  ],
  [`Text: Reason why to enable notifications`]: [
    `Enable notifications so you can receive updates to shopping lists, get riminded to do your chores, and know what the others are talking about.`,
    `Werde benachrichtigt, wenn jemand einkaufen geht, wenn Aufgaben zu erledigen sind oder dir jemand schreibt.`,
  ],
  [`notifications.skip`]: [
    `Skip`,
    `Später`,
  ],
}

export default {
  en: mapObjIndexed((val, key, obj) => val[0])(trans),
  de: mapObjIndexed((val, key, obj) => val[1])(trans),
}
