/*
 *  Wrapper for https://github.com/dpa99c/cordova-launch-review
 */

import { ifElse, equals } from 'ramda'

export default angular
.module('flatastic.app-review.service', [
  'ionic',
])

.factory('AppReview', ['$ionicPlatform', '$window', function($ionicPlatform, $window) {

  return {
    rate,
    launch,
  }

  ////

  function getPlugin() {
    return $ionicPlatform.ready().then(function() {
      if (!$window.LaunchReview) {
        return Promise.reject('Plugin "cordova-launch-review" is not installed')
      }
      return $window.LaunchReview
    })
  }

  function rate() {
    return isRatingSupported().then(ifElse(equals(true), rating, launch))
  }

  // On iOS 10.3 and above, invokes the native in-app rating dialog
  // which allows a user to rate your app without needing to open the App Store.
  function rating() {
    return getPlugin().then(
      LaunchReview => new Promise((resolve, reject) => LaunchReview.rating(resolve, reject))
    )
  }

  // Launches the App Store page for the current app in order for the user to leave a review.
  // On Android, opens the app's in the Play Store where the user can leave a review by pressing the stars to give a rating.
  // On iOS, opens the app's page in the App Store and automatically opens the dialog for the user to leave a rating or review.
  function launch() {
    return getPlugin().then(
      LaunchReview => new Promise((resolve, reject) => LaunchReview.launch(resolve, reject))
    )
  }

  // Indicates if the current platform/version supports in-app ratings dialog, i.e. calling LaunchReview.rating().
  // Will return true if current platform is iOS 10.3 or above.
  function isRatingSupported() {
    return getPlugin().then(LaunchReview.isRatingSupported)
  }

}])

.name;
