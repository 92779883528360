import template from './comp_expenses-listing.html'

export default angular.module('flatastic.expenses.expense-card', [])
  .component('expensesListing', {
    template,
    bindings: {
      expenses: '<',
      interact: '&',
      userId: '<',
      flatmates: '<',
    },
  })
  .name;
