angular.module('flatasticApp')

.directive('adaptHeight', [function () {
  return {
    restrict: 'A',
    link: function (scope, iElement, iAttrs) {
      iElement.ready(function() {
        var children = iElement.children();
        var maxHeight = 0;
        for (var i = 0; i < children.length; i++) {
          var child = children[i];
          if (child.offsetHeight > maxHeight) {
            maxHeight = child.offsetHeight;
          }
        };
        iElement.css({ 'height': maxHeight + 'px' });
      });
    },
  };
}])

.directive('sliderPagerSwitch', function() {
  return {
    restrict: 'A',
    link: function (scope, iElement, iAttrs) {
      var e;
      iElement.ready(function() {
        e = angular.element(iElement[0].querySelector('.slider-pager'));
        var parent = scope.$parent;
        if (!parent.numSlides) {
          return
        }
        var showPager = parent.numSlides() !=
                          parseInt(parent.activeSlide, 10) + 1;
        e.css({ 'display': showPager ? 'block' : 'none' });
      });
      scope.$on('showPager', function(event, show) {
        e.css({ 'display': (show ? 'block' : 'none') });
      });
    },
  };
});
