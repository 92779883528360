showAccessoryBar.$inject = ['yptKeyboard']
function showAccessoryBar(yptKeyboard) {
  return yptKeyboard.hideKeyboardAccessoryBar(false)
}

hideAccessoryBar.$inject = ['yptKeyboard']
function hideAccessoryBar(yptKeyboard) {
  return yptKeyboard.hideKeyboardAccessoryBar(true)
}

export {
  showAccessoryBar,
  hideAccessoryBar
}
