import AngularTranslate from 'angular-translate'
import ApiModule from '../../scripts/modules/api'
import CordovaModule from '../../scripts/modules/yptCordova'
import UserService from '../user/svc_user'
import WgService from '../wg/svc_wg'

export default angular.module('flatastic.auth.login.controller', [
  AngularTranslate,
  ApiModule,
  UserService,
  CordovaModule,
  WgService,
])

.controller('LoginCtrl',
    ['$scope', 'Api', 'User', 'yptNotify', 'Wg', '$filter', '$rootScope', '$translate',
    function ($scope, Api, User, yptNotify, Wg, $filter, $rootScope, $translate) {
  $scope.credentials = {};
  $scope.status = {};

  $scope.forgotPassword = function() {
    return yptNotify.prompt({
      title: $translate.instant('ATH__FORGOT_PASSWORD'),
      message: $translate.instant('ATH__FORGOT_PASSWORD-MSG'),
      defaultValue: $scope.credentials.email || '',
      buttonLabels: [
        $translate.instant('BTN_CANCEL'),
        $translate.instant('CONTINUE'),
      ],
    })
      .success(function(email) {
        return Api.get('/auth/forgot_pw', { params: { email } })
          .success(function(data) {
            $rootScope.$broadcast('track-event', {
              category: 'Login',
              action: 'Requested password',
              label: 'Success',
            });
            yptNotify.alert({
              title: $translate.instant('ATH__FORGOT_PASSWORD'),
              message: $translate.instant('ATH__FORGOT_PASSWORD-SUCCESS', {
                email,
              }),
            });
          })
          .error(function(data) {
            var temp = [];
            for (var key in data) {
              temp.push(data[key]);
            }
            $rootScope.$broadcast('track-event', {
              category: 'Login',
              action: 'Requested password',
              label: data ? temp.join('\n') : 'Error',
            });
            yptNotify.alert({
              title: $translate.instant('ATH__FORGOT_PASSWORD'),
              message: $translate.instant('ATH__FORGOT_PASSWORD-ERROR', {
                error: data ?
                  temp.join('\n') :
                  $translate.instant('NO_INTERNET'),
              }),
            });
          });
      })
      .error(function() {
        $rootScope.$broadcast('track-event', {
          category: 'Login',
          action: 'Requested password',
          label: 'Cancel',
        });
      });
  };
  $scope.login = function() {
    $scope.status.loading = true;
    return User.login($scope.credentials)
      .success(function(data) {
        $rootScope.$broadcast('track-event', {
          category: 'Login',
          action: 'Posted login',
          label: 'Success',
        });
        delete $scope.credentials.password;
        delete $scope.status.loading;
        delete $scope.status.error;
        if (data.wg) {
          Wg.set(data.wg).storeLocally();
          Wg.get();
        }
      })
      .error(function(data, status) {
        $scope.status.error = data;
        delete $scope.status.loading;
        $rootScope.$broadcast('track-event', {
          category: 'Login',
          action: 'Posted login',
          label: 'Error',
        });

        // Show wrong credentials message only for 4XX errors
        if (status >= 400 && status < 500) {
          yptNotify.alert({
            title: $translate.instant('ATH__LOGIN_ERROR-TITLE'),
            message: $translate.instant('ATH__LOGIN_ERROR-MSG'),
          });
        }
      });
  };
}])

.name
