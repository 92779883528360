import FlatasticConfig from '../../scripts/config'
import ApiService from '../../scripts/modules/api'

export default angular.module('flatastic.subscriptions.service.subscription', [
  ApiService,
  FlatasticConfig,
])

.factory('Subscription', ['Api', '$rootScope', 'FlatasticEvents', function(Api, $rootScope, FlatasticEvents) {

  var apiEndpoint = '/subscriptions';
  var dateFields = ['createdAt', 'updatedAt', 'firstBill'];

  function Subscription(data) {
    this.status = '';
    return this.set(data);
  }

  Subscription.prototype.update = function(data) {
    if (Object.keys(data).length <= 0) { return; }
    var self = this;
    self.status = 'updating';
    return Api.put(apiEndpoint, self.makeDataApiConfirm(data))
      .then(function() {
        $rootScope.$broadcast(FlatasticEvents.subscriptions.didUpdateEntry)
        self.status = '';
      })
      .catch(function() {
        self.status = 'error';
      });
  };

  Subscription.prototype.delete = function() {
    self = this;
    self.status = 'deleting';
    return Api.delete(apiEndpoint + '/id/' + self.id)
      .then(function() {
        self.status = '';
        $rootScope.$broadcast(FlatasticEvents.subscriptions.didDeleteEntry)
      })
      .catch(function() {
        self.status = 'error';
      });
  };

  Subscription.prototype.save = function() {
    var self = this;
    self.status = 'saving';
    return Api.post(apiEndpoint, self.makeDataApiConfirm(self))
      .then(function({ data }) {
        self.set(data);
        self.status = '';
        $rootScope.$broadcast(FlatasticEvents.subscriptions.didCreateEntry)
      })
      .catch(function() {
        self.status = 'error';
      });
  };


  Subscription.prototype.makeDataApiConfirm = function(data) {
    var output = {};
    if (!data.id) {
      output.id = this.id;
    }
    for (var key in data) {
      var value = data[key];
      if (value instanceof Date) {
        value = parseInt(value.getTime() / 1000, 10);
      }
      output[key] = value;
    }
    delete output.status;
    return output;
  };

  Subscription.prototype.set = function(data) {
    for (var key in data) {
      var value = data[key];
      if (dateFields.indexOf(key) > -1 && !(value instanceof Date)) {
        if (typeof value === 'string') {
          value = new Date(value);
         } else {
          value = new Date(value * 1000);
        }
      }
      this[key] = value;
    }
    return this;
  };

  return Subscription;

}])

.name;
