import { mapObjIndexed } from 'ramda'

const meta = ['en', 'de']
const trans = {
  [`Modal Title: New look`]: [
    `Flatastic is getting a fresh new look`,
    `Flatastic bekommt einen frischen Look`,
  ],
  [`Modal Body: New look`]: [
    `Every update brings us closer to the new look. You might see some inconsistencies until it's done.`,
    `Update für Update passen wir Flatastic ans neue Design an.`,
  ],
  [`Got it`]: [
    `Got it`,
    `Alles klar`,
  ],
}

export default {
  en: mapObjIndexed((val, key, obj) => val[0])(trans),
  de: mapObjIndexed((val, key, obj) => val[1])(trans),
}
