angular.module('flatasticApp')

.directive('selectOnFocus',
    ['$timeout', '$window', '$document',
    function($timeout, $window, $document) {

  return function(scope, elem, attr) {
    var hasBeenSelected = false;
    var hasBeenUnselected = false;

    // Select input text on first focus
    elem.bind('focus', function(event) {
      var self = this;
      if (elem.val() &&
           !getSelectedText() &&
           !(self === $document.activeElement) &&
           !hasBeenSelected) {
        $timeout(function() {
          self.select();
          hasBeenSelected = true;
        }, 0);
      }
    });

    // Unselect text when clicking again to enable word editing
    elem.bind('click', function(event) {
      if (elem.val() && getSelectedText() && hasBeenSelected && !hasBeenUnselected) {
        unselectText();
        hasBeenUnselected = true;
      }
    });

    elem.bind('blur', function(event) {
      hasBeenSelected = false;
    });

    function getSelectedText() {
      var text = '';
      if (typeof $window.getSelection != 'undefined') {
        text = $window.getSelection().toString();
      } else if (typeof $document.selection != 'undefined' &&
          $document.selection.type == 'Text') {
        text = $document.selection.createRange().text;
      }
      return text;
    }

    function unselectText() {
      if (!getSelectedText()) {
        return;
      }
      if ($document.selection) {
        $document.selection.empty();
      } else if ($window.getSelection ) {
        $window.getSelection().removeAllRanges();
      }
    }
  };
}]);
