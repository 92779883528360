import AngularTranslate from 'angular-translate'
import { join, map, omit, pipe, values } from 'ramda'
import FlatasticConfig from '../../scripts/config'
import CordovaModule from '../../scripts/modules/yptCordova'
import LanguageModule from '../../scripts/modules/yptLanguage'
import UserService from '../user/svc_user'
import WgService from '../wg/svc_wg'

export default angular.module('flatastic.intro.signup.controller', [
  AngularTranslate,
  UserService,
  LanguageModule,
  FlatasticConfig,
  WgService,
  CordovaModule,
])
.controller('SignUpController', SignUpController)
.name


const initialState = {
  email: '',
  password: '',
  isBusy: false,
  isError: false,
  error: undefined,
}

SignUpController.$inject = ['User', 'yptLanguage', '$rootScope', '$translate', 'Wg', '$state', '$stateParams']
function SignUpController(User, yptLanguage, $rootScope, $translate, Wg, $state, $stateParams) {
  const vm = this;

  vm.state = { ...initialState }
  vm.signUp = signUp
  vm.resetError = resetError

  init()

  ////

  function init() {
    // Get user's language
    getLanguageId()
      .then(function(languageId) {
        vm.state.languageId = languageId;
      });
  }

  function signUp() {
    resetError()
    const { email, password, languageId } = vm.state
    vm.state.isBusy = true
    vm.error = undefined
    Wg.setIsSettingUp(true)
    const data = { email, password, languageId };
    const shouldBroadcast = true
    if ($stateParams.code) {
      data.wgCode = $stateParams.code
      Wg.setIsSettingUp(false)
    }
    User.register(data, shouldBroadcast)
      .then(({ data }) => {
        vm.state = { ...initialState }
        $rootScope.$broadcast('track-event', {
          'category': 'Create WG',
          'action': 'Created WG',
          'label': 'Success',
        });
        Wg.set(data.wg).storeLocally();
        Wg.get();
        return $state.go('intro-user-name')
      })
      .catch((error) => {
        const { data, status } = error
        Wg.setIsSettingUp(false)
        vm.state = { ...vm.state, isError: true, error: data }
        $rootScope.$broadcast('track-event', {
          'category': 'Create WG',
          'action': 'Creation did fail',
          'label': data ? JSON.stringify(data) : 'Error',
        });
        vm.state.errorMessage = data && pipe(
          omit(['email', 'password']),
          values,
          map($translate.instant),
          join('\n')
        )(data)
        if (status === -1) {
          vm.state.errorMessage = $translate.instant('You seem to be offline. Please check your connection and retry.')
        }
      })
      .finally(() => {
        vm.state.isBusy = false
      })
  }

  function resetError() {
    vm.state.error = false
    vm.state.isError = false
    vm.state.errorMessage = undefined
  }

  function getLanguageId() {
    return yptLanguage.get()
      .then(function(language) {
        return ({
          'en': 1,
          'de': 2,
        })[language] || 1;
      })
  }

}
