import { defaultTo } from 'ramda'
import Currencies from '../../utils/currencies'

angular.module('flatasticApp')

.filter('wgCurrency', ['Wg', '$filter', function(Wg, $filter) {
  return function(val, currencySymbol) {
    const currencySign = defaultTo(Wg.currency)(Currencies[Wg.currency])
    var hasContintentalFormat = ['€', 'CHF', 'EUR'].indexOf(Wg.currency) > -1;
    if (hasContintentalFormat) {
      var output = $filter('currency')(val, '')
      if (val < 0) {
        output = output.replace('(', '').replace(')', '');
      }
      output += currencySign
      return output.replace(',', '_').replace('.', ',').replace('_', '.');
    }
    return $filter('currency')(val, currencySign || '€');
  };
}]);
