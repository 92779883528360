import yepptCordova from './yptCordova'

export default angular.module('flatastic.statusBar', [
  yepptCordova,
])
.factory('StatusBar', StatusBar)
.name;

StatusBar.$inject = ['$window', 'yptCordova']
function StatusBar($window, yptCordova) {

  return {
    backgroundColorByHexString,
    hide,
    setStyleDefault,
    setStyleLightContent,
    show,
  }

  //// Functions

  function warn() {
      console.warn('StatusBar Plugin is not installed');
  }

  function isStatusbarAvailable() {
    return $window.StatusBar !== undefined;
  }

  function setStyleDefault() {
    return getPlugin().then(statusBar => statusBar.styleDefault())
  }

  function setStyleLightContent() {
    return getPlugin().then(statusBar => statusBar.styleLightContent())
  }

  function show() {
    return getPlugin().then(statusBar => statusBar.show())
  }

  function hide() {
    return getPlugin().then(statusBar => statusBar.hide())
  }

  function backgroundColorByHexString(hex) {
    return getPlugin().then(statusBar => statusBar.backgroundColorByHexString(hex))
  }

  function getPlugin() {
    return yptCordova.ready.then(function() {
      if (!isStatusbarAvailable()) {
        warn()
        return Promise.reject('StatusBar Plugin is not installed')
      }
      return $window.StatusBar
    })
  }
}
