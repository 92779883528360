export default [{
  value: 'sharedflat',
  label: 'WG__TYPES-SHAREDFLAT',
}, {
  value: 'couple',
  label: 'WG__TYPES-COUPLE',
}, {
  value: 'family',
  label: 'WG__TYPES-FAMILY',
}]
