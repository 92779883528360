import flatasticConfig from '../../scripts/config'
import yepptApi from '../../scripts/modules/api'
import FileSystemModule from '../../scripts/modules/fileSystem'
import yepptActionsheet from '../../scripts/modules/yptActionsheet'
import yepptCamera from '../../scripts/modules/yptCamera'
import yepptCordova from '../../scripts/modules/yptCordova'
import yepptKeyboard from '../../scripts/modules/yptKeyboard'
import { getCountryIdFromAlpha3Code } from '../../utils/helpers'
import flatasticUserService from '../user/svc_user'
import flatasticWgService from './svc_wg'

export default angular.module('flatastic.wg.controller', [
  'ionic',
  flatasticConfig,
  flatasticUserService,
  flatasticWgService,
  yepptActionsheet,
  yepptApi,
  yepptCamera,
  yepptCordova,
  yepptKeyboard,
  FileSystemModule,
])

.controller('WgCtrl',
    ['$scope', 'Wg', 'yptNotify', '$ionicLoading', '$timeout',
    'yptActionsheet', 'yptKeyboard', '$ionicScrollDelegate', '$filter',
    'User', '$state', '$rootScope', 'yptCamera', 'Api', 'WgTypes', 'FlatasticEvents', 'Expenses',
    'Countries', 'FileSystem',
    function ($scope, Wg, yptNotify, $ionicLoading, $timeout,
    yptActionsheet, yptKeyboard, $ionicScrollDelegate, $filter,
    User, $state, $rootScope, yptCamera, Api, WgTypes, FlatasticEvents, Expenses,
    Countries, FileSystem) {

  $scope.wgData    = Wg.getScaffold();
  $scope.flatmates = Wg.flatmates;
  $scope.resetActive = false;

  Wg.imageUploading = false;

  $scope.switch    = localStorage['Flatastic.Wg.switch'] || 'flatmates';
  $scope.changeSwitch = function(val) {
    $scope.switch = val;
    localStorage['Flatastic.Wg.switch'] = val;
    $ionicScrollDelegate.resize()
  };
  $scope.languages = Wg.getAvailableLanguages();
  $scope.availableTypes = WgTypes;
  $scope.countries = Countries;
  $scope.Wg = Wg;

  $scope.setCurrency = () => {
    const country = Countries.find(country => country.alpha3 === $scope.wgData.countryCode)
    $scope.wgData.currency = country.currency
  }

  $scope.getCountryLabel = country => `${country.flag} ${$filter('translate')(country.name)}`

  $scope.showHelp = function() {
    yptNotify.alert({
      title: $filter('translate')('WG__HELP-TITLE'),
      message: $filter('translate')('WG__HELP-MSG'),
      trackEvent: {
        name: 'showHelp',
        parameters: {
          context: 'settings__wg_profile',
        },
      },
    });
    $rootScope.$broadcast('track-event', {
      category: 'Help',
      action: 'Clicked',
      label: '/wg',
    });
  };

  // Enables image upload from webapp
  $scope.data = {};
  $scope.isCordovaDevice = (window && window.device);
  $scope.$watch('data.file', function(newValue, oldValue) {
    if (Wg.imageUploading ||
          newValue === oldValue ||
          !$scope.data.file) {
      return;
    }

    Wg.imageUploading = true;
    Api.browserFileUpload($scope.data.file, '/wg/image')
    .success(function(data) {
      Wg.set({ image: data.wg_image.full_s3_url }).storeLocally();
    })
    .error(function() {
      yptNotify.alert({
        title: $filter('translate')('WG__IMAGE_ERROR_DIALOGUE-TITLE'),
        message: $filter('translate')('WG__IMAGE_ERROR_DIALOGUE-MSG'),
      });
    })
    .finally(function() {
      delete Wg.imageUploading;
    });
  });

  $scope.takeImage = function() {
    // cancel function if
    // - it is not called by the flatastic app
    // - an image is already beeing uploaded
    if (!window || !window.device || Wg.imageUploading) { return; }

    var options = {
      title: $filter('translate')('WG__IMAGE_DIALOGUE-TITLE'),
      buttonLabels: [
        $filter('translate')('WG__IMAGE_DIALOGUE-FROM_GALLERY'),
        $filter('translate')('WG__IMAGE_DIALOGUE-TAKE_PHOTO'),
      ],
      androidEnableCancelButton: false,
      addCancelButtonWithLabel: $filter('translate')('BTN_CANCEL'),
    };
    var actions = [
      function() { getPicture(0); },
      function() { getPicture(1); },
    ];
    yptActionsheet.show(options, actions);
  };

  $scope.status = function() {
    return Wg.status;
  };

  $scope.hideLoading = false;
  $scope.refresh = function() {
    $scope.hideLoading = true;
    Wg.get().finally(function() {
      $scope.$broadcast('scroll.refreshComplete');
      $scope.hideLoading = false;
    });
  };

  $scope.saveChanges =  function() {
    // Fixes bug where input from iOS select wheel wasn't taken into account
    // When clicking directly on save while the select wheel is open
    $timeout(saveChanges, 0);
  };


  /*
   * Move out
   */
  $scope.isMovingOut = false;
  $scope.moveOut = function() {
    // Prevent that action is called twice
    if ($scope.isMovingOut) {
      return;
    }
    $scope.isMovingOut = true;
    yptNotify.confirm({
      title: $filter('translate')('WG__MOVE_OUT__START-TITLE'),
      message: $filter('translate')('WG__MOVE_OUT__START-MSG'),
      buttonLabels: [
        $filter('translate')('BTN_CANCEL'),
        $filter('translate')('WG__MOVE_OUT__START-CONFIRM'),
      ],
      callback: function(answer) {
        if (answer === true) {
          prepareMoveOut();
        } else {
          $scope.isMovingOut = false;
        }
      },
    });
  };

  //// Functions

  function prepareMoveOut() {
    $scope.resetActive = true;
    $ionicLoading.show({
      template: 'Dein Auszug wird vorbereitet…',
    });
    Wg.checkMoveOut()
    .success(function(data) {
      if (!data.settlement) {
        console.log('move out error', data);
        return;
      }
      if (data.settlement.length == 0) {
        confirmMoveOut($filter('translate')('WG__MOVE_OUT_CONFIRM'));
      }
      if (data.settlement.length > 0) {
        var message = '',
            sender,
            receiver;
        data.settlement.forEach(function(cashflow) {
          sender = Wg.flatmates._get(cashflow.transaction_from).firstName;
          receiver = Wg.flatmates._get(cashflow.transaction_to).firstName;
          message += $filter('translate')('WG__SETTLEMENT_MESSAGE_PART', {
            sender: sender,
            valueString: $filter('wgCurrency')(cashflow.amount),
            receiver: receiver,
          });
        });
        yptNotify.confirm({
          title: $filter('translate')('WG__SETTLEMENT_CONFIRM-TITLE'),
          message: $filter('translate')('WG__SETTLEMENT_CONFIRM-MSG', {
            settlement: message,
          }),
          buttonLabels: [
            $filter('translate')('WG__SETTLEMENT_CONFIRM-NOT_YET'),
            $filter('translate')('WG__SETTLEMENT_CONFIRM-DO_NOW'),
          ],
          callback: function(answer) {
            if (answer === true) {
              Expenses.doResetBalance()
                .success(function() {
                  confirmMoveOut($filter('translate')('WG__SETTLEMENT_CONFIRM_SUCCESS_CONFIRM_MOVE_OUT'));
                });
            }
          },
        });
      }
    })
    .finally(function(data) {
      $scope.isMovingOut = false;
      $ionicLoading.hide();
    });
  };

  function confirmMoveOut(message) {
    yptNotify.confirm({
      title: $filter('translate')('WG__MOVE_OUT_CONFIRM-TITLE'),
      message: message,
      buttonLabels: [
        $filter('translate')('BTN_CANCEL'),
        $filter('translate')('WG__MOVE_OUT_CONFIRM_BUTTON'),
      ],
      callback: function(answer) {
        if (answer === true) {
          Wg.moveOut()
          .success(function(data) {
            User.set(data.user).storeLocally();
            yptNotify.alert({
              title: $filter('translate')('WG__MOVE_OUT_CONFIRM_SUCCESS-TITLE'),
              message: $filter('translate')('WG__MOVE_OUT_CONFIRM_SUCCESS-MSG'),
            });
            $scope.resetActive = false;
            $rootScope.$broadcast(FlatasticEvents.user.didLeaveWg);
            $state.go('homeless');
          })
          .error(function() {
            yptNotify.alert({
              title: $filter('translate')('WG__MOVE_OUT_CONFIRM_ERROR-TITLE'),
              message: $filter('translate')('WG__MOVE_OUT_CONFIRM_ERROR-MSG'),
            });
            $scope.resetActive = false;
          });
        }
      },
    });
  };

  function getPicture(sourceType) {
    yptCamera.takePicture({ sourceType: sourceType, cameraDirection: 1 })
    .success(function(fileURL) {
      $timeout(function() {
        Wg.set({ image: FileSystem.getLocalFilePath(fileURL) })
        Wg.imageUploading = true
      }, 0)
      return Api.upload(fileURL, '/wg/image')
        .then(function({ data }) {
          delete Wg.imageUploading;
          Wg.set({
            image: data.wg_image.full_s3_url,
          }).storeLocally();
        })
        .catch(function(error) {
          console.error('Image could not be uploaded', error);
          delete Wg.imageUploading;
          yptNotify.alert({
            title: $filter('translate')('WG__IMAGE_ERROR_DIALOGUE-TITLE'),
            message: $filter('translate')('WG__IMAGE_ERROR_DIALOGUE-MSG', {
              error: JSON.stringify(error),
            }),
          });
        })
        .finally(() => $timeout(0));
    })
    .error(function(data) {
      console.log('Error:', data);
    });
  };

  function saveChanges() {
    // set proposals to be english for all countries except for Germany, Austria and Switzerland
    $scope.wgData.country = getCountryIdFromAlpha3Code($scope.wgData.countryCode);
    yptKeyboard.close();
    if (Wg.status == 'saving') {
      console.log('already saving', Wg.status);
      return;
    }
    Wg.save($scope.wgData)
    .success(function() {
      $scope.wgData = Wg.getScaffold();
      yptNotify.alert({
        title: $filter('translate')('WG__SAVE_SUCCESS-TITLE'),
        message: $filter('translate')('WG__SAVE_SUCCESS-MSG'),
      });
    })
    .error(function() {
      $scope.wgData = Wg.getScaffold();
    })
  }

}])

.name;
