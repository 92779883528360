import yepptApi from '../../scripts/modules/api';
import { v4 as generateUuid } from 'uuid'

export default angular.module('flatastic.notificationsFeedback.service', [
  yepptApi,
])
.factory('TestNotification', [
  'Api', '$rootScope', '$q', '$timeout', 'Config',
  function(Api, $rootScope, $q, $timeout, Config) {

  function TestNotification(name, type, deviceToken) {
    this.uuid = generateUuid();
    this.type = type;
    this.name = name;
    this.deviceToken = deviceToken;
    this.wasSuccessful = undefined;
    this.isPending = false;
    this.timer = undefined;
    this.unsubscribeListener = undefined;
    this.deferred = $q.defer();
    this.promise = this.deferred.promise;
  }

  TestNotification.prototype.runTest = function() {
    var self = this;
    var payload = {
      uuid: self.uuid,
      type: self.type,
      deviceToken: self.deviceToken,
      senderId: Config.senderId,
    };

    self.isPending = true;
    self.timer = undefined;
    self.wasSuccessful = undefined;

    // Start listening before doing the request to avoid race conditions
    self.unsubscribeListener = $rootScope.$on('push-receive',
        function(event, notification) {
      if (notification.userdata.uuid !== self.uuid) {
        return;
      }
      self.markAsSuccessful();
    });
    // Trigger the push notification
    return Api.post('/pushes/tests', payload)
      .then(function() {
        // We wait a maximum of 10s to receive it
        self.timer = $timeout(function() {
          self.markAsUnsuccessful()
        }, 10000);
      })
      .catch(function() {
        self.markAsUnsuccessful()
      });
  };

  TestNotification.prototype.markAsSuccessful = function() {
    var self = this;
    self.wasSuccessful = true;
    var data = { received: self.wasSuccessful };
    self.isPending = false;
    self.deferred.resolve();
    if (self.timer) {
      $timeout.cancel(self.timer);
    }
    if (self.unsubscribeListener) {
      self.unsubscribeListener();
    }
    // Tell backend that push notification was received
    Api.put('/pushes/tests/' + self.uuid, data);
  };

  TestNotification.prototype.markAsUnsuccessful = function() {
    var self = this;
    self.wasSuccessful = false;
    var data = { received: self.wasSuccessful };
    self.isPending = false;
    self.deferred.reject();
    if (self.unsubscribeListener) {
      self.unsubscribeListener();
    }
    // Tell backend that push notification was not received
    Api.put('/pushes/tests/' + self.uuid, data);
  };

  return TestNotification;

}])
.name;
