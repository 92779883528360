import yepptApi from '../../scripts/modules/api';
import flatasticUserService from '../user/svc_user';

export default angular.module('flatastic.chores.history.service', [
  yepptApi,
  flatasticUserService,
])

.factory('ChoresHistory', ['Api', function(Api) {

  var choresHistory = {
    data: [],
  };
  var limitGet = 10;
  var moreCanBeLoaded = false;

  return {
    fetch: fetchFromServer,
    get: function() { return choresHistory; },
    getStatus: function() { return choresHistory.status },
    loadMore: loadMore,
    moreCanBeLoaded: function() {
      return moreCanBeLoaded;
    },
  }

  ////

  function fetchFromServer(queryParams) {
    choresHistory.status = 'loading';
    var params = {};
    for (var key in queryParams) {
      if (queryParams[key] !== null) {
        params[key] = queryParams[key];
      }
    }
    params.limit = limitGet;
    params.offset = 0;
    return Api.get('/chores/history', { params: params })
      .success(function(data) {
        choresHistory.data = data.map(formatEntry);
        moreCanBeLoaded = (data.length >= limitGet);
        delete choresHistory.error;
        delete choresHistory.status;
      })
      .error(function(data) {
        data = data || {};
        choresHistory.error = data.error || $filter('translate')('NO_INTERNET');
        delete choresHistory.status;
      })
  };

  function loadMore(queryParams) {
    var params = {};
    for (var key in queryParams) {
      if (queryParams[key] !== null) {
        params[key] = queryParams[key];
      }
    }
    params.limit = limitGet;
    params.offset = choresHistory.data.length;

    return Api.get('/chores/history', { params: params })
    .success(function(data) {
      var formatedData = data.map(formatEntry);
      choresHistory.data = choresHistory.data.concat(formatedData);
      // if 10 items are requested and only 7 returned, there aren't any more…
      moreCanBeLoaded = (data.length >= limitGet);
    });
  };

  function formatEntry(entry) {
    entry.completedAt = new Date(entry.completedAt * 1000);
    return entry;
  }
}])

.name;
