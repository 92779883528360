import { mergeAll } from 'ramda'
import choreTranslations from '../modules/chores/translations'
import introTranslations from '../modules/intro/translations'
import paywallTranslations from '../modules/paywall/translations'
import { translations as purchaseTranslations } from '../modules/purchase/cfg_purchase'
import purchaseViewTranslations from '../modules/purchase/translations'
import { translations as pushPrimerTranslations } from './modules/pushnotification'

const langToIdx = lang => ({
  en: 0,
  de: 1,
}[lang])
export const getTranslations = lang => translations => translations.reduce((acc, elem) => ({
  ...acc,
  [elem[0]]: elem[langToIdx(lang)],
}), {})

angular.module('flatasticApp')

.config(['$translateProvider', function($translateProvider) {

  var DE = {

    // Countries
    'Argentina': 'Argentinien',
    'Australia': 'Australien',
    'Austria': 'Österreich',
    'Belgium': 'Belgien',
    'Brazil': 'Brasilien',
    'Bulgaria': 'Bulgarien',
    'Canada': 'Kanada',
    'China': 'China',
    'Denmark': 'Dänemark',
    'Finland': 'Finland',
    'France': 'Frankreich',
    'Germany': 'Deutschland',
    'Greece': 'Griechenland',
    'India': 'Indien',
    'Ireland': 'Irland',
    'Italy': 'Italien',
    'Japan': 'Japan',
    'Mexico': 'Mexiko',
    'Netherlands': 'Niederlande',
    'Norway': 'Norwegen',
    'Poland': 'Polen',
    'Portugal': 'Portugal',
    'Russia': 'Russland',
    'Spain': 'Spanien',
    'Sweden': 'Schweden',
    'Switzerland': 'Schweiz',
    'Turkey': 'Türkei',
    'United Kingdom': 'Großbritanien',
    'USA': 'USA',

    // often used
    'CONFIRM': 'Alles klar',
    'ADD': 'Hinzufügen',
    'CANCEL': 'Abbrechen',
    'BTN_CANCEL': 'Abbrechen',
    'CONTINUE': 'Weiter',
    'OF_COURSE': 'Ja klar',
    'OK': 'OK',
    'YES': 'Ja',
    'NO': 'Nein',
    'DELETE': 'Löschen',
    'REMOVE': 'Entfernen',
    'DISCARD': 'Verwerfen',
    'UPDATE': 'Aktualisieren',
    'REFRESH': 'Aktualisieren',
    'SAVE': 'Speichern',
    'EDIT': 'Bearbeiten',
    'DONE': 'Fertig',
    'TRY_AGAIN': 'Erneut versuchen',
    'NO_INTERNET': 'Kein Internet?',
    'NO_MORE_ENTRIES': 'Keine weiteren Einträge vorhanden',
    'SAY_THANKS': 'Danke sagen',
    'FOLLOW': 'Öffne',
    'PROPOSALS': 'Vorschläge',
    'AND': 'und',
    'OR': 'oder',
    'NEXT': 'Danach',
    'LIKED_THIS': 'gefällt das',
    'BACK': 'Zurück',
    'ENTER_FIRST_NAME': 'Vornamen eingeben',
    'DATE': 'Datum',
    'CLOSE': 'Schließen',
    'SHOW_MORE': 'Mehr anzeigen',
    'HOUSEHOLDS': 'Haushalte',
    'REMIND': 'Erinnern',
    'COMPLETE': 'Erledigen',
    'BUY_NOW': 'Jetzt kaufen',
    'MORE': 'Mehr…',
    'DELETED': 'Gelöscht.',
    'TITLE': 'Titel',
    'PRICE': 'Preis',
    'COMING_SOON': 'Kommt Bald',

    // API ERROR HANDLINGS
    'API__ERROR__5XX': 'Oh oh. Ein Serverfehler 👿. Klicke hier für mehr ' +
        'Informationen.',
    'API__ERROR__NO_INTERNET': 'Unser Server ist nicht erreichbar. ' +
        'Bitte überprüfe deine Internetverbindung.',

    // GLOBALS
    'GLO__APP_NAME': 'Flatastic',
    'GLO__WG_NAME': 'WG-Name',
    'GLO__CITY': 'Stadt',
    'GLO__POST_CODE': 'PLZ',
    'GLO__COUNTRY': 'Land',
    'GLO__CURRENCY': 'Währung',

    // SINGLE WORDS
    'UNTIL': 'bis',
    'NEW': 'neu',
    'POINT': 'Punkt',
    'POINTS': 'Punkte',
    'TODAY': 'heute',
    'FLATMATE': 'Mitbewohner',
    'FLATMATES': 'Mitbewohner',
    'SUM': 'Summe',
    'LOGOUT': 'Logout',
    'LATER': 'Später',

    // USER
    'USR__TITLE': 'Mein Profil',
    'USR__CHANGE-FIRST_NAME': 'Namen ändern',
    'USR__CHANGE-PWD': 'Passwort ändern',
    'USR__CHANGE-PWD_INFO': 'Mindestlänge: 6 Zeichen',

    'USR__FORM-FIRST_NAME': 'Vorname',
    'USR__FORM-PWD_OLD': 'Altes Passwort',
    'USR__FORM-PWD_NEW': 'Neues Passwort',

    'USR__HELP-TITLE': 'Dein Profil',
    'USR__HELP-MSG':
        'Hier kannst du dein Profilbild, deinen Vornamen und dein ' +
        'Passwort ändern.',
    'USR__CHANGE_PW_TITLE': 'Passwort ändern',
    'USR__CHANGE_PW_SUCCESS-MSG': 'Dein Passwort wurde geändert.',
    'USR__CHANGE_PW_FAILED-MSG':
        'Dein Passwort wurde nicht geändert. Vermutlich hast ' +
        'du dein altes Passwort falsch eingegeben.',
    'USR__CHANGE_PW_ERROR-MSG':
        'Beim Ändern des Passworts ist ein Fehler aufgetreten: {{ error }} ',

    'USR__CHANGE_EMAIL': 'E-Mail-Adresse ändern',
    'USR__CHANGE_EMAIL-INFO': 'Deine aktuelle E-Mail: {{ email }}',
    'USR__CHANGE_EMAIL-EXTRAINFO': 'Bitte gib eine gültige E-Mail-Adresse ein.',
    'USR__CHANGE_EMAIL-SUCCESS': 'Deine E-Mail-Adresse ist jetzt {{ email }}',
    'USR__FORM-EMAIL_NEW': 'Neue E-Mail',

    'USR__LOGOUT-TITLE': 'Ausloggen',
    'USR__LOGOUT-MSG': 'Möchtest du dich wirklich ausloggen?',
    'USR__LOGOUT-BTN': 'Ausloggen',

    'USR__IMAGE-BTN': 'Klicke auf das Profilbild um es zu ändern',
    'USR__IMAGE_DIALOGUE-TITLE': 'Bitte wähle',
    'USR__IMAGE_DIALOGUE-FROM_GALLERY': 'Bild aus Galerie',
    'USR__IMAGE_DIALOGUE-TAKE_PHOTO': 'Foto machen',
    'USR__IMAGE_ERROR_DIALOGUE-TITLE': 'Upload fehlgeschlagen',
    'USR__IMAGE_ERROR_DIALOGUE-MSG':
        'Folgender Fehler ist aufgetreten:\n\n {{ error }}',

    'YOUR_FIRST_NAME': 'Dein Vorname',
    'YOUR_EMAIL': 'E-Mail',
    'EMAIL_PLACEHOLDER': 'max@mustermann.de',
    'YOUR_PASSWORD': 'Passwort',
    'USR__PASSWORD_INFO': 'Das Passwort muss mindestens 6 Zeichen haben.',
    'USR__PASSWORD_PLACEHOLDER': 'Min. 6 Zeichen',

    // AUTH
    'ATH__INTRO_WELCOME-TITLE': 'Willkommen zu Hause.',
    'ATH__INTRO_WELCOME-SUBTITLE_PART_1': 'flatastic ist die App',
    'ATH__INTRO_WELCOME-SUBTITLE_PART_2': 'für dich und deine WG',
    'ATH__INTRO_CHORES-TITLE': 'Putzplan',
    'ATH__INTRO_CHORES-SUBTITLE_PART_1': 'Lass dich erinnern. Erinnere andere.',
    'ATH__INTRO_CHORES-SUBTITLE_PART_2': 'Bedanke dich.',
    'ATH__INTRO_SHOPPING_LIST-TITLE': 'Einkaufsliste',
    'ATH__INTRO_SHOPPING_LIST-SUBTITLE_PART_1': 'Sieh immer was in deiner',
    'ATH__INTRO_SHOPPING_LIST-SUBTITLE_PART_2': 'WG gebraucht wird.',
    'ATH__INTRO_SHOUTS-TITLE': 'Pinnwand',
    'ATH__INTRO_SHOUTS-SUBTITLE_PART_1': 'Steht etwas an?',
    'ATH__INTRO_SHOUTS-SUBTITLE_PART_2': 'Sag es deiner WG.',
    'ATH__INTRO_EXPENSES-TITLE': 'Finanzen',
    'ATH__INTRO_EXPENSES-SUBTITLE_PART_1': 'Geld auslegen, ausleihen, zurückgeben.',
    'ATH__INTRO_EXPENSES-SUBTITLE_PART_2': 'Und den Überblick nicht verlieren.',
    'ATH__INTRO_SLOGAN_PART_1': 'Willkommen zu Hause',
    'ATH__INTRO_SLOGAN_PART_2': '',
    'ATH__INTRO_MOVE_IN': 'Zugangscode eingeben',
    'ATH__INTRO_ALREADY_HAVE_ACCOUNT': 'Ich habe bereits einen Account',

    'ATH__FOUND_WG': 'WG anlegen',
    'ATH__FOUND_WG-CONFIRM': 'Neue WG anlegen',
    'ATH__FOUND_LOADING': 'WG wird angelegt…',
    'ATH__FOUND_WG-DESCRIPTION':
        'Erstelle zum Anlegen deiner WG einen Account.',
    'ATH__FOUND_WG_ERROR':
        'Beim Anlegen der WG ist leider ein Fehler aufgetreten:\n\n{{ error }}',

    'ATH__FORGOT_PASSWORD': 'Passwort vergessen',
    'ATH__FORGOT_PASSWORD-MSG':
        'Gib deine E-Mail-Adresse ein, ' +
        'damit wir dir ein neues Passwort schicken können.',
    'ATH__FORGOT_PASSWORD-CONFIRM': 'Passwort vergessen',
    'ATH__FORGOT_PASSWORD-SUCCESS':
        'Wir haben ein neues Passwort an "{{ email }}" ' +
        'geschickt. Bitte ändere es direkt nach dem Einloggen.',
    'ATH__FORGOT_PASSWORD-ERROR':
        'Wir konnten dir kein neues Passwort schicken:\n\n{{ error }}',

    'ATH__LOGIN-TITLE': 'Anmelden',
    'ATH__LOGIN-SUBHEAD': '',
    'ATH__LOGIN-CONFIRM': 'Anmelden',

    'ATH__LOGIN_ERROR-TITLE': 'Login fehlgeschlagen',
    'ATH__LOGIN_ERROR-MSG':
        'Wir konnten dich leider nicht anmelden. Bitte ' +
        'überprüfe noch einmal die E-Mail-Adresse und das ' +
        'Passwort.',

    'ATH__REGISTER_ERROR-TITLE': 'Registrierung fehlgeschlagen',
    'ATH__REGISTER_ERROR-MSG':
        'Folgender Fehler ist aufgetreten: \n\n{{ error }}',

    'ATH__MOVE_IN-TITLE': 'In WG einziehen',
    'ATH__MOVE_IN-SUBHEAD':
        'Account erstellen und in WG einziehen.',
    'ATH__MOVE_IN-MSG': 'Bitte gib den Code für deine WG ein',
    'ATH__MOVE_IN-LOADING': 'Einzug…',

    // CHORES
    'CHO__HELP-TITLE': 'Der Putzplan',
    'CHO__HELP-MSG':
        'Flatastic erinnert dich, wenn du deinen Dienst erledigen ' +
        'musst. Erinnere deine Mitbewohner sanft, wenn sie ihren ' +
        'Dienst vergessen haben, oder übernehme ihn stattdessen. ' +
        'Das Punktesystem behält die Gerechtigkeit bei.',

    'CHO__NEW-TITLE': 'Aufgabe hinzufügen',
    'CHO__NEW_ERROR-TITLE': 'Aufgabe hinzufügen',
    'CHO__NEW_ERROR-MSG':
        'Beim Anlegen der Aufgabe ist ein Fehler aufgetreten: {{ error }}',
    'CHO__NEW-BTN': 'Neue Aufgabe',
    'CHO__NEW__GO_BACK-TITLE': 'Putzplan',
    'CHO__NEW__GO_BACK-MSG': 'Möchtest du wirklich zurück ohne vorher noch zu speichern?',

    'CHO__OVERVIEW': 'Übersicht',
    'CHO__YOUR_STATUS': 'Dein Stand',

    'CHO__STATISTIC': 'Statistik',
    'CHO__STATISTIC-LOADING': 'Statistik wird aktualisiert…',
    'CHO__STATISTIC-LOADING_ERROR': 'Fehler beim Aktualisieren',

    'CHO__STATISTICS_RESET_BTN': 'Statistik zurücksetzen',
    'CHO__STATISTICS_RESET_CONFIRM-TITLE': 'Statistik zurücksetzen',
    'CHO__STATISTICS_RESET_CONFIRM-MSG':
        'Möchtest du die Statistik wirklich zurücksetzen? Diese Aktion kann ' +
        'nicht rückgängig gemacht werden.',
    'CHO__STATISTICS_RESET_SUCCESS-TITLE': 'Statistik zurücksetzen',
    'CHO__STATISTICS_RESET_SUCCESS-MSG':
        'Die Putzplan-Statistik wurde zurückgesetzt.',
    'CHO__STATISTICS_RESET_ERROR-TITLE': 'Statistik zurücksetzen',
    'CHO__STATISTICS_RESET_ERROR-MSG':
        'Leider gab es einen Fehler:\n{{ error }}',

    'CHO__REMIND_USER-MSG':
        '🔔 {{ name }} wurde an die die Aufgabe "{{ title }}" erinnert.',
    'CHO__REMIND_USER-CONFIRM': 'Erinnern',

    'CHO__NEXT-TITLE_TAKE': 'Aufgabe übernehmen',
    'CHO__NEXT-MSG_TAKE':
        'Super nett von dir! Wer soll als nächstes dran sein?',
    'CHO__NEXT-TITLE_OWN': 'Aufgabe erledigen',
    'CHO__NEXT-MSG_OWN': 'Hast du diese Aufgabe wirklich erledigt?',

    'CHO__CHECK_FOR_PIC-TITLE': 'Für {{ name }} abhaken',
    'CHO__CHECK_FOR_PIC-MSG': 'Hat {{ name }} die Aufgabe "{{ title }}" ' +
        'wirklich bereits erledigt? {{ name }} erhält dann auch ' +
        'die Punkte.',

    'CHO__REMOVE-TITLE': 'Aufgabe löschen',
    'CHO__REMOVE-MSG':
        'Möchtest du die Aufgabe "{{ title }}" wirklich löschen?',

    'CHO__OPTIONS-TITLE': 'Aufgabe "{{ title }}"',
    'CHO__OPTIONS-BTN_DO': 'Erledigen',
    'CHO__OPTIONS-BTN_TAKE': 'Ich übernehme',
    'CHO__OPTIONS-BTN_EDIT': 'Bearbeiten',
    'CHO__OPTIONS-BTN_REMIND': '{{ name }} erinnern',
    'CHO__OPTIONS-BTN_CHECK_FOR_PIC': 'Für {{ name }} abhaken',

    'CHO__EDIT': 'Aufgabe bearbeiten',
    'CHO__FORM-TITLE': 'Titel',
    'CHO__FORM-TITLE_PlACEHOLDER': 'z.B. Bad putzen',
    'CHO__FORM-START_AT': 'Erste Frist',
    'CHO__FORM-AT': 'Am',
    'CHO__FORM-FREQUENCY': 'Frequenz',
    'CHO__FORM-EFFORT': 'Aufwand',
    'CHO__FORM-EFFORT__SMALL': 'Klein',
    'CHO__FORM-EFFORT__NORMAL': 'Normal',
    'CHO__FORM-EFFORT__BIG': 'Groß',
    'CHO__FORM-EFFORT__HUGE': 'Riesig',
    'CHO__FORM-FIXED_WEEKDAY': 'Immer am {{ day }}?',
    'CHO__FORM-SELECT_FREQUENCY': 'Bestimme die Reihenfolge durch Klicken ' +
        'der Profilbilder',
    'CHO__FORM-SELECT_PARTICIPANTS': 'Zuständig:',
    'CHO__FORM-WHO_IS_FIRST': 'Wähle Personen aus',
    'CHO__FORM-WHO_IS_NEXT': 'Füge, falls nötig, weitere Personen hinzu',
    'CHO__FORM-WHO_IS_IN': 'Wer ist dabei?',
    'CHO_FORM-EVERYONE_SELECTED': 'Die gewählte Reihenfolge:',
    'CHO__FORM-CONFIRM_EDIT': 'Bearbeiten abschließen',
    'CHO__FORM-CONFIRM_NEW': 'Fertig',

    'CHO__TITLE': 'Putzplan',
    'CHO__LOADING': 'Aufgaben werden geladen',
    'CHO__HISTORY-TITLE': 'Putz-Historie',
    'CHO__HISTORY_SHOW_BTN': 'Putz-Historie ansehen',

    'CHO__MONETIZATION__ENTRIES_LIMIT-TITLE': 'Flatastic Premium',
    'CHO__MONETIZATION__ENTRIES_LIMIT-MSG': `Du hast das Limit von {{choresLimit}} Aufgaben in der kostenlosen Version erreicht. Mit Flatastic Premium kannst du unbegrenzt viele Aufgaben anlegen.`,
    'CHO__MONETIZATION__ENTRIES_LIMIT-DISCARD': 'OK',
    'CHO__MONETIZATION__ENTRIES_LIMIT-CTA': '⭐️ Premium holen ⭐️',

    // TIME
    'TIME__AS_NEEDED': 'Bei Bedarf',
    'TIME__ONCE': 'Einmalig',
    'TIME__DAILY': 'Täglich',
    'TIME__EVERY_2_DAYS': 'Alle zwei Tage',
    'TIME__EVERY_3_DAYS': 'Alle drei Tage',
    'TIME__EVERY_4_DAYS': 'Alle vier Tage',
    'TIME__EVERY_5_DAYS': 'Alle fünf Tage',
    'TIME__EVERY_6_DAYS': 'Alle sechs Tage',
    'TIME__WEEKLY': 'Wöchentlich',
    'TIME__EVERY_2_WEEKS': 'Alle zwei Wochen',
    'TIME__EVERY_3_WEEKS': 'Alle drei Wochen',
    'TIME__EVERY_4_WEEKS': 'Alle vier Wochen',
    'TIME__EVERY_5_WEEKS': 'Alle fünf Wochen',
    'TIME__EVERY_6_WEEKS': 'Alle sechs Wochen',
    'TIME__EVERY_2_MONTHS': 'Alle zwei Monate',
    'TIME__EVERY_3_MONTHS': 'Alle drei Monate',
    'TIME__EVERY_4_MONTHS': 'Alle vier Monate',
    'TIME__MONTHLY': 'Monatlich',
    'TIME__QUARTERLY': 'Jedes Quartal',
    'TIME__EVERY_HALF_YEAR': 'Jedes halbe Jahr',
    'TIME__EVERY_YEAR': 'Jedes Jahr',
    'TIME__1_DAY_LATE': '1 Tag Verzug',
    'TIME__X_DAYS_LATE': '{{ x }} Tage Verzug',
    'TIME__IN_1_DAY': 'in 1 Tag',
    'TIME__IN_X_DAYS': 'in {{ x }} Tagen',
    'TIME__MONDAY': 'Montag',
    'TIME__TUESDAY': 'Dienstag',
    'TIME__WEDNESDAY': 'Mittwoch',
    'TIME__THURSDAY': 'Donnerstag',
    'TIME__FRIDAY': 'Freitag',
    'TIME__SATURDAY': 'Samstag',
    'TIME__SUNDAY': 'Sonntag',
    'TIME__MONDAYS': 'montags',
    'TIME__TUESDAYS': 'dienstags',
    'TIME__WEDNESDAYS': 'mittwochs',
    'TIME__THURSDAYS': 'donnerstags',
    'TIME__FRIDAYS': 'freitags',
    'TIME__SATURDAYS': 'samstags',
    'TIME__SUNDAYS': 'sonntags',

    // EXPENSES
    'EXP_TITLE': 'Finanzen',
    'EXP__BALANCE': 'Bilanz',
    'EXP__YOUR_STATUS': 'Dein Stand',
    'EXP__BALANCE_LOADING': 'Bilanz wird aktualisiert…',
    'EXP__BALANCE_LOADING_ERROR': 'Fehler beim Aktualisieren:',
    'EXP__BALANCE_RESET': 'Kassensturz durchführen',
    'EXP__BALANCE_RESET_CONFIRM_TEXT': 'Der Kassensturz setzt alle Finanzen wieder auf Null. ' +
        'Jeder Mitbewohner erhält eine E-Mail mit der genauen Abrechnung. ' +
        'Im Anschluss werden alle Finanzeinträge gelöscht.\n\n' +
        'Möchtest du jetzt abrechnen?',
    'EXP__BALANCE_RESET-MSG': '{{ creditor }} bekommt {{ credit }} von {{ debtor }}.',
    'EXP__BALANCE_RESET-MSG-ENDING': 'Klicke auf OK nachdem ihr euch das Geld ausgezahlt habt.',
    'EXP__BALANCE_BALANCED': 'Die Bilanz wurde ausgeglichen.',
    'EXP__EXPORT_CURRENT_EXPENSES': 'Finanzeinträge exportieren',
    'EXP__EXPORT_SUCCESS_DIALOG__TITLE': 'Export erfolgreich ✅',
    'EXP__EXPORT_SUCCESS_DIALOG__MESSAGE': 'Im nächsten Schritt kannst du wählen, was du mit der Datei machen möchtest.',
    'EXP__EXPORT_SHARE_DIALOG__TITLE': 'Export unserer Finanzeinträge',
    'EXP__EXPORT_SHARE_DIALOG__MESSAGE': 'Hier sind all unsere aktuellen Finanzeinträge als .csv-Datei.',

    'EXP__TOTAL_SUM': 'Gesamtsumme',
    'EXP__ENTRIES': 'Einträge',
    'EXP__LOADING': 'Ausgaben werden geladen',
    'EXP__SAVING_ENTRY': 'wird gespeichert…',
    'EXP__SAVING_ERROR': 'Fehler beim Speichern',
    'EXP__NO_ENTRIES__TITLE': 'Keine Einträge',
    'EXP__NO_ENTRIES__TEXT': 'Es gibt zur Zeit keine Einträge, die dich betreffen. ' +
        'Klicke oben auf das "+" und füge eine neue Ausgabe hinzu.',

    'EXP__DETAIL_HELP-TITLE': 'Finanzeintrag',
    'EXP__DETAIL_HELP-MSG':
        'Hier kannst du alle angelegten Finanzeinträge noch ' +
        'einmal ganz genau anschauen und deine eigenen bearbeiten '+
        'oder löschen.',

    'EXP__DELETE-TITLE': 'Eintrag löschen',
    'EXP__DELETE-MSG':
        'Möchtest du den Eintrag "{{ name }}" wirklich löschen?',

    'EXP__NEW-BTN': 'Neuen Eintrag anlegen',
    'EXP__NEW-BTN_SAVE': 'Eintrag speichern',
    'EXP__NEW-PRODUCT_PLACEHODER': 'Gekaufter Artikel …',
    'EXP__NEW-BOUGHT_FOR': 'Artikel gekauft für:',
    'EXP__NEW-I_RECEIVE': 'Ich erhalte:',
    'EXP__NEW_HELP-TITLE': 'Neue Ausgabe',
    'EXP__NEW_HELP-MSG':
        'Lege eine neue Ausgabe an und gib dieser ein Titel und ein Datum. \n' +
        'Wähle bei jedem ' +
        'Produkt die Mitbewohner an, die mitzahlen.\n' +
        'Wähle z.B. alle Mitbewohner an, wenn das Produkt für die ' +
        'ganze WG ist (z.B. Klopapier). Wähle Mitbewohner ab, '+
        'wenn sie mit dem Produkt nichts anfangen können ' +
        '(z.B. Fleisch bei Vegetariern).',
    'EXP__NEW_CONFIRM_GO_BACK-TITLE': 'Finanzen',
    'EXP__NEW_CONFIRM_GO_BACK-MSG':
        'Achtung, der Eintrag ist noch nicht gespeichert. ' +
        'Möchtest du den Eintrag speichern oder verwerfen?',
    'EXP__NEW_DEFAULT_EVENT': 'Einkauf',
    'EXP__NEW_NO_SELECTED_FM-TITLE': 'Eintrag hinzufügen',
    'EXP__NEW_NO_SELECTED_FM-MSG':
        'Bitte wähle mindestens einen Mitbewohner aus, um den ' +
        'Eintrag "{{ name }}" hinzuzufügen.',
    'EXP__NEW_ERROR-TITLE': 'Ausgaben',
    'EXP__NEW_NO_NEGATIVE_NUMBERS-MSG':
        'Es dürfen keine negativen Zahlen eingegeben werden',
    'EXP__NEW_NO_TOO_LARGE_NUMBERS-MSG':
        'Wow. Das ist teuer. Für solche Summen benutzt du besser ' +
        'ein anderes Tool.',
    'EXP__NEW_SUMMARY': 'Ich habe für <strong>{{ totalSum | wgCurrency }}</strong> ' +
        'eingekauft und bekomme <strong>{{ sum | wgCurrency  }}</strong> zurück.',

    'EXP__EDIT-BTN-DONE': 'Bearbeiten abschließen',

    'EXP__DELETE_ITEM-TITLE': 'Löschen',
    'EXP__DELETE_ITEM-MSG': 'Möchtest du diesen Eintrag wirklich löschen?',

    'EXP__EDIT_ITEM-TITLE': 'Eintrag bearbeiten',
    'EXP__EDIT_ITEM-MSG':
        'Du bearbeitest bereits einen Eintrag. Schließe den Vorgang ' +
        'zunächst ab.',

    'EXP__SET_TITLE-TITLE': 'Titel eingeben',
    'EXP__SET_TITLE-MSG': 'Wähle einen Titel für diesen Einkauf',

    'EXP__SAVE_ERROR-TITLE': 'Erneut versuchen',
    'EXP__SAVE_ERROR-MSG':
        'Beim Speichern ist ein Fehler aufgetreten:\n{{ error }}',
    'EXP__SAVE_ERROR-CONFIRM': 'OK',

    'EXP__HELP-TITLE': 'Die Finanzen',
    'EXP__HELP-MSG':
        'Behalte immer im Überblick wer was ausgegeben hat. ' +
        'Spare deiner WG lästige Kassenstürze und lass Flatastic ' +
        'die Arbeit machen.',

    'EXP__STATISTICS_INFO-EVEN':
        'Die Finanzen von {{ name }} sind genau ausgeglichen. ' +
        'Das ist einfach unglaublich :)',
    'EXP__STATISTICS_INFO-MINUS':
        '{{ name }} ist {{ value }} im Minus und ' +
        'sollte vielleicht mal wieder Einkaufen gehen.',
    'EXP__STATISTICS_INFO-PLUS':
        '{{ name }} ist {{ value }} im Plus. ' +
        'Es könnten auch mal die anderen Einkaufen gehen.',
    'EXP__STATISTICS_INFO-TITLE': 'Bilanz von {{ name }}',

    'EXP__DETAIL-I_PAY': 'Ich zahle',
    'EXP__DETAIL-I_RECEIVE': 'Ich erhalte',
    'EXP__DETAIL-BTN_EDIT': 'Eintrag bearbeiten',
    'EXP__DETAIL_BTN_DELETE': 'Eintrag löschen',

    'EXP__SPENDINGS__TITLE': 'Persönliche Ausgaben',
    'EXP__SPENDINGS__GET_PREMIUM__TEXT': 'Hole dir Flatastic Premium, um deine ' +
      'persönlichen Ausgaben zu sehen.',
    'EXP__SPENDINGS__GET_PREMIUM__CTA': 'Premium abschließen',
    'EXP__SPENDINGS__NO-ENTRIES': 'In den letzten 12 Monaten gibt es keine Ausgaben, die dich betreffen.',

    'EXP__SETTLEMENTS-TITLE': 'Kassenstürze',
    'EXP__SETTLEMENTS-BTN_SHOW_SETTLEMENTS': 'Vorherige Kassenstürze anzeigen',
    'EXP__SETTLEMENTS-BTN_SHOW_ENTRY': '{{ entriesNum }} Finanzeintrag anzeigen',
    'EXP__SETTLEMENTS-BTN_SHOW_ENTRIES': '{{ entriesNum }} Finanzeinträge anzeigen',
    'EXP__SETTLEMENTS-BTN_SHOW_BALANCE': 'Abrechnung einblenden',
    'EXP__SETTLEMENTS-BTN_HIDE_BALANCE': 'Abrechnung verbergen',
    'EXP__SETTLEMENT-ENTRIES-TITLE': 'Finanzeinträge',
    'EXP__SETTLEMENTS_PERIOD': 'Zeitraum',
    'EXP__SETTLEMENTS_TRIGGERED_BY': '{{ name }} hat diesen Kassensturz veranlasst',
    'EXP__SETTLMENTS_INFO': 'Kassenstürze vor dem 1. August 2019 werden nicht angezeigt.',
    // GENERAL
    'MENU__SHOUTS': 'Pinnwand',
    'MENU__SHOPPING_LIST': 'Einkaufsliste',
    'MENU__CHORES': 'Putzplan',
    'MENU__EXPENSES': 'Finanzen',
    'MENU__SETTINGS': 'Einstellungen',

    // HOMELESS
    'HML__MOVE_IN-TITLE': 'WG Einzug',
    'HML__MOVE_IN-ERROR':
        'Der Zugangscode wurde nicht gefunden. ' +
        'Schau doch noch einmal, ob du alles richtig ' +
        'geschrieben hast.',

    'HML__CREATE_WG_ERROR-TITLE': 'WG Einzug',
    'HML__CREATE_WG_ERROR-MSG': 'Beim Gründen der WG ist etwas schief gelaufen.',

    'HML__SUBHEADER_1': 'Hallo {{ name }},',
    'HML__SUBHEADER_2': 'du bist zur Zeit in keiner WG.',

    'HML__CREATE_WG_BUTTON': 'Neue WG gründen',
    'HML__MOVE_IN_BUTTON': 'Zugangscode eingeben',
    'HML__DELETE_ACCOUNT': 'Account löschen',
    'HML__DELETE_ACCOUNT-MSG': 'Möchtest du wirklich deinen Account bei Flatastic löschen?',
    'HML__DELETE_ACCOUNT_SUCCESS-MSG': 'Dein Account wurde erfolgreich gelöscht. ' +
        'Schade, dass du uns verlässt.',

    // FEEDBACK
    'FDB__TITLE': 'Feedback',
    'FDB__CONFIRM': 'Abschicken',
    'FDB__INFO_TEXT':
        'Wir freuen uns immer über deine Nachrichten. Ganz egal, ' +
        'ob Anregung, Kritik, Verbesserungsvorschlag oder ' +
        'ein einfaches "Hallo".',
    'FDB__CONFIRM_SUCCESS-TITLE': 'Feedback',
    'FDB__CONFIRM_SUCCESS-MSG': 'Dein Feedback wurde übermittelt. Vielen Dank!',
    'FDB__CONFIRM_ERROR-TITLE': 'Feedback',
    'FDB__CONFIRM_ERROR-MSG':
        'Dein Feedback konnte nicht übermittelt werden:\n{{ error }}',

    'FDB__HELP-TITLE': 'Feedback',
    'FDB__HELP-MSG':
        'Sende uns dein Feedback, damit wir Flatastic nach euren' +
        ' Wünschen weiterentwickeln können.',


    // SETTINGS
    'SET__TITLE': 'Einstellungen',
    'SET__COPYRIGHT':
        'flatastic {{ version }}<br/>' +
        'gemacht mit &#9728; und &#10084; in Berlin und Zürich',
    'SET__LIST-THANKS': 'Mit einem Like bedanken',
    'SET__LIST-THANKS_MONEY': 'Mit einem Bier bedanken',

    'SET__LIST-PROFILE': 'Mein Profil',
    'SET__LIST-WG': 'Meine WG',
    'SET__LIST-SHARE': 'Weiterempfehlen',
    'SET__LIST-SHARE-WITH-FRIENDS': 'Flatastic weiterempfehlen',
    'SET__LIST-FEEDBACK': 'Feedback',
    'SET__LIST-NOTIFICATIONS-FEEDBACK': 'Benachrichtigungen',
    'SET__LIST-LANGUAGE': 'Sprache ändern',
    'SET__LIST-FAQ': 'Hilfe',
    'SET__LIST-LAW_STUFF': 'Rechtliches Zeugs',

    'SET__HELP-TITLE': 'Einstellungen',
    'SET__HELP-MSG':
        'Ändere deinen Namen und dein Passwort, ' +
        'lade neue Mitbewohner ein, ' +
        'oder hinterlasse uns eine Nachricht',

    'SET__SHARE-TITLE': 'Kennst du Flatastic?',
    'SET__SHARE-MSG':
        'Hey,\n\n' +
        'kennst du schon Flatastic, die App für dich und deine WG? ' +
        'Die App hat eine geteilte Einkaufsliste, behält die ' +
        'Übersicht über die WG-Kasse und erinnert an die Aufgaben.',

    'SET__LAW_STUFF-TITLE': 'Flatastic',
    'SET__LAW_STUFF-BTN_AGB': 'AGB',
    'SET__LAW_STUFF-BTN_PRIVACY': 'Privatsphäre und Datenschutz',


    // SETTINGS - NOTIFICATIONS
    'NFDB__TITLE': 'Benachrichtigungen',
    'NFDB__REPORT-PROBLEM': 'Problem melden',
    'NFDB__TEST-NOTIFICATIONS': 'Benachrichtigungen testen',
    'NFDB__PUSHES_ARE_ACTIVATED': 'Zugriff auf Push-Benachrichtigungen ist erteilt',
    'NFDB__DEVICE_REGISTERED': 'Gerät ist registriert',
    'NFDB__TEST_SUCCESSFUL': 'Test erfolgreich. Es wurden keine Probleme festgestellt.',
    'NFDB__TEST_RESULT': 'Du hast {{ numSuccess }} von {{ numTotal }} Benachrichtigungen erhalten',
    'NFDB__NOTIFICATION_PENDING': 'Warte auf Benachrichtigung ({{ type }})',
    'NFDB__NOTIFICATION_RECEIVED': 'Benachrichtigung erhalten ({{ type }})',
    'NFDB__NOTIFICATION_NOT_RECEIVED': 'Benachrichtigung nicht erhalten ({{ type }})',
    'NFDB__RERUN_TEST': 'Test erneut starten',
    'NFDB__DEVICE_TOKEN_STORED': 'Gerät ist für Benachrichtigungen aktiviert',


    // SHOPPING LIST
    'SHL__TITLE': 'Einkaufsliste',
    'SHL__NAME_PLACEHOLDER': 'Fehlendes Produkt eingeben …',
    'SHL__BOUGHT': 'Gekauft:',
    'SHL__SYNC_BUTTON': 'Synchronisieren',
    'SHL__DELETE_BOUGHT_BUTTON': 'gekaufte Produkte entfernen',

    'SHL__HELP-TITLE': 'Die Einkaufsliste',
    'SHL__HELP-MSG':
        'Führe mit deiner WG eine gemeinsame Einkaufsliste und sieh ' +
        'immer was gebraucht wird. Du und deine Mitbewohner werden ' +
        'automatisch über Aktivitäten informiert.',

    'SHL__SHARE-TITLE': 'Flatastic Einkaufsliste',
    'SHL__SHARE-MSG': 'Hier ist unsere flatastische Einkaufsliste:\n\n' +
        '{{ items }}\n\n' +
        '––––––––––––––––\n' +
        'Wir organisieren unseren Haushalt mit der App "Flatastic"',

    'SHL__REMOVE_BOUGHT-TITLE': 'Einkaufsliste',
    'SHL__REMOVE_BOUGHT-MSG':
        'Möchtest du alle gekauften Artikel aus der Liste löschen?',

    'SHL__REMOVE_BOUGHT_ERROR-TITLE': 'Einkaufsliste',
    'SHL__REMOVE_BOUGHT_ERROR-MSG':
        'Das hat leider noch nicht geklappt.' +
        ' Die gekauften Artikel wurden nicht aus der' +
        ' Einkaufliste gelöscht.' +
        ' Vielleicht hattest du keinen Internetempfang?' +
        ' Versuch es doch noch einmal.',

    'SHL__DELETE_ITEM-TITLE': '{{ name }} löschen',
    'SHL__DELETE_ITEM-MSG': 'Möchtest du wirklich den Eintrag "{{ name }}" ' +
        'von der Einkaufsliste löschen?',

    'SHL__INTERACT__BUY': 'Abhaken',
    'SHL__INTERACT__BACK_TO_LIST': 'Auf die Liste',
    'SHL__INTERACT__EDIT': 'Bearbeiten',

    'SHL__SYNC-TITLE': 'Einkaufsliste',
    'SHL__SYNC-MSG':
        'Du hattest offensichtlich schlechtes Internet und' +
        ' die Einkaufliste ist nicht synchronisiert.\n' +
        'Jetzt synchronisieren?',

    'SHL__SYNC_SUCCESS-TITLE': 'Einkaufsliste',
    'SHL__SYNC_SUCCESS-MSG':
        'Flatastisch! Deine Einkaufsliste ist wieder synchronisiert.',

    'SHL__SYNC_ERROR-TITLE': 'Einkaufsliste',
    'SHL__SYNC_ERROR-MSG':
        'Du hattest offensichtlich schlechtes Internet und' +
        ' die Einkaufliste ist nicht synchronisiert.\n' +
        'Jetzt synchronisieren?',

    // SHOUTS
    'SHT__TITLE': 'Pinnwand',
    'SHT__FORM_PLACEHOLDER': 'Nachricht verfassen …',
    'SHT__LOADING': 'Pinnwand wird geladen',
    'SHT__CHAT': 'Nachrichten',
    'SHT__ACTIVITIES': 'Aktivitäten',

    'SHT__HELP-TITLE': 'Die Pinnwand',
    'SHT__HELP-MSG':
        'Du kochst heute Abend? Mutti kommt zu Besuch? ' +
        'Der Klempner kommt? Die Zählerstände werden abgelesen?\n' +
        'Dies ist der Platz, um es deiner WG mitzuteilen.',
    'SHT__THANKS_SUCCESS-TITLE': 'Danke sagen',
    'SHT__THANKS_SUCCESS-MSG': 'Dein Dank wurde übermittelt. 👍',
    'SHT__CONFIRM_DELETE-TITLE': 'Eintrag löschen?',
    'SHT__CONFIRM_DELETE-MSG':
        'Möchtest du diesen Eintrag wirklich löschen? Auch deine Mitbewohner ' +
        'werden den Eintrag dann nicht mehr sehen.',
    'SHT__NO_SHOUTS__TITLE': 'Noch keine Nachrichten',
    'SHT__NO_SHOUTS__TEXT': 'Hinterlasse eine Nachricht und ' +
        'begrüße deine Mitbewohner 💬👋',

    // SUBSCRIPTIONS
    'SUB__TITLE': 'Verträge und Abos',
    'SUB__MONTHLY_AMOUNT': 'Monatlicher Betrag',
    'SUB__HELP-TITLE': 'Verträge und Abos',
    'SUB__HELP-MSG': 'Behaltet eure regelmäßigen Kosten im ' +
        'Blick. In Zukunft könnt ihr hier auch regelmäßige Finanzeinträge ' +
        'erstellen und wir erinnern euch daran Verträge und Abos rechtzeitig ' +
        'zu kündigen.',
    'SUB__FIRST_BILL': 'Erste Rechnung',
    'SUB__CYCLE': 'Periode',
    'SUB__DESCRIPTION': 'Beschreibung',

    //TOASTER
    'TSR__NEAR_WGS-TITLE': 'WGs in deiner Nähe',
    'TSR__NEAR_WGS-MSG':
        'Es gibt noch nicht genügend WGs in deiner Nähe. ' +
        'Hilf uns, dies zu ändern und empfehle Flatastic ' +
        'deinen Bekannten weiter.',
    'TSR__NEAR_WGS-BTN_SHARE': 'Weiterempfehlen',
    'TSR__NEAR_WGS-SHARE_SUBJ': 'Flatastic - Die App für dich und deine WG',
    'TSR__NEAR_WGS_SHARE-MSG':
        'Hey,\n' +
        'kennst du schon Flatastic? Die App ' +
        'unterstützt deine WG bei der Organisation ' +
        'mit einer geteilten Einkaufsliste, einem ' +
        'Putzplan, einem digitalen Haushaltsbuch ' +
        'und einer digitalen Pinnwand. Schau dir die ' +
        'App einfach mal an.\n',
    'TSR__NEAR_WGS_ZIP-TITLE': 'Deine Postleitzahl',
    'TSR__NEAR_WGS_ZIP-MSG':
        'Bitte gib deine Postleitzahl an, damit wir ' +
        'WGs in deiner Nähe finden können.',

    // WG
    'WG__TITLE': 'Meine WG',
    'WG__BTN_INVITE_FLATMATE': 'Mitbewohner hinzufügen',
    'WG__INVITE_TOGGLE_FLATMATES': 'Mitbewohner',
    'WG__INVITE_TOGGLE_WG_PROFILE': 'WG-Profil',
    'WG__PROFILE_LOADING': 'WG-Profil wird geladen',
    'WG__ALREADY_MOVED_IN': 'ist bereits eingezogen',
    'WG__BTN_MOVE_OUT': 'Ausziehen',

    'WG__COVER_PICTURE': 'WG-Bild',

    'WG__HELP-TITLE': 'Deine WG',
    'WG__HELP-MSG':
        'Lade neue Mitbewohner ein, sieh wer bereits in deine WG ' +
        'eingezogen ist und gib deiner WG einen Namen.',

    'WG__SAVE_SUCCESS-TITLE': 'Deine WG',
    'WG__SAVE_SUCCESS-MSG': 'Die Änderungen wurden gespeichert.',

    'WG__MOVE_OUT__START-TITLE': 'Ausziehen',
    'WG__MOVE_OUT__START-MSG': 'Bist du dir sicher, dass du ausziehen ' +
        'möchtest? Diese Aktion kann nicht rückgängig gemacht werden.',
    'WG__MOVE_OUT__START-CONFIRM': 'Ausziehen',

    'WG__MOVE_OUT_CONFIRM':
        'Bist du wirklich sicher, dass du ausziehen möchtest?',
    'WG__MOVE_OUT_CONFIRM-TITLE': 'Ausziehen',
    'WG__MOVE_OUT_CONFIRM_BUTTON': 'Jetzt ausziehen',
    'WG__MOVE_OUT_CONFIRM_SUCCESS-TITLE': 'Auszug',
    'WG__MOVE_OUT_CONFIRM_SUCCESS-MSG': 'Du bist erfolgreich ausgezogen.',
    'WG__MOVE_OUT_CONFIRM_ERROR-TITLE': 'Auszug',
    'WG__MOVE_OUT_CONFIRM_ERROR-MSG':
        'Es ist ein Fehler aufgetreten. Bitte schick uns eine ' +
        'Email an support@flatastic-app.com, damit wir weiterhelfen können!',

    'WG__KICK_OUT_CONFIRM-TITLE': '{{ name }} rausschmeißen',
    'WG__KICK_OUT_CONFIRM-MESSAGE': 'Möchtest du {{ name }} wirklich aus der ' +
        'WG rausschmeißen? Das wäre nicht sehr nett und die Aktion kann auch ' +
        'nicht rückgängig gemacht werden. Schreibe zum Bestätigen "{{ name }}".',
    'WG__KICK_OUT_CONFIRM-BUTTON': 'Rausschmeißen',
    'WG__KICK_OUT_ERROR-MESSAGE': 'Es gab ein Problem. Bitte schreibe eine ' +
        'E-Mail an support@flatastic-app.com.',
    'WG__KICK_OUT_SETTLEMENT_NEEDED': 'Die Finanzen sind nicht ausgeglichen. ' +
        'Bitte mache zuerst einen Kassensturz.',
    'WG__KICK_OUT-LOADING': '{{ name }} wird rausgeschmissen…',
    'WG__KICK_OUT__TYPE_ERROR-TITLE': 'Vertippt…',
    'WG__KICK_OUT__TYPE_ERROR-MESSAGE': 'Da hat sich wohl ein ' +
        'Tippfehler eingeschlichen. Versuch\'s noch einmal.',

    'WG__SETTLEMENT_MESSAGE_PART':
        '{{ sender }} zahlt {{ valueString }} an {{ receiver }}\n',

    'WG__SETTLEMENT_CONFIRM-TITLE': 'Finanzen ausgleichen',
    'WG__SETTLEMENT_CONFIRM-MSG':
        'Zum Ausziehen müsst ihr erst eure Finanzen ausgleichen. ' +
        'Das geht bei euch wie folgt:\n\n{{ settlement }}',
    'WG__SETTLEMENT_CONFIRM-NOT_YET': 'Noch nicht',
    'WG__SETTLEMENT_CONFIRM-DO_NOW': 'Jetzt Finanzen ausgleichen',
    'WG__SETTLEMENT_CONFIRM_SUCCESS_CONFIRM_MOVE_OUT':
        'Eure Finanzen sind jetzt ausgeglichen. ' +
        'Bist du weiterhin sicher, dass du ausziehen möchtest?',

    'WG__INTERACT_TITLE': '{{ name }}',
    'WG__INTERACT_OPTIONS-TITLE': 'Bitte wähle',
    'WG__INTERACT_OPTIONS-DELETE_INVITE': 'Einladung löschen',
    'WG__INTERACT_OPTIONS-SEND_WG_CODE': 'Code verschicken',
    'WG__INTERACT_OPTIONS-KICKOUT': 'Rausschmeißen',

    'WG__CANCEL_INVITATION_CONFIRM-TITLE': 'Einladung zurücknehmen',
    'WG__CANCEL_INVITATION_CONFIRM-MSG':
        'Alle bisherigen Finanzeinträge, die mit “{{ name }}” in Verbindung stehen werden gelöscht. ' +
        'Möchtest du die Einladung für “{{ name }}” wirklich zurücknehmen?',
    'WG__CANCEL_INVITATION_CONFIRM-BTN': 'Einladung und Einträge löschen',
    'WG__CANCEL_INVITATION_SUCCESS-TITLE': 'Einladung wieder gelöscht',
    'WG__CANCEL_INVITATION_SUCCESS-MSG':
        '{{ name }} wurde aus deiner WG entfernt.',
    'WG__CANCEL_INVITATION_ERROR-TITLE': 'Einladung nicht gelöscht',
    'WG__CANCEL_INVITATION_ERROR-MSG':
        'Es ist ein Fehler aufgetreten. {{ name }} ' +
        'wurde nicht aus deiner WG entfernt:\n{{ error }}',

    'WG__SHARE_WG_CODE-SUBJ': 'Eine App für unser Zuhause',
    'WG__SHARE_WG_CODE-MSG':
        `Hey {{ name }},

Ich habe für uns ein neues Zuhause bei Flatastic angelegt. Es ist die perfekte App um den Haushalt zu organisieren und zu führen.
Du kannst sie unten downloaden und mit dem Zugangscode «{{ wgCode }}» einziehen.

Hier der Link zur App: https://flatastic-app.com/app`,
    'WG__SHARE_WG_CODE_SUCCESS-TITLE': 'Gut gemacht!',
    'WG__SHARE_WG_CODE_SUCCESS-MSG':
        'Sehr gut! {{ name }} kann sich jetzt mit dem Zugangscode anmelden',

    'WG__INVITE_FLATMATE-TITLE': 'Mitbewohner hinzufügen',
    'WG__INVITE_FLATMATE-MSG': 'Bitte gib den Vornamen deines Mitbewohners ein',
    'WG__INVITE_FLATMATE_NO_NAME-TITLE': 'Mitbewohner hinzufügen',
    'WG__INVITE_FLATMATE_NO_NAME-MSG': 'Du musst schon einen Namen eingeben…',
    'WG__INVITE_FLATMATE-LOADING': 'Zimmer für {{ name }} wird vorbereitet…',
    'WG__INVITE_FLATMATE_SUCCESS-TITLE': 'Mitbewohner hinzugefügt',
    'WG__INVITE_FLATMATE_SUCCESS-MSG':
        'Super! Schicke {{ name }} jetzt den Zugangscode, damit {{ name }} in die WG einziehen kann.',
    'WG__INVITE_FLATMATE_SUCCESS-SHARE_CODE': 'Zugangscode verschicken',
    'WG__INVITE_FLATMATE_ERROR-TITLE': 'Zugangscode verschicken',
    'WG__INVITE_FLATMATE_ERROR-MSG':
        'Da ist leider etwas schiefgelaufen… Probier es doch noch einmal.',

    'WG__INVITE_FLATMATE_NOT_SHARED-TITLE': 'Fast geschafft!',
    'WG__INVITE_FLATMATE_NOT_SHARED-MSG':
        '{{ name }} muss nun bei der Anmeldung den Zugangscode angeben um in ' +
        'deine WG einzuziehen.',

    'WG__INVITE_FLATMATE_NO_ONE_INVITED-MSG': 'Achtung, du hast keine ' +
        'Mitbewohner hinzugefügt.',
    'WG__INVITE_FLATMATE_NO_ONE_INVITED-CONFIRM': 'Ich wohne alleine',

    'WG__FLATMATE_REMINDER-TITLE': 'Lade deine Mitbewohner ein',
    'WG__FLATMATE_REMINDER-MSG':
        'Alleine macht WG-Leben keinen Spaß. Lade jetzt deine ' +
        'Mitbewohner ein.',
    'WG__FLATMATE_REMINDER-BTN': 'Mitbewohner einladen',

    'WG__INCOMPLETE_REMINDER-TITLE': 'Da fehlt noch jemand…',
    'WG__INCOMPLETE_REMINDER-MSG':
        'Es sind noch nicht alle deine Mitbewohner eingezogen. ' +
        'Willst du sie erinnern und den Zugangscode noch einmal ' +
        'versenden?',
    'WG__INCOMPLETE_REMINDER-BTN_SHARE': 'Mitbewohner erinnern',
    'WG__INCOMPLETE_REMINDER-BTN_LATER': 'Nicht jetzt',

    'WG__MODAL-TITLE': 'Mitbwohner hinzufügen',
    'WG__MODAL-CLOSE': 'Fertig',
    'WG__MODAL-SUBHEAD_ALONE':
        'Wilkommen! Noch bist du allein in deiner WG… ' +
        'Hier kannst du deine Mitbewohner einladen. ' +
        'Einfach einen Vornamen eingeben und auf "Hinzufügen" drücken.',
    'WG__MODAL-SUBHEAD_NOT_ALONE':
        'Füge weitere Mitbwohner hinzu, indem du einen Vornamen eingibst ' +
        'und auf "Hinzufügen" drückst.',
    'WG__MODAL_INVITE-FIRST_NAME': 'Vorname:',
    'WG__MODAL_INVITE-CONFIRM': '{{ name }} Hinzufügen',
    'WG__MODAL-WG_CODE': 'Zugangscode: {{ wgCode }}',
    'WG__MODAL-ALREADY_MOVED_IN': 'Ist schon eingezogen',
    'WG__MODAL-INFO':
        '<strong>Info:</strong> Jeder eingeladene Mitbewohner ' +
        'erhält einen Zugangscode und kann damit in deine WG einziehen. ' +
        'Du kannst den Code einfach z.B. per Mail oder SMS verschicken.',

    'WG__SETUP__ADD_FLATMATES-TITLE': 'Mitbewohner hinzufügen',
    'WG__SETUP__REMOVE_FLATMATE_CONFIRM-MSG':
        'Möchtest du {{ name }} wirklich wieder entfernen?',
    'WG__SETUP-TITLE': 'WG einrichten',
    'WG__SETUP-SUBHEADER':
        'Erstelle jetzt dein WG-Profil und lade deine Mitbewohner ein',
    'WG__SETUP-BTN_ADD_FLATMATE': 'Mitbewohner hinzufügen',
    'WG__SETUP-WHERE_ARE_YOUR_FM': 'Wo sind deine Mitbewohner?',
    'WG__SETUP-NOT_COOL_ALONE': 'Alleine macht WG keinen Spaß',
    'WG__SETUP-BTN_DONE': 'Auf geht\'s',

    'WG__IMAGE_DIALOGUE-TITLE': 'Bitte wählen',
    'WG__IMAGE_DIALOGUE-FROM_GALLERY': 'Bild aus Galerie',
    'WG__IMAGE_DIALOGUE-TAKE_PHOTO': 'Foto machen',
    'WG__IMAGE_ERROR_DIALOGUE-TITLE': 'Upload fehlgeschlagen',
    'WG__IMAGE_ERROR_DIALOGUE-MSG': 'Folgende Fehler sind aufgetreten: ' +
        '\n\n{{ error }}',
    'WG__IMAGE-BTN': 'Klicke auf das WG-Bild um es zu ändern',
    'WG__IMAGE-SETUP_BTN': 'Füge zuerst ein WG Bild hinzu',
    'WG__SELECT_TYPE': 'Haushaltstyp',
    'WG__PLEASE_CHOOSE': 'Bitte auswählen',
    'WG__TYPES-FAMILY': 'Familie',
    'WG__TYPES-COUPLE': 'Paar',
    'WG__TYPES-SHAREDFLAT': 'WG',

    'WG__FORMER_FLATMATE-FIRST_NAME': 'Ausgezogen',

    // ADS
    'ADS__WARN_DIALOG-TITLE': 'Werbung 😫',
    'ADS__WARN_DIALOG-MSG': 'Hin und wieder zeigen wir Werbung, damit wir ' +
      'Flatastic am Laufen halten und weiterentwickeln können. Du hasst ' +
      'Werbung? Dann werde Premium Nutzer indem du uns einen ausgibst.',
    'ADS__WARN_DIALOG-BTN_PRO_ADS': 'OK',
    'ADS__WARN_DIALOG-BTN_PREMIUM': 'Premium-Nutzer werden',

    // RATE
    'RATE__DIALOG-TITLE': 'Bewerte Flatastic',
    'RATE__DIALOG-MSG': 'Dir gefällt Flatastic? Dann bedanke dich mit einer ' +
        'Bewertung. Es dauert weniger als eine Minute.\n' +
        'Vielen Dank für deine Unterstützung!',
    'RATE__DIALOG-BTN_NO_THANKS': 'Nein, Danke',
    'RATE__DIALOG-BTN_REMIND_ME_LATER': 'Später',
    'RATE__DIALOG-BTN_RATE_IT_NOW': 'Jetzt Bewerten',
  };

  /*
   *
   * ENGLISH
   *
   */

  var EN = {
    // often used
    'CONFIRM': 'OK',
    'ADD': 'Add',
    'CANCEL': 'Cancel',
    'BTN_CANCEL': 'Cancel',
    'CONTINUE': 'Next',
    'OF_COURSE': 'Of course',
    'OK': 'OK',
    'YES': 'Yes',
    'NO': 'No',
    'DELETE': 'Delete',
    'REMOVE': 'Remove',
    'DISCARD': 'Discard',
    'UPDATE': 'Update',
    'REFRESH': 'Refresh',
    'SAVE': 'Save',
    'EDIT': 'Edit',
    'DONE': 'Done',
    'TRY_AGAIN': 'Try again',
    'NO_INTERNET': 'No Internet?',
    'NO_MORE_ENTRIES': 'No more entries available',
    'SAY_THANKS': 'Say thanks!',
    'FOLLOW': 'Follow',
    'PROPOSALS': 'Proposals',
    'AND': 'and',
    'OR': 'or',
    'NEXT': 'Next',
    'LIKED_THIS': 'liked this',
    'BACK': 'Back',
    'ENTER_FIRST_NAME': 'Enter first name',
    'DATE': 'Date',
    'CLOSE': 'Close',
    'SHOW_MORE': 'Show more',
    'HOUSEHOLDS': 'households',
    'REMIND': 'Remind',
    'COMPLETE': 'Complete',
    'BUY_NOW': 'Buy now',
    'MORE': 'More…',
    'DELETED': 'Deleted.',
    'TITLE': 'Title',
    'PRICE': 'Price',
    'COMING_SOON': 'Coming Soon',


    // API ERROR HANDLINGS
    'API__ERROR__5XX': 'Oh oh. A server error 👿. Click here for more ' +
        'information.',
    'API__ERROR__NO_INTERNET': 'Can\'t connect to our servers. Please check ' +
        'your internet connection.',

    // GLOBALS
    'GLO__APP_NAME': 'Flatastic',
    'GLO__WG_NAME': 'Flat Name', // Do you have a better idea?
    'GLO__CITY': 'City',
    'GLO__POST_CODE': 'Post Code',
    'GLO__COUNTRY': 'Country',
    'GLO__CURRENCY': 'Currency',

    // SINGLE WORDS
    'UNTIL': 'until',
    'NEW': 'new',
    'POINT': 'point',
    'POINTS': 'points',
    'TODAY': 'today',
    'FLATMATE': 'Flatmate',
    'FLATMATES': 'Flatmates',
    'SUM': 'Sum',
    'LOGOUT': 'Logout',
    'LATER': 'Later',

    // USER
    'USR__TITLE': 'My Profile',
    'USR__CHANGE-FIRST_NAME': 'Change Name',
    'USR__CHANGE-PWD': 'Change Password',
    'USR__CHANGE-PWD_INFO': 'Minimum length: 6 Characters',

    'USR__FORM-FIRST_NAME': 'Name',
    'USR__FORM-PWD_OLD': 'Old Password',
    'USR__FORM-PWD_NEW': 'New Password',
    'USR__HELP-TITLE': 'Your Profile',
    'USR__HELP-MSG':
        'You can change your profile picture, your name and your ' +
        'password here.',
    'USR__CHANGE_PW_TITLE': 'Change password',
    'USR__CHANGE_PW_SUCCESS-MSG': 'Your password has been changed',
    'USR__CHANGE_PW_FAILED-MSG':
        'Your password has not been changed. You probably have a typo in your old password.',
    'USR__CHANGE_PW_ERROR-MSG':
        'An error accured while changing the password: {{ error }} ',

    'USR__CHANGE_EMAIL': 'Change email address',
    'USR__CHANGE_EMAIL-INFO': 'Your current address: {{ email }}',
    'USR__CHANGE_EMAIL-EXTRAINFO': 'Please enter a valid email address.',
    'USR__CHANGE_EMAIL-SUCCESS': 'Your email address was updated to {{ email }}',
    'USR__FORM-EMAIL_NEW': 'New Email',

    'USR__LOGOUT-TITLE': 'Logout',
    'USR__LOGOUT-MSG': 'Do you really want to log out?',
    'USR__LOGOUT-BTN': 'Logout',

    'USR__IMAGE-BTN': 'Click on the profile picture to change it.',
    'USR__IMAGE_DIALOGUE-TITLE': 'Please select',
    'USR__IMAGE_DIALOGUE-FROM_GALLERY': 'Choose existing photo',
    'USR__IMAGE_DIALOGUE-TAKE_PHOTO': 'Take photo',
    'USR__IMAGE_ERROR_DIALOGUE-TITLE': 'Upload failed',
    'USR__IMAGE_ERROR_DIALOGUE-MSG':
        'Upload was unsuccessful: \n\n {{ error }}',

    'YOUR_FIRST_NAME': 'Name',
    'YOUR_EMAIL': 'Email',
    'EMAIL_PLACEHOLDER': 'john@smith.com',
    'YOUR_PASSWORD': 'Password',
    'USR__PASSWORD_INFO': 'Your password must have at least 6 characters.',
    'USR__PASSWORD_PLACEHOLDER': 'Min. 6 characters',

    // AUTH
    'ATH__INTRO_WELCOME-TITLE': 'Welcome home.',
    'ATH__INTRO_WELCOME-SUBTITLE_PART_1': 'flatastic is the App',
    'ATH__INTRO_WELCOME-SUBTITLE_PART_2': 'for you and your shared flat',

    'ATH__INTRO_CHORES-TITLE': 'Cleaning Schedule',
    'ATH__INTRO_CHORES-SUBTITLE_PART_1': 'Let them remind you. Remind others.',
    'ATH__INTRO_CHORES-SUBTITLE_PART_2': 'Say thanks.',

    'ATH__INTRO_SHOPPING_LIST-TITLE': 'Shopping List',
    'ATH__INTRO_SHOPPING_LIST-SUBTITLE_PART_1': 'Always check what is',
    'ATH__INTRO_SHOPPING_LIST-SUBTITLE_PART_2': 'needed in your shared flat',

    'ATH__INTRO_SHOUTS-TITLE': 'Bulletin Board',
    'ATH__INTRO_SHOUTS-SUBTITLE_PART_1': 'What\'s up?',
    'ATH__INTRO_SHOUTS-SUBTITLE_PART_2': 'Tell your shared flat.',

    'ATH__INTRO_EXPENSES-TITLE': 'Expenses',
    'ATH__INTRO_EXPENSES-SUBTITLE_PART_1': 'Borrow, lend and return money.',
    'ATH__INTRO_EXPENSES-SUBTITLE_PART_2': 'Without losing sight.', // Stay on top of things

    'ATH__INTRO_SLOGAN_PART_1': 'Welcome home',
    'ATH__INTRO_SLOGAN_PART_2': '',
    'ATH__INTRO_MOVE_IN': 'Enter access code',
    'ATH__INTRO_ALREADY_HAVE_ACCOUNT': 'I already have an account',

    'ATH__FOUND_WG': 'Create a new shared flat',
    'ATH__FOUND_WG-CONFIRM': 'Create shared flat',
    'ATH__FOUND_LOADING': 'Shared flat is being created…',
    'ATH__FOUND_WG-DESCRIPTION':
        'A new shared flat is created by signing up.',
    'ATH__FOUND_WG_ERROR':
        'Unfortunatelly there was an error while creating ' +
        'the shared flat: {{ error }}',

    'ATH__FORGOT_PASSWORD': 'Forgot your password',
    'ATH__FORGOT_PASSWORD-MSG':
        'Enter your email address ' +
        'and we will send you a new one.',
    'ATH__FORGOT_PASSWORD-CONFIRM': 'Forgot password',
    'ATH__FORGOT_PASSWORD-SUCCESS':
        'We have sent a new password to "{{ email }}" ' +
        '. Please change it at soon as you login.',
    'ATH__FORGOT_PASSWORD-ERROR':
        'It was not possible to send you a new password:\n\n{{ error }}',

    'ATH__LOGIN-TITLE': 'Login',
    'ATH__LOGIN-SUBHEAD':
        'Please log in with your account. ' +
        'Go back if you don\'t have one.',
    'ATH__LOGIN-CONFIRM': 'Login',

    'ATH__LOGIN_ERROR-TITLE': 'Login failed',
    'ATH__LOGIN_ERROR-MSG':
        'Login was unsuccessful. Please ' +
        'check your email and password.',

    'ATH__REGISTER_ERROR-TITLE': 'Registration failed',
    'ATH__REGISTER_ERROR-MSG':
        'Following Error occured: \n\n{{ error }}',

    'ATH__MOVE_IN-TITLE': 'Move into your Home',
    'ATH__MOVE_IN-SUBHEAD':
        'Create an account to move into your shared flat.',
    'ATH__MOVE_IN-MSG': 'Please enter your access code',
    'ATH__MOVE_IN-LOADING': 'Moving in…',

    // CHORES
    'CHO__HELP-TITLE': 'Cleaning Schedule',
    'CHO__HELP-MSG':
        'Flatastic reminds when its your turn to do the chores. ' +
        'Gently remind your flatmates of their chores ' +
        'or complete them yourself.\n' +
        'Check your score in the statistics section.',

    'CHO__NEW-TITLE': 'Create a new task',
    'CHO__NEW_ERROR-TITLE': 'Add a task',
    'CHO__NEW_ERROR-MSG':
        'An error occured while setting up the task: {{ error }}',
    'CHO__NEW-BTN': 'New Task',
    'CHO__NEW__GO_BACK-TITLE': 'Chore Schedule',
    'CHO__NEW__GO_BACK-MSG': 'Do you really want to go back without saving?',

    'CHO__OVERVIEW': 'Overview',
    'CHO__YOUR_STATUS': 'Your Score',

    'CHO__STATISTIC': 'Statistics',
    'CHO__STATISTIC-LOADING': 'Refreshing statistics…',
    'CHO__STATISTIC-LOADING_ERROR': 'Error while refreshing',

    'CHO__STATISTICS_RESET_BTN': 'Reset Statistics',
    'CHO__STATISTICS_RESET_CONFIRM-TITLE': 'Reset Statistics',
    'CHO__STATISTICS_RESET_CONFIRM-MSG':
        'Do you really want to reset the statistics? This action can\'t be undone.',
    'CHO__STATISTICS_RESET_SUCCESS-TITLE': 'Reset Statistics',
    'CHO__STATISTICS_RESET_SUCCESS-MSG':
        'Statistics have been reset.',
    'CHO__STATISTICS_RESET_ERROR-TITLE': 'Reset Statistics',
    'CHO__STATISTICS_RESET_ERROR-MSG':
        'Unfortunatelly an error occured:\n{{ error }}',

    'CHO__REMIND_USER-MSG':
        '🔔 {{ name }} has be reminded to complete the chore "{{ title }}".',
    'CHO__REMIND_USER-CONFIRM': 'Remind',

    'CHO__NEXT-TITLE_TAKE': 'Take over a task',
    'CHO__NEXT-MSG_TAKE':
        'You\'re so nice! Who should be next?',
    'CHO__NEXT-TITLE_OWN': 'Done',
    'CHO__NEXT-MSG_OWN': 'Are you sure?',


    'CHO__CHECK_FOR_PIC-TITLE': 'Check for {{ name }}',
    'CHO__CHECK_FOR_PIC-MSG': 'Has {{ name }} really completed the task ' +
        '"{{ title }}" already? {{ name }} will earn the points.',

    'CHO__REMOVE-TITLE': 'Remove task',
    'CHO__REMOVE-MSG':
        'Are you sure you want to remove "{{ title }}"?',

    'CHO__OPTIONS-TITLE': 'Task "{{ title }}"',
    'CHO__OPTIONS-BTN_DO': 'Complete',
    'CHO__OPTIONS-BTN_TAKE': 'I will do it',
    'CHO__OPTIONS-BTN_EDIT': 'Edit',
    'CHO__OPTIONS-BTN_REMIND': 'Remind {{ name }}',
    'CHO__OPTIONS-BTN_CHECK_FOR_PIC': 'Check for {{ name }}',

    'CHO__EDIT': 'Edit task',
    'CHO__FORM-TITLE': 'Title',
    'CHO__FORM-TITLE_PlACEHOLDER': 'eg. clean the bathroom',
    'CHO__FORM-START_AT': 'Start',
    'CHO__FORM-AT': 'On',
    'CHO__FORM-FREQUENCY': 'Frequency',
    'CHO__FORM-EFFORT': 'Effort',
    'CHO__FORM-EFFORT__SMALL': 'Small',
    'CHO__FORM-EFFORT__NORMAL': 'Normal',
    'CHO__FORM-EFFORT__BIG': 'Big',
    'CHO__FORM-EFFORT__HUGE': 'Huge',
    'CHO__FORM-FIXED_WEEKDAY': 'Always on {{ day }}?',
    'CHO__FORM-SELECT_FREQUENCY': 'Click on the profile images below to ' +
        'change the order:',
    'CHO__FORM-SELECT_PARTICIPANTS': 'Choose the participants',
    'CHO__FORM-WHO_IS_FIRST': 'Add persons',
    'CHO__FORM-WHO_IS_NEXT': 'Add more if necessary',
    'CHO__FORM-WHO_IS_IN': 'Who is in?',
    'CHO_FORM-EVERYONE_SELECTED': 'The selected order',
    'CHO__FORM-CONFIRM_EDIT': 'Save edited task',
    'CHO__FORM-CONFIRM_NEW': 'Done',

    'CHO__TITLE': 'Cleaning Schedule',
    'CHO__LOADING': 'Loading tasks',
    'CHO__HISTORY-TITLE': 'Chore History',
    'CHO__HISTORY_SHOW_BTN': 'Show Chore History',

    'CHO__MONETIZATION__ENTRIES_LIMIT-TITLE': 'Flatastic Premium',
    'CHO__MONETIZATION__ENTRIES_LIMIT-MSG': `You've reached the limit of {{choresLimit}} chore entries in the free version. Add an unlimited number of chores with Flatastic Premium.`,
    'CHO__MONETIZATION__ENTRIES_LIMIT-DISCARD': 'OK',
    'CHO__MONETIZATION__ENTRIES_LIMIT-CTA': '⭐️ Get Premium ⭐️',

    // TIME
    'TIME__AS_NEEDED': 'As needed',
    'TIME__ONCE': 'Once',
    'TIME__DAILY': 'Daily',
    'TIME__EVERY_2_DAYS': 'Every other day',
    'TIME__EVERY_3_DAYS': 'Every three days',
    'TIME__EVERY_4_DAYS': 'Every four days',
    'TIME__EVERY_5_DAYS': 'Every five days',
    'TIME__EVERY_6_DAYS': 'Every six days',
    'TIME__WEEKLY': 'Weekly',
    'TIME__EVERY_2_WEEKS': 'Every other week',
    'TIME__EVERY_3_WEEKS': 'Every three weeks',
    'TIME__EVERY_4_WEEKS': 'Every four weeks',
    'TIME__EVERY_5_WEEKS': 'Every five weeks',
    'TIME__EVERY_6_WEEKS': 'Every six weeks',
    'TIME__EVERY_2_MONTHS': 'Every two months',
    'TIME__EVERY_3_MONTHS': 'Every three months',
    'TIME__EVERY_4_MONTHS': 'Every four months',
    'TIME__EVERY_HALF_YEAR': 'Every six months',
    'TIME__MONTHLY': 'Monthly',
    'TIME__QUARTERLY': 'Quarterly',
    'TIME__EVERY_YEAR': 'Yearly',
    'TIME__1_DAY_LATE': '1 day late',
    'TIME__X_DAYS_LATE': '{{ x }} days late',
    'TIME__IN_1_DAY': 'in 1 day',
    'TIME__IN_X_DAYS': 'in {{ x }} days',
    'TIME__MONDAY': 'Monday',
    'TIME__TUESDAY': 'Tuesday',
    'TIME__WEDNESDAY': 'Wednesday',
    'TIME__THURSDAY': 'Thursday',
    'TIME__FRIDAY': 'Friday',
    'TIME__SATURDAY': 'Saturday',
    'TIME__SUNDAY': 'Sunday',
    'TIME__MONDAYS': 'each Monday',
    'TIME__TUESDAYS': 'each Tuesday',
    'TIME__WEDNESDAYS': 'each Wednesday',
    'TIME__THURSDAYS': 'each Thursday',
    'TIME__FRIDAYS': 'each Friday',
    'TIME__SATURDAYS': 'each Saturday',
    'TIME__SUNDAYS': 'each Sunday',

    // EXPENSES
    'EXP_TITLE': 'Expenses',
    'EXP__YOUR_STATUS': 'Your Balance',
    'EXP__BALANCE': 'Balance',
    'EXP__BALANCE_LOADING': 'Balance is being refreshed…',
    'EXP__BALANCE_LOADING_ERROR': 'Error while refreshing:',
    'EXP__BALANCE_RESET': 'Clear expenses',
    'EXP__BALANCE_RESET_CONFIRM_TEXT': 'When you clear the expenses, the statistics are set back to zero. ' +
        'Every flatmate receives an email with the old statistics. ' +
        'In the end all expenses are deleted.' +
        'Do you want to continue?',
    'EXP__BALANCE_RESET-MSG': '{{ creditor }} gets {{ credit }} from {{ debtor }}.',
    'EXP__BALANCE_RESET-MSG-ENDING': 'After you exchanged the money, continue here so the entries are made.',
    'EXP__BALANCE_BALANCED': 'Your expenses are now balanced again.',
    'EXP__EXPORT_CURRENT_EXPENSES': 'Export expenses',
    'EXP__EXPORT_SUCCESS_DIALOG__TITLE': 'Export successful ✅',
    'EXP__EXPORT_SUCCESS_DIALOG__MESSAGE': 'Please choose what you want to do with it in the next step.',
    'EXP__EXPORT_SHARE_DIALOG__TITLE': 'Export of our expenses',
    'EXP__EXPORT_SHARE_DIALOG__MESSAGE': 'Attached you can find all our expenses as a .csv file.',
    'EXP__TOTAL_SUM': 'Total Sum',
    'EXP__ENTRIES': 'Entries',
    'EXP__LOADING': 'Loading expenses',
    'EXP__SAVING_ENTRY': 'saving…',
    'EXP__SAVING_ERROR': 'Error while saving',
    'EXP__NO_ENTRIES__TITLE': 'No Entries',
    'EXP__NO_ENTRIES__TEXT': 'You are not involved in any expenses at the ' +
        'moment. Click the "+" button above to add a new expense.',

    'EXP__DETAIL_HELP-TITLE': 'Financial record',
    'EXP__DETAIL_HELP-MSG':
        'Here you can see all added ' +
        'finance records and edit or ' +
        'delete your own entries.',

    'EXP__DELETE-TITLE': 'Delete entry',
    'EXP__DELETE-MSG':
        'Do you really want to delete the entry "{{ name }}"?',

    'EXP__NEW-BTN': 'New Entry',
    'EXP__NEW-BTN_SAVE': 'Save entry',
    'EXP__NEW-PRODUCT_PLACEHODER': 'Enter product …',
    'EXP__NEW-BOUGHT_FOR': 'Product bought for:',
    'EXP__NEW-I_RECEIVE': 'I receive:',
    'EXP__NEW_HELP-TITLE': 'New expense',
    'EXP__NEW_HELP-MSG':
        'Log your expense with title and date. \n' +
        'For every product select the ' +
        'flatmates that are sharing the item ' +
        '(i.e. select the entire shared flat for toilet paper).\n' +
        'Deselect any flatmates that will not be using the items ' +
        '(i.e. deselect vegetarians if it is meat).',
    'EXP__NEW_CONFIRM_GO_BACK-TITLE': 'Expenses',
    'EXP__NEW_CONFIRM_GO_BACK-MSG':
        'Caution, the entry has not been saved. ' +
        'Do you want to save the entry?',
    'EXP__NEW_DEFAULT_EVENT': 'Supermarket',
    'EXP__NEW_NO_SELECTED_FM-TITLE': 'Add event',
    'EXP__NEW_NO_SELECTED_FM-MSG':
        'Please choose at least one of your flatmates, in order to ' +
        'add the entry "{{ name }}".',
    'EXP__NEW_ERROR-TITLE': 'Expenses',
    'EXP__NEW_NO_NEGATIVE_NUMBERS-MSG':
        'Negative values are not allowed',
    'EXP__NEW_NO_TOO_LARGE_NUMBERS-MSG':
        'Wow. That is expensive. For these kind of sums you may ' +
        'need a different tool.',
    'EXP__NEW_SUMMARY': 'I went shopping for <strong>{{ totalSum | wgCurrency }}</strong> ' +
        'and will get <strong>{{ sum | wgCurrency }}</strong> back.',

    'EXP__EDIT-BTN-DONE': 'Finish editing',

    'EXP__DELETE_ITEM-TITLE': 'Delete',
    'EXP__DELETE_ITEM-MSG': 'Do you really want to delete this entry?',

    'EXP__EDIT_ITEM-TITLE': 'Edit entry',
    'EXP__EDIT_ITEM-MSG':
        'You are already editing an entry. Please finish that instance ' +
        'first.',

    'EXP__SET_TITLE-TITLE': 'Enter title',
    'EXP__SET_TITLE-MSG': 'Choose a title for this purchase',

    'EXP__SAVE_ERROR-TITLE': 'Try again',
    'EXP__SAVE_ERROR-MSG':
        'An error occured while saving:\n{{ error }}',
    'EXP__SAVE_ERROR-CONFIRM': 'OK',

    'EXP__HELP-TITLE': 'Statistics',
    'EXP__HELP-MSG':
        'Always keep an overview of who payed for what. ' +
        'Forget about tallying up who owes who and let Flatastic ' +
        'do the work.',

    'EXP__STATISTICS_INFO-EVEN':
        'The statistics of {{ name }} are balanced. ' +
        'Congratulations :)',
    'EXP__STATISTICS_INFO-MINUS':
        '{{ name }} owes {{ value }} and ' +
        'should probably be the next one doing the shopping.',
    'EXP__STATISTICS_INFO-PLUS':
        '{{ name }} is in credit, {{ value }}. ' +
        'Somebody else should do the shopping.',
    'EXP__STATISTICS_INFO-TITLE': 'Balance of {{ name }}',

    'EXP__DETAIL-I_PAY': 'I will pay',
    'EXP__DETAIL-I_RECEIVE': 'I will receive',
    'EXP__DETAIL-BTN_EDIT': 'Edit entry',
    'EXP__DETAIL_BTN_DELETE': 'Delete entry',

    'EXP__SPENDINGS__TITLE': 'Personal spendings',
    'EXP__SPENDINGS__GET_PREMIUM__TEXT': 'Get Flatastic Premium to see your ' +
      'personal spendings.',
    'EXP__SPENDINGS__GET_PREMIUM__CTA': 'Get Premium',
    'EXP__SPENDINGS__NO-ENTRIES': 'There seem to be no expenses affecting you in the last 12 months.',

    'EXP__SETTLEMENTS-TITLE': 'Settlements History',
    'EXP__SETTLEMENTS-BTN_SHOW_SETTLEMENTS': 'Show former settlements',
    'EXP__SETTLEMENTS-BTN_SHOW_ENTRIES': 'show {{ entriesNum }} expenses',
    'EXP__SETTLEMENTS-BTN_SHOW_ENTRY': 'show {{ entriesNum }} expense',
    'EXP__SETTLEMENTS-BTN_SHOW_BALANCE': 'show final balance',
    'EXP__SETTLEMENTS-BTN_HIDE_BALANCE': 'hide balance',
    'EXP__SETTLEMENT-ENTRIES-TITLE': 'Related Expenses',
    'EXP__SETTLEMENTS_PERIOD': 'Period',
    'EXP__SETTLEMENTS_TRIGGERED_BY': '{{ name }} has triggered this settlement',
    'EXP__SETTLMENTS_INFO': `Settlements before Aug 1st 2019 are not included`,

    // GENERAL
    'MENU__SHOUTS': 'Bulletin Board',
    'MENU__SHOPPING_LIST': 'Shopping List',
    'MENU__CHORES': 'Cleaning Schedule',
    'MENU__EXPENSES': 'Expenses',
    'MENU__SETTINGS': 'Settings',

    // HOMELESS
    'HML__MOVE_IN-TITLE': 'Move in',
    'HML__MOVE_IN-ERROR':
        'The access code could not be found. ' +
        'Please check if you have entered ' +
        'everything correctly.',

    'HML__CREATE_WG_ERROR-TITLE': 'Move in',
    'HML__CREATE_WG_ERROR-MSG': 'Something went wrong while creating the shared flat.',

    'HML__SUBHEADER_1': 'Hello {{ name }},',
    'HML__SUBHEADER_2': 'you are not part of a shared flat right now.',

    'HML__CREATE_WG_BUTTON': 'Create new home',
    'HML__MOVE_IN_BUTTON': 'Enter access code ',
    'HML__DELETE_ACCOUNT': 'Delete Account',
    'HML__DELETE_ACCOUNT-MSG': 'Do you really want to delete your account?',
    'HML__DELETE_ACCOUNT_SUCCESS-MSG': 'Your account was successfully deleted. ' +
        'We\'ll miss you!',

    // FEEDBACK
    'FDB__TITLE': 'Feedback',
    'FDB__CONFIRM': 'Send',
    'FDB__INFO_TEXT':
        'Send us a message: Suggestions, criticisms, ' +
        'ideas, kudos or a simple "Hello". ' +
        'No matter what, we\'re happy to hear from you.',
    'FDB__CONFIRM_SUCCESS-TITLE': 'Feedback',
    'FDB__CONFIRM_SUCCESS-MSG': 'Your feedback has been sent. Thank you!',
    'FDB__CONFIRM_ERROR-TITLE': 'Feedback',
    'FDB__CONFIRM_ERROR-MSG':
        'Your Feedback could not be sent:\n{{ error }}',

    'FDB__HELP-TITLE': 'Feedback',
    'FDB__HELP-MSG':
        'Send us your feedback so we can develop Flatastic' +
        ' in the way that you want to use it.',


    // SETTINGS
    'SET__TITLE': 'Settings',
    'SET__COPYRIGHT':
        'flatastic {{ version }}<br/>' +
        'made with &#9728; and &#10084; in Berlin and Zurich',

    'SET__LIST-PROFILE': 'My Profile',
    'SET__LIST-WG': 'My Home',
    'SET__LIST-SHARE': 'Recommend',
    'SET__LIST-SHARE-WITH-FRIENDS': 'Tell your friends',
    'SET__LIST-THANKS': 'Say thanks with a like',
    'SET__LIST-THANKS_MONEY': 'Say thanks with a tip',
    'SET__LIST-FEEDBACK': 'Feedback',
    'SET__LIST-NOTIFICATIONS-FEEDBACK': 'Notifications',
    'SET__LIST-LANGUAGE': 'Change language',
    'SET__LIST-FAQ': 'FAQ',
    'SET__LIST-LAW_STUFF': 'Terms & Conditions',

    'SET__HELP-TITLE': 'Settings',
    'SET__HELP-MSG':
        'Change your name and password, invite ' +
        'new flatmates, change the notification behaviour ' +
        'or leave us a message',

    'SET__SHARE-TITLE': 'Have you heard of Flatastic?',
    'SET__SHARE-MSG':
        'Hey,\n\n' +
        'have you already heard of Flatastic, the App for you and your shared flat? ' +
        'The App has a shared shopping list, provides ' +
        'an overview over the shared finances and politely reminds you of your tasks.',

    'SET__LAW_STUFF-TITLE': 'Flatastic',
    'SET__LAW_STUFF-BTN_AGB': 'Terms & Conditions',
    'SET__LAW_STUFF-BTN_PRIVACY': 'Privacy',



    // SETTINGS - NOTIFICATIONS
    'NFDB__TITLE': 'Notifications',
    'NFDB__REPORT-PROBLEM': 'Report problem',
    'NFDB__TEST-NOTIFICATIONS': 'Test notifications',
    'NFDB__PUSHES_ARE_ACTIVATED': 'Push notifications are enabled',
    'NFDB__DEVICE_REGISTERED': 'Device is registered',
    'NFDB__TEST_SUCCESSFUL': 'Test successful. We couldn\'t find any problems.',
    'NFDB__TEST_RESULT': 'You received {{ numSuccess }} of {{ numTotal }} notifications',
    'NFDB__NOTIFICATION_PENDING': 'Waiting for notification ({{ type }})',
    'NFDB__NOTIFICATION_RECEIVED': 'Received notification ({{ type }})',
    'NFDB__NOTIFICATION_NOT_RECEIVED': 'Did not receive notification ({{ type }})',
    'NFDB__RERUN_TEST': 'Restart test',
    'NFDB__DEVICE_TOKEN_STORED': 'Device token is stored',


    // SHOPPING LIST
    'SHL__TITLE': 'Shopping List',
    'SHL__NAME_PLACEHOLDER': 'Enter missing product …',
    'SHL__BOUGHT': 'Bought:',
    'SHL__SYNC_BUTTON': 'Sync',
    'SHL__DELETE_BOUGHT_BUTTON': 'Remove bought items',

    'SHL__HELP-TITLE': 'The Shopping List',
    'SHL__HELP-MSG':
        'Collate a shopping list with your flatmates and always ' +
        'keep track of what is missing in the shared flat. You and your flatmates ' +
        'are automatically informed about each other\'s activities.',

    'SHL__SHARE-TITLE': 'Flatstic shopping list',
    'SHL__SHARE-MSG': 'Here\'s our shopping list:\n\n' +
        '{{ items }}\n\n' +
        '––––––––––––––––\n' +
        'we organize our household with an app called `Flatastic`',

    'SHL__REMOVE_BOUGHT-TITLE': 'Shopping List',
    'SHL__REMOVE_BOUGHT-MSG':
        'Do you want to remove all the items you\'ve bought from the list?',

    'SHL__REMOVE_BOUGHT_ERROR-TITLE': 'Shopping List',
    'SHL__REMOVE_BOUGHT_ERROR-MSG':
        'Unfortunatelly it didn\'t work. ' +
        'The bought Items were not removed ' +
        'from the shopping list. ' +
        'Maybe there was no internet connection? ' +
        'Please try again.',

    'SHL__DELETE_ITEM-TITLE': 'Delete {{ name }}',
    'SHL__DELETE_ITEM-MSG': 'Do you really want to delete ' +
        ' the item "{{ name }}"?',

    'SHL__INTERACT__BUY': 'Check',
    'SHL__INTERACT__BACK_TO_LIST': 'Back to list',
    'SHL__INTERACT__EDIT': 'Edit',

    'SHL__SYNC-TITLE': 'Shopping List',
    'SHL__SYNC-MSG':
        'There was bad internet connection and your ' +
        'shopping list has not been synchronised. \n' +
        'Retry now?',

    'SHL__SYNC_SUCCESS-TITLE': 'Shopping List',
    'SHL__SYNC_SUCCESS-MSG':
        'Flatastic! Your shopping list is up to date.',

    'SHL__SYNC_ERROR-TITLE': 'Shopping List',
    'SHL__SYNC_ERROR-MSG':
        'There was a bad internet connection and your ' +
        'shopping list has not been synchronised.\n' +
        'Sync now?',


    // SHOUTS
    'SHT__TITLE': 'Bulletin Board',
    'SHT__FORM_PLACEHOLDER': 'Write a message …',
    'SHT__LOADING': 'Bulletin board loading',
    'SHT__CHAT': 'Messages',
    'SHT__ACTIVITIES': 'Activities',

    'SHT__HELP-TITLE': 'Bulletin Board',
    'SHT__HELP-MSG':
        'Cooking dinner? Is mum visiting? ' +
        'Is the plumber waiting outside?\n' +
        'This is the place to tell your flatmates.',
    'SHT__THANKS_SUCCESS-TITLE': 'Say thanks.',
    'SHT__THANKS_SUCCESS-MSG': 'Your acknowledgment has been sent.',
    'SHT__CONFIRM_DELETE-TITLE': 'Delete entry?',
    'SHT__CONFIRM_DELETE-MSG':
        'Do you really want to delete this entry? The entry will not be ' +
        'visible for your flatmates either.',
    'SHT__NO_SHOUTS__TITLE': 'No messages yet',
    'SHT__NO_SHOUTS__TEXT': 'Leave a message and give your flatmates a ' +
        'warm welcome 💬👋',

    // SUBSCRIPTIONS
    'SUB__TITLE': 'Subscriptions',
    'SUB__MONTHLY_AMOUNT': 'Monthly Amount',
    'SUB__HELP-TITLE': 'Subscriptions',
    'SUB__HELP-MSG': 'Keep an eye on your monthly costs. In the future ' +
        ' new regulary expenses can be added and we will remind you to ' +
        ' unsubscribe your subscriptions in time.',
    'SUB__FIRST_BILL': 'First Bill',
    'SUB__CYCLE': 'Cycle',
    'SUB__DESCRIPTION': 'Description',

    //TOASTER
    'TSR__NEAR_WGS-TITLE': 'Shared flats nearby',
    'TSR__NEAR_WGS-MSG':
        'There are still not enough shared flats in your neighbourhood. ' +
        'Help us to change that and recommend Flatastic ' +
        'to your friends.',
    'TSR__NEAR_WGS-BTN_SHARE': 'Recommend',
    'TSR__NEAR_WGS-SHARE_SUBJ': 'Flatastic - The App for you and your shared Flat',
    'TSR__NEAR_WGS_SHARE-MSG':
        'Hey,\n' +
        'have you heard of Flatastic? The App ' +
        'helps organising your shared flat. ' +
        'It includes a shared shopping list, a ' +
        'cleaning plan, a digital housekeeping book ' +
        'and a digigtal pinboard. Check out the App.\n',
    'TSR__NEAR_WGS_ZIP-TITLE': 'Post Code',
    'TSR__NEAR_WGS_ZIP-MSG':
        'Please enter your post code so we ' +
        'can find nearby WGs.',

    // WG
    'WG__TITLE': 'My Home',
    'WG__BTN_INVITE_FLATMATE': 'Add flatmate',
    'WG__INVITE_TOGGLE_FLATMATES': 'Flatmates',
    'WG__INVITE_TOGGLE_WG_PROFILE': 'Flat Profile',
    'WG__PROFILE_LOADING': 'Flat profile loading',
    'WG__ALREADY_MOVED_IN': 'already moved in',
    'WG__BTN_MOVE_OUT': 'Move out',

    'WG__COVER_PICTURE': 'Cover Picture',

    'WG__HELP-TITLE': 'Your Home',
    'WG__HELP-MSG':
        'Invite new flatmates, see who has already moved in ' +
        'and give your shared flat a name.',

    'WG__SAVE_SUCCESS-TITLE': 'Your Shared Flat',
    'WG__SAVE_SUCCESS-MSG': 'Your changes have been saved.',

    'WG__MOVE_OUT__START-TITLE': 'Move Out',
    'WG__MOVE_OUT__START-MSG': 'Do you really want to move out? ' +
        'This action cannot be undone and you may loose data.',
    'WG__MOVE_OUT__START-CONFIRM': 'Move Out',

    'WG__MOVE_OUT_CONFIRM':
        'Are you sure you want to move out?',
    'WG__MOVE_OUT_CONFIRM-TITLE': 'Move out',
    'WG__MOVE_OUT_CONFIRM_BUTTON': 'Move out now',
    'WG__MOVE_OUT_CONFIRM_SUCCESS-TITLE': 'Move out',
    'WG__MOVE_OUT_CONFIRM_SUCCESS-MSG': 'You succesfully moved out.',
    'WG__MOVE_OUT_CONFIRM_ERROR-TITLE': 'Move out',
    'WG__MOVE_OUT_CONFIRM_ERROR-MSG':
        'An error occured. Please send us an ' +
        'email to support@flatastic-app.com, so we can help you!',

    'WG__KICK_OUT_CONFIRM-TITLE': 'Kick out {{ name }}',
    'WG__KICK_OUT_CONFIRM-MESSAGE': 'Do you really want to kick {{ name }} ' +
        'out of your shared home? That\'s not nice and the ' +
        'action cannot be undone. To confirm type "{{ name }}".',
    'WG__KICK_OUT_CONFIRM-BUTTON': 'Kick out',
    'WG__KICK_OUT_ERROR-MESSAGE': 'There was a problem. Please write an email ' +
        'to support@flatastic-app.com.',
    'WG__KICK_OUT_SETTLEMENT_NEEDED': 'The expenses are not settled. ' +
        'Please do a settlement first.',
    'WG__KICK_OUT-LOADING': '{{ name }} is being kicked out…',
    'WG__KICK_OUT__TYPE_ERROR-TITLE': 'Typing Mistake',
    'WG__KICK_OUT__TYPE_ERROR-MESSAGE': 'The input did not match to your ' +
        'roomie\'s name.',

    'WG__SETTLEMENT_MESSAGE_PART':
        '{{ sender }} pays {{ valueString }} to {{ receiver }}\n',

    'WG__SETTLEMENT_CONFIRM-TITLE': 'Finances balanced',
    'WG__SETTLEMENT_CONFIRM-MSG':
        'Before moving out you have to balance your finances. ' +
        'It will work out like this:\n\n{{ settlement }}',
    'WG__SETTLEMENT_CONFIRM-NOT_YET': 'Not yet',
    'WG__SETTLEMENT_CONFIRM-DO_NOW': 'Balance finances now',
    'WG__SETTLEMENT_CONFIRM_SUCCESS_CONFIRM_MOVE_OUT':
        'Your finances are now balanced. ' +
        'Are you still sure you want to move out?',

    'WG__INTERACT_TITLE': '{{ name }}',
    'WG__INTERACT_OPTIONS-TITLE': 'Please choose',
    'WG__INTERACT_OPTIONS-DELETE_INVITE': 'Delete invitation',
    'WG__INTERACT_OPTIONS-SEND_WG_CODE': 'Send access code',
    'WG__INTERACT_OPTIONS-KICKOUT': 'Kick out',

    'WG__CANCEL_INVITATION_CONFIRM-TITLE': 'Delete invitation',
    'WG__CANCEL_INVITATION_CONFIRM-MSG':
        'All expenses having to do with "{{ name }}" will be deleted. ' +
        'Do you really want to delete the invitation for "{{ name }}"?',
    'WG__CANCEL_INVITATION_CONFIRM-BTN': 'Delete invitation and expenses',
    'WG__CANCEL_INVITATION_SUCCESS-TITLE': 'Invitation has been deleted.',
    'WG__CANCEL_INVITATION_SUCCESS-MSG':
        '{{ name }} has been remove from your shared flat.',
    'WG__CANCEL_INVITATION_ERROR-TITLE': 'Invitation has not been deleted.',
    'WG__CANCEL_INVITATION_ERROR-MSG':
        'An error occured. {{ name }} ' +
        'has not been removed from your shared flat:\n{{ error }}',

    'WG__SHARE_WG_CODE-SUBJ': 'An app for our home',
    'WG__SHARE_WG_CODE-MSG': `Hey {{ name }},

I made us a new Flatastic Home – It’s the perfect app to make living together a breeze. You can download it below, create an account, then use the key "{{ wgCode }}" to join our Home.

Download the Flatastic App (https://flatastic-app.com/app)' and move in!`,
    'WG__SHARE_WG_CODE_SUCCESS-TITLE': 'Well done!',
    'WG__SHARE_WG_CODE_SUCCESS-MSG':
        'Nice! By entering the access code {{ name }} can now move into your shared flat.',

    'WG__INVITE_FLATMATE-TITLE': 'Add flatmate',
    'WG__INVITE_FLATMATE-MSG': 'Please enter the first name of your flatmate',
    'WG__INVITE_FLATMATE_NO_NAME-TITLE': 'Add flatmate',
    'WG__INVITE_FLATMATE_NO_NAME-MSG': 'You have to enter a name…',
    'WG__INVITE_FLATMATE-LOADING': 'Room for {{ name }} is beeing prepared…',
    'WG__INVITE_FLATMATE_SUCCESS-TITLE': 'Invite flatmate',
    'WG__INVITE_FLATMATE_SUCCESS-MSG':
        'Excellent! Please send {{ name }} the access code now, ' +
        'so that {{ name }} can move into your shared flat.',
    'WG__INVITE_FLATMATE_SUCCESS-SHARE_CODE': 'Send access code',
    'WG__INVITE_FLATMATE_ERROR-TITLE': 'Send access code',
    'WG__INVITE_FLATMATE_ERROR-MSG':
        'Unfortunatelly something went wrong… Please try again.',

    'WG__INVITE_FLATMATE_NOT_SHARED-TITLE': 'Almost done!',
    'WG__INVITE_FLATMATE_NOT_SHARED-MSG':
        '{{ name }} has to enter the access code in order to move into ' +
        'your shared flat.',

    'WG__INVITE_FLATMATE_NO_ONE_INVITED-MSG': 'You haven\'t added any ' +
        'flatmates.',
    'WG__INVITE_FLATMATE_NO_ONE_INVITED-CONFIRM': 'I\'m living alone',


    'WG__FLATMATE_REMINDER-TITLE': 'Send an invitation to your flatmate.',
    'WG__FLATMATE_REMINDER-MSG':
        'Living alone in a shared flat is no fun. ' +
        'Send invitation to your flatmates now.',
    'WG__FLATMATE_REMINDER-BTN': 'Send invitation',

    'WG__INCOMPLETE_REMINDER-TITLE': 'Somebody is missing…',
    'WG__INCOMPLETE_REMINDER-MSG':
        'Not all of your flatmates have moved in yet. ' +
        'Do you want to remind them and resend them ' +
        'the access code?',
    'WG__INCOMPLETE_REMINDER-BTN_SHARE': 'Remind flatmates',
    'WG__INCOMPLETE_REMINDER-BTN_LATER': 'Not now',

    'WG__MODAL-TITLE': 'Add flatmate',
    'WG__MODAL-CLOSE': 'Done',
    'WG__MODAL-SUBHEAD_ALONE':
        'Welcome! You are still alone in your shared flat… ' +
        'You can invite your flatmates here. ' +
        'Enter a name an press "Add".',
    'WG__MODAL-SUBHEAD_NOT_ALONE':
        'Add additional flatmates by entering their names ' +
        'and pressing "Add".',
    'WG__MODAL_INVITE-FIRST_NAME': 'Name:',
    'WG__MODAL_INVITE-CONFIRM': 'Add {{ name }}',
    'WG__MODAL-WG_CODE': 'Access code: {{ wgCode }}',
    'WG__MODAL-ALREADY_MOVED_IN': 'Already moved in',
    'WG__MODAL-INFO':
        '<strong>Info:</strong> Every invited flatmate ' +
        'receives an access code which is needed to move in. ' +
        'You can send the code e.g. via Mail or SMS.',

    'WG__SETUP__ADD_FLATMATES-TITLE': 'Add flatmates',
    'WG__SETUP__REMOVE_FLATMATE_CONFIRM-MSG':
        'Do you really want to remove {{ name }}?',
    'WG__SETUP-TITLE': 'Set up shared flat',
    'WG__SETUP-SUBHEADER':
        'Set up your flat profile and invite your flatmates now.',
    'WG__SETUP-BTN_ADD_FLATMATE': 'Add flatmate',
    'WG__SETUP-WHERE_ARE_YOUR_FM': 'Where are your flatmates?',
    'WG__SETUP-NOT_COOL_ALONE': 'It\'s no fun to live in a shared flat alone',
    'WG__SETUP-BTN_DONE': 'Move in',

    'WG__IMAGE-BTN': 'Click on the picture to change it.',
    'WG__IMAGE-SETUP_BTN': 'Add a flat picture now.',
    'WG__IMAGE_DIALOGUE-TITLE': 'Please select',
    'WG__IMAGE_DIALOGUE-FROM_GALLERY': 'Choose existing photo',
    'WG__IMAGE_DIALOGUE-TAKE_PHOTO': 'Take photo',
    'WG__IMAGE_ERROR_DIALOGUE-TITLE': 'Upload failed',
    'WG__IMAGE_ERROR_DIALOGUE-MSG': 'The following errors occured:\n\n {{ error }}',
    'WG__SELECT_TYPE': 'Household',
    'WG__PLEASE_CHOOSE': 'Please choose',
    'WG__TYPES-FAMILY': 'Family',
    'WG__TYPES-COUPLE': 'Couple',
    'WG__TYPES-SHAREDFLAT': 'Shared flat',

    'WG__FORMER_FLATMATE-FIRST_NAME': 'Moved out',

    // ADS
    'ADS__WARN_DIALOG-TITLE': 'Ads 😫',
    'ADS__WARN_DIALOG-MSG': 'Every now an then, we show some ads ' +
      'so that we can pay for the servers and keep on improving Flatastic. ' +
      'You hate ads? Then become a Premium user by donating us a beer or so.',
    'ADS__WARN_DIALOG-BTN_PRO_ADS': 'OK',
    'ADS__WARN_DIALOG-BTN_PREMIUM': 'Become a Premium user',

    // RATE
    'RATE__DIALOG-TITLE': 'Rate Flatastic',
    'RATE__DIALOG-MSG': 'If you enjoy using Flatastic, would you mind taking a ' +
        'moment to rate it? It won\'t take more than a minute.\n' +
        'Thanks for your support!',
    'RATE__DIALOG-BTN_NO_THANKS': 'No, Thanks',
    'RATE__DIALOG-BTN_REMIND_ME_LATER': 'Remind Me Later',
    'RATE__DIALOG-BTN_RATE_IT_NOW': 'Rate It Now',
  };

  $translateProvider
  .translations('en', mergeAll([
    EN,
    getTranslations('en')(purchaseTranslations),
    pushPrimerTranslations.en,
    purchaseViewTranslations.en,
    paywallTranslations.en,
    choreTranslations.en,
    introTranslations.en,
  ]))
  .translations('de', mergeAll([
    DE,
    getTranslations('de')(purchaseTranslations),
    pushPrimerTranslations.de,
    purchaseViewTranslations.de,
    paywallTranslations.de,
    choreTranslations.de,
    introTranslations.de,
  ]))
  .registerAvailableLanguageKeys(['de', 'en'], {
    'en_US': 'en',
    'en_UK': 'en',
    'de_DE': 'de',
    'de_CH': 'de',
    'de_AT': 'de',
  })
  .fallbackLanguage('en')
  .preferredLanguage('en');

}]);
