angular.module('flatasticApp')

.filter('range', [function() {
  return function(val, range) {
    for (var i = 0; i < range; i++) {
      val.push(i);
    }
    return val;
  };
}]);
