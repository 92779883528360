import AnalyticsModule from '../../modules/general/analytics';
import flatasticUserService from '../../modules/user/svc_user';
import flatasticWgService from '../../modules/wg/svc_wg';
import flatasticConfig from '../config';
import mcmAdmob from './mcmAdMob';
import yepptCordova from './yptCordova';

export default angular.module('flatastic.monetization', [
  'ionic',
  flatasticConfig,
  flatasticUserService,
  flatasticWgService,
  mcmAdmob,
  yepptCordova,
  AnalyticsModule,
])

.run(['$rootScope', '$window', '$state', 'AdHandler', 'Wg', 'User', 'FlatasticEvents',
    function($rootScope, $window, $state, AdHandler, Wg, User, FlatasticEvents) {

  // Shopping list
  $rootScope.$on(FlatasticEvents.shoppinglist.willCreate, function() {
    // Don't show ads if you are on the web app
    if (!ionic.Platform.isAndroid() && !ionic.Platform.isIOS()) {
      console.debug('Platform is neither Android nor iOS')
      return;
    }
    var key = 'Flatastic.shoppinglist.addCounter';
    if (!$window.localStorage[key]) {
      $window.localStorage[key] = 0;
    }
    var createCounter = ++$window.localStorage[key];
    var offset = 25;
    var frequency = 15;
    if (createCounter < offset) { return; }
    if (Wg.isPremium || User.isPremium() ) {
      // Don't show ads to Premium users
      console.debug('WG or user has premium status', {
        'Wg.isPremium': Wg.isPremium,
        'User.isPremium()': User.isPremium(),
      });
      return;
    }
    if (createCounter > 75) {
      frequency = 10;
    }
    if (createCounter % frequency !== 0) {
      console.debug('Only show ads every 15 entries');
      return;
    }
    AdHandler.showAdWarning('Shopping list')
      .then(function() {
        $state.go('wg.purchase');
      })
      .catch(AdHandler.showAd);
  })

  // Expenses
  $rootScope.$on(FlatasticEvents.expenses.willCreate, function() {
    // Don't show ads if you are on the web app
    if (!ionic.Platform.isAndroid() && !ionic.Platform.isIOS()) {
      return;
    }
    var key = 'Flatastic.expenses.createCounter';
    if (!$window.localStorage[key]) {
      $window.localStorage[key] = 0;
    }
    var createCounter = ++$window.localStorage[key];
    if (createCounter < 3) { return; } // Don't show ads too early
    if (Wg.isPremium || User.isPremium()) {
      // Don't show ads to Premium users
      console.debug('WG or user has premium status', {
        'Wg.isPremium': Wg.isPremium,
        'User.isPremium()': User.isPremium(),
      });
      return;
    }
    AdHandler.showAdWarning('Expenses')
      .then(function() {
        $state.go('wg.purchase');
      })
      .catch(AdHandler.showAd);
  });

  // Chores (create new chore)
  $rootScope.$on(FlatasticEvents.chores.willCreate, function() {
    // Don't show ads if you are on the web app
    if (!ionic.Platform.isAndroid() && !ionic.Platform.isIOS()) {
      return;
    }
    var key = 'Flatastic.chores.createCounter';
    if (!$window.localStorage[key]) {
      $window.localStorage[key] = 0;
    }
    var createCounter = ++$window.localStorage[key];
    var offset = 5;
    var frequency = 5;
    if (createCounter > 21) {
      frequency = 3;
    }
    // Show ads for every five chores that created (not for the first 5)
    if (createCounter <= offset || createCounter % frequency !== 0) { return; }

    if (Wg.isPremium || User.isPremium()) {
      // Don't show ads to Premium users
      console.debug('WG or user has premium status', {
        'Wg.isPremium': Wg.isPremium,
        'User.isPremium()': User.isPremium(),
      });
      return;
    }
    AdHandler.showAdWarning('Chores')
      .then(function() {
        $state.go('wg.purchase');
      })
      .catch(AdHandler.showAd);
  });

  // Chores (complete chore)
  $rootScope.$on(FlatasticEvents.chores.willComplete, function() {
    // Don't show ads if you are on the web app
    if (!ionic.Platform.isAndroid() && !ionic.Platform.isIOS()) {
      return;
    }
    var key = 'Flatastic.chores.completeCounter';
    if (!$window.localStorage[key]) {
      $window.localStorage[key] = 0;
    }
    var createCounter = ++$window.localStorage[key];
    var offset = 15
    var frequency = 5
    if (createCounter > 75) {
      frequency = 3;
    }
    // Show ads for every five chores that completed (not for the first 25)
    if (createCounter <= offset || createCounter % frequency !== 0) { return; }

    if (Wg.isPremium || User.isPremium()) {
      // Don't show ads to Premium users
      console.debug('WG or user has premium status', {
        'Wg.isPremium': Wg.isPremium,
        'User.isPremium()': User.isPremium(),
      });
      return;
    }
    AdHandler.showAdWarning('Chores')
      .then(function() {
        $state.go('wg.purchase');
      })
      .catch(AdHandler.showAd);
  });
}])

.factory('AdHandler',
  ['$rootScope', '$q', '$filter', 'AdMob', 'yptNotify', 'Analytics',
    function($rootScope, $q, $filter, AdMob, yptNotify, Analytics) {
  AdMob.getInterstitial();

  return {
    showAdWarning,
    showAd,
  };

  function showAd() {
    return AdMob.getInterstitial()
      .then(AdMob.showInterstitialAd)
      .catch(console.error);
  }

  function showAdWarning(reason) {
    $rootScope.$broadcast('track-event', {
      category: 'Monetization:Ads:Alert',
      action: 'Was triggered',
      label: reason || undefined,
    })
    var deferred = $q.defer() ;
    var msg = {
      title: $filter('translate')('ADS__WARN_DIALOG-TITLE'),
      message: $filter('translate')('ADS__WARN_DIALOG-MSG'),
      buttonLabels: [
        $filter('translate')('ADS__WARN_DIALOG-BTN_PRO_ADS'),
        $filter('translate')('ADS__WARN_DIALOG-BTN_PREMIUM'),
      ],
      callback: function(res) {
        $rootScope.$broadcast('track-event', {
          category: 'Monetization:Ads:Alert',
          action: 'Did click',
          label: res ? 'ADS__WARN_DIALOG-BTN_PREMIUM' : 'ADS__WARN_DIALOG-BTN_PRO_ADS',
        })
        Analytics.logEvent(res ? 'freemium__did_select_iap' : 'freemium__did_select_ad')
        return res
          ? deferred.resolve(res)
          : deferred.reject();
      },
    };
    yptNotify.confirm(msg);
    return deferred.promise;
  }

}])
.name;
