import SubscriptionsController from './ctrl_subscriptions'
import template from './view_subscriptions.html';

export default angular.module('flatastic.subscriptions', [
  SubscriptionsController,
])

.config(
    ['$stateProvider',
    function ($stateProvider) {

  $stateProvider

  .state('wg.subscriptions', {
    url: '/subscriptions',
    views: {
      'menuContent': {
        controller: 'SubscriptionsCtrl',
        template,
      },
    },
  });
}])

.name;
