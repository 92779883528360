// modules
import ExpensesListingComponent from './comp_expenses-listing'
import ExpensesController from './ctrl_expenses'
import ExpensesControllerDetails from './ctrl_expenses-details'
import ExpensesControllerNew from './ctrl_expenses-new'
import SettlementEntriesController from './ctrl_settlementEntries'
import SettlementEntriesDetailsController from './ctrl_settlementEntries-details'
import SettlementsController from './ctrl_settlements'
import PersonalSpendings from './expenses-spendings.comp'
import ExpensesService from './svc_expenses'
import SettlementEntriesService from './svc_settlementEntries'
import SettlementsService from './svc_settlements'
//templates
import expensesDetailsView from './view_expenses-details.html'
import expensesNewView from './view_expenses-new.html'
import expensesView from './view_expenses.html'
import settlementEntriesView from './view_settlementEntries.html'
import settlementsView from './view_settlements.html'


export default angular
.module('flatastic.expenses', [
  ExpensesService,
  SettlementsService,
  SettlementEntriesService,
  ExpensesController,
  ExpensesControllerNew,
  ExpensesControllerDetails,
  SettlementsController,
  SettlementEntriesController,
  SettlementEntriesDetailsController,
  ExpensesListingComponent,
  PersonalSpendings,
])
.config(
    ['$stateProvider',
    function ($stateProvider) {

  $stateProvider
  .state('wg.expenses', {
    url: '/expenses',
    views: {
      'menuContent': {
        controller: 'ExpensesCtrl',
        template: expensesView,
      },
    },
  })
  .state('wg.expenses-new', {
    url: '/expenses-new',
    views: {
      'menuContent': {
        controller: 'ExpensesNewCtrl',
        template: expensesNewView,
      },
    },
  })
  .state('wg.expenses-details', {
    url: '/expenses/:id',
    views: {
      'menuContent': {
        controller: 'ExpensesDetailsCtrl',
        template: expensesDetailsView,
      },
    },
  })
  .state('wg.expenses-edit', {
    url: '/expenses/:id/edit',
    views: {
      'menuContent': {
        controller: 'ExpensesNewCtrl',
        template: expensesNewView,
      },
    },
  })
  .state('wg.settlements', {
    url: '/settlements',
    onEnter: ['User', '$state', function(User, $state) {
      if (!User.isPremium()) {
        const label = 'Show settlements'
        $state.go('wg.premium-paywall', { label })
        throw 'User is not premium'
      }
    }],
    views: {
      'menuContent': {
        controller: 'SettlementsCtrl',
        template: settlementsView,
      },
    },
  })
  .state('wg.settlements-entries', {
    url: '/settlements/:settlementId',
    views: {
      'menuContent': {
        controller: 'SettlementEntriesCtrl',
        template: settlementEntriesView,
      },
    },
  })
  .state('wg.settlements-entries-details', {
    url: '/settlements/:settlementId/expenses/:expenseId',
    views: {
      'menuContent': {
        controller: 'SettlementEntriesDetailsCtrl',
        template: expensesDetailsView,
      },
    },
    resolve: {
      settlement: ['Settlements', 'SettlementEntries', '$stateParams', function(Settlements, SettlementEntries, $stateParams) {
        const settlementId = $stateParams.settlementId
        const settlement = Settlements.findLocally(settlementId)
        if (settlement) {
          return Promise.resolve(settlement)
        }
        return SettlementEntries.fetch({ settlementId })
      }],
    },
  });

}])
.name;
