import flatasticConfig from '../../scripts/config';
import yepptApi from '../../scripts/modules/api';
import yepptCollection from '../../scripts/modules/collection';
import flatasticUserService from '../user/svc_user';
import flatasticShoppinglistItem from './svc_shoppinglistitem';

export default angular.module('flatastic.shoppinglist.service', [
  flatasticConfig,
  flatasticShoppinglistItem,
  flatasticUserService,
  yepptApi,
  yepptCollection,
])

.run(['Shoppinglist', 'User', '$rootScope', 'FlatasticEvents',
      function(Shoppinglist, User, $rootScope, FlatasticEvents) {

  Shoppinglist.restoreLocally();
  if (User.isLoggedIn() && User.properties.groupId == 2) {
    Shoppinglist.getFromServer();
  }

  [
    FlatasticEvents.shoppinglist.update,
    FlatasticEvents.user.didEnterWg,
  ].forEach(function(event) {
    $rootScope.$on(event, function() {
      Shoppinglist.getFromServer();
    });
  });

  $rootScope.$on(FlatasticEvents.user.didLogout, function() {
    Shoppinglist.clear();
  });
  $rootScope.$on(FlatasticEvents.shoppinglist.deleteItem, function(event, item) {
    // We need to take care for the scope, so we use .call()
    Shoppinglist.remove.call(Shoppinglist.items, item.id);
    Shoppinglist.storeLocally();
  });
  $rootScope.$on(FlatasticEvents.shoppinglist.addItem, function(event, item) {
    Shoppinglist.add(item, true).storeLocally();
  });
  $rootScope.$on(FlatasticEvents.shoppinglist.saveItem, function(event, item) {
    Shoppinglist.storeLocally();
  });
}])

.factory('Shoppinglist',
    ['YepptCollection', 'Api', 'ShoppinglistItem', '$window',
    '$q', 'LocalStorageKeys', 'FlatasticEvents',
    function (YepptCollection, Api, ShoppinglistItem, $window,
    $q, LocalStorageKeys, FlatasticEvents) {

  var config = {
    shoppinglistStorageKey: LocalStorageKeys.shoppinglist.storage,
  };

  var myStatus = '';

  var items = new YepptCollection();

  var getFromServer = function() {
    myStatus = 'loading';
    return Api.get('/shoppinglist')
    .success(function(data) {
      var itemsToDelete = [];
      items.get().forEach(function(item) {
        if (item.status != 'not_synced' && item.status != 'syncing') {
          itemsToDelete.push(item.id);
        }
      });
      itemsToDelete.forEach(function(deleteId) {
        items.remove(deleteId);
      });
      data.forEach(function(serverItem) {
        serverItem.date = new Date(serverItem.date * 1000);
        if (!items.get(serverItem.id)) {
          items.push(new ShoppinglistItem(serverItem));
          storeLocally();
          return;
        }
        if (items.get(serverItem.id).date > serverItem.date) {
          items.get(serverItem.id).sync();
        } else {
          items.get(serverItem.id).set(serverItem).saveCollection();
        }
      });
    })
    .finally(function() {
      myStatus = '';
    });
  };
  var addToList = function(item) {
    var self = this;
    items.push(item, true);
    return self;
  };
  var storeLocally = function() {
    $window.localStorage[config.shoppinglistStorageKey] = JSON.stringify(items);
  };
  var restoreLocally = function() {
    if (!$window.localStorage[config.shoppinglistStorageKey]) {
      return;
    }
    var itemObjs = JSON.parse($window.localStorage[config.shoppinglistStorageKey]);
    itemObjs.forEach(function(item) {
      items.push(new ShoppinglistItem(item));
    });
  };
  var removeLocally = function() {
    delete $window.localStorage[config.shoppinglistStorageKey];
  };
  var numOutOfSync = function() {
    return items.reduce(function(pre, cur) {
      return (pre + (cur.status == 'not_synced'));
    }, 0);
  };
  var sync = function() {
    var deferred = $q.defer();
    var promise = deferred.promise;
    promise.success = function(fn) {
      promise.then(fn);
      return promise;
    };
    promise.error = function(fn) {
      promise.then(null, fn);
      return promise;
    };
    var numInSync = 0;
    var errorOccurred = false;
    items.get().forEach(function(item) {
      if (item.status == 'not_synced') {
        numInSync++;
        item.sync()
        .success(function() {
          numInSync--;
          if (numInSync == 0) {
            deferred.resolve();
          }
        })
        .error(function(data) {
          if (errorOccurred) {
            return
          }
          errorOccurred = true;
          deferred.reject(data);
        });
      }
    });
    return promise;
  };
  var isSyncing = function() {
    var numSyncing = 0;
    items.get().forEach(function(item) {
      if (item.status == 'syncing') {
        numSyncing++;
      }
    });
    return numSyncing > 0;
  };
  var removeBought = function() {
    var tempItems = angular.copy(items.get());
    angular.copy(items.get()).forEach(function(item) {
      if (item.bought == 1) {
        items.remove(item.id);
      }
    });
    return Api.get('/shoppinglist/delete_bought_items')
    .success(function(data) {
      $rootScope.$broadcast(FlatasticEvents.shoppinglist.didRemoveBoughtItems)
      angular.copy(items.get()).forEach(function(item) {
        if (item.bought == 1) {
          items.remove(item.id);
        }
      });
      storeLocally();
    })
    .error(function(data) {
      items.push(tempItems);
    });
  };
  var clear = function() {
    removeLocally();
    items.remove();
  };
  var numItems = function(val) {
    return items.reduce(function(pre, cur) {
      return pre + (cur.bought == val);
    }, 0);
  };
  var getBoughtItems = function() {
    return items.filter(function(item) {
      return item.bought;
    });
  };
  var getItemsToBuy = function() {
    return items.filter(function(item) {
      return !item.bought;
    });
  };

  // Public API here
  return {
    get: function(id) {
      return items.get(id);
    },
    items,
    add: addToList,
    remove: items.remove,
    getFromServer,
    getBoughtItems,
    getItemsToBuy,
    numOutOfSync,
    isSyncing,
    sync,
    storeLocally,
    restoreLocally,
    removeBought,
    clear,
    numItems,
    status: function() {
      return myStatus
    },
  };
}])

.name;

