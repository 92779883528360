import * as moment from 'moment'
import 'chartjs-plugin-datalabels'
import ChartJsModule from 'angular-chart.js'
import WgService from '../wg/svc_wg'
import ExpensesService from './svc_expenses'
import UserService from '../user/svc_user'

//templates
import expensesSpendingsView from './expenses-spendings.view.html';

export default angular.module('flatastic.expenses.personal-spendings', [
  ChartJsModule,
  WgService,
  ExpensesService,
  UserService,
])

.config(['ChartJsProvider', function(ChartJsProvider) {
  ChartJsProvider.setOptions({
    colors: ['#00ADF9', '#DCDCDC', '#803690', '#46BFBD', '#FDB45C', '#949FB1', '#4D5360'],
  })
}])

.controller('PersonalSpendingsController', [
  'Expenses', 'Wg', 'User',
  function(Expenses, Wg, User) {

  var vm = this;
  vm.user = User;
  vm.options = {
    plugins: {
      datalabels: {
        backgroundColor: 'rgba(255,255,255, 0.75)',
        borderRadius: 20,
        color: '#21A358',
        font: {
          weight: 'bold',
        },
        padding: {
          top: 2,
          bottom: 2,
          left: 4,
          right: 4,
        },
        align: 'end',
        anchor: 'start',
        display: function(context) {
          // Show value when it's on a vertical line
          var value = context.dataset.data[context.dataIndex];
          return context.chart.scales['x-axis-0']._ticks.reduce(function(acc, tick) {
            return acc || tick.value === value.t.getTime();
          }, false) || 'auto';
        },
        offset: 8,
        formatter: function(value, context) {
          return value.y.toFixed(2);
        },
      },
    },
    tooltips: {
      mode: 'index',
      intersect: false,
      custom: function(tooltip) {
        if (!tooltip) return;
        tooltip.displayColors = false;
      },
      callbacks: {
        title: function(tooltipItem, data) {
          var currentTooltip = tooltipItem[0];
          var currentDatasetIndex = currentTooltip.datasetIndex;
          var date = data.datasets[currentDatasetIndex].data[currentTooltip.index].t
          return moment(date).format('MMMM YYYY')
        },
        labelColor: function(tooltipItem, chart) {
          return {
            backgroundColor: 'white',
          }
        },
        label: function(tooltipItem, data) {
          return Wg.currency + ' ' + tooltipItem.yLabel.toFixed(2);
        },
      },
    },
    layout: {
      padding: {
        right: 24,
      },
    },
    scales: {
      xAxes: [{
        type: 'time',
        distribution: 'linear',
        time: {
          unit: 'month',
        },
        ticks: {
          maxRotation: 30,
          minRotation: 30,
          autoSkip: true,
        },
      }],
      yAxes: [{
        scaleLabel: {
          labelString: Wg.currency,
          display: true,
        },
        ticks: {
          beginAtZero: true,
        },
        afterFit: function(scale) {
          scale.paddingTop = 25;
        },
      }],
    },
  }

  vm.getPersonalSpendings = function() {
    return Expenses.getPersonalSpendings()
  }

  vm.getColor = function(){
    return '#21A358';
  }
}])

.component('personalSpendings', {
  template: expensesSpendingsView,
  controllerAs: 'vm',
  controller: 'PersonalSpendingsController',
})

.name;
