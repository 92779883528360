export default angular.module('flatastic.persistentStorage', [])

.factory('PersistentStorage', [
    '$window', '$q', function($window, $q) {

  return {
    fetch: fetch,
    remove: remove,
    store: store,
  };

  //// Function

  function fetch(key) {
    var deferred = $q.defer();
    if (!$window.plugins || !$window.plugins.appPreferences) {
      deferred.reject('Plugin appPreferences is not installed');
      return deferred.promise;
    }
    $window.plugins.appPreferences.fetch(function(value) {
      deferred.resolve(value);
    }, function() {
      deferred.reject('Fetching the key failed');
    }, key);
    return deferred.promise;
  }

  function remove(key) {
    var deferred = $q.defer();
    if (!$window.plugins || !$window.plugins.appPreferences) {
      deferred.reject('Plugin appPreferences is not installed');
      return deferred.promise;
    }
    $window.plugins.appPreferences.remove(function() {
      deferred.resolve('Removing the key succeeded');
    }, function() {
      deferred.reject('Removing the key failed');
    }, key);
    return deferred.promise;
  }

  function store(key, value) {
    var deferred = $q.defer();
    if (!$window.plugins || !$window.plugins.appPreferences) {
      deferred.reject('Plugin appPreferences is not installed');
      return deferred.promise;
    }
    $window.plugins.appPreferences.store(function() {
      deferred.resolve('Storing the value succeeded');
    }, function() {
      deferred.reject('Storing the value failed')
    }, key, value);
    return deferred.promise;
  }
}])

.name;
