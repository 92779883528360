import { mapObjIndexed } from 'ramda'

const meta = ['en', 'de']
const trans = {
  [`Sign in to your account`]: [
    'Sign in to your account',
    'Logge dich in deinen bestehenden Account ein',
  ],
  [`Enter Email`]: [
    'Enter Email',
    'z.B. meine@email.com',
  ],
  [`Enter Password`]: [
    'Enter Password',
    '· · · · ·',
  ],
}

export default {
  en: mapObjIndexed((val, key, obj) => val[0])(trans),
  de: mapObjIndexed((val, key, obj) => val[1])(trans),
}
