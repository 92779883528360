import template from './premium-advantages.comp.html'

export const translations = [
  [
    'Chores History',
    'Putzplan History',
  ],
  [
    'With the chores history you can see who has gotten the points when. No more cheating.',
    'Wer hat wann was erledigt: Mit der Historie des Putzplans siehst du wie Punkte verdient wurden.',
  ],
  [
    'Personal spendings',
    'Persönliche Ausgaben',
  ],
  [
    'Keep track of your own spendings. Check your monthly budget and discover outliers.',
    'Behalte deine eigenen Ausgaben im Blick. Überprüfe dein monatliches Budget und entdecke Ausreißer.',
  ],
  [
    'No more ads',
    'Keine Werbung mehr',
  ],
  [
    `We also don't like ads. But somehow we need to pay our bills.`,
    'Wir finden Werbung genauso nervig wie du. Aber jemand muss die Rechnungen zahlen.',
  ],
  [
    'Export of the expenses',
    'Export der Finanzeinträge',
  ],
  [
    `Export a file of all your household's expenses. Save justice and do your own big data magic.`,
    'Exportiere alle Einträge deiner WG in einer Datei. Speichere die Gerechtigkeit und mach deine ganz eigene Big-Data Magic',
  ],
  [
    'Settlements Archive',
    'Kassensturz-Archiv',
  ],
  [
    'Get access to the archive of all settlements and check if you have already added this expense from five weeks ago.',
    'Greife auf das Archiv deiner Finanzeinträge zu und schau dir eure letzten Abrechnungen an.',
  ],
  [
    'Image upload',
    'Bilder hochladen',
  ],
  [
    'Add images of the receipts to your expenses and post photos to the bulletin board.',
    'Damit ihr Bilder auf die Pinnwand posten und Belege zu den Finanzeinträgen hinzufügen könnt.',
  ],
]

export default angular.module('flatastic.component.premium-advantages', [])

.component('premiumAdvantages', {
  controller: [function() {
    this.premiumAdvantages = [
      {
        title: translations[0][0],
        description: translations[1][0],
      }, {
        title: translations[2][0],
        description: translations[3][0],
      }, {
        title: translations[4][0],
        description: translations[5][0],
      }, {
        title: translations[6][0],
        description: translations[7][0],
      }, {
        title: translations[8][0],
        description: translations[9][0],
      }, {
        title: translations[10][0],
        description: translations[11][0],
        soon: true,
      },
    ]
  }],
  template,
})

.name;
