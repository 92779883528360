import template from './premium-feedback.comp.html'

export const translations = [
  [
    `Help us to improve the premium version!`,
    `Hilf mit die Premium Version zu verbessern!`,
  ],
  [
    `We are happy about honest feedback from users, who want to help testing new features.`,
    `Wir freuen uns über jedes ehrliche Feedback von Nutzern, die helfen wollen, neue Features zu testen.`,
  ],
  [
    `Send a message`,
    `Nachricht senden`,
  ],
  [
    `Write feedback or just say 'hello'. We are looking forward to it.`,
    `Feedback oder ein nettes "Hallo" hinterlassen. Wir freuen uns.`,
  ],
]

export default angular.module('flatastic.component.premium-feedback', [])
.component('premiumFeedback', {
  template,
})
.name;
