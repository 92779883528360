import { map, prop } from 'ramda'

export const CHORES__FILTER__PAYWALL__MSG = 'CHORES__FILTER__PAYWALL__MSG'
export const CHORES__FILTER__OVERDUE = 'CHORES__FILTER__OVERDUE'
export const CHORES__FILTER__SECTION_FLATMATES = 'CHORES__FILTER__SECTION_FLATMATES'
export const CHORES__FILTER__SECTION_MORE = 'CHORES__FILTER__SECTION_MORE'

const translations = {
  [CHORES__FILTER__PAYWALL__MSG]: {
    en: `Get Flatastic premium to filter chores by who's responsible, the status and type.`,
    de: `Hol dir Flatastic Premium um Aufgaben nach Mitbewohnern, Status und Art zu filtern.`,
  },
  [CHORES__FILTER__OVERDUE]: {
    en: 'Overdue chores',
    de: 'Überfällig',
  },
  [CHORES__FILTER__SECTION_FLATMATES]: {
    en: 'Filter for flatmates:',
    de: 'Nach Mitbewohnern filtern:',
  },
  [CHORES__FILTER__SECTION_MORE]: {
    en: 'More filters:',
    de: 'Weitere Filter:',
  },

}

export default {
  en: map((prop('en')))(translations),
  de: map((prop('de')))(translations),
}
