import StatusBarModule from '../../scripts/modules/statusbar'
import appReviews, { translations as appReviewsTranslations } from './app-reviews.comp'
import flatasticPurchaseController from './ctrl_purchase'
import iapPlans from './iap-plans.comp'
import premiumAdvantages, { translations as premiumAdvantagesTranslations } from './premium-advantages.comp'
import premiumFeedback, { translations as premiumFeedbackTranslations } from './premium-feedback.comp'
import purchaseView from './purchase.html'
import flatasticPurchaseService, { defaultIapOfferings } from './svc_purchase'
// templates
import purchaseLegacyView from './view_purchase.html'


export const translations = [
  ...premiumAdvantagesTranslations,
  ...appReviewsTranslations,
  ...premiumFeedbackTranslations,
]

export default angular
.module('flatastic.purchase', [
  flatasticPurchaseService,
  flatasticPurchaseController,
  premiumAdvantages,
  premiumFeedback,
  StatusBarModule,
  appReviews,
  iapPlans,
])
.config(
    ['$stateProvider',
    function ($stateProvider) {

  $stateProvider
  .state('wg.purchase', {})
  .state('wg.purchase-1', {
    url: '/purchase-1',
    views: {
      'menuContent': {
        controller: 'PurchaseCtrl',
        template: purchaseLegacyView,
      },
    },
  })
  .state('wg.purchase-2', {
    url: '/purchase-2',
    onEnter: ['PurchaseModal', 'StatusBar', function(PurchaseModal, StatusBar) {
      StatusBar.setStyleDefault().catch(console.error)
      PurchaseModal.show()
    }],
    onExit: ['PurchaseModal', 'StatusBar', function(PurchaseModal, StatusBar) {
      StatusBar.setStyleLightContent().catch(console.error)
      PurchaseModal.hide()
    }],
  });
}])

.factory('PurchaseModal', ['$rootScope', '$ionicModal', function($rootScope, $ionicModal) {
  const template = purchaseView
  return $ionicModal.fromTemplate(template, {
    scope: $rootScope,
    animation: 'slide-in-up',
  })
}])

.run(['$rootScope', 'Firebase', '$state', '$timeout', function($rootScope, Firebase, $state, $timeout) {
  // Ensure multiple dont' result in going back more than once
  let didJustGoBack = false
  $rootScope.modalExit = function() {
    if (didJustGoBack) {
      return
    }
    history.back();
    didJustGoBack = true
    $timeout(750).then(() => (didJustGoBack = false))
  }
  $rootScope.$on('$stateChangeStart', function(event, toState, toParams, fromState, fromParams) {
    if (toState.name !== 'wg.purchase') {
      return
    }
    event.preventDefault();
    return Firebase.getConfigValue('iap_offerings', defaultIapOfferings)
      .then(iapOfferings => $state.go(iapOfferings.screenName, toParams))
  })
}])

.name;
