/*
 * Author: Michal Zalecki https://github.com/MichalZalecki
 *
 * Source: https://michalzalecki.com/why-using-localStorage-directly-is-a-bad-idea/
 *
 * Needs: Wrap localStorage calls with try/catch block
 *
 */
export default angular.module('flatastic.localStorage', [])

.factory('LocalStorageClass', [
    function() {

  function LocalStorageClass(storage) {
    this.localStorage = storage;
    this.inMemoryStorage = {};
  }

  LocalStorageClass.prototype.setStorage = function(storage) {
    var self = this;
    self.localStorage = storage;
  }

  LocalStorageClass.prototype.isSupported = function() {
    var self = this;
    try {
      var testKey = "random_key";
      self.localStorage.setItem(testKey, testKey);
      self.localStorage.removeItem(testKey);
      return true;
    } catch (e) {
      return false;
    }
  }

  LocalStorageClass.prototype.getItem = function(name) {
    var self = this;
    if (self.isSupported()) {
      return self.localStorage.getItem(name);
    }
    if (self.inMemoryStorage.hasOwnProperty(name)) {
      return self.inMemoryStorage[name];
    }
    return null;
  }

  LocalStorageClass.prototype.setItem = function(name, value) {
    var self = this;
    if (self.isSupported()) {
      self.localStorage.setItem(name, value);
    } else {
      if (typeof(value) !== "string"){
        self.inMemoryStorage[name] = value.toString();
      } else {
        self.inMemoryStorage[name] = value;
      }
    }
  }

  LocalStorageClass.prototype.removeItem = function(name) {
    var self = this;
    if (self.isSupported()) {
      self.localStorage.removeItem(name);
    } else {
      delete self.inMemoryStorage[name];
    }
  }
  return LocalStorageClass;
}])

.factory('LocalStorage', ['LocalStorageClass', '$window',
    function(LocalStorageClass) {

  var localStorageObj = new LocalStorageClass($window.localStorage);
  return localStorageObj;
}])

.name;
