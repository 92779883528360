import StatusBarModule from '../../scripts/modules/statusbar'
import SignUpController from './ctrl_create-account'
import LoginController from './ctrl_login'
import MoveInController from './ctrl_moveIn'
import StartController from './ctrl_start'
import createAccountView from './view_createAccount.html'
import loginView from './view_login.html'
import moveInView from './view_moveIn.html'
import startView from './view_start.html'

export default angular
.module('flatastic.auth', [
  SignUpController,
  LoginController,
  StartController,
  MoveInController,
  StatusBarModule,
])
.config(
    ['$stateProvider',
    function ($stateProvider) {

  $stateProvider
    .state('start', {
      url: '/start',
      views: {
        'root': {
          controller: 'StartCtrl',
          template: startView,
        },
      },
      onEnter,
    })
    .state('start-create-wg', {
      url: '/create-wg',
      views: {
        'root': {
          controller: 'CreateAccountCtrl',
          template: createAccountView,
        },
      },
      onEnter,
    })
    .state('start-move-in', {
      url: '/move-in/:code',
      views: {
        'root': {
          controller: 'MoveInCtrl',
          template: moveInView,
        },
      },
      onEnter,
    })
    .state('start-login', {
      url: '/login',
      views: {
        'root': {
          controller: 'LoginCtrl',
          template: loginView,
        },
      },
      onEnter,
    });

}])

.name;

onEnter.$inject = ['StatusBar']
function onEnter(StatusBar) {
  StatusBar.setStyleLightContent()
}
