import yepptCordova from './yptCordova';

export default angular.module('yeppt.file', [
  yepptCordova,
])

.service('yptFile', ['$q', function($q) {

  var lastUploadUrl = '';

  var getBody = function(data) {
    var body;
    var bodyData = data.body ? data.body : data.response;
    try {
      body = JSON.parse(bodyData);
    } catch (e) {
      body = bodyData;
      console.warn('Could not JSON parse FileTransfer Body', e, bodyData);
    }
    return body;
  };
  var getStatus = function(data) {
    return data.http_status || data.responseCode;
  };
  var getHeaders = function(data) {
    return {};
  };
  var getConfig = function(data) {
    return {
      method: 'POST',
      url: data.target || lastUploadUrl,
    };
  }


  var upload = function(fileURL, uri, settings) {
    // General stuff
    var deferred = $q.defer();
    var promise = deferred.promise;
    var onSuccess = function(data) {
      deferred.resolve(data);
    };
    var onError = function(data) {
      deferred.reject(data);
    };
    promise.success = function(fn) {
      promise.then(function(data) {
        fn(getBody(data), getStatus(data), getHeaders(data), getConfig(data));
      });
      return promise;
    };
    promise.error = function(fn) {
      promise.then(null, function(data) {
        fn(getBody(data), getStatus(data), getHeaders(data), getConfig(data));
      });
      return promise;
    };

    // Check if plugin is installed first
    if (typeof FileTransfer === 'undefined') {
      onError('File Transfer Plugin is missing');
      return promise;
    }

    var mySettings = settings || {};

    // Preparing options
    var options = new FileUploadOptions();
    options.fileKey  = mySettings.fileKey  || 'file';
    options.fileName = mySettings.fileName ||
                       fileURL.substr(fileURL.lastIndexOf('/') + 1);
    // When you pick an image of the gallery of an Android phone,
    // the file ending is missing and a 400 Error is thrown.
    // This prevents it - but it's a hack :/
    if (/^.*\.jpg$/.test(options.fileName) === false) {
      options.fileName = options.fileName + '.jpg';
    }
    // options.mimeType = mySettings.mimeType || "text/plain";
    options.mimeType = mySettings.mimeType || "image/jpeg";

    // next line is needed for android…
    // not sure if it harms iOS
    options.chunkedMode = false;

    options.headers = mySettings.headers;

    var ft = new FileTransfer();
    // ft.onprogress = function(progressEvent) {
    //   if (progressEvent.lengthComputable) {
    //       loadingStatus.setPercentage(progressEvent.loaded / progressEvent.total);
    //   } else {
    //    loadingStatus.increment();
    //   }
    // };
    ft.upload(fileURL, encodeURI(uri), onSuccess, onError, options);
    lastUploadUrl = uri;

    return promise;
  };

  var download = function(source, fileName, config) {
    var uri = encodeURI(source);
    var fileTransfer = new FileTransfer();
    var deferred = $q.defer();
    var promise = deferred.promise;

    var storageLocation = '';
    switch (device.platform) {
        case "Android":
            storageLocation = cordova.file.dataDirectory;
            break;
        case "iOS":
            storageLocation = cordova.file.documentsDirectory;
            break;
        default:
          return promise.reject('Unknown platform');
    }

    promise.success = function(fn) {
      promise.then(function(data) {
        console.log(data);
        fn(getBody(data), getStatus(data), getHeaders(data), getConfig(data));
      });
      return promise;
    };
    promise.error = function(fn) {
      promise.then(null, function(data) {
        console.log(data);
        fn(getBody(data), getStatus(data), getHeaders(data), getConfig(data));
      });
      return promise;
    };
    fileTransfer.download(
      uri,
      storageLocation + fileName,
      function(result) {
        if (result.exception) {
          return deferred.reject(result);
        }
        deferred.resolve(result);
      },
      function(error) {
        deferred.reject(error);
      },
      false,
      config
    );
    return promise;
  }

  return {
    upload: upload,
    download: download,
  };

}])

.name;
