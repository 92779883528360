import FlatasticConfig from '../../scripts/config'
import CordovaService from '../../scripts/modules/yptCordova'
import UserService from '../user/svc_user'
import WgService from '../wg/svc_wg'

export default angular.module('flatastic.homeless.controller', [
  WgService,
  UserService,
  CordovaService,
  'ionic',
  FlatasticConfig,
])

.controller('HomelessCtrl',
    ['$rootScope', '$scope', 'User', 'yptNotify', '$state', 'Wg',
    '$ionicHistory', '$filter', '$ionicLoading', '$timeout', 'FlatasticEvents',
    function ($rootScope, $scope, User, yptNotify, $state, Wg,
    $ionicHistory, $filter, $ionicLoading, $timeout, FlatasticEvents) {


  $ionicHistory.clearHistory();

  $scope.user   = User.properties;
  $scope.logout = User.logout;

  // Animate Flatastic logo when clicked
  $scope.animateLogo = false;
  $scope.animate = function() {
    var cssAnimationDuration = 2500;
    if ($scope.animateLogo) {
      return;
    }
    $scope.animateLogo = true;
    $timeout(function() {
      $scope.animateLogo = false;
    }, cssAnimationDuration);
  };

  $scope.moveIn = function() {
    yptNotify.prompt({
      title: $filter('translate')('ATH__MOVE_IN-TITLE'),
      message: $filter('translate')('ATH__MOVE_IN-MSG'),
      buttonLabels: [
        $filter('translate')('BTN_CANCEL'),
        $filter('translate')('CONTINUE'),
      ],
    })
    .success(function(wgCode) {
      $ionicLoading.show({
        template: $filter('translate')('ATH__MOVE_IN-LOADING'),
      });
      Wg.moveIn(wgCode)
      .success(function(data) {
        User.set(data.user).storeLocally();
        $rootScope.$broadcast(FlatasticEvents.user.didEnterWg, User.properties);
        $state.go('wg.shouts')
          .then(function() {
            $timeout(function() {
              $ionicHistory.clearHistory();
            }, 500);
          });
      })
      .error(function() {
        yptNotify.alert({
          title: $filter('translate')('HML__MOVE_IN-TITLE'),
          message: $filter('translate')('HML__MOVE_IN-ERROR'),
        });
      })
      .finally(function() {
        $ionicLoading.hide();
      });
    });
  };

  $scope.createWg = function() {
    Wg.setIsSettingUp(true)
    $ionicLoading.show({
      template: $filter('translate')('ATH__FOUND_LOADING'),
    });
    Wg.createWg()
    .success(function(data) {
      $rootScope.$broadcast('track-event', {
        'category': 'Homeless',
        'action': 'Created WG',
        'label': 'Success',
      });
      User.set(data.user).storeLocally();
      Wg.set(data.wg).storeLocally();
      $rootScope.$broadcast(FlatasticEvents.user.didEnterWg);
      $state.go(Wg.isSettingUp() ? 'wg-setup-invite' : 'wg.shouts');
    })
    .error(function() {
      Wg.setIsSettingUp(false)
      yptNotify.alert({
        title: $filter('translate')('HML__CREATE_WG_ERROR-TITLE'),
        message: $filter('translate')('HML__CREATE_WG_ERROR-MSG'),
      });
    })
    .finally(function() {
      $ionicLoading.hide();
    });
  };

  $scope.deleteAccount = function() {
    yptNotify.confirm({
      title: $filter('translate')('HML__DELETE_ACCOUNT'),
      message: $filter('translate')('HML__DELETE_ACCOUNT-MSG'),
      buttonLabels: [
        $filter('translate')('BTN_CANCEL'),
        $filter('translate')('HML__DELETE_ACCOUNT'),
      ],
      callback: function(answer) {
        if (answer === true) {
          User.delete()
          .success(function() {
            yptNotify.alert({
              title: $filter('translate')('HML__DELETE_ACCOUNT'),
              message: $filter('translate')('HML__DELETE_ACCOUNT_SUCCESS-MSG'),
            });
          });
        } else {
          return;
        }
      },
    });
  };

}])

.name;
