angular.module('flatasticApp')

.directive('shrinkBanner', ['$document', '$ionicScrollDelegate',
      function($document, $ionicScrollDelegate) {

  var state = 'is-maximized';

  var shrink = function(banner, content, amt, max, defaultTop, maxTranslation) {
    amt = Math.min(max, amt);
    var fadeAmt = 1 - amt / max;
    var bannerTranslateAmt = Math.min(amt, maxTranslation);

    var bannerValue = banner.querySelector('.ft-banner__value');
    var bannerLabel = banner.querySelector('.ft-banner__label');

    var ratio = bannerTranslateAmt / maxTranslation;

    var newOpacity = ratio === 1 ? null : 1 - ratio;

    var startAlpha = 0.75;
    var finalAlpha = 0.95;
    var alphaDiff = finalAlpha - startAlpha;
    var newAlpha = startAlpha + alphaDiff * ratio;

    banner.style[ionic.CSS.TRANSFORM] = 'translate3d(0, -' + bannerTranslateAmt + 'px, 0)';
    if (state !== 'is-shrinked' && bannerTranslateAmt >= maxTranslation) {
      // banner has minimum size
      banner.classList.remove('is-shrinking');
      banner.classList.add('is-shrinked');
      state = 'is-shrinked';
      $ionicScrollDelegate.resize();
    } else if (state !== 'is-maximized' && bannerTranslateAmt <= 0) {
      // banner has maximum size
      banner.classList.remove('is-shrinked');
      banner.classList.remove('is-shrinking');
      state = 'is-maximized';
      $ionicScrollDelegate.resize();
    } else if (state !== 'is-shrinking' && bannerTranslateAmt < maxTranslation && bannerTranslateAmt > 0) {
      // banner is shrinking or expanding
      banner.classList.add('is-shrinking');
      banner.classList.remove('is-shrinked');
      state = 'is-shrinking';
    }

    content.style.top = (defaultTop - amt) + 'px';
    ionic.requestAnimationFrame(function() {
      banner.style[ionic.CSS.TRANSFORM] = 'translate3d(0, -' + bannerTranslateAmt + 'px, 0)';
      banner.style.backgroundColor = 'rgba(255, 255, 255, ' + newAlpha + ')';
      if (bannerValue && bannerLabel) {
        bannerValue.style.opacity = newOpacity;
        bannerLabel.style.opacity = newOpacity;
      }
    });
  };

  return {
    restrict: 'A',
    link: function($scope, $element, $attr) {
      var banner = $document[0].getElementById($attr.shrinkBannerId);
      if (!banner) {
        return;
      }

      var content = $element[0];
      var contentTop;

      var shrinkAmt;
      var bannerHeight;
      var maxTranslation;

      setTimeout(function() {
        bannerHeight = banner.offsetHeight; // 110
        maxTranslation = bannerHeight - ($attr.shrinkBannerMinHeight || 66); //44px
        contentTop = content.offsetTop;
      }, 250);

      $element.bind('scroll', function(e) {
        var scrollTop = null;
        if (e.detail){
          scrollTop = e.detail.scrollTop;
        } else if (e.target){
          scrollTop = e.target.scrollTop;
        }
        if (scrollTop > 0) {
          // Start shrinking
          shrinkAmt = bannerHeight - Math.max(0, bannerHeight - scrollTop);
          shrink(banner, content, shrinkAmt, bannerHeight, contentTop, maxTranslation);
        } else {
          shrink(banner, content, 0, bannerHeight, contentTop, maxTranslation);
        }
      });
    },
  }
}]);
