import KeyboardModule from '../../scripts/modules/yptKeyboard'
import DatePickerModule from '../../scripts/modules/yptDatepicker'

export default angular.module('flatastic.diretive.datepicker', [
  KeyboardModule,
  DatePickerModule,
])

.directive('datepicker',
    ['yptDatepicker', '$filter', '$window', 'yptKeyboard',
    function(yptDatepicker, $filter, $window, yptKeyboard) {

  return {
    restrict: 'A',
    scope: {
      model: '=ngModel',
    },
    link: function(scope, iElement, iAttrs) {
      // Don't do anything if it's not cordova or Android <= 4
      if (!$window.device) {
        return;
      } else if ($window.device.platform === 'Android' &&
          $window.device.version.split('.')[0] < 5) {
        return;
      }
      var isShown = false;
      iElement.attr('readonly', 'readonly');
      iElement.on('click', function(e) {
        var count = 0;
        var interval = setInterval(function() {
          if (yptKeyboard.isVisible() || count++ > 50) {
            clearInterval(interval);
            yptKeyboard.close();
          }
        }, 10);
        if (isShown) {
          console.debug('datepicker: isShown is truthy');
          return false;
        }
        isShown = true;
        yptDatepicker.show({
          date: iElement.val() ? new Date(iElement.val()) : new Date(),
          okText: $filter('translate')('DONE'),
          doneButtonLabel: $filter('translate')('DONE'),
          cancelButtonLabel: $filter('translate')('BTN_CANCEL'),
          cancelText: $filter('translate')('BTN_CANCEL'),
          allowOldDates: true,
          allowFutureDates: true,
        }, function(res) {
          scope.model = res || scope.model;
          isShown = false;
        }, function() {
          isShown = false;
        });
        return false;
      });
    },
  };
}])

.name;
