export default {
  USD: '$',
  EUR: '€',
  GBP: '£',
  BRL: 'R$',
  CAD: '$',
  CHF: 'CHF',
  NOK: 'kr',
  SEK: 'kr',
  MXN: '$',
  TRY: '₺',
  INR: '₹',
  CNY: '¥',
  JPY: '¥',
  RUB: '₽',
  ARS: '$',
  PLN: 'zł',
  DKK: 'kr',
  AUD: '$',
}
