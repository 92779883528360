import template from './iap-plans.comp.html'

export default angular.module('flatastic.component.iap-plans', [])

.component('iapPlans', {
  bindings: {
    onPurchase: '&',
  },
  controller: ['Purchase', function(Purchase) {
    const self = this
    this.plans = []
    Purchase.getProducts()
      .then(products => (self.plans = products))
  }],
  template,
})

.name;
