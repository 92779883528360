import { version as versionNumber } from '../../package.json'

export const config = {
  apiUrl: 'https://dev.api.flatastic-app.com/index.php/api',
  // apiUrl: 'http://192.168.0.123:8089/index.php/api',
  defaultApiKey: 'publicKey',
  versionNumber,
  senderId: '319060687536',
  debug: false,
}

export const localStorageKeys = {
  choreProposals: {
    hide: 'Flatastic.choreProposals.hide',
    data: 'Flatastic.choreProposals.data',
  },
  experiments: {
    settings: 'Flatastic.experiments',
    lastUpdate: 'Flatastic.experiments.lastUpdate',
  },
  user: {
    store: 'Flatastic.User',
    dataSent: 'Flatastic.User.dataSent',
  },
  notifications: {
    activities: 'Flatastic.Activities',
  },
  shoppinglist: {
    storage: 'Flatastic.Shoppinglist',
  },
  wg: {
    setup: 'Flatastic.wgSetupPending',
  },
  behavior: {
    lastUsedModule: 'Flatastic.lastUsedModule',
  },
}

export default angular.module('flatastic.config', [])

.value('Config', config)

.value('FlatasticEvents', {
  badge: {
    update: 'Flatastic:Badge:update',
  },
  chores: {
    update: 'Flatastic:Chores:update',
    willCreate: 'chores:will-create',
    willComplete: 'chores:will-complete',
    didDeleteChore: 'Chore:deleted',
    didCreateChore: 'Flatastic:Chores:didCreate',
    didCompleteChore: 'Chore:done',
    didResetStatistics: 'Flatastic:Chores:didResetStatistics',
  },
  expenses: {
    update: 'Flatastic:Expenses:update',
    willCreate: 'expenses:will-create',
    didCreate: 'expenses:did-create',
    didResetBalance: 'Flatastic:Expenses:didResetBalance',
  },
  shoppinglist: {
    update: 'Flatastic:Shoppinglist:update',
    addItem: 'ShoppinglistItem:add',
    willCreate: 'ShoppinglistItem:will-create',
    deleteItem: 'ShoppinglistItem:deleted',
    saveItem: 'ShoppinglistItem:save',
    didAddItem: 'Flatastic:Shoppinglist:didAddItem',
    didCheckItem: 'Flatastic:Shoppinglist:didCheckItem',
    didRemoveBoughtItems: 'Flatastic:Shoppinglist:didRemoveBoughtItems',
  },
  shouts: {
    update: 'Flatastic:Shouts:update',
    didCreate: 'Flatastic:Shouts:didCreateShout',
    didDelete: 'Flatastic:Shouts:didDeleteShout',
    didLike: 'Flatastic:Shouts:didLikeShout',
    didUnlike: 'Flatastic:Shouts:didUnlikeShout',
  },
  subscriptions: {
    update: 'Flatastic:Subscriptions:update',
    didCreateEntry: 'Flatastic:Subscriptions:didCreateEntry',
    didDeleteEntry: 'Flatastic:Subscriptions:didDeleteEntry',
    didUpdateEntry: 'Flatastic:Subscriptions:didUpdateEntry',
  },
  user: {
    didLogout: 'FlatasticUser:logout',
    didEnterWg: 'Flatastic:UserEnteredWg',
    didLogin: 'FlatasticUser:loginSuccess',
    didRegister: 'FlatasticUser:registerSuccess',
    didLeaveWg: 'Flatastic:UserLeftWg',
    didGetPremium: 'FlatasticUser:DidGetPremium',
    didUpdate: 'FlatasticUser:DidUpdate',
  },
  wg: {
    didUpdate: 'Flatastic:Wg:update',
    didShareInvitationCode: 'Flatastic:Wg:DidShareInvitationCode',
  },
})

.value('LocalStorageKeys', localStorageKeys)

.name;
