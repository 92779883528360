import UserService from '../user/svc_user'

export default angular.module('flatastic.intro.user-name.controller', [
  UserService,
])
.controller('UserNameController', UserNameController)
.name;

UserNameController.$inject = ['User', '$state']
function UserNameController(User, $state) {
  const vm = this

  vm.state = {
    isBusy: false,
    error: null,
    firstName: User.properties.firstName,
  }

  vm.setName = setName


  //// Functions

  function setName(firstName) {
    vm.state.isBusy = true
    vm.state.error = null
    console.log('Set Name', { firstName })

    return User.update({ firstName })
      .then((user) => {
        return $state.go('intro-avatar')
      })
      .catch((error) => {
        vm.state.error = error
      })
      .finally(() => {
        vm.state.isBusy = false
      })
  }
}
