import yepptActionSheet from '../../scripts/modules/yptActionsheet'
import yepptCamera from '../../scripts/modules/yptCamera'
import yepptCordova from '../../scripts/modules/yptCordova'
import yepptKeyboard from '../../scripts/modules/yptKeyboard'
import flatasticWgService from '../wg/svc_wg'
import flatasticUserService from './svc_user'

export default angular.module('flatastic.user.controller', [
  flatasticUserService,
  flatasticWgService,
  yepptActionSheet,
  yepptCamera,
  yepptCordova,
  yepptKeyboard,
])

.controller('UserCtrl',
    ['$scope', 'User', 'yptCamera', 'yptActionsheet', '$filter',
    'yptNotify', 'yptKeyboard', 'Wg', '$rootScope', '$timeout', 'FileSystem',
    function ($scope, User, yptCamera, yptActionsheet, $filter,
    yptNotify, yptKeyboard, Wg, $rootScope, $timeout, FileSystem) {

  $scope.user = User;

  delete User.profileImageUploading;

  $scope.refresh = function() {
    User.get()
    .finally(function() {
      $scope.$broadcast('scroll.refreshComplete');
    });
  };

  $scope.showHelp = function() {
    yptNotify.alert({
      title: $filter('translate')('USR__HELP-TITLE'),
      message: $filter('translate')('USR__HELP-MSG'),
      trackEvent: {
        name: 'showHelp',
        parameters: {
          context: 'settings__user_profile',
        },
      },
    });
    $rootScope.$broadcast('track-event', {
      category: 'Help',
      action: 'Clicked',
      label: '/user',
    });
  };

  $scope.email = {
    old: User.properties.email,
    new: '',
    error: '',
    success: false,
  };
  $scope.changeEmail = function() {
    $scope.email.success = false;
    User.changeEmail($scope.email.new)
    .success(function(data) {
      $scope.email = {
        old: User.properties.email,
        new: '',
        error: '',
        success: true,
      };
      $timeout(function() {
        $scope.email.success = false;
      }, 4000);
    })
    .error(function(err, status) {
      if (status >= 500) {
        $scope.email.error = 'Server Error';
        return;
      }
      $scope.email.error = err;
    });
  };
  $scope.removeEmailError = function() {
    $scope.email.error = '';
  };

  $scope.forUpdate = {
    firstName: User.properties.firstName,
    // -- We don't ask for this data at the moment --
    // ,dob:       $filter('date')(User.properties.dob ||
    //                new Date('1992-06-15'), 'yyyy-MM-dd'),
    // phone:     User.properties.phone
  };
  var copyForUpdate = JSON.stringify($scope.forUpdate);
  $scope.userDataHasChanged = function() {
    return JSON.stringify($scope.forUpdate) !== copyForUpdate;
  };

  $scope.password = {
    old: '',
    new: '',
  };

  $scope.changePassword = function() {
    yptKeyboard.close();
    User.changePassword($scope.password.old, $scope.password.new)
    .success(function(result) {
      var message = '';
      if (result == 'true' || result === true) {
        message = $filter('translate')('USR__CHANGE_PW_SUCCESS-MSG');
      } else {
        message = $filter('translate')('USR__CHANGE_PW_FAILED-MSG');
      }
      yptNotify.alert({
        title: $filter('translate')('USR__CHANGE_PW_TITLE'),
        message: message,
      });
    })
    .error(function(data) {
      yptNotify.alert({
        title: $filter('translate')('USR__CHANGE_PW_TITLE'),
        message: $filter('translate')('USR__CHANGE_PW_ERROR-MSG', {
          error: data,
        }),
      });
    })
    .finally(function() {
      $scope.password = {};
    });
  };

  $scope.update = function() {
    yptKeyboard.close();
    User.update($scope.forUpdate)
      .then(function() {
        copyForUpdate = JSON.stringify($scope.forUpdate);
        // update user in flatmates as well
        for (var key in $scope.forUpdate) {
          Wg.flatmates.get(User.id)[key] = $scope.forUpdate[key];
        }
      });
    // We don't ask for this data at the moment
    // $scope.forUpdate.dob = $filter('date')(User.properties.dob, 'yyyy-MM-dd');
  };

  var isLoggingOut = false;
  $scope.logout = function() {
    if (isLoggingOut) { return; }
    isLoggingOut = true;
    yptNotify.confirm({
      title: $filter('translate')('USR__LOGOUT-TITLE'),
      message: $filter('translate')('USR__LOGOUT-MSG'),
      buttonLabels: [
        $filter('translate')('BTN_CANCEL'),
        $filter('translate')('USR__LOGOUT-BTN'),
      ],
      callback: function(answer) {
        isLoggingOut = false;
        if (answer === true) {
          User.logout();
        }
      },
    });
  };

  // Enables image upload from webapp
  $scope.data = {};
  $scope.isCordovaDevice = (window && window.device);
  $scope.$watch('data.file', function(newValue, oldValue) {
    if (User.profileImageUploading ||
          newValue === oldValue ||
          !$scope.data.file) {
      return;
    }
    const userId = User.properties.id
    Wg.updateProfilePictureOfUser(userId, $scope.data.file)
    User.uploadProfilePicture($scope.data.file, true)
      .then(function(data) {
        Wg.updateProfilePictureOfUser(userId)
      })
      .catch(function(data) {
        yptNotify.alert({
          title: $filter('translate')('USR__IMAGE_ERROR_DIALOGUE-TITLE'),
          message: $filter('translate')('USR__IMAGE_ERROR_DIALOGUE-MSG', {
            error: JSON.stringify(data),
          }),
        });
      })
      .finally(() => $timeout(0))
  });


  $scope.takeImage = function() {
    // cancel function if
    // - it is not called by the flatastic app
    // - an image is already beeing uploaded
    if (!window || !window.device || User.profileImageUploading) { return; }

    var options = {
      title: $filter('translate')('USR__IMAGE_DIALOGUE-TITLE'),
      buttonLabels: [
        $filter('translate')('USR__IMAGE_DIALOGUE-FROM_GALLERY'),
        $filter('translate')('USR__IMAGE_DIALOGUE-TAKE_PHOTO'),
      ],
      androidEnableCancelButton: false,
      addCancelButtonWithLabel: $filter('translate')('BTN_CANCEL'),
    };
    var actions = [
      function() { getPicture(0); },
      function() { getPicture(1); },
    ];
    yptActionsheet.show(options, actions);
  };

  var getPicture = function(sourceType) {
    yptCamera.takePicture({
      sourceType: sourceType,
      cameraDirection: 1,
      targetWidth: 1000,
      targetHeight: 1000,
    })
    .success(function(fileURL) {
      const userId = User.properties.id
      Wg.updateProfilePictureOfUser(userId, fileURL)
      return User.uploadProfilePicture(fileURL)
        .then(function(data) {
          return Wg.updateProfilePictureOfUser(userId, User.properties.profileImage)
        })
        .catch(function(data) {
          yptNotify.alert({
            title: $filter('translate')('USR__IMAGE_ERROR_DIALOGUE-TITLE'),
            message: $filter('translate')('USR__IMAGE_ERROR_DIALOGUE-MSG', {
              error: JSON.stringify(data),
            }),
          });
        })
        .finally(() => $timeout(0))
    })
    .error(function(data) {
      console.log('getPicture Error:', data);
    });
  };

}])

.name;
