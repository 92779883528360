export default {
  en: {
    PAYWALL_GENERIC__TITLE: `You've discovered a premium feature!`,
    PAYWALL_GENERIC__TEXT: `Unlock this and many more features by getting Flatastic Premium. Thanks to your support we'll develop even more awesome features.`,
    PAYWALL_GENERIC__CTA: `Go Premium`,
  },
  de: {
    PAYWALL_GENERIC__TITLE: 'Du hast ein Premium Feature entdeckt!',
    PAYWALL_GENERIC__TEXT: `Schalte dieses und viele weitere Features frei, indem du Premium Mitglied wirst. Mit deiner Unterstützung entwickeln wir tolle neue Features!`,
    PAYWALL_GENERIC__CTA: `Go Premium`,
  },
}
