/**
 * Copyright 2014 yeppt UG (haftungsbeschränkt). All Rights Reserved.
 * Authors: Clemens Bachmair (clemens@yeppt.com),
 *          Moritz von Hase (moritz@yeppt.com)
 * Parts from: http://www.ng-newsletter.com/posts/angular-on-mobile.html
 */

export default angular.module('yeppt.contacts', [])

.service('yptContacts', ['$q', '$timeout', '$window',
    function ($q, $timeout, $window) {

  var getAll = function() {
    // General stuff
    var deferred = $q.defer();
    var promise = deferred.promise;
    var onSuccess = function(data) {
      if ($window.device && $window.device.platform === 'iOS') {
        data = data.map(function(contact) {
          contact.displayName = contact.name.formatted;
          if (contact.emails && contact.emails.length > 0) {
            contact.emails = contact.emails.map(function(e) {
              return e.value;
            });
          }
          return contact;
        });
      }
      data = data.filter(function(contact) {
        return contact.displayName && contact.emails && contact.emails.length > 0;
      });
      var allNames = [];
      data = data.filter(function(contact) {
        if (allNames.indexOf(contact.displayName) > 0) {
          return false;
        }
        allNames.push(contact.displayName);
        return true;
      });
      data = data.sort(function(a, b) {
        if (typeof a.displayName !== 'string' || typeof b.displayName !== 'string') {
          return 0;
        }
        if (a.displayName.toLowerCase() < b.displayName.toLowerCase()) {
          return -1;
        }
        if (a.displayName.toLowerCase() > b.displayName.toLowerCase()) {
          return 1;
        }
        return 0;
      });
      deferred.resolve(data);
    };
    var onError = function(data) {
      deferred.reject(data);
    };
    promise.success = function(fn) {
      promise.then(fn);
      return promise;
    };
    promise.error = function(fn) {
      promise.then(null, fn);
      return promise;
    };

    navigator.contacts.find(["*"], onSuccess, onError, {
      multiple: true,
    });

    return promise;
  };

  var pickContact = function() {
    // General stuff
    var deferred = $q.defer();
    var promise = deferred.promise;
    var onSuccess = function(data) {
      deferred.resolve(data);
    };
    var onError = function(data) {
      deferred.reject(data);
    };
    promise.success = function(fn) {
      promise.then(fn);
      return promise;
    };
    promise.error = function(fn) {
      promise.then(null, fn);
      return promise;
    };

    if (!navigator || !navigator.contacts) {
      onError('Contacts Plugin not installed');
      return promise;
    }

    navigator.contacts.pickContact(onSuccess, onError);

    return promise;
  };


  return {
    getAll: getAll,
    pickContact: pickContact,
  };

}])

.name;
