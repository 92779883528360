angular.module('flatasticApp')

.filter('enum', ['Wg', '$filter',
    function(Wg, $filter) {

  var and = $filter('translate')('AND');

  return function(items) {
    var items = items.map(function(item) {
      return Wg.flatmates._get(item.userId).firstName;
    });
    var last = items.splice(-1);
    var output = items.join(', ');
    output += items.length > 0 ? (' ' + and + ' ') : '';
    output += last;
    return output;
  };
}]);
