angular.module('flatasticApp')

.directive('bgImg', [function () {
  return {
    restrict: 'A',
    scope: {
      'bgImg': '=bgImg',
    },
    link: function (scope, element, attrs) {
      var img = new Image();
      img.onload = function() {
        element.css({
          'background-image': 'url(' + scope.bgImg + ')',
        });
      }
      img.onerror = function() {
        element.css({
          'background-image:': '',
        });
      }
      if (scope.bgImg) {
        img.src = scope.bgImg;
      }
      scope.$watch('bgImg', function(newValue, oldValue) {
        if (newValue !== oldValue && scope.bgImg) {
          element.css({
            'background-image': 'url(' + scope.bgImg + ')',
          });
        }
      });
    },
  };
}]);
