angular.module('flatasticApp')

.directive('adaptiveTextarea', [function () {
  return {
    restrict: 'A',
    link: function (scope, iElement, iAttrs) {
      iElement.bind('keyup blur', function(e) {
        iElement.css({ height: 0 });
        iElement.css({ height: iElement[0].scrollHeight + 'px' });
      });
    },
  };
}]);
