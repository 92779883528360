import ToastService from '../../scripts/modules/mcmToast'
import ActionsheetService from '../../scripts/modules/yptActionsheet'
import CordovaService from '../../scripts/modules/yptCordova'
import UserService from '../user/svc_user'
import WgService from '../wg/svc_wg'
import NotificationService from './svc_notifications'
import ShoutService from './svc_shout'
import ShoutsService from './svc_shouts'

export default angular.module('flatastic.shouts.controller', [
  ShoutService,
  ShoutsService,
  CordovaService,
  WgService,
  ActionsheetService,
  UserService,
  ToastService,
  'ionic',
  NotificationService,
])

.controller('ShoutsCtrl',
    ['$scope', 'Shout', 'Shouts', 'yptNotify', 'Wg', 'yptActionsheet',
    '$window', '$state',
    '$filter', 'User', 'yptCordova', '$rootScope', 'mcmToast',
    '$ionicScrollDelegate', '$timeout', 'Notifications', '$ionicHistory',
    function ($scope, Shout, Shouts, yptNotify, Wg, yptActionsheet,
    $window, $state,
    $filter, User, yptCordova, $rootScope, mcmToast,
    $ionicScrollDelegate, $timeout, Notifications, $ionicHistory) {

  $scope.shoutData  = Shout.getScaffold();
  $scope.shouts     = Shouts.get;
  $scope.activities = Notifications.get;
  $scope.status     = Shouts.status;
  $scope.flatmates  = Wg.flatmates;
  $scope.user       = User.properties;
  $scope.hasBackView = $ionicHistory.backView;

  $scope.loadMoreIsActivated = false;
  function activateLoadMore() {
    $timeout(function() {
      $scope.loadMoreIsActivated = true;
    }, 1000);
  }
  activateLoadMore();

  var availableServices = {
    chat: Shouts,
    activities: Notifications,
  };

  $scope.focusInput = function() {
    $rootScope.$broadcast('shouts:shout')
  };

  // When coming back to view scroll to top
  // This prevents an issue where nothin was shown after going to a child view
  // and back while new data was loaded from server in between
  // The controller does not seem to be reloaded when just going back
  // within the ionicHistory
  $rootScope.$on('$stateChangeSuccess',
      function(event, to, toParams, from, fromParams) {
    if (to.name !== 'wg.shouts') {
      return;
    }
    $timeout(function() {
      $ionicScrollDelegate.scrollTop();
      $ionicScrollDelegate.resize();
    }, 0)
  });

  // Guide a first time user to click the menu icon by letting it pulsate
  $scope.menuIconNotYetClicked = false;
  if (!$window.localStorage['Flatastic.menuIconClicked']) {
    $scope.menuIconNotYetClicked = true;
    $scope.clickedMenuIcon = function() {
      $window.localStorage['Flatastic.menuIconClicked'] = true;
      $scope.menuIconNotYetClicked = false;
    }
  }

  // Track when it was the last time a tab bar was openend
  var now = new Date().getTime();
  var lastRead = {
    chat: new Date(now - 24 * 60 * 60 * 1000),
    activities: new Date(now - 24 * 60 * 60 * 1000),
  };
  function getLastReads() {
    var now = new Date().getTime();
    var lastReads = {
      chat: new Date(now - 24 * 60 * 60 * 1000),
      activities: new Date(now - 24 * 60 * 60 * 1000),
    };
    var data = $window.localStorage['Flatastic.BulletinBoard.LastRead'];
    if (data) {
      try {
        lastReads = JSON.parse(data);
        for (var key in lastReads) {
          if (!(lastReads[key] instanceof Date)) {
            lastReads[key] = new Date(lastReads[key]);
          }
        }
      } catch (e) {
        console.error(e);
      }
    }
    return lastReads;
  }

  $scope.switch = localStorage['Flatastic.Shouts.switch'] || 'chat';
  var scrollPosition = {};
  $scope.changeSwitch = function(val) {
    lastRead[$scope.switch] = new Date();
    lastRead[val] = new Date();
    $window.localStorage['Flatastic.BulletinBoard.LastRead'] = JSON.stringify(lastRead);
    scrollPosition[$scope.switch] = $ionicScrollDelegate.getScrollPosition();
    localStorage['Flatastic.Shouts.switch'] = val;
    $scope.loadMoreIsActivated = false;
    activateLoadMore();
    $scope.switch = val;
    if (scrollPosition[val]) {
      $ionicScrollDelegate.scrollTo(scrollPosition[val].left, scrollPosition[val].top);
      $ionicScrollDelegate.resize();
    } else {
      $ionicScrollDelegate.scrollTop();
      $ionicScrollDelegate.resize();
    }
  };
  $scope.scrollTop = function() {
    $ionicScrollDelegate.scrollTop();
  };

  $scope.numUnreads = function(type) {
    var collection = availableServices[type];
    if (!collection) {
      return false;
    }
    if (type === $scope.switch) {
      return false;
    }
    // Add 45s because of possibly unsynchronized clocks
    var reference = new Date(getLastReads()[type].getTime() + 45 * 1000);
    return collection.get().filter(function(elem) {
      var isOwn = (elem.creatorId === User.properties.id);
      var isDivider = (elem.shoutType === 'divider');
      var isNew = elem.insertDate > reference;
      return (!isOwn && !isDivider && isNew);
    }).length;
  };

  $scope.showHelp  = function() {
    yptNotify.alert({
      title: $filter('translate')('SHT__HELP-TITLE'),
      message: $filter('translate')('SHT__HELP-MSG'),
      trackEvent: {
        name: 'showHelp',
        parameters: {
          context: 'shouts',
        },
      },
    });
    $rootScope.$broadcast('track-event', {
      category: 'Help',
      action: 'Clicked',
      label: '/shouts',
    });
  };

  $scope.loadMoreErrorCounter = 0;
  $scope.loadMore = function(fromType) {
    var service = availableServices[fromType];
    if (!service) { return; }
    service.loadMore()
      .catch(function() {
        $scope.loadMoreErrorCounter++;
      })
      .finally(function() {
        $scope.$broadcast('scroll.infiniteScrollComplete');
      });
  };

  $scope.moreCanBeLoaded = function(fromType) {
    var service = availableServices[fromType];
    if (!service || !$scope.loadMoreIsActivated) { return false; }
    return service.moreCanBeLoaded();
  }

  $scope.createShout = function() {
    $scope.shoutData.justCreated = true;
    var newShout = new Shout($scope.shoutData);
    newShout.toCollection().create();
    $scope.shoutData = Shout.getScaffold();
  };
  $scope.resendShout = function(shout) {
    var actions = [function sendAgain() {
      shout.create();
    }, function remove() {
      shout.removeFromCollection();
    }];
    var options = {
      // 'title': 'Pinnwand',
      'buttonLabels': [
        $filter('translate')('TRY_AGAIN'),
        $filter('translate')('DELETE'),
      ],
      'androidEnableCancelButton': false,
      'addCancelButtonWithLabel': $filter('translate')('BTN_CANCEL'),
    };
    yptActionsheet.show(options, actions);
  };

  $scope.hideLoading = false;
  $scope.refresh = function() {
    var service = availableServices[$scope.switch];
    if (!service) { return; }
    $scope.hideLoading = true;
    service.getFromServer()
    .finally(function() {
      $scope.$broadcast('scroll.refreshComplete');
      $scope.hideLoading = false;
    });
  };

  $scope.goToModule = function(shout) {
    var type = shout.shoutType.split('_')[0];
    var dictView = {
      shoppinglist: 'wg.shoppinglist',
      chore: 'wg.chores',
      cashflow: 'wg.expenses',
      wg: 'wg.settings-wg',
      profile: 'wg.settings-wg',
    };
    if (!dictView[type]) {
      return;
    }
    $state.go(dictView[type]);
  };

  $scope.interact = function(shout, event) {

    var followLink = function(url) {
      return function() {
        yptCordova.openLink(url);
      };
    };

    var likeShout = function() {
      shout.like()
      .success(function() {
        mcmToast.show({
          message: $filter('translate')('SHT__THANKS_SUCCESS-MSG'),
        });
      });
    };

    var deleteShout = function() {
      yptNotify.confirm({
        title: $filter('translate')('SHT__CONFIRM_DELETE-TITLE'),
        message: $filter('translate')('SHT__CONFIRM_DELETE-MSG'),
        buttonLabels: [
          $filter('translate')('BTN_CANCEL'),
          $filter('translate')('DELETE'),
        ],
        callback: function(answer) {
          if (answer === true) {
            shout.removeFromCollection().delete();
          }
        },
      });
    };

    var goToModule = function() {
      $scope.goToModule(shout);
    };

    var options = {};
    var actions = [];
    var buttonLabels = [];

    if (shout.creatorId === User.properties.id) {
      actions.push(deleteShout);
      options.addDestructiveButtonWithLabel = $filter('translate')('DELETE');
    }

    if (shout.creatorId !== User.properties.id) {
      buttonLabels.push($filter('translate')('SAY_THANKS'));
      actions.push(likeShout);
    }

    if (shout.shoutType !== 'shout') {
      buttonLabels.push('Zum Modul');
      actions.push(goToModule);
    }

    if (event && event.toElement.tagName === 'SPAN') {
      var url = event.target.innerHTML;
      buttonLabels.push($filter('translate')('FOLLOW') + ' ' + event.target.innerHTML);

      if (url.indexOf('http') < 0) {
        url = 'http://' + url;
      }
      actions.push(followLink(url));
    }

    // Trim title length if neccessary
    var title = shout.shout.substr(0, 80);
    if (shout.shout.length > 80) {
      title += '…';
    }

    options = angular.extend(options, {
      'title': title,
      'buttonLabels': buttonLabels,
      'androidEnableCancelButton': false,
      'addCancelButtonWithLabel': $filter('translate')('BTN_CANCEL'),
    });

    yptActionsheet.show(options, actions);
  }

}])

.name;
