/**
 * Copyright 2016 mcm.init UG (haftungsbeschränkt). All Rights Reserved.
 * Authors: Clemens Bachmair (clemens@mcm-init.com)
 * Wrapper for the following Plugins:
 *   https://github.com/mcminit/WifiWizard
 *   https://github.com/apache/cordova-plugin-network-information
 */
export default angular.module('mcm.connection', ['ionic'])

.service('mcmConnection',
    ['$q', '$window',
    function($q, $window) {

  return {
    canBeDataHeavy: canBeDataHeavy,
    getType: getType,
    getWifiInfo: getWifiInfo,
    hasInternet: hasInternet,
  };

  ////

  function canBeDataHeavy() {
    var state = getType();
    if (!state) {
      return false;
    }

    return ([Connection.ETHERNET, Connection.WIFI].indexOf(state) > -1);
  }

  function getType() {
    if (!navigator || !navigator.connection) {
      console.error('Connection plugin is not installed.');
      return false;
    }

    return navigator.connection.type;
  }

  function getWifiInfo() {
    var deferred = $q.defer();
    var output = {};
    var promises = [];

    promises.push(getBSSID().then(function(result) {
      output.bssid = result;
    }));
    promises.push(getSSID().then(function(result) {
      output.ssid = result;
    }));

    $q.all(promises).then(function() {
      deferred.resolve(output);
    });

    return deferred.promise;
  }


  function getSSID() {
    var deferred = $q.defer();
    var promise = deferred.promise;
    var onSuccess = function(ssid) {
      deferred.resolve(ssid.replace(/"(.*)"/i, '$1'));
    };
    var onError = function(err) {
      deferred.reject(err);
    };

    if (!$window.WifiWizard) {
      onError('Plugin is not installed');
      return promise;
    }

    $window.WifiWizard.getCurrentSSID(onSuccess, onError);

    return promise;
  }

  function getBSSID() {
    var deferred = $q.defer();
    var onSuccess = function(bssid) {
      deferred.resolve(bssid.replace(/"(.*)"/i, '$1'));
    };
    var onError = function(err) {
      deferred.reject(err);
    };

    if (!$window.WifiWizard) {
      onError('Plugin is not installed');
      return deferred.promise;
    }

    $window.WifiWizard.getCurrentBSSID(onSuccess, onError);

    return deferred.promise;
  }

  function hasInternet() {
    var type = getType();
    if (!type) {
      return undefined;
    }

    return (status !== Connection.NONE);
  }

}])

.name;
