import StatusBarModule from '../../scripts/modules/statusbar'
import yepptKeyboard from '../../scripts/modules/yptKeyboard'
import wgGeneralView from '../general/view_wg.html'
import { hideAccessoryBar, showAccessoryBar } from '../keyboardController'
import flatasticWgController from './ctrl_wg'
import flatasticWgControllerFlatmates from './ctrl_wg-invite-flatmates'
import flatasticWgControllerInvite from './ctrl_wg-invite-modal'
import flatasticWgControllerSetup from './ctrl_wg-setup'
import flatasticWgService from './svc_wg'
// templates
import wgSetupInviteView from './view_wg-setup-invite.html'
import wgSetupSettingsView from './view_wg-setup-settings.html'


export default angular
.module('flatastic.wg', [
  flatasticWgController,
  flatasticWgControllerFlatmates,
  flatasticWgControllerInvite,
  flatasticWgControllerSetup,
  flatasticWgService,
  yepptKeyboard,
  StatusBarModule,
])
.config(
    ['$stateProvider',
    function ($stateProvider) {

  $stateProvider
  .state('wg-setup-invite', {
    url: '/wg-setup-invite',
    views: {
      'root': {
        controller: 'WgSetupCtrl',
        template: wgSetupInviteView,
      },
    },
  })
  .state('wg-setup-settings', {
    url: '/wg-setup-settings',
    views: {
      'root': {
        controller: 'WgSetupCtrl',
        template: wgSetupSettingsView,
      },
    },
    onEnter: showAccessoryBar,
    onExit: hideAccessoryBar,
  })
  .state('wg', {
    abstract: true,
    views: {
      'root': {
        template: wgGeneralView,
      },
    },
    onEnter: ['StatusBar', function(StatusBar) {
      StatusBar.setStyleLightContent()
    }],
  });

}])

.name;
