import flatasticProposalsPersonal from './svc_personalproposals';
import flatasticShoppinglistItem from './svc_shoppinglistitem';
import flatasticShoppinglistService from './svc_shoppinglist';
import flatasticUserService from '../user/svc_user';
import flatasticWgService from '../wg/svc_wg';
import yepptActionSheet from '../../scripts/modules/yptActionsheet';
import yepptCordova from '../../scripts/modules/yptCordova';
import yepptSocialSharing from '../../scripts/modules/yptSocialsharing';

export default angular.module('flatastic.shoppinglist.controller', [
  flatasticProposalsPersonal,
  flatasticShoppinglistItem,
  flatasticShoppinglistService,
  flatasticUserService,
  flatasticWgService,
  yepptActionSheet,
  yepptCordova,
  yepptSocialSharing,
  'ionic',
])

.controller('ShoppinglistCtrl',
    ['$scope', 'Shoppinglist', 'ShoppinglistItem', 'User',
    'yptNotify', '$filter', 'Wg', 'yptActionsheet',
    '$ionicScrollDelegate', 'yptSocialsharing', '$ionicHistory',
    'PersonalProposals', '$rootScope', '$timeout', '$state',
    function ($scope, Shoppinglist, ShoppinglistItem, User,
     yptNotify, $filter, Wg, yptActionsheet,
     $ionicScrollDelegate, yptSocialsharing, $ionicHistory,
    PersonalProposals, $rootScope, $timeout, $state) {

  $scope.items          = Shoppinglist.get;
  $scope.numItems       = Shoppinglist.numItems,
  $scope.getBoughtItems = Shoppinglist.getBoughtItems,
  $scope.getItemsToBuy  = Shoppinglist.getItemsToBuy,
  $scope.numOutOfSync   = Shoppinglist.numOutOfSync;
  $scope.isSyncing      = Shoppinglist.isSyncing;
  $scope.status         = Shoppinglist.status;
  $scope.flatmates      = Wg.flatmates;
  $scope.numProposals   = 10;
  $scope.show           = { proposals: false };

  $scope.itemOrder = function(item) {
    return item.date.getTime();
  };

  $scope.hasBackView = $ionicHistory.backView;

  $scope.shareShoppinglist = function() {
    var itemsToBuy = $scope.getItemsToBuy();
    var orderFunction = $scope.itemOrder;
    itemsToBuy = $filter('orderBy', orderFunction, -1)(itemsToBuy);

    // Make a nice list out of the items array
    var items = itemsToBuy.map(function(item) {
      return ' - ' + item.name;
    }).join('\n');
    yptSocialsharing.share({
      subject: $filter('translate')('SHL__SHARE-TITLE'),
      message: $filter('translate')('SHL__SHARE-MSG', {
        items: items,
      }),
      url: 'https://flatastic-app.com',
      trackEvent: {
        name: 'Share Shopping List',
        parameters: {
          context: 'shoppinglist-nav-bar',
          content: $filter('translate')('SHL__SHARE-TITLE'),
        },
      },
    });
  };

  $scope.newItem = ShoppinglistItem.getScaffold(User.properties.id);
  $scope.addToList = function() {
    var newItem = new ShoppinglistItem($scope.newItem);
    PersonalProposals.add(newItem.name);
    newItem.toCollection().create();
    $scope.newItem = ShoppinglistItem.getScaffold(User.properties.id);
  };

  $scope.hideLoading = false;
  $scope.refresh = function() {
    $scope.hideLoading = true;
    Shoppinglist.getFromServer().finally(function() {
      $scope.$broadcast('scroll.refreshComplete');
      $scope.hideLoading = false;
    });
  };


  $scope.showMoreProposals = function(val) {
    $scope.numProposals += val;
    $ionicScrollDelegate.resize();
  };
  $scope.hideProposals = function() {
    $scope.numProposals = 10;
    $scope.show.proposals = false;
    $ionicScrollDelegate.resize();
  };
  $scope.addProposal = function(item) {
    PersonalProposals.add(item);
    // If item is on the "bought list" put it back on the list
    var boughtItems = Shoppinglist.getBoughtItems();
    for (var i = 0; i < boughtItems.length; i++) {
      if (boughtItems[i].name.toLowerCase() === item.toLowerCase()) {
        boughtItems[i].toggle();
        $scope.newItem = ShoppinglistItem.getScaffold();
        $scope.newItem.inserterId = User.properties.id;
        return;
      }
    }
    // Otherwise add new item
    $scope.newItem.name = item;
    if (!$scope.newItem.name) {
      return;
    }
    var newItem = new ShoppinglistItem($scope.newItem);
    newItem.toCollection().create();
    $scope.newItem = ShoppinglistItem.getScaffold();
    $scope.newItem.inserterId = User.properties.id;
  }

  $scope.interact = function(item) {
    var options = {
      title: item.name + ' (' + Wg.flatmates._get(item.inserterId).firstName + ')',
      addDestructiveButtonWithLabel: $filter('translate')('DELETE'),
      buttonLabels: (item.bought === 0) ?
      [
        $filter('translate')('SHL__INTERACT__EDIT'),
        $filter('translate')('SHL__INTERACT__BUY'),
      ]
        :
      [
        $filter('translate')('SHL__INTERACT__BACK_TO_LIST'),
      ],
      'androidEnableCancelButton': false,
      'addCancelButtonWithLabel': $filter('translate')('BTN_CANCEL'),
    };
    var actions = [
      deleteItem,
      (item.bought === 0) ? editItem : undefined,
      toggleItem,
    ].filter(function(item) { return Boolean(item); });
    yptActionsheet.show(options, actions);

    function deleteItem() {
      $scope.deleteItem(item);
    }

    function toggleItem() {
      item.toggle();
    }

    function editItem() {
      if (!User.isPremium()) {
        const label = 'Rename item on shoppinglist';
        return $state.go('wg.premium-paywall', { label })
      }
      $scope.editedItem = {
        id: item.id,
        name: item.name,
      };
      $timeout(function() {
        $rootScope.$broadcast('focus:input:' + item.id)
      });
    }
  }

  $scope.cancelEdit = cancelEdit;

  $scope.updateItem = function(item){
    if ($scope.editedItem.name !== item.name) {
      delete $scope.editedItem.id;
      return item.update($scope.editedItem)
        .finally(function() { cancelEdit(); });
    }
    cancelEdit();
  }

  $scope.deleteItem = function(item) {
    yptNotify.confirm({
      title: $filter('translate')('SHL__DELETE_ITEM-TITLE', { name: item.name }),
      message: $filter('translate')('SHL__DELETE_ITEM-MSG', { name: item.name }),
      buttonLabels: [
        $filter('translate')('BTN_CANCEL'),
        $filter('translate')('YES'),
      ],
      callback: function(answer) {
        if ( ! answer) { return; }
        item.delete();
      },
    });
  };

  $scope.removeBought = function() {
    yptNotify.confirm({
      title: $filter('translate')('SHL__REMOVE_BOUGHT-TITLE'),
      message: $filter('translate')('SHL__REMOVE_BOUGHT-MSG'),
      buttonLabels: [
        $filter('translate')('BTN_CANCEL'),
        $filter('translate')('YES'),
      ],
      callback: function(answer) {
        if (!answer) {
          return;
        }
        Shoppinglist.removeBought()
        .success(function(){
          $ionicScrollDelegate.scrollTop();
        })
        .error(function() {
          yptNotify.alert({
            title: $filter('translate')('SHL__REMOVE_BOUGHT_ERROR-TITLE'),
            message: $filter('translate')('SHL__REMOVE_BOUGHT_ERROR-MSG'),
          });
        });
      },
    });
  };

  $scope.syncAll = function() {
    yptNotify.confirm({
      title: $filter('translate')('SHL__SYNC-TITLE'),
      message: $filter('translate')('SHL__SYNC-MSG'),
      buttonLabels: [
        $filter('translate')('BTN_CANCEL'),
        $filter('translate')('OK'),
      ],
      callback: function(answer) {
        if (!answer) {
          return;
        }
        Shoppinglist.sync()
        .success(function() {
          yptNotify.alert({
            title: $filter('translate')('SHL__SYNC_SUCCESS-TITLE'),
            message: $filter('translate')('SHL__SYNC_SUCCESS-MSG'),
          });
        })
        .error(function() {
          yptNotify.alert({
            title: $filter('translate')('SHL__SYNC_ERROR-TITLE'),
            message: $filter('translate')('SHL__SYNC_ERROR-MSG'),
          });
        });
      },
    });
  };

  //// Functions

  function cancelEdit() {
    $scope.editedItem = {};
  }
}])

.name;
