import AngularTranslate from 'angular-translate'
import ConfigModule from '../../scripts/config'
import ActionsheetModule from '../../scripts/modules/yptActionsheet'
import CameraModule from '../../scripts/modules/yptCamera'
import UserService from '../user/svc_user'

export default angular.module('flatastic.intro.user-avatar.controller', [
  AngularTranslate,
  UserService,
  CameraModule,
  ActionsheetModule,
  ConfigModule,
])
.controller('UserAvatarController', UserAvatarController)
.name;

UserAvatarController.$inject = ['User', '$translate', 'yptCamera', '$scope', 'Wg', 'yptNotify', 'yptActionsheet', '$state', '$timeout']
function UserAvatarController(User, $translate, yptCamera, $scope, Wg, yptNotify, yptActionsheet, $state, $timeout) {
  const vm = this
  vm.state = {
    isBusy: false,
    error: null,
    file: undefined,
    user: User.properties,
    didUploadPicture: false,
    isCreatingHousehold: Wg.isSettingUp(),
  }
  vm.goToNextScreen = goToNextScreen
  vm.isCordovaDevice = isCordovaDevice
  vm.updateProfilePicture = updateProfilePicture

  $scope.$watch(() => vm.state.file, function(newValue, oldValue) {
    if (User.profileImageUploading ||
        newValue === oldValue ||
        !vm.state.file) {
      return;
    }
    browserUpdatePicture(vm.state.file)
  });

  //// Functions

  function goToNextScreen() {
    const nextRoute = Wg.isSettingUp() ? 'intro-household-type' : 'intro-notifications'
    return $state.go(nextRoute)
  }

  function browserUpdatePicture(file) {
    console.debug('Updating picture via browser method');
    return uploadPicture(file, true)
  }

  function updateProfilePicture() {
    if (!isCordovaDevice()) {
      console.debug('updateProfilePicture: Not a cordova device. Skipping.')
      return
    }
    return selectPicture()
      .then(getPicture)
      .then(uploadPicture)
  }

  function isCordovaDevice() {
    return window && window.device
  }

  function selectPicture() {
    const options = {
      title: $translate.instant('USR__IMAGE_DIALOGUE-TITLE'),
      buttonLabels: [
        $translate.instant('USR__IMAGE_DIALOGUE-FROM_GALLERY'),
        $translate.instant('USR__IMAGE_DIALOGUE-TAKE_PHOTO'),
      ],
      androidEnableCancelButton: false,
      addCancelButtonWithLabel: $translate.instant('BTN_CANCEL'),
    }
    const imageSourceType = {
      gallery: 0,
      photo: 1,
    }
    return new Promise((resolve, reject) => {
      const actions = [
        function() { resolve(imageSourceType.gallery) },
        function() { resolve(imageSourceType.photo) },
      ]
      yptActionsheet.show(options, actions)
    })
  }

  function uploadPicture(fileURL, useBrowserUpload) {
    vm.state.isBusy = true
    console.log({ fileURL, useBrowserUpload })
    return User.setLocalProfilePicture(fileURL)
      .then(user => {
        vm.state.user = user.properties;
        updateProfilePictureInFlatmates(user, user.properties.profileImage)
        return $timeout(5)
      })
      .then(() => {
        return User.uploadProfilePicture(fileURL, useBrowserUpload)
      })
      .then(user => {
        vm.state.user = user.properties
        vm.state.didUploadPicture = true
        updateProfilePictureInFlatmates(user)
        return Promise.resolve()
      })
      .catch(function(error) {
        yptNotify.alert({
          title: $translate.instant('USR__IMAGE_ERROR_DIALOGUE-TITLE'),
          message: $translate.instant('USR__IMAGE_ERROR_DIALOGUE-MSG', {
            error: JSON.stringify(error),
          }),
        });
      })
      .finally(() => {
        $timeout().then(() => { vm.state.isBusy = false; })
      })
  }

  function getPicture(sourceType) {
    return yptCamera.takePicture({
      sourceType,
      cameraDirection: 1,
      targetWidth: 1000,
      targetHeight: 1000,
      saveToPhotoAlbum: false,
    })
  }

  function updateProfilePictureInFlatmates(user, imageUrl) {
    Wg.flatmates._get(user.properties.id).profileImage = imageUrl || user.properties.profileImage
    Wg.storeLocally()
  }

}
