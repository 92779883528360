import { mergeDeepLeft, reduce } from 'ramda'
import householdLocationTranslations from './household-location.trans'
import householdMembersTranslations from './household-members.trans'
import householdNameTranslations from './household-name.trans'
import householdPhotoTranslations from './household-photo.trans'
import householdTypeTranslations from './household-type.trans'
import householdTranslations from './household.trans'
import introTranslations from './intro.trans'
import loginTranslations from './login.trans'
import uiUnderConstructionModalTranslations from './modal-ui-under-construction'
import notificationsTranslations from './notifications.trans'
import startTranslations from './start.trans'
import userAvatarTranslations from './user-avatar.trans'
import userNameTranslations from './user-name.trans'
import userSignUpTranslations from './user-signup.trans'

const exp = reduce(mergeDeepLeft, {})([
  introTranslations,
  startTranslations,
  loginTranslations,
  userSignUpTranslations,
  userNameTranslations,
  userAvatarTranslations,
  householdTypeTranslations,
  householdNameTranslations,
  householdPhotoTranslations,
  householdLocationTranslations,
  householdMembersTranslations,
  notificationsTranslations,
  householdTranslations,
  uiUnderConstructionModalTranslations,
])

export default exp
