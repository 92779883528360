import ChoreService from './svc_chore'
import ChoresService from './svc_chores'
import WgService from '../wg/svc_wg'
import CordovaService from '../../scripts/modules/yptCordova'
import RotationTimeService from './svc_rotation-times'
import EffortValuesService from './svc_effort-values'

export default angular.module('flatastic.chores.controller.new-chore', [
  ChoreService,
  ChoresService,
  WgService,
  CordovaService,
  RotationTimeService,
  EffortValuesService,
])

.controller('ChoresNewCtrl',
    ['$scope', '$rootScope', 'Chores', 'Chore', '$state',
    '$filter', 'Wg', 'yptNotify', 'RotationTimes', 'EffortValues',
    function ($scope, $rootScope, Chores, Chore, $state,
    $filter, Wg, yptNotify, RotationTimes, EffortValues) {

  // Check if edit or create chore
  $scope.editMode = false;
  if ($state.params && $state.params.id) {
    $scope.editMode = true;
    $scope.chore    = Chores.get($state.params.id);
    var nextTime    = ($scope.chore.lastDoneDate +
        $scope.chore.rotationTime) * 1000;
    $scope.chore.newData = {
      title: $scope.chore.title,
      startDate: new Date(nextTime),
      rotationTime: $scope.chore.rotationTime,
      fixed: $scope.chore.fixed ? 1 : 0,
      users: $scope.chore.users,
      points: $scope.chore.points,
    };
  } else {
    $scope.chore = new Chore({
      local: true,
    });
    var date = new Date();
    date.setDate(date.getDate() + 3);
    $scope.chore.newData.startDate = date;
    $scope.chore.newData.fixed = 1;
    $scope.chore.newData.users = Wg.flatmates.accumulate('id');
  }

  // Rotation Times
  $scope.rotationTimes = RotationTimes.values;
  $scope.chore.newData.rotationTime =
      $scope.chore.newData.rotationTime || 7 * 60 * 60 * 24;
  $scope.flatmates = Wg.flatmates.get();

  $scope.points = EffortValues.values;
  $scope.chore.newData.points = $scope.chore.newData.points || 1;

  // Check if it is save to go back
  // overwrite ionic's back button functionality
  var oldSoftBack = $rootScope.$ionicGoBack;
  var unchangedData = angular.copy($scope.chore.newData);
  $scope.unchangedData = unchangedData;
  var doCustomBack = function() {
    var isSafeToGoBack = true;
    var keys = [
      'title',
      'rotationTime',
      'fixed',
      'users',
      'points',
      'startDate',
    ];

    keys.forEach(function(key) {
        isSafeToGoBack = isSafeToGoBack &&
          (JSON.stringify(unchangedData[key])
              == JSON.stringify($scope.chore.newData[key]));
    });

    if (isSafeToGoBack) {
      return oldSoftBack();
    }

    yptNotify.confirm({
      title: $filter('translate')('CHO__NEW__GO_BACK-TITLE'),
      message: $filter('translate')('CHO__NEW__GO_BACK-MSG'),
      buttonLabels: [
        $filter('translate')('BTN_CANCEL'),
        $filter('translate')('YES'),
      ],
      callback: function(answer) {
        if (answer === true) {
          oldSoftBack();
        } else {
          return;
        }
      },
    });
  };

  $rootScope.$ionicGoBack = function() {
    doCustomBack();
  };

  var deregisterSoftBack = function() {
      $rootScope.$ionicGoBack = oldSoftBack;
  };
  // cancel custom back behaviour
  $scope.$on('$destroy', function() {
      deregisterSoftBack();
  });

  $scope.showHelp = function() {
    yptNotify.alert({
      title: $filter('translate')('CHO__HELP-TITLE'),
      message: $filter('translate')('CHO__HELP-MSG'),
      trackEvent: {
        name: 'showHelp',
        parameters: {
          context: 'chores__new',
        },
      },
    });
    $rootScope.$broadcast('track-event', {
      category: 'Help',
      action: 'Clicked',
      label: '/chores-new',
    });
  };

  // Fix Weekday
  $scope.showFixedWeekday = function() {
    return ($scope.chore.newData.rotationTime / 60 / 60 / 24) % 7 === 0;
  };

  var weekdays = [
    $filter('translate')('TIME__SUNDAY'),
    $filter('translate')('TIME__MONDAY'),
    $filter('translate')('TIME__TUESDAY'),
    $filter('translate')('TIME__WEDNESDAY'),
    $filter('translate')('TIME__THURSDAY'),
    $filter('translate')('TIME__FRIDAY'),
    $filter('translate')('TIME__SATURDAY'),
  ];
  $scope.getWeekday = function() {
    return weekdays[new Date($scope.chore.newData.startDate).getDay()];
  };
  $scope.fixedDayAnswers = [{
    value: 1,
    label: $filter('translate')('YES'),
  }, {
    value: 0,
    label: $filter('translate')('NO'),
  }];

  $scope.orderSorter = function(elem) {
    if (!$scope.chore.newData.users) {
      return;
    }
    var value = $scope.chore.newData.users.indexOf(elem.id);
    if (value === -1) {
      value = elem.id;
    }
    return value;
  };
  $scope.toggleSelected = function(flatmate) {
    var idx = $scope.chore.newData.users.indexOf(flatmate.id);
    if (idx > -1) {
      $scope.chore.newData.users.splice(idx, 1);
    } else {
      $scope.chore.newData.users.push(flatmate.id);
    }
  };
  $scope.selectedNameArray = function() {
    if (!$scope.chore.newData.users) {
      return;
    }
    return $scope.chore.newData.users.map(function(id) {
      return Wg.flatmates._get(id).firstName;
    });
  };

  $scope.addNewChore = function() {

    $scope.requestPending = true;

    // Only weekly rotation times can have the flag fixed
    if (!$scope.showFixedWeekday()) {
      $scope.chore.newData.fixed = 0;
    }

    // Due dates time is always 23:59:59:999
    $scope.chore.newData.startDate =
        new Date($scope.chore.newData.startDate);
    $scope.chore.newData.startDate.setHours(23);
    $scope.chore.newData.startDate.setMinutes(59);
    $scope.chore.newData.startDate.setSeconds(59);
    $scope.chore.newData.startDate.setMilliseconds(999);

    // Edit Mode
    if ($scope.editMode) {
      $scope.chore.users = $scope.chore.newData.users;
      $scope.chore.update();
      delete $scope.requestPending;
      $state.go('wg.chores');
      return;
    }

    // New Chore
    $scope.chore.newData.currentUser = $scope.chore.newData.users[0];

    $scope.chore
    .set($scope.chore.newData)
    .toCollection()
    .create()
    .success(function() {
      Chores.getFromServer();
      $state.go('wg.chores');
    })
    .error(function(data) {
      $scope.chore.removeFromCollection();
      var temp = [];
      for (var key in data) {
        temp.push(data[key]);
      }

      yptNotify.alert({
        title: $filter('translate')('CHO__NEW_ERROR-TITLE'),
        message: $filter('translate')('CHO__NEW_ERROR-MSG', {
          error: data ? temp.join('\n') : $filter('translate')('NO_INTERNET'),
        }),
      });
    })
    .finally(function() {
      delete $scope.requestPending;
    });
  };
}])

.name;
