import translationsModule from 'angular-translate'
import { anyPass, filter, isEmpty, isNil, not, pipe } from 'ramda'
import flatasticConfig from '../../scripts/config'
import yepptApi from '../../scripts/modules/api'
import yepptCollection from '../../scripts/modules/collection'
import FileSystemModule from '../../scripts/modules/fileSystem'
import flatasticFirebase from '../../scripts/modules/flatasticFirebase'
import Countries from '../../utils/countries'
import Currencies from '../../utils/currencies'
import WgTypes from '../../utils/wgTypes'
import flatasticUserService from '../user/svc_user'
import flatasticWgFlatmate from './svc_flatmate'


export default angular.module('flatastic.wg.service', [
  translationsModule,
  flatasticConfig,
  flatasticUserService,
  flatasticWgFlatmate,
  flatasticFirebase,
  yepptApi,
  yepptCollection,
  FileSystemModule,
])

.run(['Wg', 'User', '$rootScope', '$timeout', '$window', '$filter', 'FlatasticEvents', 'Firebase',
    function(Wg, User, $rootScope, $timeout, $window, $filter, FlatasticEvents, Firebase) {

  if (User.isLoggedIn() && User.properties.groupId == 2) {
    Wg.restoreLocally().get();

    // User should invite flatmates if he/she is alone
    console.log('Wg.flatmates.length()', Wg.flatmates.length())
    var numGhosts = Wg.flatmates.reduce(function(pre, cur) {
      return pre + (cur.groupId == 5)
    }, 0);
    if (Wg.flatmates.length() < 2) {
      $timeout(function() {
        $rootScope.$broadcast('toaster-show', {
          title: $filter('translate')('WG__FLATMATE_REMINDER-TITLE'),
          message: $filter('translate')('WG__FLATMATE_REMINDER-MSG'),
          actions: [{
            label: $filter('translate')('WG__FLATMATE_REMINDER-BTN'),
            goTo: ['wg.settings', 'wg.settings-wg'],
          }],
        });
      }, 2500);
    } else if (numGhosts > 0 &&
        !$window.localStorage['Flatastic.ShoutsSecondView']) {
      $timeout(function() {
        $window.localStorage['Flatastic.ShoutsSecondView'];
        $rootScope.$broadcast('toaster-show', {
          title: $filter('translate')('WG__INCOMPLETE_REMINDER-TITLE'),
          message: $filter('translate')('WG__INCOMPLETE_REMINDER-MSG'),
          actions: [{
            label: $filter('translate')('WG__INCOMPLETE_REMINDER-BTN_SHARE'),
            goTo: ['wg.settings', 'wg.settings-wg'],
          }, {
            label: $filter('translate')('WG__INCOMPLETE_REMINDER-BTN_LATER'),
            close: true,
          }],
        });
      }, 2500);
    }
  }

  [
    FlatasticEvents.wg.didUpdate,
    FlatasticEvents.user.didEnterWg,
  ].forEach(function(event) {
    $rootScope.$on(event, function() {
      Wg.get().then(function({ data: { type } }) {
        Firebase.setUserProperty('household_type', type)
      });
    });
  });

  [
    FlatasticEvents.user.didLogout,
    FlatasticEvents.user.didLeaveWg,
  ].forEach(function(event) {
    $rootScope.$on(event, function() {
      Wg.clear();
    });
  });

  $rootScope.$on(FlatasticEvents.user.didUpdate, function(event, userData) {
    Wg.flatmates.remove(userData.id);
    Wg.flatmates.push(userData);
    Wg.storeLocally();
  });

}])

.value('WgTypes', WgTypes)
.value('Currencies', Currencies)
.value('Countries', Countries)

.factory('Wg',
    ['Api', 'YepptCollection', 'Flatmate', '$window', '$rootScope', '$filter', 'FlatasticEvents', 'FileSystem', '$timeout', 'LocalStorageKeys',
    function (Api, YepptCollection, Flatmate, $window, $rootScope, $filter, FlatasticEvents, FileSystem, $timeout, LocalStorageKeys) {

  var config = {
    wgStorageKey: 'Flatastic.Wg',
  };

  var Wg = {};

  Wg.flatmates = new YepptCollection('id');

  Wg.formerFlatmate = new Flatmate({
    firstName: $filter('translate')('WG__FORMER_FLATMATE-FIRST_NAME'),
    profileImage: 'images/flatastic-avatar.png',
  });

  // TODO: perhaps this can be done better
  Wg.flatmates._get = function(id) {
    return Wg.flatmates.get(id) || Wg.formerFlatmate;
  };

  Wg.set = function(wgJson) {
    for (var key in wgJson) {
      if (key === 'flatmates') {
        Wg.flatmates.remove();
        Wg.flatmates.push(wgJson[key]);
      } else {
        Wg[key] = wgJson[key];
      }
    }
    Wg.flatmates.manipulate(function(flatmate) {
      flatmate.id = parseInt(flatmate.id, 10);
      return flatmate;
    });
    return Wg;
  };

  Wg.storeLocally = function() {
    // make sure that first name of former flatmate is set with the correct language
    Wg.formerFlatmate.firstName = $filter('translate')('WG__FORMER_FLATMATE-FIRST_NAME');

    $window.localStorage[config.wgStorageKey] = JSON.stringify(Wg);
    return Wg;
  };

  Wg.moveIn = function(wgCode) {
    return Api.post('/homeless/movein', {
      wgCode: wgCode,
    })
    .success(function(data) {
      Wg.set(data.wg).storeLocally();
    });
  };

  Wg.createWg = function() {
    return Api.get('/homeless/newwg')
    .success(function(data) {
      Wg.set(data.wg).storeLocally();
    });
  };

  Wg.restoreLocally = function() {
    if ($window.localStorage[config.wgStorageKey]) {
      Wg.set(JSON.parse($window.localStorage[config.wgStorageKey]));
    }
    return Wg;
  };

  Wg.clear = function() {
    delete $window.localStorage[config.wgStorageKey];
    Wg.flatmates.remove();
    for (var key in Wg.getScaffold()) {
      delete Wg[key];
    }
    delete Wg.id;
  };

  Wg.getScaffold = function() {
    return {
      name: Wg.name     || '',
      city: Wg.city     || '',
      country: Wg.country  || '',
      countryCode: Wg.countryCode || '',
      currency: Wg.currency || '',
      street: Wg.street   || '',
      postCode: Wg.postCode || '',
      type: Wg.type,
    };
  };

  Wg.getInviteScaffold = function() {
    return {
      firstName: '',
    };
  };

  Wg.getAvailableLanguages = function() {
    var languages = new YepptCollection('id');
    languages.push([{
      id: 1,
      language: 'english',
      abbreviation: 'en_UK',
      image: 'en_UK.png',
    }, {
      id: 2,
      language: 'german',
      abbreviation: 'de_DE',
      image: 'de_DE.png',
    }, {
      id: 3,
      language: 'austria',
      abbreviation: 'de_AT',
      image: 'de_AT.png',
    }, {
      id: 4,
      language: 'switzerland',
      abbreviation: 'de_CH',
      image: 'de_CH.png',
    }]);
    return languages.get();
  };

  Wg.get = function() {
    Wg.status = 'loading';
    return Api.get('/wg')
    .success(function(data) {
      Wg.set(data).storeLocally();
    })
    .finally(function() {
      Wg.status = '';
    });
  };

  Wg.save = function(data) {
    var propertiesBackup = Wg.getScaffold();
    Wg.status = 'saving';
    const updateData = filter(pipe(anyPass([isNil, isEmpty]), not))(data)
    Wg.set(updateData);
    return Api.post('/wg', updateData)
      .success(function(data) {
        delete Wg.status;
        delete Wg.errorMessage;
        $rootScope.$broadcast(FlatasticEvents.wg.didUpdate, data)
        Wg.set(data).storeLocally();
      })
      .error(function(data) {
        Wg.status = 'error';
        Wg.errorMessage = data;
        Wg.set(propertiesBackup).storeLocally();
      });
  };

  /*
   * inviteObj = {
   *   firstName:  'Alberto'
   * }
   */
  Wg.inviteFlatmate = function(inviteObj) {
    Wg.status = 'saving';
    return Api.post('/wg/invite', inviteObj)
      .success(function(data) {
        Wg.flatmates.push(new Flatmate(data.newFlatmate), true);
        delete Wg.status;
        delete Wg.errorMessage;
        Wg.storeLocally();
      })
      .error(function(data) {
        Wg.status = 'error';
        Wg.errorMessage = data;
        Wg.storeLocally();
      });
  };

  Wg.cancelInvitation = function(flatmate) {
    Wg.status = 'saving';
    return Api.post('/wg/cancel_invitation', { userId: flatmate.id })
      .success(function(data) {
        delete Wg.status;
        delete Wg.errorMessage;
        Wg.set(data);
        // Ensure canceled invited ghost is removed everywhere by refetching data
        $rootScope.$broadcast(FlatasticEvents.user.didEnterWg);
      })
      .error(function(data) {
        Wg.status = 'error';
        Wg.errorMessage = data;
      });
  };

  Wg.checkMoveOut = function() {
    return Api.get('/cashflow/settlement');
  };

  Wg.moveOut = function() {
    return Api.get('/wg/move_out')
    .success(function() {
      $rootScope.$broadcast(FlatasticEvents.user.didLeaveWg);
    });
  };

  Wg.kickOut = function(flatmate) {
    Wg.status = 'kicking out flatmate';
    return Api.post('/wg/kickout', { userId: flatmate.id })
    .success(function(data) {
      delete Wg.status;
      delete Wg.errorMessage;
      Wg.set(data);
      // Ensure kicked out flatmate is removed everywhere by refetching data
      $rootScope.$broadcast(FlatasticEvents.user.didEnterWg);
    })
    .error(function(data) {
      Wg.status = 'error';
      Wg.errorMessage = data;
    });
  };

  Wg.uploadPhoto = function(fileURL, useBrowserUpload) {
    const upload = useBrowserUpload ? Api.browserFileUpload : Api.upload
    const localImage = FileSystem.getLocalFilePath(fileURL)
    Wg.imageUploading = true
    // Use timeout to trigger a $digest
    return $timeout(5)
      .then(() => Wg.set({ image: localImage }))
      .then(() => upload(fileURL, '/wg/image'))
      .then(function({ data }) {
        Wg.set({ image: data.wg_image.full_s3_url })
        return Wg
      })
      .finally(function() {
        delete Wg.imageUploading
      })
  }

  Wg.updateProfilePictureOfUser = function(userId, imageUrl) {
    Wg.flatmates._get(userId).profileImage = FileSystem.getLocalFilePath(imageUrl)
    Wg.storeLocally()
  }

  Wg.setIsSettingUp = function(isSettingUp) {
    if (isSettingUp) {
      $window.localStorage[LocalStorageKeys.wg.setup] = true
    } else {
      delete $window.localStorage[LocalStorageKeys.wg.setup]
    }
    return Wg
  }

  Wg.isSettingUp = function () {
    return Boolean($window.localStorage[LocalStorageKeys.wg.setup])
  }

  return Wg;
}])

.name;
