import FlatasticConfig from '../../scripts/config';
import PushNotificationsModule from '../../scripts/modules/pushnotification';
import ChoresService from '../chores/svc_chores';
import UserService from '../user/svc_user';

export default angular.module('flatastic.service.badge', [
  UserService,
  FlatasticConfig,
  ChoresService,
  PushNotificationsModule,
])

.run(['Badge', '$rootScope', 'User', 'Chores', 'FlatasticEvents',
  function(Badge, $rootScope, User, Chores, FlatasticEvents) {
    setBadgeIfPossible()
    $rootScope.$on(FlatasticEvents.badge.update, setBadgeIfPossible);

    function setBadgeIfPossible() {
      if (User.isLoggedIn() && User.properties.groupId == 2) {
        Badge.setOverdueBadgeCount(Chores.collection.collection, User.properties.id);
      }
    }
  }])

.factory('Badge',
  ['PushNotification',
    function (PushNotification) {

      return {
        setOverdueBadgeCount,
      }

      //// Functions

      function setOverdueBadgeCount(choreCollection, userId) {
        var overdueCounter = 0;
        choreCollection.forEach(function(chore) {
          if (chore.timeLeftNext <= 1 && chore.currentUser == userId && chore.rotationTime !== -1) {
            overdueCounter++;
          }
        });
        console.debug(`Badge.setOverdueBadgeCount to ${overdueCounter}`);
        PushNotification.setBadgeNumber(overdueCounter);
      };
    }])

.name;
