import flatasticConfig from '../config'
import FileSystemPlugin from './fileSystem'
import flatasticPersistentStorage from './mcmPersistentStorage'

export default angular.module('yeppt.api', [
  flatasticConfig,
  flatasticPersistentStorage,
  FileSystemPlugin,
])

.config(['$httpProvider', function($httpProvider) {
  $httpProvider.interceptors.push('authInterceptor');
}])

.factory('authInterceptor', ['$q', 'ApiService', function($q, ApiService) {
  var myInterceptor = {
    request: function(config) {
      if (!config || !config.url) { return config; }
      if (config.url.indexOf(ApiService.baseUrl) === -1) { return config; }
      ApiService.log(config);
      config.headers = ApiService.configureHeader(config.headers);
      if (ApiService.isDeactivated()) {
        return $q.reject({
          error: 'There were too many requests from your device. Try it again later',
        }, 429, {}, {});
      }
      return config;
    },
    responseError: function(response) {
      if (!response || !response.config || !response.config.url) {
        return $q.reject(response);
      }
      if (response.config.url.indexOf(ApiService.baseUrl) === -1) {
        return $q.reject(response);
      }
      ApiService.handleError(response.data, response.status, response.headers, response.config);
      return $q.reject(response);
    },
  };
  return myInterceptor;
}])

.factory('ApiService', [
    '$rootScope', '$window', '$timeout', 'PersistentStorage', 'Config',
    function($rootScope, $window, $timeout, PersistentStorage, Config) {

  var apiConfig = {
    apiKeyName: 'apiKey',
    apiKeyHeader: 'x-api-key',
    apiUrl: Config.apiUrl || '/api',
    additionalHeaders: {
      'x-api-version': '2.0.0',
      'x-client-version': Config.versionNumber,
    },
    debug: Config.debug,
  };
  var apiKey = $window.localStorage[apiConfig.apiKeyName] || Config.defaultApiKey;
  var isApiDeactivated = false;

  return {
    baseUrl: apiConfig.apiUrl,
    configureHeader,
    isDebugMode: apiConfig.debug,
    isDeactivated,
    deleteApiKey,
    getApiKey,
    handleError,
    log,
    setApiKey,
  };

  //// Functions

  function log() {
    if (!apiConfig.debug) { return; }
    console.log.apply(this, arguments);
  }

  // 401: If the client cant get access by authentication use this
  // 403: Request should not be repeated. Client won't be able to get access.
  function handleError(data, status, headers, config) {
    if (!config || !config.url) { return; }
    console.error('ERROR: ' + config.method + ' ' + config.url, data);
    $rootScope.$broadcast('Api:Error', status, {
      data: data,
      method: config ? config.method : JSON.stringify(config),
      url: config ? config.url : JSON.stringify(config),
    });

    switch (status) {
      case 401:
        $rootScope.$broadcast('Api:Error:401');
        break;
      case 403:
        $rootScope.$broadcast('Api:Error:403');
        break;
      case 429:
        temporaryDeactivateApi();
        $rootScope.$broadcast('Api:Error:429');
        break;
      default:
        break;
    }
  };

  function temporaryDeactivateApi() {
    console.log('deactivating api');
    isApiDeactivated = true;
    $timeout(function() {
      isApiDeactivated = false;
    }, 3 * 60 * 1000);
  }

  function isDeactivated() {
    return isApiDeactivated;
  }

  function setApiKey(newApiKey) {
    apiKey = newApiKey;
    $window.localStorage[apiConfig.apiKeyName] = newApiKey;
    PersistentStorage.store(apiConfig.apiKeyName, newApiKey)
      .then(console.log)
      .catch(console.error);
  }

  function getApiKey() {
    if (apiKey) { return apiKey; }
    if (!$window.localStorage[apiConfig.apiKeyName]) {
      $window.localStorage[apiConfig.apiKeyName] = Config.defaultApiKey;
    }
    return $window.localStorage[apiConfig.apiKeyName];
  }

  function deleteApiKey() {
    apiKey = null;
    delete $window.localStorage[apiConfig.apiKeyName];
    PersistentStorage.remove(apiConfig.apiKeyName)
      .then(console.log)
      .catch(console.error);
  }

  function configureHeader(headersBefore) {
    var headers = {}
    for (var index in headersBefore) {
      headers[index] = headersBefore[index];
    }
    headers[apiConfig.apiKeyHeader] = getApiKey();
    for (var index in apiConfig.additionalHeaders) {
      headers[index] = apiConfig.additionalHeaders[index];
    }
    return headers;
  }

}])

.factory('Api',
    ['$http', '$rootScope', 'ApiService', 'FileSystem',
    function($http, $rootScope, ApiService, FileSystem) {


  // Public API here
  return {
    // HTTP requests
    get,
    put,
    patch,
    post,
    delete: remove,
    upload,
    browserFileUpload,
    download,
    // apiKey functions
    getApiKey: ApiService.getApiKey,
    setApiKey: ApiService.setApiKey,
    deleteApiKey: ApiService.deleteApiKey,
  };

  ////

  function get(url, config) {
    return $http.get(ApiService.baseUrl + url, config);
  };

  function remove(url, config) {
    return $http.delete(ApiService.baseUrl + url, config);
  };

  function post(url, data, config) {
    $rootScope.$broadcast('track-event', {
      category: 'Api',
      action: 'POST',
      label: url,
    });
    return $http.post(ApiService.baseUrl + url, data, config);
  };

  function patch(url, data, config) {
    return $http.patch(ApiService.baseUrl + url, data, config);
  }

  function put(url, data, config) {
    return $http.put(ApiService.baseUrl + url, data, config);
  };

  function download(url, config) {
    return $http.get(ApiService.baseUrl + url, config)
      .then((response) => {
        const data = response.data
        const contentType = response.headers('content-type').replace(/;$/, '')
        const base64 = `data:${contentType};base64,${btoa(data)}`
        return base64
      })
  }

  function browserFileUpload(file, url) {
    var config = {
      transformRequest: angular.identity,
      headers: { 'Content-Type': undefined },
    };
    var formData = new FormData();
    formData.append('file', file);

    return $http.post(ApiService.baseUrl + url, formData, config);
  };

  function upload(fileURL, url) {
    return FileSystem.getFileBlob(fileURL)
      .then(blob => {
        const formData = new FormData()
        const fileName = fileURL.replace(/.*?([^/]+\.[\w]+$)/i, '$1')
        formData.append('file', blob, fileName)
        var config = {
          transformRequest: angular.identity,
          headers: { 'Content-Type': undefined },
        };
        return $http.post(`${ApiService.baseUrl}${url}`, formData, config)
      })
  }

}])

.name;
