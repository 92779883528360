import flatasticExpensesService from '../../modules/expenses/svc_expenses'
import flatasticUserService from '../../modules/user/svc_user'
import yepptApi from '../../scripts/modules/api'
import yepptCordova from '../../scripts/modules/yptCordova'
import yepptSocialSharing from '../../scripts/modules/yptSocialsharing'

export default angular.module('flatastic.expenses.controller', [
  'ionic',
  yepptSocialSharing,
  yepptApi,
  flatasticUserService,
  flatasticExpensesService,
  yepptCordova,
])

.controller('ExpensesCtrl',
    ['$scope', '$q', 'yptNotify', 'Expenses', 'User', '$filter', 'Wg',
    '$ionicScrollDelegate', '$state', '$rootScope', '$timeout', '$ionicHistory', 'Api',
    'yptSocialsharing',
    function ($scope, $q, yptNotify, Expenses, User, $filter, Wg,
    $ionicScrollDelegate, $state, $rootScope, $timeout, $ionicHistory, Api,
    yptSocialsharing) {

  $scope.user = User.properties;

  $scope.hasBackView = $ionicHistory.backView;

  // Expenses Switch -- use of local storage for saving status
  $scope.switch = localStorage['Flatastic.Expenses.switch'] || 'expenses';
  $scope.changeSwitch = function(val) {
    $scope.switch = val;
    localStorage['Flatastic.Expenses.switch'] = val;
    $ionicScrollDelegate.resize()
  };

  $scope.myBalance = function() {
    var myBalance = 0;
    Expenses.getStatistics().data.forEach(function(flatmate) {
      if (flatmate.id == User.properties.id) {
        myBalance = flatmate.balance;
      }
    });
    return myBalance;
  };

  $scope.exportExpenses = function() {
    if (!User.isPremium()) {
      const label = 'Export expenses'
      return $state.go('wg.premium-paywall', { label })
    }
    $rootScope.$broadcast('track-event', {
      category: 'Expenses',
      action: 'Export',
      label: 'Did start',
    });
    $scope.exportIsPending = true;
    Api.download('/cashflow?format=csv')
    .then(function(base64) {
      $timeout(function() {
        $scope.exportIsPending = false;
      })
      $rootScope.$broadcast('track-event', {
        category: 'Expenses',
        action: 'Export',
        label: 'Did succeed',
      });
      const handleDownload = ionic.Platform.is('browser') ? saveForBrowser : saveForIOSorAndroid
      return handleDownload(base64)
    })
    .catch(function(error) {
      $rootScope.$broadcast('track-event', {
        category: 'Expenses',
        action: 'Export',
        label: 'Did fail: ' + JSON.stringify(error),
      });
      console.error('Error while exporting', error);
      $timeout(function() {
        $scope.exportIsPending = false;
      })
    });

    function saveForBrowser(base64) {
        return fetch(base64).then(res => res.blob()).then(function(blob) {
          const url = window.URL.createObjectURL(blob);
          return window.open(url);
        })
    }

    function saveForIOSorAndroid(base64) {
      var deferred = $q.defer();
      yptNotify.alert({
        title: $filter('translate')('EXP__EXPORT_SUCCESS_DIALOG__TITLE'),
        message: $filter('translate')('EXP__EXPORT_SUCCESS_DIALOG__MESSAGE'),
        buttonLabel: $filter('translate')('CONTINUE'),
        callback: function() {
          deferred.resolve(base64);
        },
      });
      return deferred.promise.then(function(base64) {
        return yptSocialsharing.share({
          subject: $filter('translate')('EXP__EXPORT_SHARE_DIALOG__TITLE'),
          message: $filter('translate')('EXP__EXPORT_SHARE_DIALOG__MESSAGE'),
          files: [`df:expenses.csv;${base64}`],
        });
      })
    }
  }

  $scope.resetActive = false;
  $scope.wg = Wg;

  $scope.isEqual = function() {
    var balance = $filter('balanceSum')(Expenses.getStatistics().data, 1);
    return balance == 0;
  };

  $scope.reset = function() {
    if ($scope.resetActive) {
      return;
    }
    $scope.resetActive = true;
    yptNotify.confirm({
      title: $filter('translate')('EXP__BALANCE_RESET'),
      message: $filter('translate')('EXP__BALANCE_RESET_CONFIRM_TEXT'),
      buttonLabels: [
        $filter('translate')('BTN_CANCEL'),
        $filter('translate')('CONTINUE'),
      ],
      callback: getSettlement,
    });
  };

  var getSettlement = function(isConfirmed) {
    if (!isConfirmed) {
      return $scope.resetActive = false;
    }
    Expenses.resetBalance()
    .success(function(data) {
      var text = '';
      data.settlement.forEach(function(item, index) {
        var debtor   = Wg.flatmates._get(data.settlement[index].transaction_from).firstName;
        var creditor = Wg.flatmates._get(data.settlement[index].transaction_to).firstName;
        var credit   = data.settlement[index].amount;
        text += $filter('translate')('EXP__BALANCE_RESET-MSG', {
          debtor: debtor,
          creditor: creditor,
          credit: $filter('wgCurrency')(credit),
        }) + '\n';
      });
      text += '\n' + $filter('translate')('EXP__BALANCE_RESET-MSG-ENDING');
      yptNotify.confirm({
        title: $filter('translate')('EXP__BALANCE_RESET'),
        message: text,
        buttonLabels: [
          $filter('translate')('BTN_CANCEL'),
          $filter('translate')('OK'),
        ],
        callback: doResetBalance,
      });
    })
    .error(function() {
      $scope.resetActive = false;
    });
  };

  var doResetBalance = function(isConfirmed) {
    if (!isConfirmed) {
      return $scope.resetActive = false;
    }
    Expenses.doResetBalance()
    .success(function() {
      yptNotify.alert({
        title: $filter('translate')('EXP__BALANCE_RESET'),
        message: $filter('translate')('EXP__BALANCE_BALANCED'),
      });
    })
    .then(function() {
      $scope.resetActive = false;
    });
  };

  $scope.expenses = Expenses.get;
  $scope.statistics = Expenses.getStatistics;
  $scope.myBalance = function() {
    var output = 0;
    Expenses.getStatistics().data.forEach(function(item) {
      if (item.id == User.properties.id) {
        output = item.balance;
      }
    });
    return output;
  };

  $scope.flatmates = Wg.flatmates;

  $scope.interact = function(expense) {
    if (!expense.status && !expense.error) {
      $state.go('wg.expenses-details', { id: expense.id });
      return;
    }
    if (expense.error) {
      yptNotify.alert({
        title: $filter('translate')('EXP__SAVE_ERROR-TITLE'),
        message: $filter('translate')('EXP__SAVE_ERROR-MSG', {
          error: JSON.stringify(expense.error),
        }),
        buttonLabel: $filter('translate')('EXP__SAVE_ERROR-CONFIRM'),
        callback: function() {
          delete expense.error;
          if (expense.local) {
            expense.create();
          } else {
            expense.edit();
          }
        },
      });
    }
  };

  $scope.status = Expenses.status;
  $scope.error = Expenses.error;
  $scope.hideLoading = false;
  $scope.refresh = function() {
    $scope.hideLoading = true;
    Expenses.getStatisticsFromServer();
    Expenses.getFromServer()
    .finally(function() {
      $scope.$broadcast('scroll.refreshComplete');
      $scope.hideLoading = false;
    });
  };

  $scope.showHelp = function() {
    yptNotify.alert({
      title: $filter('translate')('EXP__HELP-TITLE'),
      message: $filter('translate')('EXP__HELP-MSG'),
      trackEvent: {
        name: 'showHelp',
        parameters: {
          context: 'expenses',
        },
      },
    });
    $rootScope.$broadcast('track-event', {
      category: 'Help',
      action: 'Clicked',
      label: '/expenses',
    });
  };

  $scope.loadMore = function() {
    Expenses.loadMore()
    .finally(function() {
      $scope.$broadcast('scroll.infiniteScrollComplete');
    });
  };
  $scope.moreCanBeLoaded = Expenses.moreCanBeLoaded;

  $scope.statisticsInfo = function(item) {
    var text = '';
    var name = Wg.flatmates._get(item.id).firstName || 'Mitbewohner';
    if (item.balance == 0) {
      text = $filter('translate')('EXP__STATISTICS_INFO-EVEN', {
        name: name,
      });
    } else if (item.balance < 0 ) {
      text = $filter('translate')('EXP__STATISTICS_INFO-MINUS', {
        name: name,
        value: $filter('wgCurrency')(Math.abs(item.balance)),
      });
    } else if (item.balance > 0) {
      text = $filter('translate')('EXP__STATISTICS_INFO-PLUS', {
        name: name,
        value: $filter('wgCurrency')(item.balance),
      });
    }
    yptNotify.alert({
      title: $filter('translate')('EXP__STATISTICS_INFO-TITLE', {
        name: name,
      }),
      message: text,
      callback: function() {},
    })
  };

}])

.name;
