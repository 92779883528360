import ShoutsController from './ctrl_shouts'
import ShoutDirective from './directive_shouts'
import template from './view_shouts.html'

export default angular
.module('flatastic.shouts', [
  ShoutsController,
  ShoutDirective,
])
.config(
    ['$stateProvider',
    function ($stateProvider) {

  $stateProvider.state('wg.shouts', {
    url: '/shouts',
    views: {
      'menuContent': {
        controller: 'ShoutsCtrl',
        template,
      },
    },
  });

}])

.name;
