import { mapObjIndexed } from 'ramda'

const meta = ['en', 'de']
const trans = {
  [`Step 1 of 2`]: [
    `Step 1 of 2`,
    `Schritt 1 von 2`,
  ],
  [`What's your name?`]: [
    `What's your name?`,
    `Wie heißt du?`,
  ],
  [`Your cohabitants can tell who you are by your name. Don't let them down!`]: [
    `The others can tell who you are by your name. Don't let them down!`,
    `Anhand deines Namens wissen deine Mitbewohner wer du bist.`,
  ],
  [`Your Name`]: [
    `Your Name`,
    `Dein Name`,
  ],
  [`Your name`]: [
    `Your name`,
    `Dein Name`,
  ],
  [`Continue`]: [
    `Continue`,
    `Weiter`,
  ],
}

export default {
  en: mapObjIndexed((val, key, obj) => val[0])(trans),
  de: mapObjIndexed((val, key, obj) => val[1])(trans),
}
