function ChoresMonetization(Firebase, yptNotify, Chores, $filter, $rootScope, $state, User, Wg) {
  return Firebase.getConfigValues([
    ['chores_entries_limit', null],
    ['days_without_limitations', null],
  ])
    .then(([choresLimit, daysWithoutLimitations]) => {
      const numChores = Chores.length()
      const createdAt = moment(User.properties.createdOn * 1000);
      const userAge = moment().diff(createdAt, 'days');
      if (Wg.isPremium || User.isPremium() ) {
        $rootScope.$broadcast('track-event', {
          category: 'Monetization',
          action: `Chores: Did not hit limitation`,
          label: 'User is premium',
        })
        return
      }
      if (choresLimit === null || numChores <= choresLimit) {
        $rootScope.$broadcast('track-event', {
          category: 'Monetization:Limitation',
          action: `Chores: Did not hit limitation`,
          label: `User has not more than ${choresLimit} chore entries`,
        })
        return
      }
      if (daysWithoutLimitations === null || userAge < daysWithoutLimitations) {
        $rootScope.$broadcast('track-event', {
          category: 'Monetization',
          action: `Chores: Did not hit limitation`,
          label: `User is younger than ${daysWithoutLimitations} days`,
        })
        return
      }
      const message = $filter('translate')('CHO__MONETIZATION__ENTRIES_LIMIT-MSG', { choresLimit })
      const title = $filter('translate')('CHO__MONETIZATION__ENTRIES_LIMIT-TITLE')
      yptNotify.confirm({
        title,
        message,
        buttonLabels: [
          $filter('translate')('CHO__MONETIZATION__ENTRIES_LIMIT-DISCARD'),
          $filter('translate')('CHO__MONETIZATION__ENTRIES_LIMIT-CTA'),
        ],
        callback: function(res) {
          if (!res) {
            return
          }
          $state.go('wg.purchase');
        },
      })
      $rootScope.$broadcast('track-event', {
        category: 'Monetization:Limitation',
        action: `Chores: Did hit limitation`,
        label: `User has reached the limit of ${choresLimit} chore entries`,
      })
      throw `User has reached the limit of ${choresLimit} chore entries`
    })
}
ChoresMonetization.$inject = [
  'Firebase',
  'yptNotify',
  'Chores',
  '$filter',
  '$rootScope',
  '$state',
  'User',
  'Wg',
]

export default ChoresMonetization
