/**
 * Wrapper for https://github.com/appfeel/admob-google-cordova
 */
export default angular.module('mcm.admob', [
  'ionic',
])

.constant('AdMobPublisherIds', {
  ios: {
    banner: 'ca-app-pub-1576024766679504/3902385472',
    interstitial: 'ca-app-pub-4916967444564921/5187661896',
  },
  android: {
    banner: 'ca-app-pub-1576024766679504/5518719474',
    interstitial: 'ca-app-pub-4916967444564921/5327262695',
  },
})

.factory('AdMob',
    ['$q', '$ionicPlatform', 'AdMobPublisherIds', '$document', '$window',
    function($q, $ionicPlatform, AdMobPublisherIds, $document, $window) {

  var plugin;
  var interstitialIsReady;

  init();

  return {
    init,
    getInterstitial,
    showInterstitialAd,
    createBannerView,
  };


  //// Functions


  function init() {
    console.debug('Initializing AdMob');
    var adMobId = ionic.Platform.isAndroid()
      ? AdMobPublisherIds.android
      : AdMobPublisherIds.ios;

    return plugin = $ionicPlatform.ready().then(function() {
      if (!$window.admob) {
        console.debug('AdMob plugin is not installed.');
        return $q.reject('AdMob plugin is not installed.');
      }
      admob.setOptions({
        publisherId: adMobId.banner,
        interstitialAdId: adMobId.interstitial,
      });
      admob.interstitial.config({
        interstitialAdId: adMobId.interstitial,
        autoShow: false,
      });
      // Add event listeners
      resetInterstitialIsReady();
      [
        'admob.interstitial.events.LOAD',
        'admob.interstitial.events.LOAD_FAIL',
        'admob.interstitial.events.OPEN',
        'admob.interstitial.events.CLOSE',
        'admob.interstitial.events.EXIT_APP',
      ].forEach(function(eventName) {
        $document[0].addEventListener(eventName, function(e) {
          console.log(eventName, e);
          if (eventName === 'admob.interstitial.events.LOAD') {
            console.log('Interstitial is ready to be displayed');
            interstitialIsReady.resolve(e);
          }
        });
      });
      return admob;
    });
  }

  function resetInterstitialIsReady() {
    interstitialIsReady = $q.defer();
  }

  function getInterstitial() {
    console.log('AdMob: GetInterstitial');
    resetInterstitialIsReady();
    return plugin.then(function(admob) {
      return admob.interstitial.prepare()
    })
    .then(function() {
      return interstitialIsReady.promise;
    })
    .catch(function(error) {
      console.error('Error while getting interstitial', error);
      return $q.reject(arguments);
    });
  }

  function showInterstitialAd() {
    return plugin
      .then(function(admob) {
        return admob.interstitial.show()
      });
  }

  function createBannerView() {
    return plugin.then(function(admob) {
      admob.createBannerView();
    });
  }

}])

.name;
