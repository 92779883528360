import translationsModule from 'angular-translate'
import { always, equals, hasPath, map, nth, path, when } from 'ramda'
import flatasticConfig from '../config'
import yepptApi from './api'
import yepptCordova from './yptCordova'

export default angular.module('flatastic.pushnotifications', [
  'ionic',
  translationsModule,
  flatasticConfig,
  yepptApi,
  yepptCordova,
])
.factory('PushNotification', PushNotification)
.name;

const words = {
  PUSH_PRIMER__TITLE: [
      'Push Notifications',
      'Push Benachrichtigungen',
    ],
  PUSH_PRIMER__BODY: [
      `In order to get notified when chores are due, when someone writes a message, adds an expense or goes shopping you'll need to give your permission to receive push notifications.`,
      `Um Nachrichten zu erhalten, an Aufgaben erinnert zu werden und mitzubekommen, wenn z.B. einer deiner Mitbewohnber einkaufen geht, benötigst du Push-Benachrichtigungen`,
    ],
  PUSH_PRIMER__OK: [
      'Confirm',
      'Geht klar',
    ],
  PUSH_PRIMER__LATER: [
      'Not now',
      'Nicht jetzt',
    ],
}

export const translations = {
  en: map(nth(0))(words),
  de: map(nth(1))(words),
}

function PushNotification(Firebase, Api, $ionicPlatform, Config, $window, $rootScope, yptNotify, $filter) {

  const config = {
    storageKey: 'deviceToken',
    hasUserAllowedPushAccess: 'push-access-allowed',
  }

  return {
    getAPNSToken,
    getBadgeNumber,
    setBadgeNumber,
    getToken,
    grantPermission,
    hasPermission,
    hasBackendDeviceToken,
    unregisterDevice,
    checkPermissionAndInit: init,
    getDeviceTokenAndTellBackend,
  }

  function init() {
    return primeForPermission()
      .then(getDeviceTokenAndTellBackend)
      .catch(trackAndRejectError('PushNotification.init did fail'))
  }

  function getDeviceTokenAndTellBackend() {
    return Firebase.hasPermission()
      .then(when(equals(false), Firebase.grantPermission))
      .then(() => Promise.all([
        getToken(),
        getPlatform(),
        Config.senderId,
        getAPNSToken().catch(always(undefined)),
      ])
      .then(([...params]) => sendDeviceToken(...params)))
  }

  function hasUserAllowedPushAccess () {
    return getPlatform()
      .then(platform => {
        if (platform.toLowerCase() !== 'ios') {
          return Promise.resolve(true)
        }
        return $window.localStorage[config.hasUserAllowedPushAccess] === 'true'
      })
  }

  function checkIfUserIsGoingToGivePermissions() {
    const dialog = {
      title: $filter('translate')('PUSH_PRIMER__TITLE'),
      message: $filter('translate')('PUSH_PRIMER__BODY'),
      buttonLabels: [
        $filter('translate')('PUSH_PRIMER__LATER'),
        $filter('translate')('PUSH_PRIMER__OK'),
      ],
    }
    return yptNotify.showConfirmDialog(dialog)
      .then(() => {
        // Track OK
        console.debug('User confirmed to give access to push notifications')
        $window.localStorage[config.hasUserAllowedPushAccess] = 'true'
      })
      .catch(() => {
        // Track NOK
        console.debug('User rejected to give access to push notifications')
        return Promise.reject('User rejected to give access to push notifications')
      })
  }

  function primeForPermission() {
    return Firebase.hasPermission()
      .then(hasPermission => {
        return hasPermission ? Promise.resolve(true) : handleNoPermissionCase()
      })

    function handleNoPermissionCase() {
      return hasUserAllowedPushAccess()
        .then(hasAllowed => {
          if (hasAllowed) {
            return Promise.resolve(true)
          }
          return checkIfUserIsGoingToGivePermissions()
        })
    }
  }

  function trackAndRejectError(message) {
    return function(error) {
      $rootScope.$broadcast('track-event', {
        event: 'push_notification_error',
        error: JSON.stringify(error),
        message,
      });
      console.debug({ message, error })
      return Promise.reject(error);
    }
  }

  function getPlatform() {
    return $ionicPlatform.ready()
      .then(() => {
        if (!hasPath(['device', 'platform'])($window)) {
          return Promise.reject('Cannot get platform')
        }
        return path(['device', 'platform'])($window)
      })
  }

  function sendDeviceToken(deviceToken, platform, senderId, apnsToken) {
    return Api.post('/user/register_device', {
      deviceToken,
      platform,
      senderId,
      apnsToken,
    })
  }

  function hasBackendDeviceToken() {
    return Firebase.getToken()
      .then(deviceToken => Api.get('/user/check_device_token', { params: { deviceToken } }))
      .then(({ data: isDeviceTokenStored }) =>  isDeviceTokenStored)
  }

  function unregisterDevice() {
    return Promise.all([
      getToken(),
      getPlatform(),
      Config.senderId,
      getAPNSToken().catch(always(undefined)),
    ])
      .then(([deviceToken, platform, senderId, apnsToken]) =>
        Api.post('/auth/unregister_device', { deviceToken, platform, senderId, apnsToken }))
      .then(() => Firebase.unregister())
      .catch(trackAndRejectError('Failed to unregister device'))
  }

  function hasPermission() {
    return Firebase.hasPermission()
  }

  function getToken() {
    return Firebase.getToken()
  }

  function grantPermission() {
    return Firebase.grantPermission()
  }

  function getAPNSToken() {
    return Firebase.getAPNSToken()
  }

  function getBadgeNumber() {
    return Firebase.getBadgeNumber()
  }

  function setBadgeNumber(num) {
    return Firebase.setBadgeNumber(num)
  }
}

PushNotification.$inject = ['Firebase', 'Api', '$ionicPlatform', 'Config', '$window', '$rootScope', 'yptNotify', '$filter']
