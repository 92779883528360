import AngularTranslate from 'angular-translate'
import { filter, not, pick, pipe, propEq } from 'ramda'
import FlatasticConfig from '../../scripts/config'
import FirebaseModule from '../../scripts/modules/flatasticFirebase'
import CordovaModule from '../../scripts/modules/yptCordova'
import SocialSharingModule from '../../scripts/modules/yptSocialsharing'
import { isNilOrEmpty } from '../../utils/helpers'
import UserService from '../user/svc_user'
import WgService from '../wg/svc_wg'

export default angular.module('flatastic.household.controller', [
  AngularTranslate,
  CordovaModule,
  UserService,
  FirebaseModule,
  FlatasticConfig,
  SocialSharingModule,
  WgService,
  'ionic',
])
.controller('HouseholdController', HouseholdController)
.name;


HouseholdController.$inject = [
  '$rootScope',
  '$translate',
  'Countries',
  'Wg',
  '$state',
  'WgTypes',
  'yptNotify',
  '$ionicLoading',
  'yptSocialsharing',
  'yptActionsheet',
  'FlatasticEvents',
  'User',
]

function HouseholdController($rootScope, $translate, Countries, Wg, $state, WgTypes, yptNotify, $ionicLoading, yptSocialsharing, yptActionsheet, FlatasticEvents, User) {

  const vm = this;
  vm.householdTypes = WgTypes

  vm.getCountryLabel = getCountryLabel
  vm.saveHousehold = saveHousehold
  vm.inviteMember = inviteMember
  vm.getMembers = function() { return Wg.flatmates.get() }
  vm.interactWithMember = interactWithMember
  vm.finishInviteMembers = finishInviteMembers
  vm.setCurrency = setCurrency

  vm.countries = Countries
  vm.state = {
    user: User.properties,
    household: {
    },
  }

  init()

  //// Functions

  function init() {
    syncStateWithWgService()
  }

  function syncStateWithWgService() {
    const values = pipe(pick(['type', 'name', 'postCode', 'city', 'countryCode']), filter(pipe(isNilOrEmpty, not)))(Wg)
    vm.state.household = { ...vm.state.household, ...values }
  }

  function getCountryLabel(country) {
    return `${country.flag} ${$translate.instant(country.name)}`
  }

  function setCurrency() {
    const country = Countries.find(propEq('alpha3', vm.state.household.countryCode))
    vm.state.household.currency = country.currency
  }

  function saveHousehold(uiSref) {
    vm.state.error = null;
    vm.state.isBusy = true;
    return Wg.save(vm.state.household)
      .then(() => {
        syncStateWithWgService()
        return uiSref && $state.go(uiSref)
      })
      .catch((error) => {
        vm.state.error = error
        throw error
      })
      .finally(() => {
        vm.state.isBusy = false;
      })
  }

  function interactWithMyself(member) {
    yptNotify.alert({
      message: $translate.instant(`You moved in already`, { name: member.firstName }),
      title: $translate.instant(`Title: That's you`, { name: member.firstName }),
      buttonLabel: $translate.instant('CONFIRM'),
    })
  }

  function interactWithMember(member) {
    if (member.id == User.properties.id) {
      return interactWithMyself(member)
    }
    const options = {
      title: $translate.instant(`Title: Interact with member`, { name: member.firstName, code: member.wgCode }),
      buttonLabels: [
        $translate.instant('Button Label: Share invitation code'),
      ],
      addDestructiveButtonWithLabel: $translate.instant('Delete invitation'),
      androidEnableCancelButton: false,
      addCancelButtonWithLabel: $translate.instant('BTN_CANCEL'),
    }
    const actions = [
      function() { cancelInvitation(member, true) },
      function() { shareInvitationCode(member) },
    ]
    yptActionsheet.show(options, actions)
  }

  function inviteMember() {
    yptNotify.prompt({
      message: $translate.instant(`Please enter the person's first name`),
      title: $translate.instant(`Title: Add person`),
      buttonLabels: [$translate.instant('BTN_CANCEL'), $translate.instant('ADD')],
    })
    .then(function (firstName) {
      if (!firstName) {
        yptNotify.alert({
          message: $translate.instant(`You need to enter a name…`),
          title: $translate.instant('Title: Add person'),
        });
        return;
      }
      $ionicLoading.show({
        template: $translate.instant(`Room for person is being prepared…`, {
          name: firstName,
        }),
      });
      return Wg.inviteFlatmate({ firstName })
        .then(function({ data }) {
          $rootScope.$broadcast('track-event', {
            category: 'WG',
            action: 'Invited Flatmate',
            label: 'Success',
          });
          yptNotify.alert({
            title: $translate.instant(`Title: Add person`),
            message: $translate.instant('Person has been successfully added to your home. Now, share the invitation code with person', {
              name: data.newFlatmate.firstName,
              invitationCode: data.wgCode,
            }),
            buttonLabel: $translate.instant(`Button Label: Share invitation code`),
            callback: function() {
              shareInvitationCode({
                invitationCode: data.wgCode,
                firstName: data.newFlatmate.firstName,
              });
            },
          });
        })
        .catch(function({ data }) {
          $rootScope.$broadcast('track-event', {
            category: 'WG',
            action: 'Invited Flatmate',
            label: 'Error',
          });
          yptNotify.alert({
            message: $translate.instant(`Unfortunatelly something went wrong… Please try again.`),
            title: $translate.instant(`Title: Share invitation code`),
          });
          return;
        })
        .finally(function() {
          $ionicLoading.hide();
        });
    });
  }

  function shareInvitationCode(member) {
    return yptSocialsharing.share({
      message: $translate.instant(`WG__SHARE_WG_CODE-MSG`, {
        name: member.firstName,
        invitationCode: member.wgCode,
      }),
      subject: $translate.instant('An app for our home'),
      trackEvent: {
        name: 'Share',
        parameters: {
          context: 'sharecode',
          content: $translate.instant('SET__SHARE-TITLE'),
        },
      },
    })
    .then(function(res) {
      if (res && res.completed) {
        $rootScope.$broadcast(FlatasticEvents.wg.didShareInvitationCode, { app: res.app })
        return yptNotify.alert({
          message: $translate.instant(`Nice! By entering the invitation code the person can move into your home.`, {
            name: member.firstName,
          }),
          title: $translate.instant('Title: Well done.'),
        });
      }
      return yptNotify.alert({
        message: $translate.instant('Please share the invitation code so the other person can move into your home', {
          name: member.firstName,
        }),
        title: $translate.instant('Title: Share invitation code'),
      });
    })
    .catch(function(err) {
      console.log('Wg-code not shared.', err);
    })
    .finally(function() {
      $rootScope.$broadcast('track-event', {
        category: 'WG',
        action: 'Shared WG Code',
        label: $translate.instant('WG__SHARE_WG_CODE-SUBJ'),
      });
    });
  }

  function cancelInvitation(member, creatingWg) {

    yptNotify.confirm({
      title: $translate.instant(`Title: Delete invitation`),
      message: $translate.instant(`Do you really want to remove that person?`, {
        name: member.firstName,
      }),
      buttonLabels: [
        $translate.instant('BTN_CANCEL'),
        $translate.instant(`Button Label: Delete invitation`),
      ],
      callback: function(answer) {
        if (answer !== true) {
          return;
        }
        return removeInvitation()
          .finally(function() {
            if (!creatingWg) {
              return;
            }
            $rootScope.$broadcast('track-event', {
              category: 'Create WG',
              action: 'Cancelled Invitation',
              label: $translate.instant('WG__INTERACT_OPTIONS-DELETE_INVITE'),
            });
          });
      },
    });

    function removeInvitation() {
      return Wg.cancelInvitation(member)
        .success(function(data) {
          yptNotify.alert({
            title: $translate.instant(`Title: Delete invitation`),
            message: $translate.instant(`The invitation was succesfully revoked`, {
              name: member.firstName,
            }),
          });
        })
        .error(function(data) {
          yptNotify.alert({
            title: $translate.instant(`Title: Delete invitation`),
            message: $translate.instant(`There was an issue. The person's invitation wasn't revoked.`, {
              name: member.firstName,
              error: JSON.stringify(data.error) ||
                      $translate.instant('NO_INTERNET'),
            }),
          });
        });
    }
  }

  function finishInviteMembers() {
    Wg.setIsSettingUp(false)
    return $state.go('intro-notifications')
  }
}
