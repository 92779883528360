angular.module('flatasticApp')

.directive('openLink', ['yptCordova', function (yptCordova) {
  return {
    restrict: 'A',
    link: function (scope, iElement, iAttrs) {
      iElement.bind('click', function(e) {
        e.preventDefault();
        var isInApp = iAttrs.hasOwnProperty('openLinkInApp');
        var options = {
          location: 'no',
        };
        yptCordova.openLink(iAttrs.openLink, isInApp, options);
      });
    },
  };
}]);
