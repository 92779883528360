import 'angular'
import 'angular-animate'
import 'angular-sanitize'
import '@uirouter/angularjs'
import 'ionic-bower/js/ionic.js'
import 'ionic-bower/js/ionic-angular.js'
import 'ionic-bower/css/ionic.min.css'

// External libraries
import 'angular-moment'
import 'angular-translate'
import './styles/main.scss'

import flatasticAppModule from './scripts/app';

// modules folder: files need to be refactored to be in seprate modules.

// expenses
import filterExpensesProposals from './modules/expenses/filter_expensesproposals';
import filterStatistics from './modules/expenses/filter_statistics';

// general
import directiveAdaptiveTextarea from './modules/general/directive_adaptive-textarea';
import directiveBackgroundImage from './modules/general/directive_background-image';
import directiveFileModel from './modules/general/directive_file-model';
import directiveFocusOnSubmit from './modules/general/directive_focus-on-submit';
import directiveGeneral from './modules/general/directive_general';
import directiveKeepRatio from './modules/general/directive_keepratio';
import directiveMenu from './modules/general/directive_menu';
import directiveOnReturn from './modules/general/directive_on-return';
import directiveOpenLink from './modules/general/directive_open-link';
import directiveSelectOnFocus from './modules/general/directive_select-on-focus';
import directiveShrinkBanner from './modules/general/directive_shrink-banner';
import filterEnumeration from './modules/general/filter_enumeration';
import filterNewlineToBr from './modules/general/filter_newline-to-br';
import filterRange from './modules/general/filter_range';
import filterUrl from './modules/general/filter_url';
import filterWgCurrency from './modules/general/filter_wg-currency';

// shoppinglist
import svcShoppingListProposals from './modules/shoppinglist/svc_shoppinglistproposals';
import filterShoppingList from './modules/shoppinglist/filter_shoppinglist';

// scripts folder: files need to be refactored to be in seprate modules.
import helpers from './scripts/helpers';
import translations from './scripts/translations';

angular.bootstrap(document, [flatasticAppModule]);
