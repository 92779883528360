import { mapObjIndexed } from 'ramda'

const meta = ['en', 'de']
const trans = {
  [`Step 2 of 3`]: [
    `Step 2 of 3`,
    `Schritt 2 von 3`,
  ],
  [`Add a picture of your home`]: [
    `Add a picture of "{{ householdName }}"`,
    `Wähle ein Bild für «{{ householdName }}»`,
  ],
  [`Make sure the others feel right at home when you invite them.`]: [
    `Make sure the others feel right at home when you invite them.`,
    `So fühlen sich deine Mitbewohner gleich zuhause.`,
  ],
  [`Add Photo`]: [
    `Add Photo`,
    `Bild auswählen`,
  ],
  [`Skip`]: [
    `Skip`,
    `Überspringen`,
  ],
  [`Continue`]: [
    `Continue`,
    `Weiter`,
  ],
}

export default {
  en: mapObjIndexed((val, key, obj) => val[0])(trans),
  de: mapObjIndexed((val, key, obj) => val[1])(trans),
}
