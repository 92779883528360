export default angular
  .module('flatastic.wg.flatmate', [])
  .factory('Flatmate', function () {

    function Flatmate(initObj) {
      var self = this;
      for (var key in initObj) {
        self[key] = initObj[key];
      }
    }
    return Flatmate;
  })
  .name;
