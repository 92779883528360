angular.module('flatasticApp')

.directive('onReturn', [function() {
  return function(scope, elem, attr) {
    elem.bind('keydown keypress', function(event) {
      if (event.which === 13 || event.which === 9) {
        scope.$emit(attr.onReturn);
        return false;
      }
    });
  };
}]);
