import yepptActionSheet from '../../scripts/modules/yptActionsheet'
import yepptCordova from '../../scripts/modules/yptCordova'
import yepptSocialSharing from '../../scripts/modules/yptSocialsharing'
import flatasticUserService from '../user/svc_user'
import flatasticWgService from './svc_wg'

export default angular.module('flatastic.wg.controller.flatmates', [
  flatasticUserService,
  flatasticWgService,
  yepptActionSheet,
  yepptCordova,
  yepptSocialSharing,
  'ionic',
])

.controller('InviteFlatmatesCtrl',
    ['$scope', 'Wg', 'yptNotify', '$ionicLoading', 'yptSocialsharing',
    'yptActionsheet', '$filter', '$rootScope', 'User',
    function ($scope, Wg, yptNotify, $ionicLoading, yptSocialsharing,
    yptActionsheet, $filter, $rootScope, User) {

  $scope.flatmates = Wg.flatmates;

  $scope.kickout = function(flatmate) {
    // You cannot kick out yourself
    if (flatmate.id === User.properties.id) {
      return;
    }
    // You cannot kick out ghosts
    if (flatmate.groupId != 2) {
      return;
    }

    yptNotify.prompt({
      title: $filter('translate')('WG__KICK_OUT_CONFIRM-TITLE', {
        name: flatmate.firstName,
      }),
      message: $filter('translate')('WG__KICK_OUT_CONFIRM-MESSAGE', {
        name: flatmate.firstName,
      }),
      buttonLabels: [
        $filter('translate')('BTN_CANCEL'),
        $filter('translate')('WG__KICK_OUT_CONFIRM-BUTTON'),
      ],
    })
    .success(function(input) {
      // user must type the flatmates name
      if (input.toLowerCase() !== flatmate.firstName.toLowerCase()) {
        yptNotify.alert({
          title: $filter('translate')('WG__KICK_OUT__TYPE_ERROR-TITLE'),
          message: $filter('translate')('WG__KICK_OUT__TYPE_ERROR-MESSAGE'),
        });
        return;
      }
      Wg.checkMoveOut()
      .then(function(response) {
        if (!angular.isArray(response.data.settlement)) {
          throw {
            status: 1,
            message: 'data.settlement is missing in the response.',
          };
        }
        if (response.data.settlement.length > 0) {
          throw {
            status: 2,
            message: 'You need to settle the expenses first',
          };
        }
        $ionicLoading.show({
          template: $filter('translate')('WG__KICK_OUT-LOADING', {
            name: flatmate.firstName,
          }),
        });
        return Wg.kickOut(flatmate);
      })
      .catch(function(err) {
        if (err.status === 1) {
          yptNotify.alert({
            title: $filter('translate')('WG__KICK_OUT_CONFIRM-TITLE', {
              name: flatmate.firstName,
            }),
            message: $filter('translate')('WG__KICK_OUT_ERROR-MESSAGE'),
          });
          return;
        }
        if (err.status === 2) {
          yptNotify.alert({
            title: $filter('translate')('WG__KICK_OUT_CONFIRM-TITLE', {
              name: flatmate.firstName,
            }),
            message: $filter('translate')('WG__KICK_OUT_SETTLEMENT_NEEDED'),
          });
          return;
        }
        yptNotify.alert({
          title: 'Oh oh…',
          message: err.data.error,
        });
      })
      .finally(function(data) {
        $ionicLoading.hide();
      });

    });
  }

  $scope.interact = function(flatmate, creatingWg) {
    // Flatmate is already in WG
    if (!flatmate.wgCode) {
      var options = {
        title: $filter('translate')('WG__INTERACT_TITLE', {
          name: flatmate.firstName,
        }),
        addDestructiveButtonWithLabel:
            $filter('translate')('WG__INTERACT_OPTIONS-KICKOUT'),
        buttonLabels: [],
        androidEnableCancelButton: false,
        addCancelButtonWithLabel: $filter('translate')('BTN_CANCEL'),
      }
      var actions = [
        function() {
          $scope.kickout(flatmate)
        },
      ];
      yptActionsheet.show(options, actions);
      return;
    }

    // Flatmate has not moved in yet
    var options = {
      'title': $filter('translate')('WG__INTERACT_OPTIONS-TITLE'),
      'addDestructiveButtonWithLabel': $filter('translate')('WG__INTERACT_OPTIONS-DELETE_INVITE'),
      'buttonLabels': [
        $filter('translate')('WG__INTERACT_OPTIONS-SEND_WG_CODE'),
      ],
      'androidEnableCancelButton': false,
      'addCancelButtonWithLabel': $filter('translate')('BTN_CANCEL'),
    };

    var shareCode = function() {
      $scope.shareCode(flatmate);
    };

    var cancelInvitation = function() {
      $scope.cancelInvitation(flatmate);
    };

    var actions = [
      cancelInvitation,
      shareCode,
    ];

    yptActionsheet.show(options, actions);
  };

  $scope.shareCode = function(flatmate) {
    yptSocialsharing.share({
      message: $filter('translate')('WG__SHARE_WG_CODE-MSG', {
        name: flatmate.firstName,
        wgCode: flatmate.wgCode,
      }),
      subject: $filter('translate')('WG__SHARE_WG_CODE-SUBJ'),
      trackEvent: {
        name: 'Share',
        parameters: {
          context: 'sharecode',
          content: $filter('translate')('SET__SHARE-TITLE'),
        },
      },
    })
    .success(function(res) {
      if (res) {
        yptNotify.alert({
          message: $filter('translate')('WG__SHARE_WG_CODE_SUCCESS-MSG', {
            name: flatmate.firstName,
          }),
          title: $filter('translate')('WG__SHARE_WG_CODE_SUCCESS-TITLE'),
        });
      }
    })
    .error(function(err) {
      console.log('Wg-code not shared.', err);
    })
    .finally(function() {
      $rootScope.$broadcast('track-event', {
        category: 'WG',
        action: 'Shared WG Code',
        label: $filter('translate')('WG__SHARE_WG_CODE-SUBJ'),
      });
    });
  };

  $scope.firstName = '';
  $scope.addFlatmate = function() {
    console.log('Adding Flatmate');
    $ionicLoading.show({
      template: $filter('translate')('WG__INVITE_FLATMATE-LOADING', {
        name: $scope.firstName,
      }),
    });
    Wg.inviteFlatmate({ firstName: $scope.firstName })
    .success(function(data) {
      $rootScope.$broadcast('track-event', {
        category: 'WG',
        action: 'Invited Flatmate',
        label: 'Success',
      });
      $scope.firstName = null;
      yptNotify.alert({
        title: $filter('translate')('WG__INVITE_FLATMATE_SUCCESS-TITLE'),
        message: $filter('translate')('WG__INVITE_FLATMATE_SUCCESS-MSG', {
          name: data.newFlatmate.firstName,
          wgCode: data.wgCode,
        }),
        buttonLabel: $filter('translate')('WG__INVITE_FLATMATE_SUCCESS-SHARE_CODE'),
        callback: function() {
          $scope.shareCode({
            wgCode: data.wgCode,
            firstName: data.newFlatmate.firstName,
          });
        },
      });
    })
    .error(function(data) {
      $rootScope.$broadcast('track-event', {
        category: 'WG',
        action: 'Invited Flatmate',
        label: 'Error',
      });
      yptNotify.alert({
        message: $filter('translate')('WG__INVITE_FLATMATE_ERROR-MSG'),
        title: $filter('translate')('WG__INVITE_FLATMATE_ERROR-TITLE'),
      });
      return;
    })
    .finally(function() {
      $ionicLoading.hide();
    });
  };

  $scope.inviteNewFlatmate = function() {
    yptNotify.prompt({
      message: $filter('translate')('WG__INVITE_FLATMATE-MSG'),
      title: $filter('translate')('WG__INVITE_FLATMATE-TITLE'),
      buttonLabels: [$filter('translate')('BTN_CANCEL'), $filter('translate')('ADD')],
    })
    .success(function (firstName) {
      if (!firstName) {
        yptNotify.alert({
          message: $filter('translate')('WG__INVITE_FLATMATE_NO_NAME-MSG'),
          title: $filter('translate')('WG__INVITE_FLATMATE_NO_NAME-TITLE'),
        });
        return;
      }
      $ionicLoading.show({
        template: $filter('translate')('WG__INVITE_FLATMATE-LOADING', {
          name: firstName,
        }),
      });
      Wg.inviteFlatmate({ firstName: firstName })
      .success(function(data) {
        $rootScope.$broadcast('track-event', {
          category: 'WG',
          action: 'Invited Flatmate',
          label: 'Success',
        });
        yptNotify.alert({
          title: $filter('translate')('WG__INVITE_FLATMATE_SUCCESS-TITLE'),
          message: $filter('translate')('WG__INVITE_FLATMATE_SUCCESS-MSG', {
            name: data.newFlatmate.firstName,
            wgCode: data.wgCode,
          }),
          buttonLabel: $filter('translate')('WG__INVITE_FLATMATE_SUCCESS-SHARE_CODE'),
          callback: function() {
            $scope.shareCode({
              wgCode: data.wgCode,
              firstName: data.newFlatmate.firstName,
            });
          },
        });
      })
      .error(function(data) {
        $rootScope.$broadcast('track-event', {
          category: 'WG',
          action: 'Invited Flatmate',
          label: 'Error',
        });
        yptNotify.alert({
          message: $filter('translate')('WG__INVITE_FLATMATE_ERROR-MSG'),
          title: $filter('translate')('WG__INVITE_FLATMATE_ERROR-TITLE'),
        });
        return;
      })
      .finally(function() {
        $ionicLoading.hide();
      });
    });
  };

  $scope.cancelInvitation = function(flatmate, creatingWg) {

    var confirmDialog = {
      title: $filter('translate')('WG__CANCEL_INVITATION_CONFIRM-TITLE'),
      message: $filter('translate')(creatingWg
          ? 'WG__SETUP__REMOVE_FLATMATE_CONFIRM-MSG'
          : 'WG__CANCEL_INVITATION_CONFIRM-MSG', {
        name: flatmate.firstName,
      }),
    };
    var alertDialog = {
      success: {
        title: $filter('translate')('WG__CANCEL_INVITATION_SUCCESS-TITLE'),
        message: $filter('translate')('WG__CANCEL_INVITATION_SUCCESS-MSG', {
          name: flatmate.firstName,
        }),
      },
      error: function(data) {
        return {
          title: $filter('translate')('WG__CANCEL_INVITATION_ERROR-TITLE'),
          message: $filter('translate')('WG__CANCEL_INVITATION_ERROR-TITLE', {
            name: flatmate.firstName,
            error: JSON.stringify(data.error) ||
                    $filter('translate')('NO_INTERNET'),
          }),
        };
      },
    };

    yptNotify.confirm({
      title: confirmDialog.title,
      message: confirmDialog.message,
      buttonLabels: [
        $filter('translate')('BTN_CANCEL'),
        $filter('translate')('WG__CANCEL_INVITATION_CONFIRM-BTN'),
      ],
      callback: function(answer) {
        if (answer !== true) {
          return;
        }
        removeInvitation().finally(function() {
          if (!creatingWg) {
            return;
          }
          $rootScope.$broadcast('track-event', {
            category: 'Create WG',
            action: 'Cancelled Invitation',
            label: $filter('translate')('WG__INTERACT_OPTIONS-DELETE_INVITE'),
          });
        });
      },
    });

    function removeInvitation() {
      return Wg.cancelInvitation(flatmate)
      .success(function(data) {
        yptNotify.alert({
          title: alertDialog.success.title,
          message: alertDialog.success.message,
        });
      })
      .error(function(data) {
        yptNotify.alert({
          title: alertDialog.error(data).title,
          message: alertDialog.error(data).message,
        });
      });
    }
  };

}])

.name;
