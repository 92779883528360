angular.module('flatasticApp')

.directive('focusOn',
    ['$timeout', 'yptKeyboard', function($timeout, yptKeyboard) {
  return function(scope, elem, attr) {
    scope.$on(attr.focusOn, function(e, selectContent) {
      $timeout(function() {
        elem[0].focus();
        if (selectContent) {
          elem[0].select();
        }
        yptKeyboard.show();
      }, 150);
    });

    if (attr.focusAfter) {
      $timeout(function() {
        scope.$broadcast(attr.focusOn);
      }, attr.focusAfter);
    }
  };
}]);
