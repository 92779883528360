import { is, map, when } from 'ramda'
import { decodeHtmlEntities } from '../../utils/helpers'

export default angular.module('yeppt.actionsheet', [
  'ionic',
])

.service('yptActionsheet',
    ['$ionicActionSheet',
    function($ionicActionSheet) {

  return {
    init,
    show,
  };

  //// Functions

  function init() {
    if (!window.plugins || !window.plugins.actionsheet) {
      alert('The Action Sheet Plugin is not installed.');
      return;
    }
  };

  /*
   * If obj.addDestructiveButtonWithLabel is defined,
   * the first action must be the destructive action
   */
  function show(obj, actions, cancelAction) {

    // Use Ionic's action sheet if native plugin is not installed
    if (!window.plugins || !window.plugins.actionsheet) {
      var options = {};
      if (obj.title) {
        options.titleText = obj.title;
      }
      obj.buttonLabels = obj.buttonLabels || [];
      options.buttons = obj.buttonLabels.map(function(buttonLabel) {
        return { text: buttonLabel };
      });
      if (obj.addDestructiveButtonWithLabel) {
        options.destructiveText = obj.addDestructiveButtonWithLabel;
        // The first action is always the destructive one
        var destructiveFunction = actions.shift();
        options.destructiveButtonClicked = function() {
          destructiveFunction();
          return true;
        };
      }
      if (obj.addCancelButtonWithLabel) {
        options.cancelText = obj.addCancelButtonWithLabel;
        options.cancel = cancelAction;
      }
      options.buttonClicked = function(index) {
        actions[index]();
        // Hide Actionsheet after click
        return true;
      };
      $ionicActionSheet.show(options);
      return;
    }

    var actionSheet = window.plugins.actionsheet;

    // Make sure default theme for android is set
    obj.androidTheme = obj.androidTheme ||
        window.plugins.actionsheet.ANDROID_THEMES.THEME_DEVICE_DEFAULT_LIGHT;

    var totalLabelLength = obj.buttonLabels.length
        + (obj.addDestructiveButtonWithLabel ? 1 : 0);
    if (actions.length !== totalLabelLength) {
      console.error('There must be as many actions as labels.');
      return false;
    }
    obj = map(when(is(String), decodeHtmlEntities))(obj)
    actionSheet.show(obj, callback);

    function callback(res) {
      var labels = obj.buttonLabels.concat('Cancel');
      if (obj.addDestructiveButtonWithLabel) {
        labels.unshift(obj.addDestructiveButtonWithLabel);
      }
      // destructive action will always be the top most action
      // cancel is always highgest
      if (!cancelAction) {
        cancelAction = function(res) {
          console.log('ActionSheet Cancel:', res);
        };
      }
      return actions.concat(cancelAction)[res-1](res);
    }
  };

}])

.name;
