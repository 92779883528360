import CordovaService from '../../scripts/modules/yptCordova'
import KeyboardService from '../../scripts/modules/yptKeyboard'
import WgService from '../wg/svc_wg'
// templates
import subscriptionNewView from './modal_subscription-new.html'
import SubscriptionService from './svc_subscription'
import SubscriptionsService from './svc_subscriptions'


export default angular.module('flatastic.subscriptions.controller', [
  SubscriptionsService,
  'ionic',
  WgService,
  CordovaService,
  SubscriptionService,
  KeyboardService,
])

.controller('SubscriptionsCtrl',
    ['$scope', 'Subscriptions', '$ionicModal', '$ionicScrollDelegate',
    '$timeout', 'Wg', 'yptNotify', '$filter', '$rootScope',
    'Subscription', '$ionicHistory', 'yptKeyboard',
    function ($scope, Subscriptions, $ionicModal, $ionicScrollDelegate,
    $timeout, Wg, yptNotify, $filter, $rootScope,
    Subscription, $ionicHistory, yptKeyboard) {

  Subscriptions.getLocalProposals();

  var subscriptions = Subscriptions.subscriptions;
  var proposals = Subscriptions.proposals;
  $scope.subscriptions = subscriptions;
  $scope.proposals = proposals;
  $scope.monthlySum = Subscriptions.monthlySum;
  $scope.hasBackView = $ionicHistory.backView;

  $scope.wg = Wg;

  $scope.showHelp = function() {
    yptNotify.alert({
      title: $filter('translate')('SUB__HELP-TITLE'),
      message: $filter('translate')('SUB__HELP-MSG'),
      trackEvent: {
        name: 'showHelp',
        parameters: {
          context: 'subscriptions',
        },
      },
    });
    $rootScope.$broadcast('track-event', {
      category: 'Help',
      action: 'Clicked',
      label: '/subscriptions',
    });
  };

  $scope.removeSubscription = function(subscription) {
    if (!subscription[subscriptions.uniqueKey]) {
      return false;
    }
    subscription.delete().then(function() {
      subscriptions.remove(subscription.id);
    });
  };

  $scope.refresh = function() {
    Subscriptions.getFromServer()
      .finally(function() {
        $scope.$broadcast('scroll.refreshComplete');
      });
  };

  $scope.addSubscription = function() {
    var cycleDict = {
      monthly: 'TIME__MONTHLY',
      yearly: 'TIME__EVERY_YEAR',
      biannual: 'TIME__EVERY_HALF_YEAR',
      quarterly: 'TIME__QUARTERLY',
    };
    var cycle = $scope.newSubscription.cycle;
    $scope.newSubscription.cycle = cycleDict[cycle] ? cycleDict[cycle] : cycle;
    if ($scope.newSubscription && $scope.newSubscription.id) {
      var dataToUpdate = {};
      for (var key in $scope.newSubscription) {
        var newValue = JSON.stringify($scope.newSubscription[key]);
        var oldValue = JSON.stringify(subscriptionCopy[key]);
        if (newValue !== oldValue) {
          dataToUpdate[key] = $scope.newSubscription[key];
        }
      }
      $scope.newSubscription.update(dataToUpdate);
    } else {
      var id = subscriptions.push($scope.newSubscription, true);
      subscriptions.get(id).save();
    }
    $scope.newSubscription = {
      firstBill: new Date(),
    };
    $scope.modal.hide();
  };

  $scope.proposalNotAddedYet = function(item) {
    return subscriptions.accumulate('title').indexOf(item.title) === -1;
  };


  /*
   * Modal
   */

  $scope.modal = $ionicModal.fromTemplate(subscriptionNewView, {
    scope: $scope,
    animation: 'fade-in-scale',
  });

  var subscriptionCopy;
  $scope.openModal = function(subscription) {
    yptKeyboard.hideKeyboardAccessoryBar(false);
    if (subscription) {
      subscriptionCopy = angular.copy(subscription);
      $scope.newSubscription = subscription;
    } else {
      subscriptionCopy = null;
      $scope.newSubscription = new Subscription({
        firstBill: new Date(),
        cycle: 'TIME__MONTHLY',
      });
    }
    $scope.modal.show();
  };
  $scope.closeModal = function() {
    yptKeyboard.hideKeyboardAccessoryBar(true);
    // Undo changes when cancelling
    if (subscriptionCopy) {
      for (var key in $scope.newSubscription) {
        var newValue = JSON.stringify($scope.newSubscription[key]);
        var oldValue = JSON.stringify(subscriptionCopy[key]);
        if (oldValue !== newValue) {
          $scope.newSubscription[key] = subscriptionCopy[key];
        }
      }
    }
    $timeout(function() {
      $scope.newSubscription = {
        firstBill: new Date(),
      };
    }, 350);
    $scope.modal.hide();
  };

  //Cleanup the modal when we're done with it!
  $scope.$on('$destroy', function() {
    $scope.modal.remove();
  });

  // Execute action on hide modal
  $scope.$on('modal.hidden', function() {
    $ionicScrollDelegate.resize();
  });

  // Execute action on remove modal
  $scope.$on('modal.removed', function() {
    // Execute action
  });


}])

.name;
