export default angular.module('flatastic.proposals.personal', [])

.factory('PersonalProposals', [function() {

  var config = {
    key: 'Flatastic.personalProposals',
  };
  var proposals = load();

  return {
    add,
    clear,
    get,
    remove,
    key: config.key,
    load,
  };

  //// Functions

  function clear() {
    delete localStorage[config.key];
    proposals = load()
  }

  function load() {
    return localStorage[config.key] ? JSON.parse(localStorage[config.key] || 'null') : {};
  }

  function save() {
    localStorage[config.key] = JSON.stringify(proposals);
  }

  function remove(name) {
    delete proposals[name];
    save();
  }

  function get(minCount) {
    return Object.keys(proposals).map(function(name) {
      return {
        name: name,
        count: proposals[name],
      }
    }).filter(function(elem) {
      return !minCount || elem.count >= minCount;
    }).sort(function(a, b) {
      return b.count - a.count;
    }).map(function(elem) {
      return elem.name;
    });
  }

  function add(name) {
    if (!name) { return; }
    proposals[name] = proposals[name] ? ++proposals[name] : 1;
    save();
  }

}])

.name;
