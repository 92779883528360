import yepptCordova from './yptCordova';

export default angular.module('mcm.facebook.connect', [
  yepptCordova,
])

.run(['yptCordova', function(yptCordova) {

  yptCordova.ready.then(function() {

    if (window.facebookConnectPlugin) {
      facebookConnectPlugin.logEvent('fb_mobile_activate_app');
    } else {
      console.warn('facebookConnectPlugin not installed. App installs will not be tracked.');
    }

  });

}])

.name;
