import { mapObjIndexed } from 'ramda'

const meta = ['en', 'de']
const trans = {
  [`Share access code`]: [
    `Share access code`,
    `Zugangscode teilen`,
  ],
  [`Title: That's you`]: [
    `{{ name }}`,
    `{{ name }}`,
  ],
  [`You have already moved in`]: [
    `You are "{{ name }}" and you have already moved in. Invite the others!`,
    `Du bist «{{ name }}» und bereits eingezogen. Lade die anderen ein!`,
  ],
}

export default {
  en: mapObjIndexed((val, key, obj) => val[0])(trans),
  de: mapObjIndexed((val, key, obj) => val[1])(trans),
}
