import FeedbackController from './ctrl_feedback'
import template from './view_feedback.html'

export default angular
.module('flatastic.feedback', [
  FeedbackController,
])
.config(
    ['$stateProvider',
    function ($stateProvider) {

  $stateProvider
  .state('wg.settings-feedback', {
    url: '/feedback',
    views: {
      'menuContent': {
        controller: 'FeedbackCtrl',
        template,
      },
    },
  })

}])

.name;
