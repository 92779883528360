import flatasticConfig from '../../scripts/config'
import yepptApi from '../../scripts/modules/api'
import yepptActionsheet from '../../scripts/modules/yptActionsheet'
import yepptCamera from '../../scripts/modules/yptCamera'
import yepptCordova from '../../scripts/modules/yptCordova'
import yepptKeyboard from '../../scripts/modules/yptKeyboard'
import { getCountryIdFromAlpha3Code } from '../../utils/helpers'
import flatasticWgService from './svc_wg'

export default angular.module('flatastic.wg.controller.setup', [
  'ionic',
  flatasticConfig,
  flatasticWgService,
  yepptActionsheet,
  yepptApi,
  yepptCamera,
  yepptCordova,
  yepptKeyboard,
])

.controller('WgSetupCtrl',
    ['$scope', '$rootScope', 'Wg', 'yptNotify',
     'yptActionsheet', 'yptKeyboard', '$state', '$ionicHistory',
     '$timeout', 'Api', 'yptCamera', '$filter', 'WgTypes', 'FlatasticEvents', 'Countries',
    function ($scope, $rootScope, Wg, yptNotify,
     yptActionsheet, yptKeyboard, $state, $ionicHistory,
     $timeout, Api, yptCamera, $filter, WgTypes, FlatasticEvents, Countries) {

  $scope.wgData    = Wg.getScaffold();
  $scope.Wg        = Wg;
  $scope.flatmates = Wg.flatmates;
  $scope.languages = Wg.getAvailableLanguages();
  $scope.availableTypes = WgTypes;

  // Don't show the predefined wg name
  $scope.wgData.name = '';
  $scope.countries = Countries;

  Wg.imageUploading = false;

  $scope.setCurrency = () => {
    const country = Countries.find(country => country.alpha3 === $scope.wgData.countryCode)
    $scope.wgData.currency = country.currency
  }
  $scope.getCountryLabel = country => `${country.flag} ${$filter('translate')(country.name)}`

  $scope.hideKeyboard = function() {
    yptKeyboard.close();
  };

  $scope.goToWgSettings = function() {
    // Enforce user to add flatamtes if she hasn't done so
    if ($scope.flatmates.length() <= 1) {
      yptNotify.confirm({
        title: $filter('translate')('WG__INVITE_FLATMATE-TITLE'),
        message: $filter('translate')('WG__INVITE_FLATMATE_NO_ONE_INVITED-MSG'),
        buttonLabels: [
          $filter('translate')('BACK'),
          $filter('translate')('WG__INVITE_FLATMATE_NO_ONE_INVITED-CONFIRM'),
        ],
        callback: function(answer) {
          if (answer) {
            $state.go('wg-setup-settings');
          }
        },
      });
      return;
    }
    $state.go('wg-setup-settings');
  };

  $scope.setupReady = function() {
    // Fixes a bug where wg type selection via the wheel wasn't considered iOS
    $timeout(finalizeSetup, 0);
  };

  // Enables image upload from webapp
  $scope.data = {};
  $scope.isCordovaDevice = (window && window.device);
  $scope.$watch('data.file', function(newValue, oldValue) {
    if (Wg.imageUploading ||
          newValue === oldValue ||
          !$scope.data.file) {
      return;
    }

    Wg.imageUploading = true;
    Api.browserFileUpload($scope.data.file, '/wg/image')
    .success(function(data) {
      Wg.set({ image: data.wg_image.full_s3_url }).storeLocally();
    })
    .error(function() {
      yptNotify.alert({
        title: $filter('translate')('WG__IMAGE_ERROR_DIALOGUE-TITLE'),
        message: $filter('translate')('WG__IMAGE_ERROR_DIALOGUE-MSG'),
      });
    })
    .finally(function() {
      delete Wg.imageUploading;
    });
  });

  $scope.takeImage = function() {
    // cancel function if
    // - it is not called by the flatastic app
    // - an image is already beeing uploaded
    if (!window || !window.device || Wg.imageUploading) { return; }

    var options = {
      title: $filter('translate')('WG__IMAGE_DIALOGUE-TITLE'),
      buttonLabels: [
        $filter('translate')('WG__IMAGE_DIALOGUE-FROM_GALLERY'),
        $filter('translate')('WG__IMAGE_DIALOGUE-TAKE_PHOTO'),
      ],
      androidEnableCancelButton: false,
      addCancelButtonWithLabel: $filter('translate')('BTN_CANCEL'),
    };
    var actions = [
      function() { getPicture(0); },
      function() { getPicture(1); },
    ];
    yptActionsheet.show(options, actions);
  };

  var getPicture = function(sourceType) {
    yptCamera.takePicture({ sourceType: sourceType, cameraDirection: 1 })
    .success(function(fileURL) {
      Wg.set({
        image: fileURL,
      });
      Wg.imageUploading = true;
      Api.upload(fileURL, '/wg/image')
      .success(function(data) {
        delete Wg.imageUploading;
        data.response = JSON.parse(data.response);
        Wg.set({
          image: data.response.wg_image.full_s3_url,
        }).storeLocally();
      })
      .error(function(data) {
        console.error('Image could not be uploaded', data);
        delete Wg.imageUploading;
        yptNotify.alert({
          title: $filter('translate')('WG__IMAGE_ERROR_DIALOGUE-TITLE'),
          message: $filter('translate')('WG__IMAGE_ERROR_DIALOGUE-MSG'),
        });
      });
    })
    .error(function(data) {
      console.log('Error:', data);
    });
  };

  //// Functions

  function finalizeSetup() {
    $scope.wgData.country = getCountryIdFromAlpha3Code($scope.wgData.countryCode);
    Wg.save($scope.wgData);
    $state.go('wg.shouts')
    .then(function() {
      Wg.setIsSettingUp(false)
      $timeout(function() {
        $ionicHistory.clearHistory();
      }, 750);
      $rootScope.$broadcast(FlatasticEvents.user.didEnterWg);
    });
  }

}])

.name;
