import { anyPass, defaultTo, isEmpty, isNil } from 'ramda'

export const getCountryIdFromAlpha3Code = alpha3Code => defaultTo(1)(({
  'DEU': 2,
  'AUT': 3,
  'CHE': 4,
})[alpha3Code])

export const moduleAbbreviationToScreenName = moduleFromData => (({
  'wg': 'wg.settings-wg',
  'cho': 'wg.chores',
  'exp': 'wg.expenses',
  'fdb': 'wg.settings-feedback',
  'usr': 'wg.user',
  'shl': 'wg.shoppinglist',
  'bb': 'wg.shouts',
  'iap': 'wg.purchase',
  'sbs': 'wg.subscriptions',
})[moduleFromData])

export function wait(durationInMs) {
  return new Promise(resolve => setTimeout(resolve, durationInMs))
}

export const isNilOrEmpty = anyPass([isNil, isEmpty])

export const waitForCondition = (fn, conditionFn, retries = 5, startWaitTimeInMs = 10) => {
  return fn().then(res => {
    if (conditionFn(res)) {
      return res
    }
    if (retries === 0) {
      return Promise.reject(null)
    }
    return wait(startWaitTimeInMs).then(
      () => waitForCondition(fn, conditionFn, retries - 1, startWaitTimeInMs * 2)
    )
  })
}

// Taken from: https://stackoverflow.com/a/44195856
export function decodeHtmlEntities(encodedString) {
    var translate_re = /&(nbsp|amp|quot|lt|gt);/g;
    var translate = {
        nbsp: ' ',
        amp: '&',
        quot: '"',
        lt: '<',
        gt: '>',
    };
    return encodedString.replace(translate_re, function(match, entity) {
        return translate[entity];
    }).replace(/&#(\d+);/gi, function(match, numStr) {
        var num = parseInt(numStr, 10);
        return String.fromCharCode(num);
    });
}
