import flatasticShoppinglist from '../shoppinglist/cfg_shoppinglist';
import shoppinglistProposalsService from '../shoppinglist/svc_shoppinglistproposals';

export default angular.module('flatastic.expenses.proposals', [
  flatasticShoppinglist,
  shoppinglistProposalsService,
])

.factory('ExpensesProposals', ['ShoppinglistProposals',
    function (ShoppinglistProposals) {

  return ShoppinglistProposals;
}])

.name;
