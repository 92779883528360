import yepptApi from '../../scripts/modules/api'
import flatasticWgService from '../wg/svc_wg'
import flatasticPersonalProposalsService from '../shoppinglist/svc_personalproposals'

export default angular.module('flatastic.shoppinglist.proposals', [
  flatasticPersonalProposalsService,
  flatasticWgService,
  yepptApi,
])

.factory('ShoppinglistProposals',
    ['$http', 'Api', 'Wg', '$window', 'PersonalProposals',
    function ($http, Api, Wg, $window, PersonalProposals) {

  var config = {
    storageKey: 'Flatastic.ShoppinglistProposals',
    updateInterval: 12 * 60 * 60, // refresh list every 12hrs
  };
  var proposals = [];
  var countryToLanguage = {
    1: 'en_UK', // 'en_UK',
    2: 'de_DE', // 'de_DE',
    3: 'de_DE', // 'de_AT',
    4: 'de_DE', // 'de_CH',
  };

  var language = countryToLanguage[Wg.country];

  init();

  return {
    get: getProposals,
  };

  //////

  function init() {
    var updateNeeded = false;
    if ($window.localStorage[config.storageKey]) {
      var localProposals = JSON.parse($window.localStorage[config.storageKey]);
      var now = (new Date()).getTime() / 1000;
      var lastUpdate = new Date(localProposals.lastUpdate).getTime() / 1000;
      proposals = localProposals.values;
      updateNeeded = (lastUpdate + config.updateInterval < now)
                        || (localProposals.country !== Wg.country);
    } else {
      getLocalProposals();
      updateNeeded = true;
    }

    // update proposals if needed
    if (updateNeeded) {
      getProposalsFromServer();
    }
  }

  function getProposals() {
    var minCount = 3;
    var output = [].concat(PersonalProposals.get(minCount)).concat(proposals);
    return output.filter(function(elem, index, self) {
      return self.indexOf(elem) === index;
    });
  }

  function getLocalProposals() {
    return $http.get('data/shoppinglist_proposals.json')
    .success(function(data) {
      proposals = data[language];
    });
  }

  function getProposalsFromServer() {
    return Api.get('/shoppinglist/proposals')
    .success(function(data) {
      if (!data || !angular.isArray(data)) {
        return;
      }
      proposals = data;
      $window.localStorage[config.storageKey] = JSON.stringify({
        lastUpdate: new Date(),
        country: Wg.country,
        values: data,
      });
    });
  }

}])

.name;
