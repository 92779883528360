import yepptCollection from '../../scripts/modules/collection';
import yepptApi from '../../scripts/modules/api';

export default angular.module('flatastic.expenses.service.expense.item', [
  yepptCollection,
  yepptApi,
])

.factory('ExpenseItem', [function () {

  function ExpenseItem(initObj) {
    this.set(initObj);
    return this;
  }

  ExpenseItem.prototype.set = function(initObj) {
    var self = this;
    for (var key in initObj) {
      self[key] = initObj[key];
    }
    if (Array.isArray(self.sharers)) {
      this.sharers = this.sharers.map(function(userId) {
        return parseInt(userId, 10);
      });
    }
  };

  // Returns how much the user with id <userId> gets or has to pay.
  ExpenseItem.prototype.shareOf = function(userId) {
    var self = this;
    var output;
    // user is sharer of item
    if (self.sharers.indexOf(userId) !== -1) {
      var numSharers = self.sharers.length
      output = self.price /  numSharers * (numSharers - 1);
    } else {
      output = self.price;
    }
    self.sum = output;
    return output;
  };

  ExpenseItem.scaffold = function(userId) {
   return {
      name: '',
      price: null,
      quantity: 1,
      sharers: [],
      creatorId: userId,
    };
  };

  return ExpenseItem;
}])

.name;
