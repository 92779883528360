import flatasticExpensesProposals from './svc_expensesproposals';
import flatasticExpensesService from './svc_expenses';
import flatasticExpensesServiceExpense from './svc_expense';
import flatasticExpensesServiceExpenseItem from './svc_expense-item';
import flatasticShoppinglist from '../shoppinglist/cfg_shoppinglist';
import yepptActionsheet from '../../scripts/modules/yptActionsheet';
import yepptCordova from '../../scripts/modules/yptCordova';

//templates
import expensesNewModalView from './view_expenses-new_modal.html';

export default angular.module('flatastic.expenses.controller.new', [
  'ionic',
  flatasticExpensesProposals,
  flatasticExpensesService,
  flatasticExpensesServiceExpense,
  flatasticExpensesServiceExpenseItem,
  flatasticShoppinglist,
  yepptActionsheet,
  yepptCordova,
])

.controller('ExpensesNewCtrl',
    ['$scope', 'yptNotify', 'Expenses', 'Expense', 'ExpenseItem',
    '$ionicScrollDelegate', 'yptActionsheet', 'yptDatepicker',
    'User', '$stateParams', '$timeout', 'Wg', '$filter',
    'ExpensesProposals', 'Shoppinglist', '$rootScope', '$ionicModal',
    function ($scope, yptNotify, Expenses, Expense, ExpenseItem,
    $ionicScrollDelegate, yptActionsheet, yptDatepicker, User,
    $stateParams, $timeout, Wg, $filter,
    ExpensesProposals, Shoppinglist, $rootScope, $ionicModal) {

  $scope.display = {
    flatmateSelection: false,
    proposals: false,
    overlay: false,
  };

  $scope.showHelp = function() {
    yptNotify.alert({
      title: $filter('translate')('EXP__NEW_HELP-TITLE'),
      message: $filter('translate')('EXP__NEW_HELP-MSG'),
      trackEvent: {
        name: 'showHelp',
        parameters: {
          context: 'expenses__new',
        },
      },
    });
    $rootScope.$broadcast('track-event', {
      category: 'Help',
      action: 'Clicked',
      label: '/expenses-new',
    });
  };


  // overwrite ionic's back button functionality
  var oldSoftBack = $rootScope.$ionicGoBack;
  var doCustomBack = function() {
    if ($scope.expense.items.get().length > 0) {
      yptNotify.confirm({
        title: $filter('translate')('EXP__NEW_CONFIRM_GO_BACK-TITLE'),
        message: $filter('translate')('EXP__NEW_CONFIRM_GO_BACK-MSG'),
        buttonLabels: [
          $filter('translate')('DISCARD'),
          $filter('translate')('SAVE'),
        ],
        callback: function(answer) {
          if (answer === true) {
            $scope.addExpense();
          } else {
            oldSoftBack();
          }
        },
      });
    } else {
      oldSoftBack();
    }
  };

  $rootScope.$ionicGoBack = function() {
    doCustomBack();
  };

  var deregisterSoftBack = function() {
      $rootScope.$ionicGoBack = oldSoftBack;
  };
  // cancel custom back behaviour
  $scope.$on('$destroy', function() {
      deregisterSoftBack();
  });

  // userId should be the UserId of the logged in user
  var userId = parseInt(User.properties.id, 10);
  $scope.flatmates = Wg.flatmates;
  $scope.tracker = {
    lastInput: false,
  };
  // check for new vs. edit
  if ($stateParams.id && Expenses.get($stateParams.id)) {
    console.log('Editing Task…');
    $scope.editMode = true;
    $scope.expense = Expenses.get($stateParams.id);
  } else {
    $scope.expense = new Expense({
      creatorId: userId,
      date: new Date(),
      name: $filter('translate')('EXP__NEW_DEFAULT_EVENT'),
      local: true,
    });
  }
  $scope.itemData = ExpenseItem.scaffold(userId);
  // $scope.expense.date = $filter('date')($scope.expense.date, 'yyyy-MM-dd');

  /*
   * Create proposals taking bought items from the shopping list
   * and popular products
   */
  $scope.getProposals = function() {
    var proposals = ExpensesProposals.get();
    if ($scope.editMode) {
      return proposals;
    }

    // add bought items on the shopping list to proposals and ensure uniquness
    var boughtProducts = Shoppinglist.getBoughtItems()
      .map(function(product) {
        return product.name;
      })
      .filter(function(boughtProduct, index, self) {
        return self.indexOf(boughtProduct) === index;
      });
    // remove bought products from proposals(no duplicated entries)
    proposals = proposals.filter(function(proposal) {
      return boughtProducts.indexOf(proposal) === -1;
    });
    // create output, bought products have a higher prioritiy
    return boughtProducts.concat(proposals);
  };
  $scope.addProposal = function(proposal) {
    if (angular.isObject(proposal)) {
      $scope.itemData.name = proposal.name;
      $scope.itemData.price = proposal.price;
    } else {
      $scope.itemData.name = proposal;
    }
    $scope.$broadcast('expenses:productPrice', true);
    $scope.display.proposals = false;
    // $scope.display.flatmateSelection = true;
  };

  /*
   * Add an item
   */
  $scope.$on('expenses:maybeAddItem', function() {
    $timeout(function() {
      $scope.addItem();
    }, 0);
  });
  $scope.addItem = function() {
    // check if item has a name
    if (!$scope.itemData.name) {
      $scope.$broadcast('expenses:productName');
      return;
    }
    // Check if anyone is selected
    if ($scope.itemData.sharers.length < 1) {
      yptNotify.alert({
        title: $filter('translate')('EXP__NEW_NO_SELECTED_FM-TITLE'),
        message: $filter('translate')('EXP__NEW_NO_SELECTED_FM-MSG', {
          name: $scope.itemData.name,
        }),
      });
      return;
    }
    // check for price
    if (!$scope.itemData.price || $scope.itemData.price < 0) {
      $scope.$broadcast('expenses:productPrice');
      if ($scope.itemData.price < 0) {
        yptNotify.alert({
          title: $filter('translate')('EXP__NEW_ERROR-TITLE'),
          message: $filter('translate')('EXP__NEW_NO_NEGATIVE_NUMBERS-MSG'),
        });
      }
      return;
    }
    // don't allow too big numbers
    if ($scope.itemData.price > 99999) {
      delete $scope.itemData.price;
      $scope.$broadcast('expenses:productPrice');
      yptNotify.alert({
        title: $filter('translate')('EXP__NEW_ERROR-TITLE'),
        message: $filter('translate')('EXP__NEW_NO_TOO_LARGE_NUMBERS-MSG'),
      });
      return;
    }
    // everything is fine, add entry
    $scope.expense.addItem(new ExpenseItem($scope.itemData));

    // Set title of expense to product's name if just one is added
    var defaultTitle = $filter('translate')('EXP__NEW_DEFAULT_EVENT');
    if ($scope.expense.items.length() < 2 && !$scope.editMode) {
      if (!$scope.expense.name || $scope.expense.name === defaultTitle) {
        $scope.expense.name = $scope.itemData.name;
      }
    } else {
      $scope.expense.name = $scope.editMode ?
          $scope.expense.name :
          defaultTitle;
    }

    // Prepare everything for next entry
    delete $scope.tracker.lastInput;
    var saveSelected = [].concat($scope.itemData.sharers);
    $scope.itemData = ExpenseItem.scaffold(userId);
    $scope.preselect(saveSelected);
    $scope.display.proposals = false;
    $scope.display.flatmateSelection = false;
    // $scope.$broadcast('expenses:productName');
  };
  /*
   * Selection of Flatmates
   */
  $scope.toggleSelection = function (flatmate) {
    $scope.$broadcast('expenses:' + $scope.tracker.lastInput);
    var idx = $scope.itemData.sharers.indexOf(flatmate.id);
    if (idx > -1) {
      $scope.itemData.sharers.splice(idx, 1);
    } else {
      $scope.itemData.sharers.push(flatmate.id);
    }
  };
  $scope.preselect = function(ids) {
    $scope.itemData.sharers = [];
    ids.forEach(function(id) {
      $scope.itemData.sharers.push(id);
    });
  };
  $scope.selectedNames = function(sharerIds) {
    return sharerIds.map(function(id) {
      return Wg.flatmates._get(id).firstName;
    });
  };
  /*
   * Interact With Item
   */
  $scope.interactWithItem = function(item) {
    var options = {
      title: 'Eintrag: ' + item.name,
      buttonLabels: [$filter('translate')('EDIT')],
      addDestructiveButtonWithLabel: $filter('translate')('DELETE'),
      androidEnableCancelButton: false,
      addCancelButtonWithLabel: $filter('translate')('BTN_CANCEL'),
    };
    var actions = [function(){
      $scope.deleteItem(item);
    }, function() {
      $scope.editItem(item);
    }];
    yptActionsheet.show(options, actions);
  };
  /*
   * Delete an item
   */
  $scope.deleteItem = function(item) {
    yptNotify.confirm({
      title: $filter('translate')('EXP__DELETE_ITEM-TITLE'),
      message: $filter('translate')('EXP__DELETE_ITEM-MSG'),
      buttonLabels: [
        $filter('translate')('BTN_CANCEL'),
        $filter('translate')('YES'),
      ],
      callback: function(answer) {
        if (answer === true) {
          $scope.expense.removeItem(item);
        }
      },
    });
  };
  /*
   * Edit an item
   */
  $scope.editItem = function(item) {
    if ($scope.itemData.price || $scope.itemData.name) {
      yptNotify.alert({
        title: $filter('translate')('EXP__EDIT_ITEM-TITLE'),
        message: $filter('translate')('EXP__EDIT_ITEM-MSG'),
      })
      return;
    }
    $ionicScrollDelegate.scrollTop();
    $timeout(function() {
      $ionicScrollDelegate.resize();
    }, 50);
    $scope.itemData = ExpenseItem.scaffold(userId);
    $scope.itemData.name = item.name;
    $scope.itemData.price = item.price;
    item.sharers.forEach(function(sharerId) {
      $scope.itemData.sharers.push(sharerId);
    });
    $scope.expense.removeItem(item);
    $scope.$broadcast('expenses:productName');
    // yptKeyboard.show();
  };
  /*
   * Set Title
   */
  $scope.setTitle = function() {
    return yptNotify.prompt({
      message: $filter('translate')('EXP__SET_TITLE-MSG'),
      title: $filter('translate')('EXP__SET_TITLE-TITLE'),
      buttonLabels: [
        $filter('translate')('BTN_CANCEL'),
        $filter('translate')('DONE'),
      ],
      defaultValue: $scope.expense.name,
    })
    .success(function(res) {
      $scope.expense.set({ name: res });
    });
  };

  $scope.modal = $ionicModal.fromTemplate(expensesNewModalView, {
    scope: $scope,
    animation: 'slide-in-up',
  });

  $scope.$on('$destroy', function() {
    $scope.modal.remove();
  });
  $scope.showSummary = function(show) {
    if (show) {
      $scope.modal.show();
      return;
    }
    $scope.modal.hide();
  }

  /*
   * Add Expense to Expenses
   */
  $scope.addExpense = function() {
    // Make sure the right tab is open when user returns to wg.expenses
    localStorage['Flatastic.Expenses.switch'] = 'expenses';

    // remind user to set a proper name for the expense
    $scope.expense.date = new Date($scope.expense.date);

    // Make sure a title is set. Otherwise there will be an error.
    $scope.expense.title = $scope.expense.title ||
        $filter('translate')('EXP__NEW_DEFAULT_EVENT');
    if (!$scope.editMode) {
      $scope.expense.toCollection().create();
      oldSoftBack();
    } else {
      $scope.expense.edit();
      oldSoftBack();
    }
    $scope.showSummary(false);
  };

  $scope.preselect(
    Wg.flatmates._get().map(function(flatmate) {
      return flatmate.id;
    })
  );

}])

.name;
