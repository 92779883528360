import flatasticSettlementEntriesService from './svc_settlementEntries';
import flatasticWgService from '../wg/svc_wg';
import flatasticUserService from '../user/svc_user';


export default angular.module('flatastic.settlementEntries.controller', [
  flatasticSettlementEntriesService,
  flatasticWgService,
  flatasticUserService,
  'ionic',
])

.controller('SettlementEntriesCtrl',
    ['$scope', '$stateParams', 'SettlementEntries', 'Wg', 'User', '$state',
    function ($scope, $stateParams, SettlementEntries, Wg, User, $state) {

  $scope.flatmates = Wg.flatmates;
  $scope.settlements = [];
  $scope.getError = getError;
  $scope.isLoading = isLoading;
  $scope.hideLoadingIndicator = false;
  $scope.user = User.properties;

  $scope.refresh = refresh;
  $scope.loadMore = loadMore;
  $scope.moreCanBeLoaded = SettlementEntries.moreCanBeLoaded;
  $scope.goToDetails = goToDetails;

  init();


  //// Functions


  function init() {
    fetchData();
  }

  function goToDetails({ id }) {
    return $state.go('wg.settlements-entries-details', {
      ...$stateParams,
      expenseId: id,
    });
  }

  function fetchData() {
    return SettlementEntries
      .fetch($stateParams)
      .success(updateData)
  }

  function refresh() {
    $scope.hideLoadingIndicator = true;
    return fetchData()
      .finally(function() {
        $scope.$broadcast('scroll.refreshComplete');
        $scope.hideLoadingIndicator = false;
      });
  }

  function updateData() {
    $scope.settlements = SettlementEntries.get().data;
  }

  function isLoading() {
    return SettlementEntries.get().status;
  }

  function getError() {
    return SettlementEntries.get().error;
  }

  function loadMore() {
    $scope.hideLoadingIndicator = true;
    return SettlementEntries
      .loadMore($stateParams)
      .success(updateData)
      .finally(function() {
        $scope.$broadcast('scroll.infiniteScrollComplete');
        $scope.hideLoadingIndicator = false;
      });
  }
}])

.name;
