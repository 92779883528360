import Config from '../../scripts/config'
import CordovaService from '../../scripts/modules/yptCordova'
import LanguageService from '../../scripts/modules/yptLanguage'
import UserService from '../user/svc_user'
import WgService from '../wg/svc_wg'

export default angular.module('flatastic.auth.signup.controller', [
  UserService,
  WgService,
  CordovaService,
  LanguageService,
  Config,
])

.controller('CreateAccountCtrl',
    ['$scope', 'User', 'Wg', 'yptNotify', '$filter', '$rootScope', 'yptLanguage', 'LocalStorageKeys',
    function ($scope, User, Wg, yptNotify, $filter, $rootScope, yptLanguage, LocalStorageKeys) {

  $scope.credentials = {
    languageId: 1, // English
  };
  $scope.status = {};
  $scope.messages = {};

  // Get user's language
  getLanguageId()
    .then(function(languageId) {
      $scope.credentials.languageId = languageId;
    });

  $scope.register = function() {
    $scope.status.loading = true;
    Wg.setIsSettingUp(true)
    return User.register($scope.credentials)
    .success(function(data) {
      $rootScope.$broadcast('track-event', {
        'category': 'Create WG',
        'action': 'Created WG',
        'label': 'Success',
      });
      delete $scope.messages.error;
      delete $scope.credentials.password;
      delete $scope.status.loading;
      delete $scope.status.error;
      Wg.set(data.wg).storeLocally();
      Wg.get();
    })
    .error(function(data) {
      Wg.setIsSettingUp(false)
      $scope.status.error = data;
      delete $scope.status.loading;
      var temp = [];
      if (data) {
        for (var key in data) {
          temp.push(data[key]);
        }
      }
      $rootScope.$broadcast('track-event', {
        'category': 'Create WG',
        'action': 'Created WG',
        'label': data ? temp.join('\n') : 'Error',
      });
      yptNotify.alert({
        title: $filter('translate')('ATH__FOUND_WG'),
        message: $filter('translate')('ATH__FOUND_WG_ERROR', {
          error: data ?
              temp.join('\n') :
              $filter('translate')('NO_INTERNET'),
        }),
      });
    });
  };


  //// Functions

  function getLanguageId() {
    return yptLanguage.get()
    .then(function(language) {
      return ({
        'en': 1,
        'de': 2,
      })[language] || 1;
    })
  }

}])

.name;
