import { mapObjIndexed } from 'ramda'

const meta = ['en', 'de']
const trans = {
  [`New Home`]: [
    `New Home`,
    `Haushaltstyp`,
  ],
  [`What kind of home do you want to set up?`]: [
    `What kind of home do you want to set up?`,
    `Was beschreibt am besten deinen Haushalt?`,
  ],
  [`This will help us get everything up and running properly.`]: [
    `This will help us get everything up and running properly.`,
    `So können wir Flatastic für deinen Haushalt optimieren.`,
  ],
  [`Shared Flat`]: [
    `Shared Flat`,
    `WG`,
  ],
  [`Family`]: [
    `Family`,
    `Familie`,
  ],
  [`Couple`]: [
    `Couple`,
    `Pärchen`,
  ],
  [`Single`]: [
    `Single`,
    `Single`,
  ],
  [`Continue`]: [
    `Continue`,
    `Weiter`,
  ],
}

export default {
  en: mapObjIndexed((val, key, obj) => val[0])(trans),
  de: mapObjIndexed((val, key, obj) => val[1])(trans),
}
