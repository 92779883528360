export default angular.module('flatastic.email-validator', [])

.directive('validateEmail', [function() {
  var pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+(?:[A-Z]{2,})\b/i;
  return {
    require: 'ngModel',
    link: function($scope, $attr, $elem, ngModel) {
      ngModel.$validators.customValidator = function(email) {
        return ((email || '').match(pattern) !== null);
      }
    },
  }
}])

.directive('validateEmailAsync', ['$http', function($http) {
  var endpoint = 'https://vsgvhv6fd3.execute-api.eu-west-1.amazonaws.com/prod/validators/email';
  return {
    require: 'ngModel',
    link: function($scope, $attr, $elem, ngModel) {
      ngModel.$asyncValidators.asyncValidator = function(email) {
        return $http.get(endpoint, { params: { email: email } });
      }
    },
  }
}])

.name;
