import yepptCordova from './yptCordova'

export default angular.module('yeppt.socialsharing', [
  yepptCordova,
])

.run(['yptCordova', 'yptSocialsharing',
    function(yptCordova, yptSocialsharing) {
  yptCordova.ready.then( function() {
    yptSocialsharing.init();
  });
}])

.service('yptSocialsharing',
    ['$q', '$rootScope', '$window',
    function($q, $rootScope, $window) {

  return {
    init,
    share,
  };

  ////

  function init() {
    if (!$window.plugins || !$window.plugins.socialsharing) {
      console.warn('The Social Sharing plugin is not installed.');
      return;
    }
  };

  /*
   * share returns a .success-.error-promise
   * obj: {
   *   message: 'bla',
   *   subject: 'bla',
   *   fileOrFileArray: 'http://www.google.com/image.gif' // local or web)
   *   url: '…' // link to share
   *   files, // An array of filenames either locally or remotely
   * }
   */
  function share(obj) {

    var socialSharing;

    if ($window.plugins && $window.plugins.socialsharing) {
      socialSharing = $window.plugins.socialsharing;
    }

    // General stuff
    var deferred = $q.defer();
    var promise = deferred.promise;
    var onSuccess = function(data) {
      deferred.resolve(data);
    };
    var onError = function(data) {
      deferred.reject(data);
    };
    promise.success = function(fn) {
      promise.then(fn);
      return promise;
    };
    promise.error = function(fn) {
      promise.then(null, fn);
      return promise;
    };

    if (obj.trackEvent) {
      $rootScope.$broadcast('track-event', obj.trackEvent);
      delete obj.trackEvent
    }

    if (!socialSharing) {
      console.info('The Socialsharing plugin is not installed.');
      // need fallback
      var body = obj.message + (obj.url ? '\n\n' + obj.url : '');
      $window.location.href = 'mailto:' +
                              '?subject=' + encodeURIComponent(obj.subject) +
                              '&body='    + encodeURIComponent(body);
      deferred.reject('The Socialsharing plugin is not installed.');
      return promise;
    }

    // message, subject, fileOrFileArray, url, successCallback, errorCallback
    socialSharing.shareWithOptions(
      obj,
      onSuccess,
      onError
    );

    return promise;
  };
}])

.name;
