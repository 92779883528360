import translationsModule from 'angular-translate'
import angularMoment from 'angular-moment'

 export default angular.module('yeppt.language', [
  angularMoment,
  translationsModule,
])

.factory('yptLanguage', ['$translate', '$q', '$window', 'amMoment',
      function($translate, $q, $window, amMoment) {

  var config = {};

  var momentLanguages = {
    'en': 'en-gb',
    'de': 'de',
  };

  return {
    init: init,
    get: getLanguage,
    set: setLanguage,
    delete: deleteLanguage,
  };

  /////////////////

  function getLanguage(preferredLanguageString) {
    var deferred = $q.defer();
    var nav = $window.navigator;

    if (preferredLanguageString) {
      // A preferred language was given
      deferred.resolve(preferredLanguageString);
    } else if ($window.localStorage[config.storageKey]) {
      // A language is saved in local storage
      deferred.resolve($window.localStorage[config.storageKey]);
    } else if (!nav && !(nav.globalization || nav.language)) {
      // we need either globalization or browser language to guess  language
      deferred.reject('Language cannot be guessed because neither globalization' +
        'nor language is available.');
    } else if (nav.globalization) {
      // get language via globalization plugin
      nav.globalization.getPreferredLanguage(function(res) {
        deferred.resolve(extractTranslateLanguage(res));
      }, function(err) {
        deferred.reject(res);
      });
    } else if (nav.language) {
      // get language via browser language
      deferred.resolve(extractTranslateLanguage({ value: nav.language }));
    } else {
      // fallback language is english (should never happen)
      deferred.resolve(extractTranslateLanguage({ value: 'en-UK' }));
    }

    return deferred.promise;

    function extractTranslateLanguage(languageObj) {
      return languageObj.value.split('-')[0];
    }
  }

  // init (if language is known initalize with correct language)
  function init(preferredLanguage, storageKey) {
    config.storageKey = storageKey;
    return setLanguage(preferredLanguage, config.storageKey);
  }

  function changeLanguageTo(language) {
    if (!language) {
      console.error('You need to specify a language');
      return;
    }
    if (config.storageKey) {
      $window.localStorage[config.storageKey] = language;
    }
    $translate.use(language);

    if (momentLanguages[language]) {
      amMoment.changeLocale(momentLanguages[language]);
    }
  }

  function setLanguage(preferredLanguage) {
    return getLanguage(preferredLanguage)
    .then(function(language) {
      changeLanguageTo(language);
    })
    .catch(function(error) {
      console.error('yptLanguage.init: There was an error: ', error);
    });
  }

  function deleteLanguage() {
    delete $window.localStorage['Flatastic.userLanguage'];
  }

}])
.name;
