import flatasticShoppinglistController from './ctrl_shoppinglist'
import ShoppinglistFilters from './filter_shoppinglist'
import flatasticShoppinglistService from './svc_shoppinglist'
//templates
import template from './view_shoppinglist.html'


export default angular.module('flatastic.shoppinglist', [
  flatasticShoppinglistService,
  flatasticShoppinglistController,
  ShoppinglistFilters,
])
.config(
    ['$stateProvider',
    function ($stateProvider) {

  $stateProvider
  .state('wg.shoppinglist', {
    url: '/shoppinglist',
    views: {
      'menuContent': {
        controller: 'ShoppinglistCtrl',
        template,
      },
    },
  });
}])

.name;
