/**
 * Copyright 2014 yeppt UG (haftungsbeschränkt). All Rights Reserved.
 * Authors: Clemens Bachmair (clemens@yeppt.com),
 *          Moritz von Hase (moritz@yeppt.com)
 * Wrapper for: https://github.com/edorex/cordova-plugin-datepicker
 */

export default angular.module('yeppt.datepicker', [])


/*
 * Usage:
 *
 * options: {
 *   mode: <'date'|'time'|'dateTime'> // Default: date (datetime > Android 4.4)
 *   date: new Date(), // !this will be overwritten after datePicker.show()
 *   minDate: <new Date() | ''>, // Default: empty String
 *                               // If set: Labels don't work
 *   maxDate: <new Date() | ''>, // Default: empty String
 *                               // If set: Labels don't work
 *   allowOldDates: <boolean>, // Default: true, iOS only
 *   allowFutureDates: <boolean>, // Default: true, iOS only
 *   doneButtonLabel: <String>, // Default: 'Done'
 *   doneButtonColor: <String (hex color)>, // Only iOS, Default: '#000FF'
 *   cancelButtonLabel: <String>, // Default: 'Done'
 *   cancelButtonColor: <String (hex color)>, // Only iOS, Default: '#000FF'
 *   clearButtonLabel: <String>, // Default: 'Done'
 *   clearButtonColor: <String (hex color)>, // Only iOS, Default: '#000FF'
 *   x: <String>, // iPad only, X position of date picker.The position is
 *                   absolute to the root view of the application.
 *   y: <String>, // iPad only, Y position of date picker.The position is
 *                   absolute to the root view of the application.
 * }
 */
.service('yptDatepicker',
    ['$timeout', '$window', '$filter',
    function($timeout, $window, $filter) {

  this.init = function() {
    if (!$window.datePicker) {
      console.error('The Datepicker Plugin is not installed.');
      return;
    }
  };

  this.show = function(options, onSuccess, onError) {
    if (!$window.datePicker) {
      console.error('The Datepicker Plugin is not installed.');
      return;
    }

    var defaultOptions = {
      date: new Date(),
      androidTheme: $window.datePicker.ANDROID_THEMES.THEME_DEVICE_DEFAULT_LIGHT,
      is24Hour: true,
      todayText: $filter('translate')('TODAY'),
      cancelText: $filter('translate')('BTN_CANCEL'),
      cancelButtonLabel: $filter('translate')('BTN_CANCEL'),
      okText: $filter('translate')('OK'),
      doneButtonLabel: $filter('translate')('OK'),
      mode: 'date',
      locale: $window.navigator && $window.navigator.language,
    };

    var options = angular.extend(defaultOptions, options);
    datePicker.show(options, function(res) {
      $timeout(function() {
        onSuccess && onSuccess(res);
      }, 0);
    }, function(error) {
      $timeout(function() {
        onError && onError(error);
      }, 0);
    });
  };
}])

.name;
