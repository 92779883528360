import flatasticCustomUrl from '../scripts/modules/customUrlScheme';

export default angular.module('flatastic.customUrl.handler', [
  flatasticCustomUrl,
  'ionic',
])

.run(['$rootScope', '$state', function($rootScope, $state) {

  var regex = {
    screen: /^flatastic:\/\/screen\/(.*)/,
  };

  $rootScope.$on('customURL', function(event, url) {
    var screen = regex.screen.exec(url);
    if (screen) {
      $state.go(screen[1]);
    }
  });

}])

.name;
