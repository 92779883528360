
export default angular.module('flatastic.chores.service.effort-values', [])

.service('EffortValues', ['$filter', function($filter) {
  var values = [{
    label: $filter('translate')('CHO__FORM-EFFORT__NORMAL')
        + ' (1 ' + $filter('translate')('POINT') + ')',
    value: 1,
  }, {
    label: $filter('translate')('CHO__FORM-EFFORT__BIG')
        + ' (2 ' + $filter('translate')('POINTS') + ')',
    value: 2,
  }, {
    label: $filter('translate')('CHO__FORM-EFFORT__HUGE')
        + ' (4 ' + $filter('translate')('POINTS') + ')',
    value: 4,
  }];

  this.values = values;
  this.getLabelFor = function(value) {
    for (var i = 0; i < values.length; i++) {
      var elem = values[i];
      if (value === elem.value) {
        return elem.label;
      }
    }
    return value;
  };

}])

.name;
