import ApiService from '../../scripts/modules/api'
import CollectionService from '../../scripts/modules/collection'
import ShoutService from './svc_shout'

export default angular.module('flatastic.shouts.service.shouts', [
  ApiService,
  CollectionService,
  ShoutService,
])

.run(['Shouts', '$rootScope', '$timeout', 'User', 'FlatasticEvents',
    function(Shouts, $rootScope, $timeout, User, FlatasticEvents) {

  // Only load data when user is in an wg
  if (User.isLoggedIn() && User.properties.groupId == 2) {
    Shouts.getFromServer();
  }

  [
    FlatasticEvents.shouts.update,
    FlatasticEvents.user.didEnterWg,
  ].forEach(function(event) {
    $rootScope.$on(event, function() {
      Shouts.getFromServer();
    });
  })
  $rootScope.$on(FlatasticEvents.user.didLogout, function() {
    Shouts.clear();
  });
  $rootScope.$on('Shout:deleted', function(event, shoutId) {
    console.debug('deleting shout id', shoutId);
    $timeout(function() {
      Shouts.delete(shoutId);
      Shouts.updateShoutsWithDividers();
    });
  });
  $rootScope.$on('Shout:create', function(event, newShout) {
    console.debug('adding shout to shouts', newShout);
    Shouts.push(newShout, true);
    Shouts.updateShoutsWithDividers();
  });
}])

.factory('Shouts',
    ['Api', 'YepptCollection', 'Shout',
    function (Api, YepptCollection, Shout) {

  var shouts = new YepptCollection();

  var myStatus        = '';
  var limitGet        = 15;
  var moreCanBeLoaded = false;

  var getFromServer = function(limit) {
    myStatus = 'loading';
    var params = {
      limit: limit || limitGet,
      type: 'shout',
    };
    return Api.get('/shouts', { params })
      .then(function({ data }) {
        var temp = [];
        data.forEach(function(shout) {
          temp.push(new Shout(shout));
        });
        shouts.remove();
        shouts.push(temp);
        moreCanBeLoaded = (data.length >= limitGet);
        updateShoutsWithDividers();
      })
      .finally(function() {
        myStatus = '';
      });
  };

  var loadMore = function(obj) {
    obj = obj || {};
    var length = shouts.filter(function(item) {
      return item.shoutType !== 'divider';
    }).length;
    var params = {
      limit: obj.limit  || limitGet,
      offset: obj.offset || length,
      type: 'shout',
    };
    return Api.get('/shouts', { params })
      .then(function({ data }) {
        var temp = [];
        data.forEach(function(shout) {
          temp.push(new Shout(shout));
        });
        shouts.push(temp);
        moreCanBeLoaded = (data.length >= limitGet);
        updateShoutsWithDividers();
      });
  };

  var clear = function() {
    shouts.remove();
  };

  var updateShoutsWithDividers = function() {

    var i;
    var shout;
    var currentDate;
    var lastDate;
    var divider;

    // Remove all dividers
    shouts.removeWhere({ shoutType: 'divider' });

    // Add dividers
    for (i = 0; i < shouts.length(); i++) {
      shout = shouts.get()[i];
      currentDate = shout.insertDate.toDateString();
      if (currentDate !== lastDate) {
        lastDate = currentDate;
        divider = new Shout({
          shoutType: 'divider',
          insertDate: shout.insertDate,
        });
        shouts.insert(i, divider);
        i++;
      }
    }
  };

  return {
    length: function() {
      return shouts.length();
    },
    get: function(id) {
      return shouts.get(id);
    },
    push: function(shoutObj, unshift) {
      return shouts.push(shoutObj, unshift);
    },
    delete: function(id) {
      return shouts.remove(id);
    },
    getFromServer,
    status: function() {
      return myStatus
    },
    loadMore,
    moreCanBeLoaded: function() {
      return moreCanBeLoaded;
    },
    updateShoutsWithDividers,
    clear,
  };

}])

.name;
