import UserService from '../user/svc_user'
import WgService from '../wg/svc_wg'
import CordovaModule from '../../scripts/modules/yptCordova'
import FlatasticConfig from '../../scripts/config'
import LanguageModule from '../../scripts/modules/yptLanguage'

export default angular.module('flatastic.auth.movein.controller', [
  UserService,
  WgService,
  CordovaModule,
  FlatasticConfig,
  LanguageModule,
])

.controller('MoveInCtrl',
    ['$scope', 'User', '$stateParams', 'Wg', 'yptNotify',
    '$filter', '$rootScope', 'yptLanguage', 'FlatasticEvents',
    function ($scope, User, $stateParams, Wg, yptNotify,
    $filter, $rootScope, yptLanguage, FlatasticEvents) {

  $scope.credentials = {
    wgCode: $stateParams.code,
    languageId: 1, // English as default language
  };
  $scope.status = {};
  $scope.messages = {};

  // Set correct user's language
  getLanguageId()
    .then(function(languageId) {
      $scope.credentials.languageId = languageId;
    });

  $scope.register = function() {
    $scope.status.loading = true;
    User.register($scope.credentials)
    .success(function(data) {
      $rootScope.$broadcast('track-event', {
        category: 'Join WG',
        action: 'Posted registration',
        label: 'Success',
      });
      // body
      delete $scope.messages.error;
      delete $scope.status.loading;
      delete $scope.status.error;
      Wg.set(data.wg).storeLocally();
      Wg.get();
      $rootScope.$broadcast(FlatasticEvents.user.didEnterWg);
    })
    .error(function(data) {
      $scope.status.error = data;
      delete $scope.status.loading;
      delete $scope.credentials.password;
      var temp = [];
      if (data) {
        for (var key in data) {
          temp.push(data[key]);
        }
      }
      yptNotify.alert({
        title: $filter('translate')('ATH__REGISTER_ERROR-TITLE'),
        message: $filter('translate')('ATH__REGISTER_ERROR-MSG', {
          error: data ?
              temp.join('\n') :
              $filter('translate')('NO_INTERNET'),
        }),
      });
      $rootScope.$broadcast('track-event', {
        category: 'Join WG',
        action: 'Posted registration',
        label: data ? temp.join('\n') : 'Error',
      });
    });
  };

  //// Functions

  function getLanguageId() {
    return yptLanguage.get()
    .then(function(language) {
      return ({
        'en': 1,
        'de': 2,
      })[language] || 1;
    })
  }

}])

.name;
