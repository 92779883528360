import appReviewService from './appReview.svc'

export default angular

.module('flatastic.app-review', [
  appReviewService,
])

.config(['$stateProvider', function ($stateProvider) {

  $stateProvider
    .state('wg.app-review-rate', {
      url: '/app-review/rate',
      resolve: {
        appReview: ['AppReview', function(AppReview) {
          return AppReview.rate().then(() => Promise.reject())
        }],
      },
    })
    .state('wg.app-review-launch', {
      url: '/app-review/launch',
      resolve: {
        appReview: ['AppReview', function(AppReview) {
          return AppReview.launch().then(() => Promise.reject())
        }],
      },
    })

}])

.name;
