import flatasticSettlementsService from './svc_settlements';
import flatasticWgService from '../wg/svc_wg';

export default angular.module('flatastic.settlements.controller', [
  flatasticSettlementsService,
  flatasticWgService,
  'ionic',
])

.controller('SettlementsCtrl',
    ['$scope', 'Settlements', 'Wg', '$state',
    function ($scope, Settlements, Wg, $state) {

  $scope.flatmates = Wg.flatmates;
  $scope.settlements = [];
  $scope.getError = getError;
  $scope.isLoading = isLoading;

  $scope.refresh = refresh;
  $scope.loadMore = loadMore;
  $scope.moreCanBeLoaded = Settlements.moreCanBeLoaded;
  $scope.goToEntries = goToEntry;

  $scope.toggleShow = function(item) {
    item.show = !item.show;
  };


  $scope.calcWidth = function(value, maxWidth) {
    return (value >= 0 ? 100 : -100) * (value / maxWidth);
  }

  init();


  //// Functions


  function init() {
    return fetchData();
  }

  function loadMore() {
    return Settlements.loadMore()
      .success(updateData)
      .finally(function() {
        $scope.$broadcast('scroll.infiniteScrollComplete');
      });
  }

  function goToEntry(settlementId) {
    return $state.go('wg.settlements-entries', { settlementId });
  }

  function fetchData() {
    return Settlements
      .fetch()
      .success(updateData)
  }

  function refresh() {
    $scope.showLoadingIndicator = true;
    return fetchData()
      .finally(function() {
        $scope.$broadcast('scroll.refreshComplete');
        $scope.showLoadingIndicator = false;
      });
  }

  function updateData() {
    $scope.settlements = Settlements.get().data;
  }

  function isLoading() {
    return Settlements.get().status;
  }

  function getError() {
    return Settlements.get().error;
  }
}])

.name;
