import template from './part_shout.html';

export default angular.module('flatastic.shouts.directive.shout', [])

.directive('shout', [function () {
  return {
    restrict: 'E',
    template,
    link: function (scope, iElement, iAttrs) {
    },
  };
}])

.name
