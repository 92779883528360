import AngularTranslate from 'angular-translate'
import ActionsheetModule from '../../scripts/modules/yptActionsheet'
import CameraModule from '../../scripts/modules/yptCamera'
import UserService from '../user/svc_user'

export default angular.module('flatastic.intro.household-photo.controller', [
  AngularTranslate,
  UserService,
  CameraModule,
  ActionsheetModule,
])
.controller('HouseholdPhotoController', HouseholdPhotoController)
.name;

HouseholdPhotoController.$inject = ['$translate', 'yptCamera', '$scope', 'Wg', 'yptNotify', 'yptActionsheet']
function HouseholdPhotoController($translate, yptCamera, $scope, Wg, yptNotify, yptActionsheet) {
  const vm = this
  vm.isCordovaDevice = isCordovaDevice
  vm.state = {
    isBusy: false,
    error: null,
    file: undefined,
    household: Wg,
    didUploadPicture: false,
  }
  vm.uploadPhoto = uploadCordovaPhoto

  $scope.$watch(() => vm.state.file, function(newValue, oldValue) {
    if (Wg.imageUploading ||
        newValue === oldValue ||
        !vm.state.file) {
      return;
    }
    uploadPicture(vm.state.file, true)
  });

  //// Functions

  function uploadCordovaPhoto() {
    if (!isCordovaDevice()) {
      console.debug('updateProfilePicture: Not a cordova device. Skipping.')
      return
    }
    return selectPictureSource()
      .then(getPicture)
      .then(uploadPicture)
  }

  function isCordovaDevice() {
    return window && window.device
  }

  function selectPictureSource() {
    const options = {
      title: $translate.instant('USR__IMAGE_DIALOGUE-TITLE'),
      buttonLabels: [
        $translate.instant('USR__IMAGE_DIALOGUE-FROM_GALLERY'),
        $translate.instant('USR__IMAGE_DIALOGUE-TAKE_PHOTO'),
      ],
      androidEnableCancelButton: false,
      addCancelButtonWithLabel: $translate.instant('BTN_CANCEL'),
    }
    const imageSourceType = {
      gallery: 0,
      photo: 1,
    }
    return new Promise((resolve, reject) => {
      const actions = [
        function() { resolve(imageSourceType.gallery) },
        function() { resolve(imageSourceType.photo) },
      ]
      yptActionsheet.show(options, actions)
    })
  }

  function uploadPicture(fileURL, isBrowserUpload) {
    vm.state.isBusy = true
    return Wg.uploadPhoto(fileURL, isBrowserUpload)
      .then(wg => {
        vm.state.didUploadPicture = true
      })
      .catch(function(data) {
        yptNotify.alert({
          title: $translate.instant('USR__IMAGE_ERROR_DIALOGUE-TITLE'),
          message: $translate.instant('USR__IMAGE_ERROR_DIALOGUE-MSG', {
            error: JSON.stringify(data),
          }),
        });
      })
      .finally(() => {
        vm.state.isBusy = false;
      })
  }

  function getPicture(sourceType) {
    return yptCamera.takePicture({
      sourceType,
      cameraDirection: 1,
      targetWidth: 1000,
      targetHeight: 1000,
    })
  }

}
